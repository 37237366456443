import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import SubmitButton from "../form/SubmitButton";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      isError: false,
      isSubmitButtonLoading: false,
    };
  }
  addCard = async (paylaod, stripe) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CARD.service,
      ApiRoutes.ADD_CARD.url,
      ApiRoutes.ADD_CARD.method,
      ApiRoutes.ADD_CARD.authenticate,
      undefined,
      paylaod
    );

    this.setState({ isAdding: false });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({ isAdding: false });
    } else {
      toast.success(response.data.message);
      this.props.fetchCards();
      setTimeout(() => {
        this.changeSubscription();
      }, 2000);
    }
  };
  changeSubscription = () => {
    this.props.changeSubscription(this.props.stripe);
  };
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    this.setState({
      isError: false,
      isAdding: true,
    });

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement("card");
    const result = await stripe.createToken(cardElement);

    if (result.error) {
      // toast.error(result.error.message);
      this.setState({
        isError: result.error.message,
        isAdding: false,
      });
    } else {
      let token = { stripeToken: result.token.id };
      if (result?.token?.card?.id) {
        this.props.handlePrimaryCard(result?.token?.card?.id);
      }
      this.addCard(token, stripe);
      cardElement.clear();
    }
  };

  render() {
    const { isAdding, isError } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        {this.props.showMethod ? (
          <>
            <CardElement />

            <div className="text-danger mt-4">{isError}</div>

            <div className="justify-content-between d-block modal-footer">
              <SubmitButton
                className={"customSubmitBtn mt-4 w-auto"}
                text={`${
                  this.props.planAmount?.status === "change"
                    ? "Change"
                    : "Upgrade"
                } Now`}
                disabled={!this.props.stripe || isAdding}
                isLoading={isAdding}
              />
            </div>
          </>
        ) : (
          <div className="justify-content-between d-block modal-footer">
            <button
              type="submit"
              onClick={this.changeSubscription}
              className="logout-btn"
              disabled={this.props.update}
            >
              {this.props.update ? (
                <>
                  <i className="fa fa-spinner fa-spin"></i>
                  {this.props.planAmount?.status === "change"
                    ? "Changing"
                    : "Upgrading"}
                </>
              ) : (
                `${
                  this.props.planAmount?.status === "change"
                    ? "Change"
                    : "Upgrade"
                } Now`
              )}
            </button>
          </div>
        )}
      </form>
    );
  }
}

export default function CardStripeForm({
  fetchCards,
  changeSubscription,
  handlePrimaryCard,
  userCards,
  showMethod,
  update,
  planAmount,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          update={update}
          showMethod={showMethod}
          fetchCards={fetchCards}
          changeSubscription={changeSubscription}
          handlePrimaryCard={handlePrimaryCard}
          stripe={stripe}
          elements={elements}
          userCards={userCards}
          planAmount={planAmount}
        />
      )}
    </ElementsConsumer>
  );
}
