import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import settingClose from "../../../assets/img/settingClose.svg";
import changeImage from "../../../assets/img/change-image.svg";
import edit from "../../../assets/img/imageEditor.svg";
import accountImg from "../../../assets/img/AccountSetting.svg";
import arrow from "../../../assets/img/linkArrow.svg";
import subscription from "../../../assets/img/subscription.svg";
import manageStaff from "../../../assets/img/manageStaff.svg";
import { Link } from "react-router-dom";
import avtaruser from "../../../assets/img/avtar.jfif";
import { ApiRoutes, AppConfig, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";

export default function SettingPopup({
  showSetting,
  closeSetting,
  profileDetail,
  onFileChange,
  isUploading,
}) {
  const moduleName = [
    {
      image: accountImg,
      label: "Manage Account",
      link: AppRoutes.PROFILE,
    },
    {
      image: subscription,
      label: "Your Subscription",
      link: AppRoutes.Create_Plan,
    },
    {
      image: manageStaff,
      label: "Manage Staff",
      link: AppRoutes.MANAGE_STAFF_LIST,
    },
  ];

  // UPDATE_CLIENT_INFO
  return (
    <>
      <Modal show={showSetting} onHide={closeSetting} className="settingModal">
        {/* <Modal.Header></Modal.Header> */}
        <Modal.Body
          style={{
            backgroundImage: profileDetail?.user?.backgroundImage
              ? `url(${AppConfig.FILES_ENDPOINT}${profileDetail?.user?.backgroundImage})`
              : "linear-gradient(180deg, #00ACB9 23.76%, rgba(17, 228, 244, 0.37) 116.51%)",
          }}
        >
          <div className="settingModal_header">
            <span className="">
              <label className="mb-0 coverHolder">
                {isUploading ? (
                  <>
                    <i className="fa fa-spinner fa-spin test-spin"></i>
                    Change Cover
                  </>
                ) : (
                  <>
                    <img src={changeImage} alt="" />
                    Change Cover
                  </>
                )}

                <input
                  name="backgroundImage"
                  type="file"
                  onChange={onFileChange}
                  accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                  hidden
                />
              </label>
            </span>
            <span onClick={closeSetting} className="closeSetting">
              <img src={settingClose} />
            </span>
          </div>
          <div className="image-holder">
            <div className="user-image">
              <img
                className="img"
                src={
                  profileDetail?.user?.profile_picture
                    ? profileDetail?.user?.profile_picture.startsWith(
                        "https://"
                      )
                      ? profileDetail?.user?.profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profileDetail?.user?.profile_picture}`
                    : avtaruser
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avtaruser;
                }}
                alt="user"
              />
              <span className="imageEdit">
                <label className="">
                  <img src={edit} alt="" />
                  <input
                    name="profile_picture"
                    type="file"
                    onChange={onFileChange}
                    accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                    hidden
                  />
                </label>
              </span>
            </div>
          </div>
          <div className="companyName">
            <h5>
              {profileDetail?.companyDetails?.companyName}{" "}
              <span className="heading-selector"></span>{" "}
              {profileDetail?.user?.clientId}
            </h5>
          </div>
          <div className="moduleHolder">
            {moduleName &&
              moduleName.map((info, index) => (
                <>
                  <Link
                    className="moduleLink"
                    to={info.link}
                    onClick={closeSetting}
                    key={index}
                  >
                    <div className="moduleName">
                      <img src={info.image} alt="" />
                      <p>{info.label} </p>
                      <img src={arrow} className="arrow" alt="" />
                    </div>
                  </Link>
                </>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
