/** @format */
import React from "react";
import "./batches.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import calendarIcon3 from "../../../assets/img/calenderIcon2.svg";
import exportReport from "../../../assets/img/exportReport.svg";
import moment from "moment";

export const ReportHeader = ({
  selectedTime,
  dateRangeCallback,
  changeSelectedTime,
  onExport,
  customStartDate,
  customEndDate,
  isExporting,
}) => {
  return (
    <div className="col-md-12">
      <div className="exportRow">
        <div className="d-flex align-items-center flex-column flex-md-row">
          <div className="button_grp mb-3 mb-md-0">
            <button
              className={selectedTime === "Today" ? "active" : ""}
              onClick={() => changeSelectedTime("Today")}
            >
              Today
            </button>
            <button
              className={selectedTime === "Yesterday" ? "active" : ""}
              onClick={() => changeSelectedTime("Yesterday")}
            >
              Yesterday
            </button>
            <button
              className={selectedTime === "Week" ? "active" : ""}
              onClick={() => changeSelectedTime("Week")}
            >
              Week
            </button>
            <button
              className={selectedTime === "Month" ? "active" : ""}
              onClick={() => changeSelectedTime("Month")}
            >
              Month
            </button>
            <button
              className={selectedTime === "Year" ? "active" : ""}
              onClick={() => changeSelectedTime("Year")}
            >
              Year
            </button>
          </div>
          <div className="datePickerBox position-relative">
            <DateRangePicker
              initialSettings={{ maxDate: new Date() }}
              onCallback={dateRangeCallback}
              autoUpdateInput={false}
            >
              <input
                readOnly={true}
                type="text"
                className="form-control"
                value={
                  selectedTime === "Custom"
                    ? moment(customStartDate).format("L") +
                      " - " +
                      moment(customEndDate).format("L")
                    : "Select date"
                }
              />
            </DateRangePicker>
            <img src={calendarIcon3} className="calendarIcon" alt="" />
          </div>
        </div>
        <button
          className="addProductbtn"
          onClick={onExport}
          style={{ minWidth: "105px" }}
        >
          {isExporting ? (
            <>
              <i className="fa fa-spinner fa-spin test-spin mr-1"></i>
              Exporting
            </>
          ) : (
            <>
              <img className="mr-1" src={exportReport} /> Export
            </>
          )}
        </button>
      </div>
    </div>
  );
};
