import Validator from 'js-object-validation';
import { message } from '../common';

export const userValidator = data => {
  const validations = {
    first_name: {
      required: true,
      alpha: true,
      space: true,
    },
    email: {
      required: true,
      email: true,
    },
    phone: {
      required: true,
      numeric: true,
    },
    last_name: {
      space: true,
    },
    other_info: {
      address1: {
        space: true,
      },
      address2: {
        space: true,
      },
      postCode: {
        space: true,
        numeric: true,
        minlength: 4,
        maxlength: 6,
      }
    }
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(':item', 'first name'),
      alpha: message.InvalidName.replace(':item', 'first name'),
      space: message.SpaceNotAllowed.replace(':item', 'First name'),
    },
    email: {
      required: message.Required.replace(':item', 'email address'),
      email: message.InvalidEmail,
      space: message.SpaceNotAllowed.replace(':item', 'Email'),
    },
    phone: {
      required: message.Required.replace(':item', 'phone number'),
      numeric: message.InvalidPhone,
      space: message.SpaceNotAllowed.replace(':item', 'Phone'),
    },
    other_info: {
      postCode: {
        space: message.SpaceNotAllowed.replace(':item', 'Post code'),
        minlength: message.postCodeLength.replace(':item', 'Post code'),
        maxlength: message.postCodeLength.replace(':item', 'Post code'),
      },
      address2: {
        space: message.SpaceNotAllowed.replace(':item', 'Address line 2'),
      },
      address1: {
        space: message.SpaceNotAllowed.replace(':item', 'Address line 1'),
      }
    },
    last_name: {
      space: message.SpaceNotAllowed.replace(':item', 'Last name'),
    }
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
