import Validator from "js-object-validation";
import { message } from "../common";

export const registerValidator = (data) => {
  const validations = {
    // email: {
    //   required: true,
    //   email: true,
    // },
    // companyName: {
    //   required: true,
    // },
    // website: {
    //   required: true,
    // },
    // businessRegNo: {
    //   required: true,
    // },
    // VATNumber: {
    //   required: true,
    // },
    // address1: {
    //   required: true,
    // },
    // address2: {
    //   required: true,
    // },
    // businessRegCertificateNo: {
    //   required: true,
    // },
    // birth_date: {
    //   required: true,
    // },
    // TradeMarkCertificateNo: {
    //   require: true,
    // },
    countryCode: {
      require: true,
    },
  };
  // Error messages
  const messages = {
    // email: {
    //   required: message.Required.replace(":item", "email address"),
    //   email: message.InvalidEmail,
    // },
    // companyName: {
    //   required: message.Required.replace(":item", "company name"),
    // },
    // website: {
    //   required: message.Required.replace(":item", "website name"),
    // },
    // businessRegNo: {
    //   required: message.Required.replace(
    //     ":item",
    //     "Business Registration Number"
    //   ),
    // },
    // VATNumber: {
    //   required: message.Required.replace(":item", "VAT Number"),
    // },
    // address1: {
    //   required: message.Required.replace(":item", "Address Line 1"),
    // },
    // address2: {
    //   required: message.Required.replace(":item", "Address Line 2"),
    // },
    // businessRegCertificateNo: {
    //   required: message.Required.replace(
    //     ":item",
    //     "Business Registration Certificate Number"
    //   ),
    // },
    // birth_date: {
    //   required: message.Required.replace(":item", "Birth Date"),
    // },
    countryCode: {
      required: message.Required.replace(":item", "country Code"),
    },
    // TradeMarkCertificateNo: {
    //   required: message.Required.replace(
    //     ":item",
    //     "Trade Mark Certificate Number"
    //   ),
    // },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
