import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import SubmitButton from "../form/SubmitButton";

export default function StatusModal({
  showStatus,
  closeStatus,
  clientData,
  getSubscription,
}) {
  const [status, setStatus] = useState(clientData && clientData.status);
  const [statusEdit, setStatusEdit] = useState(clientData && clientData.status);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (clientData && clientData.status) {
      setStatus(clientData.status);
    }
  }, [clientData]);
  const changeStatus = async (data) => {
    setUpdate(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CLIENT_SUBSCRIPTION_STATUS.service,
      ApiRoutes.CLIENT_SUBSCRIPTION_STATUS.url.replace(":id", clientData._id),
      ApiRoutes.CLIENT_SUBSCRIPTION_STATUS.method,
      ApiRoutes.CLIENT_SUBSCRIPTION_STATUS.authenticate,
      undefined,
      data
    );
    setUpdate(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      closeStatus();
      getSubscription();
    }
  };

  const handleSubmit = async (event) => {
    event && event.preventDefault();

    let data = {
      status: statusEdit,
    };
    changeStatus(data);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);

    setStatusEdit(value);
  };

  return (
    <Modal show={showStatus} onHide={closeStatus} className="statusModal">
      <Modal.Header>
        <h5>Subscription Status</h5>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} className="statusList">
          <ul>
            <li>
              <label className="chechboxLabel">
                <input
                  name="status"
                  onChange={handleChange}
                  checked={statusEdit === "Cancel" ? true : false}
                  value="Cancel"
                  type="radio"
                />
                <span className="checkmark"></span>
                <span className="select">Cancel Subscription</span>
              </label>
              <p className="desc">
                If you cancel your subcription then to reactivate your account
                you will need to purchase a new subscription
              </p>
            </li>

            {/* {status !== "Pause" && status === "Active" ? (
              <li className="mt-4">
                <label className="chechboxLabel">
                  <input
                    name="status"
                    onChange={handleChange}
                    value="Pause"
                    checked={statusEdit === "Pause" ? true : false}
                    type="radio"
                  />
                  <span className="checkmark"></span>
                  <span className="select">Pause Subscription</span>
                </label>
                <p className="desc">
                  You can pause your subscription to stop using the PHL
                  benefites temporarily and whenever you want to resume, you
                  just need to click on Resume and your subscription will be
                  activated (if your subscription validilty has not been
                  expired).
                </p>
              </li>
            ) : (
              <li className="mt-4">
                <label className="chechboxLabel">
                  <input
                    name="status"
                    onChange={handleChange}
                    value="Active"
                    checked={statusEdit === "Active" ? true : false}
                    type="radio"
                  />
                  <span className="checkmark"></span>
                  <span className="select">Active Subscription</span>
                </label>
                <p className="desc">
                  Activate your subscription to resume your current plan
                  (related amount will be deducted if your plan has been
                  expired).
                </p>
              </li>
            )} */}
          </ul>
          <div className="modal-footer">
            <div className="">
              <button
                type="button"
                onClick={closeStatus}
                className="customCancelBtn mt-0 btn mt-4 d-flex align-items-center justify-content-center"
              >
                Cancel
              </button>
            </div>
            <div className="">
              <button
                disabled={update ||statusEdit !== "Cancel"}
                onClick={handleSubmit}
                className="customSubmitBtn mt-4 btn"
              >
                {update ? (
                  <>
                    <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                    Update
                  </>
                ) : (
                  <>Update</>
                )}
              </button>
              {/* <SubmitButton
                className={"customSubmitBtn mt-4"}
                text={"Update"}
                isLoading={update || statusEdit !== "Cancel"}
                onSubmit={handleSubmit}
              /> */}
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
