import React from 'react';
import { Button, Form } from 'react-bootstrap';
import logo from '../../../assets/img/clarity_logo.png';
import vector from '../../../assets/img/resetPassword_vector.svg';
import InputTextFeild from '../form/InputTextFeild';
import SubmitButton from '../form/SubmitButton';

const ResetPasswordFormComponent = ({
    password,
    cnfpassword,
    onChange,
    errors,
    isLoading,
    onSubmit
}) => {
    // const currentRoute = window.location.pathname.split('/')
    // console.log('id ---> ', currentRoute);
    // const  = currentRoute[currentRoute.length - 1]
    // const queryParams = new URLSearchParams(window.location.search);
    // const id = queryParams.get("/");
    // useEffect(() => {
    //     const tok = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1)
    //     console.log(tok);
    // },[])
    return (
        <>
            <section className='formLeftSection'>
                <div className='glassEffects nextonboarding'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-6">
                                <img className='logo' src={logo} />
                                <h2>Reset Password</h2>
                                <p>Change your password bt entering below details</p>
                                <Form>
                                    <label>New Password</label>
                                    <InputTextFeild
                                        name="password" 
                                        type="password" 
                                        placeholder="Password" 
                                        value={password} 
                                        onChange={onChange} 
                                        errorMessage={errors && errors.password ? errors.password : ''}
                                    />

                                    <label>Confirm Password</label>
                                    <InputTextFeild
                                        name="cnfpassword" 
                                        type="password" 
                                        placeholder="Confirm Password" 
                                        value={cnfpassword}
                                        onChange={onChange}
                                        errorMessage={errors && errors.cnfpassword ? errors.cnfpassword : ''}
                                    />
                                    <div className='buttonSetPassword '>
                                        <Button className='customCancelBtn mt-0' variant="" type="button">
                                            Cancel
                                        </Button>
                                        <SubmitButton
                                            text={'Reset'}
                                            isLoading={isLoading}
                                            onSubmit={onSubmit}
                                            className={'customSubmitBtn mt-0'}
                                        >
                                        </SubmitButton>
                                    </div>
                                </Form>
                            </div>
                            <div className="col-md-6 d-none d-md-block image-loader">
                                <div className='h-100 d-flex align-items-center justify-content-center'>
                                    <img className='w-100' src={vector} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>         
        </>
    );
}

export default ResetPasswordFormComponent;