import React from "react";
import { Breadcrumbs } from "../../partial/Breadcrumbs";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { useState } from "react";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TermsPage } from "../../loader/HelpLoaders/TermsPage";

function PrivacyPolicy() {
  const location = useLocation();

  const [privacyData, setPrivacyData] = useState();
  const [updateOn, setUpdateOn] = useState();
  const [pageLoader, setPageLoader] = useState();

  useEffect(() => {
    getuser();
  }, []);

  const getuser = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setPageLoader(false);
    } else {
      const data = response?.data?.data?.user?.role;
      getTermsData(
        data === "C" ? "clientPrivacyAndPolicy " : "staffPrivacyAndPolicy "
      );
    }
  };

  const getTermsData = async (key) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TERMS_PRIVACY.service,
      ApiRoutes.GET_TERMS_PRIVACY.url.replace(":key", key),
      ApiRoutes.GET_TERMS_PRIVACY.method,
      ApiRoutes.GET_TERMS_PRIVACY.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      setPageLoader(false);
    } else {
      if (response.data.data.getKeyValue) {
        setUpdateOn(response.data.data.getKeyValue?.updatedAtDate);
        handleChange(window?.atob(response?.data?.data?.getKeyValue?.value));
      }
      setPageLoader(false);
    }
  };

  const handleChange = (value) => {
    setPrivacyData(value);
  };
  return (
    <>
      <div className="container-fluid fluid-pd-2 pb-5 support_contact">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={location.pathname} />
          </h3>
        </div>

        <div className="productFormBox termCondition">
          {pageLoader ? (
            <TermsPage />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <h1>Privacy Policy</h1>
                <h2>Updated on {updateOn}</h2>

                <ReactQuill
                  // placeholder="Type something here..."
                  value={privacyData}
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
