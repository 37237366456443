import Validator from 'js-object-validation';
import { message } from '../common';
import { replaceAll } from '../helpers';

export const productValidator = data => {
  const validations = {
    productName: {
      required: true,
      maxlength: 50,
    },
    productNumber: {
      required: true,
      maxlength: 50,
    },
    productDescription: {
      required: true,
    },
    // productImage: {
    //   required: true,
    // },
  };
  // Error messages
  const messages = {
    productName: {
      required: message.Required.replace(':item', 'product name'),
      maxlength: replaceAll(message.MaxLengthError, {
        ':item': 'product name',
        ':length': 50,
      }),
    },
    productNumber: {
      required: message.Required.replace(':item', 'product number'),
      maxlength: replaceAll(message.MaxLengthError, {
        ':item': 'product number',
        ':length': 50,
      }),
    },
    productDescription: {
      required: message.Required.replace(':item', 'product description'),
    },
    // productImage: {
    //   required: message.RequiredImage.replace(':item', 'profile pic'),
    // },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
