import React from "react";
import listIcon from "../../../../assets/img/staff_menu_light.svg";
import reopenIcon from "../../../../assets/img/resend_invite.svg";
import minusCircleIcon from "../../../../assets/img/crossIcon.svg";
import minusCircleIcon2 from "../../../../assets/img/staff_user_icon.svg";
import dummyclient from "../../../../assets/img/staffuser.png";
import { AppConfig } from "../../../../config";

const RequestRow = ({
  requestData,
  viewUser,
  isUpdating,
  updateStatus,
  resendInvitation,
}) => {
  const {
    first_name = "",
    last_name = "",
    email = "",
    employee_id = "",
    status = "",
  } = requestData;

  const renderStatus = () => {
    switch (status) {
      case "Accepted":
        return <span className="staffStatus accepted-span">Accepted</span>;
      case "Rejected":
        return <span className="staffStatus rejected-span">Rejected</span>;
      case "Cancelled":
        return <span className="staffStatus cancelled-span">Cancelled</span>;
      case "Invited":
        return <span className="staffStatus invited-span">Invited</span>;
      case "Pending":
        return <span className="staffStatus pending-span">Pending</span>;
      default:
        return <span className="staffStatus invited-span">Invited</span>;
    }
  };
  const buttonLabel = () => {
    switch (status) {
      case "Invited":
        return (
          <button
            className="printButton reopen"
            disabled={isUpdating}
            style={{ minWidth: "160px" }}
            onClick={() =>
              updateStatus("Cancelled", "Do you want to cancel this invitation")
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Cancel Invitation
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Cancel Invitation{" "}
              </>
            )}
          </button>
        );
      case "Accepted":
        return (
          <button
            className="printButton reopen"
            disabled={isUpdating}
            style={{ minWidth: "160px" }}
            onClick={() =>
              updateStatus(
                "Active",
                "Are you sure, you want to Convert this to your employee?"
              )
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Convert to Staff
              </>
            ) : (
              <>
                <img src={minusCircleIcon2} alt="" /> Convert to Staff{" "}
              </>
            )}
          </button>
        );
      case "Cancelled":
        return (
          <button
            className="printButton reopen"
            disabled={isUpdating}
            onClick={resendInvitation}
            style={{ minWidth: "160px" }}
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Resend Invitation
              </>
            ) : (
              <>
                <img src={reopenIcon} alt="" /> Resend Invitation{" "}
              </>
            )}
          </button>
        );
      case "Pending":
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={() =>
              updateStatus("Accepted", "Do you want to accept this invitation")
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Accept
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Accept{" "}
              </>
            )}
          </button>
        );
      default:
        return (
          <button
            className="btn btn-secondary th-btn"
            disabled={isUpdating}
            onClick={() =>
              updateStatus(
                "Active",
                "Are you sure, you want to Convert this to your employee?"
              )
            }
          >
            {" "}
            {isUpdating ? (
              <>
                <i className="fa fa-spinner fa-spin"></i> Convert to an employee
              </>
            ) : (
              <>
                <img src={minusCircleIcon} alt="" /> Convert to an employee{" "}
              </>
            )}
          </button>
        );
    }
  };
  return (
    <tr className="btachRow">
      <td>
        <div className="nameBox_stafflist">
          {/* <div>
            <img
              className="list-view-img-staff"
              src={
                requestData.profile_picture
                  ? requestData.profile_picture.startsWith("https://")
                    ? requestData.profile_picture
                    : `${AppConfig.FILES_ENDPOINT}${requestData.profile_picture}`
                  : dummyclient
              }
            />
          </div> */}
          {requestData && requestData.profile_picture ? (
            <div className="">
              <img
                className="list-view-img-staff"
                src={
                  requestData.profile_picture
                    ? requestData.profile_picture.startsWith("https://")
                      ? requestData.profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${requestData.profile_picture}`
                    : dummyclient
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = dummyclient;
                }}
                alt=""
              />
            </div>
          ) : (
            <div className="img_placeholder" style={{ fontSize: "14px" }}>
              {first_name ? first_name.substring(0, 1) : ""}
              {last_name ? last_name.substring(0, 1) : ""}
            </div>
          )}
          <p>
            <span className="textContol">
              {" "}
              {[first_name , last_name].filter(Boolean).join(" ") || "N/A"}
            </span>
          </p>
        </div>
      </td>
      <td>
        <span className="textContol">{employee_id || "N/A"}</span>
      </td>
      <td>
        {" "}
        <span className="textContol">{requestData.roleName} </span>
      </td>
      <td>
        {" "}
        <span className="textContol">{email || "N/A"} </span>
      </td>
      <td>
        {" "}
        <span className="textContol">{renderStatus()} </span>
      </td>

      <td>
        <div className="d-flex aliugn-items-center">
          <button className="editButtonNew mr-2" onClick={viewUser}>
            {" "}
            <img src={listIcon} alt="" /> View{" "}
          </button>
          {buttonLabel()}
        </div>
      </td>
    </tr>
  );
};

export default RequestRow;
