import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import SubmitButton from "../form/SubmitButton";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdding: false,
      isError: false,
    };
  }
  addCard = async (paylaod) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CARD.service,
      ApiRoutes.ADD_CARD.url,
      ApiRoutes.ADD_CARD.method,
      ApiRoutes.ADD_CARD.authenticate,
      undefined,
      paylaod
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isAdding: false,
      });
    } else {
      toast.success(response.data.message);
      this.props.fetchCards();
    }
  };
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    this.setState({
      isError: false,
      isAdding: true,
    });
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const cardElement = elements.getElement("card");

    const result = await stripe.createToken(cardElement);

    if (result.error) {
      // toast.error(result.error.message);
      this.setState({
        isError: result.error.message,
        isAdding: false,
      });
    } else {
      let token = { stripeToken: result.token.id };
      this.addCard(token);
      cardElement.clear();
    }
  };

  render() {
    const { isAdding, isError } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement />

        <div className="text-danger mt-4">{isError}</div>

        <div className="row">
          <div
            className="col-md-6 mt-sm-0 mt-md-4"
            onClick={this.props.handleAccordion}
          >
            <span
              className={
                "customCancelBtn mt-0 btn mt-4 d-flex align-items-center justify-content-center"
              }
            >
              Cancel
            </span>
          </div>
          <div className="col-md-6 mt-sm-0 mt-md-4">
            <SubmitButton
              className={"customSubmitBtn mt-4"}
              text={"Add"}
              disabled={!this.props.stripe || isAdding}
              isLoading={isAdding}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default function StripeForm({ fetchCards, handleAccordion }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          fetchCards={fetchCards}
          stripe={stripe}
          elements={elements}
          handleAccordion={handleAccordion}
        />
      )}
    </ElementsConsumer>
  );
}
