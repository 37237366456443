/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import "./batches.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { ReportHeader } from "./ReportHeader";
import { ReportCard } from "./ReportCard";
import { ProductTable } from "./ProductTable";
import FileSaver from "file-saver";
import moment from "moment";
class ReportProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: "Today",
      customStartDate: new Date(),
      customEndDate: new Date(),
      cardsLoading: true,
      tableLoading: true,
      cardsData: null,
      tableData: null,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      sortBy: "createdAt",
      order: "desc",
      isBatchExporting: false,
    };
  }

  dateRangeCallback = async (start, end, label) => {
    this.setState({
      selectedTime: "Custom",
      customStartDate: new Date(moment(start)),
      customEndDate: new Date(moment(end)),
    });
    setTimeout(() => {
      this.fetchData();
    }, 500);
  };

  onPageChanged = (page) => {
    const { selectedTime, customStartDate, customEndDate, limit, currentPage } =
      this.state;
    if (page !== currentPage) {
      this.setState({
        currentPage: page,
      });
      let skip = limit * (page - 1);
      const payload = {
        selectedTime,
        customStartDate,
        customEndDate,
        skip,
        limit,
      };
      this.fetchTable(payload);
    }
  };

  changeSelectedTime = async (val) => {
    await this.setState({
      selectedTime: val,
    });

    switch (val) {
      case "Today":
        this.setState({
          customStartDate: new Date(),
          customEndDate: new Date(),
        });
        break;
      case "Yesterday":
        this.setState({
          customStartDate: new Date(moment().subtract(1, "day")),
          customEndDate: new Date(moment().subtract(1, "day")),
        });
        break;
      case "Week":
        this.setState({
          customStartDate: new Date(moment().startOf("week")),
          customEndDate: new Date(moment().endOf("week")),
        });
        break;
      case "Month":
        this.setState({
          customStartDate: new Date(moment().startOf("month")),
          customEndDate: new Date(moment().endOf("month")),
        });
        break;
      case "Year":
        this.setState({
          customStartDate: new Date(moment().startOf("year")),
          customEndDate: new Date(moment().endOf("year")),
        });
        break;
      default:
    }

    this.fetchData();
  };

  componentDidMount = () => {
    this.fetchData();
  };

  setFilter = (sortBy, order) => {
    if (sortBy) {
      this.setState({
        sortBy: sortBy,
      });
    }
    if (order) {
      this.setState({
        order: order,
      });
    }
    this.fetchData();
  };

  fetchData = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      limit,
      currentPage,
      order,
      sortBy,
    } = this.state;
    let skip = limit * (currentPage - 1);
    const payload = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
    };
    let data = {
      selectedTime,
      order,
      sortBy,
      customStartDate,
      customEndDate,
    };
    this.fetchCards(payload);
    this.fetchTable(data);
  };

  fetchCards = async (data) => {
    this.setState({
      cardsLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_PRODUCT_CARDS.service,
      ApiRoutes.REPORTS_PRODUCT_CARDS.url,
      ApiRoutes.REPORTS_PRODUCT_CARDS.method,
      ApiRoutes.REPORTS_PRODUCT_CARDS.authenticate,
      data,
      undefined
    );

    this.setState({
      cardsLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        cardsData: response.data.data,
      });
    }
  };

  fetchTable = async (data) => {
    this.setState({
      tableLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_PRODUCT_TABLE.service,
      ApiRoutes.REPORTS_PRODUCT_TABLE.url,
      ApiRoutes.REPORTS_PRODUCT_TABLE.method,
      ApiRoutes.REPORTS_PRODUCT_TABLE.authenticate,
      data,
      undefined
    );

    this.setState({
      tableLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        tableData: response.data.data.products,
        totalRecords: response.data.data.totalRecords,
      });
    }
  };

  //  10/06/22
  onExport = async () => {
    const { selectedTime, customStartDate, customEndDate } = this.state;
    let data = {
      selectedTime,
      customStartDate,
      customEndDate,
    };
    let type = "csv";

    let blobType, extension;
    if (data) {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.service,
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.url,
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.method,
      ApiRoutes.EXPORT_REPORTS_PRODUCT_TABLE.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `Product.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };

  render() {
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const thead = ["Product Number", "Product Name", "Created On"];
    return (
      <div className="container-fluid fluid-pd-2 pb-5">
        <div className="tableFilter overflow-hidden add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>

        <div className="productFormBox reportBatches">
          <div className="row">
            <ReportHeader
              selectedTime={this.state.selectedTime}
              dateRangeCallback={this.dateRangeCallback}
              changeSelectedTime={this.changeSelectedTime}
              onExport={this.onExport}
              customStartDate={this.state.customStartDate}
              customEndDate={this.state.customEndDate}
              isExporting={this.state.isBatchExporting}
            />

            <ReportCard
              loading={this.state.cardsLoading}
              title="Total Products"
              total={this.state.cardsData?.totalProducts}
              // perc={0}
              // prevString={''}
              perc={this.state.cardsData?.addedCalculation}
              prevString={this.state.cardsData?.dateString}
              size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
            />

            <ReportCard
              loading={this.state.cardsLoading}
              title="Products Added"
              total={this.state.cardsData?.addedProducts}
              perc={this.state.cardsData?.addedCalculation}
              prevString={this.state.cardsData?.dateString}
              size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
            />

            <div className="col-md-12 mt-5">
              <div className="card tableCard">
                <div className="tabel-outer">
                  <div className="table-responsive fixed-height-table">
                    <ProductTable
                      loading={this.state.tableLoading}
                      thead={thead}
                      tbody={this.state.tableData}
                      setFilter={this.setFilter}
                      sortBy={this.state.sortBy}
                      order={this.state.order}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-pg px-0 px-lg-3 w-100">
              {!this.state.tableLoading ? (
                <>
                  <div className="float-left mt-2">
                    {this.state.totalRecords > this.state.limit ? (
                      <div>
                        <p className="totalRecords">
                          Page{" "}
                          {this.state.totalRecords > 0
                            ? this.state.currentPage
                            : 0}{" "}
                          of{" "}
                          {this.state.totalRecords % this.state.limit > 0
                            ? parseInt(
                                this.state.totalRecords / this.state.limit
                              ) + 1
                            : parseInt(
                                this.state.totalRecords / this.state.limit
                              )}{" "}
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <div className="float-right mt-0">
                    <div className="d-flex align-items-center tableFooter">
                      {this.state.totalRecords > this.state.limit ? (
                        <div className="float-right d-flex align-items-center">
                          <Pagination
                            totalRecords={this.state.totalRecords}
                            currentPage={this.state.currentPage}
                            pageLimit={this.state.limit}
                            onPageChanged={this.onPageChanged}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportProduct;
