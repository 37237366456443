import React from "react";
// import { Line } from 'react-chartjs-2';
import Chart from "react-apexcharts";

export const ScanGraphComponent = ({ scanDataSet, prevScanDataSet, type }) => {
  let chartCategoryArray = [];
  let xaxisType = "datetime";
  if (scanDataSet) {
    if (type !== "Year") {
      for (let x in scanDataSet.label) {
        chartCategoryArray.push(
          new Date(scanDataSet.label[x] + "T00:00:00.025385Z").getTime()
        );
      }
    } else {
      xaxisType = "year";
      for (let x in scanDataSet.label) {
        chartCategoryArray.push(scanDataSet.label[x]);
      }
    }
  }
  const series = [
    {
      name: `Current ${type}`,
      data: scanDataSet ? scanDataSet.data : [],
    },
    {
      name: `Last ${type}`,
      data: prevScanDataSet ? prevScanDataSet.data : [],
    },
  ];
  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#246A18", "#C60F0F"],

    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    xaxis: {
      type: xaxisType,
      categories: chartCategoryArray,
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  return <Chart options={options} series={series} type="line" height={300} />;
  // return <Line data={data} options={graphOptions} width={400} height={250} />;
};
