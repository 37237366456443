import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { message } from "../../../common";
import { ApiRoutes, websiteRegex } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { addressValidator } from "../../../validators/addressValidator";
import { clientOwnerInfo } from "../../../validators/clientOwnerInfo";
import { clientStoreInfo } from "../../../validators/clientStoreInfo";
import { emailInfo } from "../../../validators/emailvalid";
import { phoneInfo } from "../../../validators/poneInfo";
import AddAddressModal from "../../components/accountSetting/profile/AddAddressModal";
import Addresses from "../../components/accountSetting/profile/Addresses";
import ContactDetails from "../../components/accountSetting/profile/ContactDetails";

import OrganizationInfo from "../../components/accountSetting/profile/OrganizationInfo";
import OwnerInfo from "../../components/accountSetting/profile/OwnerInfo";
import SweetAlertComponent from "../../components/sweetAlertComponent";
const countryState = require("../../../common/countryState.json");

function Profile() {
  useEffect(() => {
    getProfile();
  }, []);

  const [clientEdit, setClientEdit] = useState();
  const [clientLoader, setClientLoader] = useState(false);
  const [storeLoader, setStoreLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [storeEdit, setStoreEdit] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isEditStore, setIsEditStore] = useState(false);
  const [storeError, setStoreError] = useState(false);
  const [error, setError] = useState();
  const [dateError, setDateError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [addressData, setAddressData] = useState();
  const [addressDataEdit, setAddressDataEdit] = useState();
  const [statesOption, setStatesOption] = useState();
  const [show, setShow] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [otp, setOtp] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState(false);
  const [otpEdit, setOtpEdit] = useState();

  const [emailShow, setEmailShow] = useState(false);
  const [PhoneShow, setPhoneShow] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [secondEmail, setSecondEmail] = useState();
  const [defaultEmail, setDefaultEmail] = useState(false);

  const [countryCode, setCountryCode] = useState({
    label: "+353",
    value: "+353",
  });

  const handleClose = () => {
    setShow(false);
    setIsAddressEdit(false);
    setAddressError();
    setStatesOption();
    setAddressDataEdit();
  };

  const onEdit = () => {
    setIsEdit(true);
  };
  const onCancel = () => {
    setIsEdit(false);
  };
  const onEditStore = () => {
    setIsEditStore(true);
  };
  const onCancelStore = () => {
    setIsEditStore(false);
  };

  const getProfile = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setPageLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.id;
      getClientProfile(data);
    }
  };

  const getClientProfile = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ACCOUNT_SETTINGS.service,
      ApiRoutes.GET_ACCOUNT_SETTINGS.url.replace(":id", id),
      ApiRoutes.GET_ACCOUNT_SETTINGS.method,
      ApiRoutes.GET_ACCOUNT_SETTINGS.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      const storeData = response?.data?.data?.store;
      setStoreEdit({
        ...storeData,
        insideUSACanada: storeData.insideUSACanada ? "Yes" : "No",
      });
      setClientEdit({
        ...data,
        // birth_date: data?.birth_date
        //   ? moment(data?.birth_date).toISOString(true)
        //   : "" || "",
        birth_date: moment(data?.birth_date).subtract(1, "d")._d,
        // birth_date: data?.birth_date ? new Date(data?.birth_date) : "" || "",
        country: data?.other_info?.country
          ? data?.other_info?.country
          : "" || "",
        phone: data?.store?.phone,
        countryCode: data?.store?.countryCode
          ? data?.store?.countryCode
          : "" || "",
      });
      getAddress();
    }
  };
  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setClientEdit({
      ...clientEdit,
      [name]: value.trimLeft(),
    });
  };

  const onChangeEmail = (event) => {
    const {
      target: { value },
    } = event;

    setSecondEmail(value.trimLeft());
  };

  const emailSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      secondEmail: secondEmail,
      // backupEmail: secondEmail,
    };

    const { isValid, errors } = emailInfo(data);
    if (isValid) {
      setEmailError();
      setEmailLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ADD_CLIENT_EMAIL.service,
        ApiRoutes.ADD_CLIENT_EMAIL.url,
        ApiRoutes.ADD_CLIENT_EMAIL.method,
        ApiRoutes.ADD_CLIENT_EMAIL.authenticate,
        undefined,
        data
      );
      if (response && response.isError) {
        toast.error(response.messages[0]);
        setEmailLoader(false);
      } else {
        const data = response?.data?.data?.otp;
        setOtp(data ? data : response?.data?.message);
        setEmailLoader(false);
        toast.success(response?.data?.message);
      }
    } else {
      setEmailLoader(false);
      setEmailError(errors);
    }
  };

  const otpChange = (value) => {
    setOtpEdit(value ? value : "");
  };

  const emailverfiy = async (event) => {
    event && event.preventDefault();
    let data = {
      secondEmail: secondEmail,
      // backupEmail: secondEmail,
      otp: otpEdit,
    };
    const { isValid, errors } = emailInfo(data);
    if (isValid) {
      setEmailLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.VERIFY_EMAIL.service,
        ApiRoutes.VERIFY_EMAIL.url,
        ApiRoutes.VERIFY_EMAIL.method,
        ApiRoutes.VERIFY_EMAIL.authenticate,
        undefined,
        data
      );
      if (response && response.isError) {
        toast.error(response.messages[0]);
        setEmailLoader(false);
        setOtpEdit();
      } else {
        toast.success(response.messages[0]);
        getProfile();
        setEmailLoader(false);
        setEmailShow(false);
        setOtp();
        setOtpEdit();
      }
    } else {
      setEmailLoader(false);
      setEmailError(errors);
    }
  };
  const handleStoreChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "businessRegNo" && isNaN(value)) {
      return;
    }
    if (name === "VATNumber" && isNaN(value)) {
      return;
    }
    if (name === "TradeMarkCertificateNo" && isNaN(value)) {
      return;
    }
    setStoreEdit({
      ...storeEdit,
      [name]: value.trimLeft(),
    });
  };

  const onSelectStore = (options, name) => {
    setStoreEdit({
      ...storeEdit,
      [name]: options.label,
    });
  };

  const onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;

    await uploadPicture(files[0], name);
    event.target.value = "";
  };

  const uploadPicture = async (data, name) => {
    setImageLoader(name);

    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setImageLoader(false);
    } else {
      const { path } = response.data || {};
      if (
        name === "storeLogo" ||
        name === "businessRegNo_image" ||
        name === "VATNumber_image" ||
        name === "TradeMarkCertificateNo_image"
      ) {
        setStoreEdit({ ...storeEdit, [name]: path });
      } else {
        setClientEdit({ ...clientEdit, [name]: path });
      }
      setImageLoader(false);
    }
  };
  const onSelect = (options, name) => {
    setClientEdit({
      ...clientEdit,
      [name]: options.label,
    });
  };

  const onDateChange = (date) => {
    setClientEdit({
      ...clientEdit,
      birth_date: date,
    });
  };

  const updateOwnerInfo = async (data) => {
    setClientLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_INFO.service,
      ApiRoutes.UPDATE_CLIENT_INFO.url.replace(":id", clientEdit._id),
      ApiRoutes.UPDATE_CLIENT_INFO.method,
      ApiRoutes.UPDATE_CLIENT_INFO.authenticate,
      undefined,
      {
        ...data,
        birth_date: moment(data.birth_date).toISOString(true),
      }
    );

    if (response && response.isError) {
      setClientLoader(false);
      toast.error(response.messages[0]);
    } else {
      setClientLoader(false);
      setIsEdit(false);
      toast.success(response.messages[0]);
    }
  };

  const handleOwnerSubmit = () => {
    let { isValid, errors } = clientOwnerInfo(clientEdit);
    if (isValid && clientEdit?.birth_date !== "") {
      updateOwnerInfo(clientEdit);
      setError();
      setDateError();
    } else {
      setError(errors);
      if (clientEdit?.birth_date) {
        setDateError(false);
      } else {
        setDateError(true);
      }
    }
  };

  const updateStoreInfo = async (data) => {
    setStoreLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STORE_INFO.service,
      ApiRoutes.UPDATE_STORE_INFO.url.replace(":id", clientEdit._id),
      ApiRoutes.UPDATE_STORE_INFO.method,
      ApiRoutes.UPDATE_STORE_INFO.authenticate,
      undefined,
      {
        storeData: {
          ...data,
          insideUSACanada: data?.insideUSACanada === "Yes" ? true : false,
        },
      }
    );
    if (response && response.isError) {
      setStoreLoader(false);
      toast.error(response.messages[0]);
    } else {
      setStoreLoader(false);
      setIsEditStore(false);
      toast.success(response.messages[0]);
    }
  };

  const handleStoreSubmit = () => {
    if (storeEdit?.website && !websiteRegex.test(storeEdit?.website)) {
      setStoreError({
        website: message.InvalidWebite,
      });
      return;
    }
    const { isValid, errors } = clientStoreInfo(storeEdit);
    if (isValid) {
      updateStoreInfo(storeEdit);
      setStoreError();
    } else {
      setStoreError(errors);
    }
  };

  const onAddressChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setAddressDataEdit({
      ...addressDataEdit,
      [name]: value.trimLeft(),
    });
    setAddressError({
      ...addressError,
      [name]: "",
    });
  };

  const handleAddressNew = () => {
    setShow(true);
  };

  const getAddress = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_ADDRESS.service,
      ApiRoutes.GET_CLIENT_ADDRESS.url.replace(":id", id),
      ApiRoutes.GET_CLIENT_ADDRESS.method,
      ApiRoutes.GET_CLIENT_ADDRESS.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const address = response?.data?.data?.address;
      setAddressData(address);
    }
  };

  const updateAddress = async (data) => {
    setClientLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_ADDRESS.service,
      ApiRoutes.UPDATE_CLIENT_ADDRESS.url,
      ApiRoutes.UPDATE_CLIENT_ADDRESS.method,
      ApiRoutes.UPDATE_CLIENT_ADDRESS.authenticate,
      undefined,
      data
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      setClientLoader(false);
    } else {
      setClientLoader(false);
      setShow(false);
      setAddressError();
      setAddressDataEdit();
      getAddress();
      if (!isAddressEdit) {
        setStatesOption();
      }
      toast.success(response.messages[0]);
    }
  };
  const submitAddress = async (event) => {
    event && event.preventDefault();

    let data = {
      addressType: addressDataEdit?.addressType,
      addressLine1: addressDataEdit?.addressLine1,
      addressLine2: addressDataEdit?.addressLine2,
      // state: addressDataEdit?.state,
      country: addressDataEdit?.country,
      postCode: addressDataEdit?.postCode,
    };
    // To made states required only if selected origins have states
    if ((statesOption && statesOption.length) || !data.country) {
      data = {
        ...data,
        state: addressDataEdit?.state,
      };
    }
    const { isValid, errors } = addressValidator(data);
    if (isValid) {
      updateAddress({
        addresModule: data,
      });
      setAddressError();
      setIsAddressEdit(false);
    } else {
      setAddressError(errors);
      return;
    }
  };

  const onSelectAddress = (options, name) => {
    // TO reset states on country selection
    if (name === "country") {
      setAddressDataEdit({
        ...addressDataEdit,
        [name]: options.label,
        state: "",
      });
      setAddressError({
        ...addressError,
        [name]: "",
      });
      if (options && options.value) {
        handleStatesOption(options.value);
      }
    } else {
      setAddressDataEdit({
        ...addressDataEdit,
        [name]: options.label,
      });
      setAddressError({
        ...addressError,
        [name]: "",
      });
    }
  };

  const handleStatesOption = (value) => {
    let temp = [];
    let selectedCountryIndex = countryState.findIndex(
      (element) => element.name === value
    );
    if (selectedCountryIndex > -1) {
      countryState[selectedCountryIndex].states
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((state) => {
          temp.push({
            label: state.name,
            value: state.name,
          });
        });
      setStatesOption(temp);
    }
  };

  const onChangePhone = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "secondPhone" && isNaN(value)) {
      setEmailError({
        ...emailError,
        secondPhone: "Phone number must be in numeric digit only",
      });
    }

    setClientEdit({
      ...clientEdit,
      [name]: value.trimLeft(),
    });
  };
  const onCodeSelect = (label) => {
    if (label) {
      setCountryCode(label);
    }
  };

  const phoneSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      secondPhone: clientEdit?.secondPhone,
      countryCode: countryCode?.value,
      // backupPhone: clientEdit?.secondPhone,
    };
    const { isValid, errors } = phoneInfo(data);
    if (isValid) {
      setEmailError();
      setEmailLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ADD_CLIENT_EMAIL.service,
        ApiRoutes.ADD_CLIENT_EMAIL.url,
        ApiRoutes.ADD_CLIENT_EMAIL.method,
        ApiRoutes.ADD_CLIENT_EMAIL.authenticate,
        undefined,
        data
      );

      if (response && response.isError) {
        toast.error(response.messages[0]);
        setEmailLoader(false);
      } else {
        const data = response?.data?.data?.otp;
        setPhoneOtp(data ? data : response?.data?.message);
        setEmailLoader(false);
        toast.success(response?.data?.message);
      }
    } else {
      setEmailLoader(false);
      setEmailError(errors);
    }
  };

  const phoneVerfiy = async (event) => {
    event && event.preventDefault();

    setEmailLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_EMAIL.service,
      ApiRoutes.VERIFY_EMAIL.url,
      ApiRoutes.VERIFY_EMAIL.method,
      ApiRoutes.VERIFY_EMAIL.authenticate,
      undefined,
      {
        secondPhone: clientEdit?.secondPhone,
        countryCode: countryCode?.value,
        // backupPhone: clientEdit?.secondPhone,
        otp: otpEdit,
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setOtpEdit();
      setEmailLoader(false);
    } else {
      setEmailLoader(false);
      setPhoneShow(false);
      setPhoneOtp();
      setOtpEdit();
      toast.success(response.messages[0]);
      getProfile();
    }
  };

  let allCountry = [];
  countryState.forEach((element) => {
    allCountry.push({ label: element.name, value: element.name });
  });

  const dletePhone = async (data, type) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure you want to delete this ${
        type === "email" ? "email" : "number"
      } ?`,
    });
    if (!value) {
      return;
    }
    let payload = {};
    if (type === "email") {
      payload = {
        secondEmail: data,
      };
    } else {
      payload = {
        secondPhone: data,
      };
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_EMAIL.service,
      ApiRoutes.DELETE_EMAIL.url,
      ApiRoutes.DELETE_EMAIL.method,
      ApiRoutes.DELETE_EMAIL.authenticate,
      undefined,
      payload
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      getProfile();
    }
  };

  const setModalOpen = (data) => {
    setDefaultEmail(data);
  };
  const setPrimaryEmail = async () => {
    let data = {
      email: defaultEmail,
    };
    if (defaultEmail?.phone) {
      data = {
        phone: defaultEmail?.phone,
      };
    }
    setEmailLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SET_PRIMARY_EMAIL.service,
      ApiRoutes.SET_PRIMARY_EMAIL.url,
      ApiRoutes.SET_PRIMARY_EMAIL.method,
      ApiRoutes.SET_PRIMARY_EMAIL.authenticate,
      undefined,
      data
    );
    setEmailLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      setDefaultEmail(false);
      getProfile();
    }
  };

  const handleEdit = (value) => {
    setIsAddressEdit(true);
    setShow(true);
    setAddressDataEdit(value);
    handleStatesOption(value.country);
  };
  const onPopupCancel = (type) => {
    if (type === "email") {
      setOtp();
    } else {
      setPhoneOtp();
    }
  };
  const closePhone = () => [
    setPhoneShow(false),
    setClientEdit({
      ...clientEdit,
      secondPhone: "",
    }),
    setCountryCode({
      label: "+353",
      value: "+353",
    }),
    setEmailError(),
  ];

  const close = () => {
    setEmailShow(false);
    setSecondEmail();
    setEmailError();
  };
  return (
    <div className="container-fluid fluid-pd-2">
      <div className="row">
        <div className="col-md-12">
          <OrganizationInfo
            storeEdit={storeEdit}
            handleChange={handleStoreChange}
            isEditStore={isEditStore}
            onEditStore={onEditStore}
            onCancelStore={onCancelStore}
            onFileChange={onFileChange}
            onSelectStore={onSelectStore}
            handleStoreSubmit={handleStoreSubmit}
            error={storeError}
            storeLoader={storeLoader}
            imageLoader={imageLoader}
            pageLoader={pageLoader}
          />
        </div>
        <div className="col-md-12">
          <OwnerInfo
            handleChange={handleChange}
            clientEdit={clientEdit}
            onFileChange={onFileChange}
            isEdit={isEdit}
            onEdit={onEdit}
            onCancel={onCancel}
            allCountry={allCountry}
            onSelect={onSelect}
            onDateChange={onDateChange}
            handleOwnerSubmit={handleOwnerSubmit}
            error={error}
            dateError={dateError}
            clientLoader={clientLoader}
            pageLoader={pageLoader}
          />
        </div>
        <div className="col-md-12">
          <ContactDetails
            clientEdit={clientEdit}
            handleChange={onChangeEmail}
            emailSubmit={emailSubmit}
            emailError={emailError}
            emailLoader={emailLoader}
            otp={otp}
            emailverfiy={emailverfiy}
            show={emailShow}
            PhoneShow={PhoneShow}
            handleShow={() => setEmailShow(true)}
            handlePhoneShow={() => setPhoneShow(true)}
            close={close}
            closePhone={closePhone}
            onChangePhone={onChangePhone}
            phoneSubmit={phoneSubmit}
            onCodeSelect={onCodeSelect}
            phoneVerfiy={phoneVerfiy}
            pageLoader={pageLoader}
            otpChange={otpChange}
            otpEdit={otpEdit}
            secondEmail={secondEmail}
            setPrimaryEmail={setPrimaryEmail}
            dletePhone={dletePhone}
            phoneOtp={phoneOtp}
            onPopupCancel={onPopupCancel}
            countryCode={countryCode}
            setModalOpen={setModalOpen}
            defaultEmail={defaultEmail}
          />
        </div>

        <div className="col-md-12">
          <Addresses
            address={addressData}
            handleShow={handleAddressNew}
            handleEdit={handleEdit}
          />
        </div>
      </div>
      <AddAddressModal
        onChange={onAddressChange}
        addressDataEdit={addressDataEdit}
        submitAddress={submitAddress}
        onSelectOption={onSelectAddress}
        addressError={addressError}
        allCountry={allCountry}
        statesOption={statesOption}
        show={show}
        clientLoader={clientLoader}
        handleClose={handleClose}
        isAddressEdit={isAddressEdit}
      />
    </div>
  );
}

export default Profile;
