import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import errorImg from '../../../assets/img/error.svg';

export const DeleteErrorModal = ({
  moduleName = '',
  message = '',
  open = false,
  handleClose,
}) => {
  useEffect(() => {
    new Image().src = errorImg;
  }, []);
  return (
    <Modal show={open} onHide={handleClose} dialogClassName='modal-25w'>
      <Modal.Body>
        <div className='row lf-10'>
          <div className='col-md-12 text-center '>
            <img src={errorImg} alt='' className='alert-logo' />
            <h4 className='modal-head'>Can’t Delete {moduleName}</h4>
            <span className='modal-desc'> {message}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='col-md-12 footer-form'>
          <button
            className='btn btn-primary submit-btn modal-btn-ft-center'
            onClick={handleClose}
          >
            {' '}
            OK
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
