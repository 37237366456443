import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  setUserData,
  setCheck,
  getCheck,
  clearCheck,
  isLogin,
} from "../../../helpers/userData";
import { ApiRoutes, AppRoutes } from "../../../config";
import { loginValidator } from "../../../validators";
import { ApiHelper } from "../../../helpers";
import LoginFormComponent from "../../components/login";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: null,
      islogged: false,
      isLoading: false,
    };
  }

  componentWillMount() {
    const data = getCheck();
    if (data) {
      const { email, password } = data;
      this.setState({ email, password, islogged: true });
    }
  }

  componentDidMount() {
    document.body.classList.add("bg-gradient-primary");
    const isLoggedInUser = isLogin();
    // Redirect to dashboard if admin already loggedin
    if (isLoggedInUser) {
      this.props.history.push(AppRoutes.DASHBOARD);
    }
  }

  handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;
    this.setState({
      [name]: type === "checkbox" ? checked : value,
      errors: { ...this.state.errors, [name]: "" },
    });
  };

  handleSubmit = async (event) => {
    event && event.preventDefault();
    const { email, password, islogged } = this.state;
    let data = {
      email,
      password,
    };
    let { isValid, errors } = loginValidator(data);
    if (isValid) {
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.LOGIN.service,
        ApiRoutes.LOGIN.url,
        ApiRoutes.LOGIN.method,
        ApiRoutes.LOGIN.authenticate,
        undefined,
        data
      );
      this.setState({
        isLoading: false,
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        const { data } = response.data;
        console.log(data);
        // return
        setUserData(data);
        if (data?.is_first_login) {
          this.props.history.push(AppRoutes.SET_PASSWORD);
        } else {
          if (data?.MFAEnable) {
            setUserData();
            this.props.history.push({
              pathname: AppRoutes.VERIFYIDENTITY,
              state: {
                data,
              },
            });
          } else {
            setUserData(data);
            toast.success("Login Successfull");
            const { email, password } = this.state;
            islogged ? setCheck(email, password) : clearCheck();
            this.props.history.push(AppRoutes.DASHBOARD);
          }
        }
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };

  getCheckbox = () => {
    this.setState({ islogged: !this.state.islogged });
  };

  componentWillUnmount = () => {
    document.body.classList.remove("bg-gradient-primary");
  };
  render() {
    const { email, password, errors, islogged, isLoading } = this.state;
    return (
      <>
        <LoginFormComponent
          email={email}
          password={password}
          errors={errors}
          islogged={islogged}
          isLoading={isLoading}
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

export default Login;
