import React from "react";
import Skeleton from "react-skeleton-loader";

export const CurrentPlanGraphsLoader = () => (
  <>
    <div class="cardHeader">
      <h4><Skeleton height={"29px"} width={"150px"} color="#e5e9ef" /></h4>
    </div>
    <div className="cardBody card_overflow">
      <div className="text-center">
        {/* <Skeleton borderRadius={"250px"} height={"250px"} width={"350px"} color="#e5e9ef" /> */}
        <Skeleton height={"50px"} width={"300px"} color="#e5e9ef" />
        <Skeleton height={"50px"} width={"300px"} color="#e5e9ef" />
        <Skeleton height={"50px"} width={"300px"} color="#e5e9ef" />
      </div>
    </div>
  </>
);
