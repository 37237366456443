import Validator from "js-object-validation";
import { message } from "../common";

export const clientStoreInfo = (data) => {
  const validations = {
    companyName: {
      required: true,
    },
    website: {
      required: true,
    },
    businessRegNo: {
      required: true,
    },
    businessRegNo: {
      required: true,
    },
    VATNumber: {
      required: true,
    },
    TradeMarkCertificateNo: {
      required: true,
    },
    businessRegNo_image: {
      required: true,
    },
    VATNumber_image: {
      required: true,
    },
    TradeMarkCertificateNo_image: {
      required: true,
    },
    storeLogo: {
      required: true,
    },
  };
  // if (data.insideUSACanada === "yes") {
  //   validations.businessRegNo = { required: true };
  //   validations.VATNumber = { required: true };
  // }
  // if (data.insideUSACanada === "no") {
  //   validations.businessRegCertificateNo = { required: true };
  //   validations.stateOfIssuingCertificate = { required: true };
  // }
  // Error messages
  const messages = {
    companyName: {
      required: message.Required.replace(":item", "company name"),
    },
    website: {
      required: message.Required.replace(":item", "website"),
    },
    businessRegNo: {
      required: message.Required.replace(
        ":item",
        "business registration number."
      ),
    },
    VATNumber: {
      required: message.Required.replace(":item", "vat number"),
    },
    TradeMarkCertificateNo: {
      required: message.Required.replace(
        ":item",
        "trade mark certificate number"
      ),
    },
    TradeMarkCertificateNo_image: {
      required: message.Required.replace(
        ":item",
        "trade mark certificate number image"
      ),
    },
    businessRegNo_image: {
      required: message.Required.replace(
        ":item",
        "business registration number image"
      ),
    },
    VATNumber_image: {
      required: message.Required.replace(":item", "vat number image"),
    },
    storeLogo: {
      required: message.Required.replace(":item", "store logo"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
