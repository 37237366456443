export const AppRoutes = {
  HOME: "/",
  LOGIN: "/login",
  LOGOUT: "/login",
  SET_PASSWORD: "/set-password",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  CHANGE_PASSWORD: "/change-password",
  VERIFYIDENTITY: "/verify-identity",
  OTPFORM: "/otp",
  DASHBOARD: "/dashboard",
  CLARITY: "/clarity",
  COUNTERFEIT: "/clarity/counterfeit",
  COUNTERFEIT_VIEW: "/clarity/counterfeit/:id",
  COUNTERFEIT_MAP_VIEW: "/clarity/counterfeit/:id/map",
  SCANS: "/clarity/scans",
  MANAGE_USER: "/clarity/users",
  ADD_USER: "/clarity/users/add",
  VIEW_USER: "/clarity/users/:id",
  VIEW_VIDEO: "/clarity/videos/details",
  MANAGE_VIDEO_CATEGORY: "/clarity/video/category",
  MANAGE_VIDEO: "/clarity/video/category/:id",
  // SETTING: '/setting',
  INVITE_FORM: "/register",
  MANAGE_PRODUCT: "/products",
  ADD_PRODUCT: "/products/add",
  EDIT_PRODUCT: "/products/edit/:id",
  MANAGE_BATCH: "/batches",
  ADD_BATCH: "/batches/add",
  EDIT_BATCH: "/batches/edit/:id",
  VIEW_BATCH: "/batches/view/:id",
  PRINT_BATCH: "/batches/:id/print/:unit",
  MANAGE_ORIGIN: "/origins",
  BATCHES: "/batches",
  MANAGE_CLIENTS: "/clients",
  MANAGE_CLIENTS_REQUESTS: "/clients/requests",
  MANAGE_CLIENTS_INVITATION: "/clients/invitation",
  VIEW_CLIENT_REQUEST: "/clients/requests/view/:id",
  VIEW_CLIENT_BATCH: "/clients/view/batch/:id/product/:productId",
  MANAGE_CLIENTS_PERMISSION: "/clients/view/permissions/:id",
  VIEW_CLIENT: "/clients/view/:id",
  EDIT_CLIENT_REQUEST: "/clients/requests/edit/:id",
  MANAGE_STAFF: "/staff",
  MANAGE_STAFF_LIST: "/staff/list",
  MANAGE_STAFF_ROLE: "/staff/role",
  MANAGE_STAFF_REQUESTS: "/staff/onboarding",
  VIEW_STAFF_REQUEST: "/staff/list/requests/view/:id",
  MANAGE_STAFF_INVITATION: "/staff/invitation",
  MANAGE_STAFF_PERMISSION: "/staff/list/requests/view/permissions/:id",
  VIEW_STAFF: "/staff/list/view/:id",
  TERMS: "/terms",
  PRIVACY_POLICY: "/privacy_policy",
  USER_DATA_DELETION: "/user_data_deletion",
  CONTACT: "/cms/contact",
  TERMS_AND_CONDITION: "/cms/terms",
  CMS_PRIVACY_POLICY: "/cms/policy",
  FAQS: "/cms/faqs",
  PUSH_NOTIFICATIONS: "/cms/push-notifications",
  REPORTS: "/reports",
  BATCHES_REPORTS: "/reports/batches",
  COUNTERFEIT_REPORTS: "/reports/reports-counterfeits",
  PRODUCT_REPORTS: "/reports/products",
  USERS_REPORTS: "/reports/reportusers",
  SCAN_REPORTS: "/reports/reports-scans",
  MANAGE_CLARITY: "clarity",
  CMS: "cms",
  MANAGE_REPORTS: "reports",
  TEST: "test_page",
  IDFORMATS: "/settings/idformats",
  SETTINGS: "/settings",
  NOTIFICATION: "/settings/notification",
  HELP: "/help",
  FAQ: "/help/faq",
  TUTORIALS: "/help/tutorials",
  CONTACTUS: "/help/contact",
  TERMANDCON: "/help/term-condition",
  PRIVACYPOLICY: "/help/privacy-policy",
  Manage_Subscription: "/manage-subscription",
  Create_Plan: "/create-plan",
  Billing: "/billing",
  Payment: "/payment",
  Purchase: "/purchase",
  Track_Status: "/track-status",
  PROFILE: "/profile",
  SECURITY: "/security",
  AUTHENTICATION: "/authentication",
  PREFERENCES: "/preferences",
  SESSION: "/session",
  MAILER: "/mailer",
  TERMS_AND_CONDITION_APP: "/app/terms-and-conditions",
  TERMS_AND_CONDITION_CLIENT: "/client/terms-and-conditions",
  TERMS_AND_CONDITION_STAFF: "/staff/terms-and-conditions",
  PRIVACY_POLICY_APP: "/app/privacy-policy",
  PRIVACY_POLICY_CLIENT: "/client/privacy-policy",
  PRIVACY_POLICY_STAFF: "/staff/privacy-policy",
};
