import Validator from "js-object-validation";
import { message } from "../common";
import { replaceAll } from "../helpers";

export const planValidator = (data) => {
  const validations = {
    title: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    title: {
      required: message.Required.replace(":item", "Title"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
