import React from "react";
import Skeleton from "react-skeleton-loader";
import { TableLoader } from "./Table";

export const BatchHistoryLoader = () => (
  <>
    {" "}

    <div className='productFormBox tableCard mb-3'>
          <div className='card-body'>
            <div className='table-responsive '>
            <TableLoader columns={5} rows={10} />
            </div>
          </div>
        </div>
            


    
  </>
);
