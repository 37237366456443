import React from "react";
import Idformats from "../../components/settings/Idformats";

function Settings(props) {
  return (
    <>
      <div className="container-fluid fluid-pd-2 pb-5">
        <Idformats />
      </div>
    </>
  );
}

export default Settings;
