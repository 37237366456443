/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ScanGraphComponent } from "./ScanGraphComponent";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import "./batches.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { ReportHeader } from "./ReportHeader";
import { ReportCard } from "./ReportCard";
import { ScanTable } from "./ScanTable";
import { TopScannedProductGraphComponent } from "./TopScannedProductGraphComponent";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import FileSaver from "file-saver";
import no_data from "../../../assets/img/no_data.svg";
import moment from "moment";

class ReportScan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: "Today",
      customStartDate: new Date(),
      customEndDate: new Date(),
      cardsLoading: true,
      tableLoading: true,
      cardsData: null,
      tableData: null,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      productsGraphLoading: true,
      productsGraphData: null,
      topScannedProd: null,
      scanData: {},
      scanStatsBy: { label: "This week", value: "Week" },
      isScanStatisticLoading: false,
      sortBy: "createdAt",
      order: "desc",
      isBatchExporting: false,
      totalScans: "",
    };
  }

  dateRangeCallback = async (start, end, label) => {
    this.setState({
      selectedTime: "Custom",
      customStartDate: new Date(moment(start)),
      customEndDate: new Date(moment(end)),
    });
    setTimeout(() => {
      this.fetchData();
    }, 500);
  };

  onPageChanged = (page) => {
    const { selectedTime, customStartDate, customEndDate, limit, currentPage } =
      this.state;
    if (page !== currentPage) {
      this.setState({
        currentPage: page,
      });
      let skip = limit * (page - 1);
      const payload = {
        selectedTime,
        customStartDate,
        customEndDate,
        skip,
        limit,
      };
      this.fetchTable(payload);
    }
  };

  changeSelectedTime = async (val) => {
    await this.setState({
      selectedTime: val,
      scanStatsBy: { value: val },
    });

    switch (val) {
      case "Today":
        this.setState({
          customStartDate: new Date(),
          customEndDate: new Date(),
        });
        break;
      case "Yesterday":
        this.setState({
          customStartDate: new Date(moment().subtract(1, "day")),
          customEndDate: new Date(moment().subtract(1, "day")),
        });
        break;
      case "Week":
        this.setState({
          customStartDate: new Date(moment().startOf("week")),
          customEndDate: new Date(moment().endOf("week")),
        });
        break;
      case "Month":
        this.setState({
          customStartDate: new Date(moment().startOf("month")),
          customEndDate: new Date(moment().endOf("month")),
        });
        break;
      case "Year":
        this.setState({
          customStartDate: new Date(moment().startOf("year")),
          customEndDate: new Date(moment().endOf("year")),
        });
        break;
      default:
    }

    this.fetchData();
  };

  componentDidMount = () => {
    this.fetchData();
  };

  setFilter = (sortBy, order) => {
    if (sortBy) {
      this.setState({
        sortBy: sortBy,
      });
    }
    if (order) {
      this.setState({
        order: order,
      });
    }
    this.fetchData();
  };

  fetchData = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      limit,
      currentPage,
      order,
      sortBy,
    } = this.state;
    let skip = limit * (currentPage - 1);
    const payload = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
    };
    let data = {
      selectedTime,
      customStartDate,
      customEndDate,
      order,
      sortBy,
    };
    this.fetchCards(payload);
    this.fetchTable(data);
    this.fetchProductsGraph(payload);
    // this.getScanStatistics();
  };

  fetchCards = async (data) => {
    this.setState({
      cardsLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_SCAN_CARDS.service,
      ApiRoutes.REPORTS_SCAN_CARDS.url,
      ApiRoutes.REPORTS_SCAN_CARDS.method,
      ApiRoutes.REPORTS_SCAN_CARDS.authenticate,
      data,
      undefined
    );

    this.setState({
      cardsLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      let scanData = response.data.data.scanGraph;
      delete response.data.data.scanGraph;
      this.setState({
        cardsData: response.data.data,
        scanData: scanData,
        totalScans: response?.data?.data?.totalScans,
      });
    }
  };

  fetchTable = async (data) => {
    this.setState({
      tableLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_SCAN_TABLE.service,
      ApiRoutes.REPORTS_SCAN_TABLE.url,
      ApiRoutes.REPORTS_SCAN_TABLE.method,
      ApiRoutes.REPORTS_SCAN_TABLE.authenticate,
      data,
      undefined
    );

    this.setState({
      tableLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        tableData: response.data.data.scans,
        totalRecords: response.data.data.totalRecords,
      });
    }
  };

  fetchProductsGraph = async (data) => {
    this.setState({
      productsGraphLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.service,
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.url,
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.method,
      ApiRoutes.REPORTS_SCAN_GRAPH_PRODUCTS.authenticate,
      data,
      undefined
    );

    this.setState({
      productsGraphLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { dataset, topScannedProduct },
      } = response;
      this.setState({
        productsGraphData: {
          labels: dataset.labels ? dataset.labels.reverse() : [],
          value: dataset.value ? dataset.value.reverse() : [],
        },
        topScannedProd: { topScannedProduct },
      });
    }
  };

  // getScanStatistics = async () => {
  //   const { scanStatsBy } = this.state;
  //   this.setState({
  //     isScanStatisticLoading: true,
  //   });
  //   const response = await new ApiHelper().FetchFromServer(
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.service,
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.url,
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.method,
  //     ApiRoutes.DASHBOARD_SCAN_STATISTICS.authenticate,
  //     { type: scanStatsBy ? scanStatsBy.value : 'week' },
  //     undefined
  //   );
  //   this.setState({
  //     isScanStatisticLoading: false,
  //   });
  //   if (response && response.isError) {
  //     // toast.error(response.messages[0]);
  //   } else {
  //     const {
  //       data: { currentScanDataSet, todayScans, totalScans, prevScanDataSet },
  //     } = response;
  //     this.setState({
  //       scanData: {
  //         currentScanDataSet,
  //         todayScans,
  //         totalScans,
  //         prevScanDataSet,
  //       },
  //     });
  //   }
  // };

  //  10/06/22

  onExport = async () => {
    const { selectedTime, customStartDate, customEndDate } = this.state;
    let data = {
      selectedTime,
      customStartDate,
      customEndDate,
    };
    let type = "csv";
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }

    let blobType, extension;
    if (data) {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.service,
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.url,
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.method,
      ApiRoutes.EXPORT_REPORTS_SCAN_TABLE.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `scan.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };

  render() {
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const thead = ["Scan ID", "Product Name", "Scan Location", "Scanned On"];

    const { isScanStatisticLoading, scanData, scanStatsBy, totalScans } =
      this.state;

    const { currentScanDataSet, prevScanDataSet } = scanData;

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>
        <div className="productFormBox reportBatches">
          <div className="row">
            <ReportHeader
              selectedTime={this.state.selectedTime}
              dateRangeCallback={this.dateRangeCallback}
              changeSelectedTime={this.changeSelectedTime}
              onExport={this.onExport}
              customStartDate={this.state.customStartDate}
              customEndDate={this.state.customEndDate}
              isExporting={this.state.isBatchExporting}
            />

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 mt-4 mt-md-0">
              <div className="row">
                <ReportCard
                  loading={this.state.cardsLoading}
                  title="Total Scans"
                  total={this.state.cardsData?.totalScans}
                  perc={this.state.cardsData?.totalScansCalculation}
                  prevString={this.state.cardsData?.dateString}
                  size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
                />

                <ReportCard
                  loading={this.state.cardsLoading}
                  title="Scans Increased"
                  total={this.state.cardsData?.addedScanPercentage + "%"}
                  perc={this.state.cardsData?.addedScansCalculation}
                  prevString={this.state.cardsData?.dateString}
                  size="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-6 mt-md-0"
                />

                <div className="col-md-12 mt-4">
                  <div className="card tableCard">
                    <div className="tabel-outer">
                      <div
                        className="table-responsive fixed-height-table"
                        style={{ height: "636px" }}
                      >
                        <ScanTable
                          loading={this.state.tableLoading}
                          thead={thead}
                          tbody={this.state.tableData}
                          setFilter={this.setFilter}
                          sortBy={this.state.sortBy}
                          order={this.state.order}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-pg px-0 px-lg-3 w-100">
                  {!this.state.tableLoading ? (
                    <>
                      <div className="float-left mt-2">
                        {this.state.totalRecords > this.state.limit ? (
                          <div>
                            <p className="totalRecords">
                              Page{" "}
                              {this.state.totalRecords > 0
                                ? this.state.currentPage
                                : 0}{" "}
                              of{" "}
                              {this.state.totalRecords % this.state.limit > 0
                                ? parseInt(
                                    this.state.totalRecords / this.state.limit
                                  ) + 1
                                : parseInt(
                                    this.state.totalRecords / this.state.limit
                                  )}{" "}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="float-right mt-0">
                        <div className="d-flex align-items-center tableFooter">
                          {this.state.totalRecords > this.state.limit ? (
                            <div className="float-right d-flex align-items-center">
                              <Pagination
                                totalRecords={this.state.totalRecords}
                                currentPage={this.state.currentPage}
                                pageLimit={this.state.limit}
                                onPageChanged={this.onPageChanged}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-4 mt-md-0">
              <div className="userReport dashboardPage">
                <div className="mycard">
                  <div className="">
                    <div className="cardHeader p-0">
                      <div className="row">
                        <div className="col-md-12">
                          <h6 className="mt-0 font-book ">Scans</h6>
                        </div>
                      </div>
                    </div>
                    <div className="cardBody pt-0 px-0">
                      <div id="chart">
                        {isScanStatisticLoading ? (
                          <GraphLoader />
                        ) : (
                          <>
                            {/* <div className="description">
                                <div className="head">{totalScans}</div>
                                <div className={"mb-2 today-count"}>
                                  Today {todayScans} scanned{" "}
                                  <img alt="" src={upIcon} alt="" />
                                </div>
                              </div> */}
                            {totalScans > 0 ? (
                              <div>
                                <ScanGraphComponent
                                  type={
                                    scanStatsBy && scanStatsBy.value === "Week"
                                      ? "Week"
                                      : scanStatsBy.value === "Month"
                                      ? "Month"
                                      : "Year"
                                  }
                                  scanDataSet={currentScanDataSet}
                                  prevScanDataSet={prevScanDataSet}
                                />
                              </div>
                            ) : (
                              <div
                                className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                                style={{ paddingRight: "20px" }}
                              >
                                <img alt="" src={no_data} />
                                <h5>No Data Yet</h5>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mycard mt-4">
                  <div className="">
                    <div className="cardHeader p-0  align-items-center justify-content-between">
                      <div className="row">
                        <div className="col-md-12">
                          <h6 className="mt-0 font-book ">
                            Top Scanned Products
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body px-0">
                      {this.state.productsGraphLoading ? (
                        <GraphLoader />
                      ) : (
                        <div>
                          {this.state.productsGraphData?.labels?.length ? (
                            <TopScannedProductGraphComponent
                              dataset={this.state.productsGraphData}
                              topScannedProd={this.state.topScannedProd}
                            />
                          ) : (
                            <div
                              className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                              style={{ paddingRight: "20px" }}
                            >
                              <img alt="" src={no_data} />
                              <h5>No Data Yet</h5>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportScan;
