/** @format */

export const message = {
  postCodeLength: ":item should have min 4 and max 6 numbers",
  Password:
    ":item must contain one uppercase, one lowercase, one number and one special character and should be 8 charater long",
  Required: "Please enter :item",
  SpaceNotAllowed: ":item cannot start with space",
  RequiredImage: "Please upload  :item",
  InvalidEmail: "Please enter valid email address",
  InvalidName: ":item must contain alphabets only",
  InvalidPhone: "Phone number must be in numeric digit only",
  RequiredReason: "Please choose the reason",
  RequriedPhone: "Please enter valid phone number",
  ValidLink: "Please enter valid youtube video link",
  InvalidImages: "Only images of type jpg, jpeg and png are allowed",
  InvalidItemCount: ":item count must be a whole number",
  MinItemCount: ":item count should be greater than :length character",
  InvalidItemCountNumber: "Item count number too big max 10 digit are allowed",
  PrintBatchError:
    "There is only :count available units remaining for this batch. Please enter an amount equal to or lower than the available count.",
  MaxLengthError: ":item length must not exceed :length character",
  SubModuleRequired: "Please select area of access",
  InvalidWebite: "Please enter valid website link",
  InvalidVideoName: "Only alphanumeric characters are allowed",
  PasswordMatch: "Password and confirm password didn't match",
};
