import React from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { AppConfig } from "../../../config";
import { Button } from "react-bootstrap";
import InputTextField from "../form/InputTextFeild";
import SubmitButton from "../form/SubmitButton";
import "react-datepicker/dist/react-datepicker.css";
import calender from "../../../assets/img/calenderIcon.svg";
import arrow from "../../../assets/img/selectArrow.svg";
import uploadProductImage from "../../../assets/img/ph_product.jpg";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "Poppins",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "Poppins",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrow} alt="" className="d-none" />
    </components.DropdownIndicator>
  );
};

export const BatchFormComponent = ({
  batchNumber,
  productId,
  expiryDate,
  itemCount,
  productOptions,
  isEditMode,
  errors,
  isAdding,
  onChange,
  onDateChange,
  onSelect,
  handleCancel,
  handleSubmit,
  loadProductOptions,
  productImage,
}) => {
  return (
    <form className=" batch_add" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 order-2 order-lg-0 mt-5 mt-lg-0">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Batch Number</label>
                <InputTextField
                  id="batch_number"
                  label={"Enter Batch Number"}
                  className={"form-control"}
                  placeholder="Enter Batch Number"
                  name="batchNumber"
                  value={batchNumber}
                  onChange={onChange}
                  errorMessage={
                    errors && errors.batchNumber ? errors.batchNumber : ""
                  }
                  maxLength={15}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Product Name</label>
                <AsyncSelect
                  components={{ DropdownIndicator }}
                  loadOptions={loadProductOptions}
                  placeholder={"-- Select Product -- "}
                  className="form-control p-0"
                  maxMenuHeight={200}
                  styles={customStyles}
                  defaultOptions={productOptions}
                  value={productId}
                  onChange={(selectedOption) => {
                    onSelect(selectedOption, "productId");
                  }}
                  noOptionsMessage={() =>
                    "No product found related to your search"
                  }
                  // isSearchable={false}
                  // isDisabled={isEditMode}
                />
                <img src={arrow} alt="" className="calneder_icon" />
                <div className={"text-danger"}>
                  {errors && errors.productId ? errors.productId : ""}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Enter Unit Count</label>
                <InputTextField
                  id="product_name"
                  label={"Item Count"}
                  type={"number"}
                  className={"form-control-product"}
                  placeholder="-- Unit Count --"
                  name="itemCount"
                  value={itemCount}
                  onChange={onChange}
                  min={1}
                  errorMessage={
                    errors && errors.itemCount ? errors.itemCount : ""
                  }
                />
                {/* <img src={arrow} alt="" className='calneder_icon'  /> */}
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              <div className="form-group">
                <label className={`addBatch-Labels `}>Expiry</label>
                <DatePicker
                  className={"form-control"}
                  placeholderText={"Select Date"}
                  selected={expiryDate}
                  minDate={new Date()}
                  onChange={onDateChange}
                  dateFormat="dd/MM/yyyy"
                  // dateFormat={DEFAULT_DATE_FORMAT?.toLowerCase()}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                <img src={calender} alt="" className="calneder_icon" />
                <div className={"text-danger"}>
                  {errors && errors.expiryDate ? errors.expiryDate : ""}
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                          <div className="form-group">
                                            <label className={`addBatch-Labels`}>Country</label>
                                            <AsyncSelect
                                              loadOptions={loadOriginOptions}
                                              placeholder={'Select Country'}
                                              className='form-control p-0'
                                              styles={customStyles}
                                              maxMenuHeight={200}
                                              defaultOptions={originOptions}
                                              value={originId}
                                              onChange={(selectedOption) =>
                                                onSelect(selectedOption, 'originId')
                                              }
                                              noOptionsMessage={() =>
                                                'No origin found related to your search'
                                              }
                                            />
                                            <div className={'text-danger'}>
                                              {errors && errors.originId ? errors.originId : ''}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                                          <div className="form-group">
                                            <label className={`addBatch-Labels`}>State</label>
                                            <Select
                                              placeholder={'Select State'}
                                              options={stateOptions}
                                              styles={customStyles}
                                              className='form-control p-0'
                                              value={state}
                                              maxMenuHeight={200}
                                              onChange={(selectedOption) =>
                                                onSelect(selectedOption, 'state')
                                              }
                                              noOptionsMessage={() => {
                                                return originId && originId.value
                                                  ? 'No states found related to your search'
                                                  : 'First select an origin';
                                              }}
                                            />
                                            <div className={'text-danger'}>
                                              {errors && errors.state ? errors.state : ''}
                                            </div>
                                          </div>
                                        </div> */}
            <div className="col-md-12">
              <div className="row align-items-center mt-3 batch-btn">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <Button
                      className="customCancelBtn mt-0"
                      variant=""
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <SubmitButton
                      className={"customSubmitBtn mt-0"}
                      text={isEditMode ? "UPDATE" : "ADD"}
                      isLoading={isAdding}
                      onSubmit={handleSubmit}
                      disabled={
                        !batchNumber || !productId || !expiryDate || !itemCount
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
          <div className="imageUploadBox">
            <div className="imageUploadView batch_img">
              {/* <img className="" src={uploadProductImage} alt="" /> */}
              {productId ? (
                <img
                  className=""
                  // src={
                  //   productId
                  //     ? `${AppConfig.FILES_ENDPOINT}${productId.productImage}`
                  //     : ""
                  // }

                  // src={
                  //   productId
                  //     ? productId.startsWith("https://")
                  //       ? productId
                  //       : `${AppConfig.FILES_ENDPOINT}${productImage}`
                  //     : uploadProductImage
                  // }
                  // alt=""

                  src={
                    productId.productImage
                      ? productId.productImage.startsWith("https://")
                        ? productId.productImage
                        : `${AppConfig.FILES_ENDPOINT}${productId.productImage}`
                      : uploadProductImage
                  }
                />
              ) : (
                <span className="noImage_text">
                  Product image will appear here
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
