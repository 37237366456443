import React from "react";
import Skeleton from "react-skeleton-loader";

export const FormatLoader = () => (
  <>
    <div className="card-body card-table">
      <div className="row">
        <div className="col-md-12">
          <div className="exportRow">
            <div className="d-flex align-items-center">
              <div className="position-relative mr-4">
                <Skeleton height={"48px"} width={"150px"} color="#e5e9ef" />
              </div>
              <div className="position-relative mr-4">
                <Skeleton height={"48px"} width={"150px"} color="#e5e9ef" />
              </div>
              <div className="position-relative">
                <Skeleton height={"48px"} width={"150px"} color="#e5e9ef" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <form className="product_add mr-0">
            <div className="position-relative">
              <Skeleton height={"14px"} width={"200px"} color="#e5e9ef" />
            </div>
            <Skeleton height={"40px"} width={"400px"} color="#e5e9ef" />
            <div>
              <Skeleton height={"14px"} width={"300px"} color="#e5e9ef" />
              <br />
              <Skeleton height={"14px"} width={"150px"} color="#e5e9ef" />
            </div>
            <div style={{ marginTop: "29px" }}>
              <div className="d-flex justify-content-center">
                <div className="mr-3">
                  <Skeleton height={"40px"} width={"130px"} color="#e5e9ef" />
                </div>
                <Skeleton height={"40px"} width={"130px"} color="#e5e9ef" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);
