import React from "react";
import Skeleton from "react-skeleton-loader";

export const ContactLoader = () => (
  <>
    <div className="row">
      <div className="col-md-12 titleRow">
        <h4>
          <Skeleton color="#e5e9ef" height={"40px"} width={"20%"} />
        </h4>
        <p>
          <Skeleton color="#e5e9ef" height={"24px"} width={"30%"} />
        </p>
      </div>
      <div className="col-md-6 col-lg-4">
        <div className="contactGrid" style={{ paddingBottom: "20px" }}>
          <Skeleton color="#e5e9ef" height={"35px"} width={"35px"} />
          <h5>
            <Skeleton color="#e5e9ef" height={"21px"} width={"138px"} />
          </h5>
          <h6>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </h6>
          <p>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </p>
        </div>
      </div>
      <div className="col-md-6 col-lg-4">
        <div className="contactGrid" style={{ paddingBottom: "20px" }}>
          <Skeleton color="#e5e9ef" height={"35px"} width={"35px"} />
          <h5>
            <Skeleton color="#e5e9ef" height={"21px"} width={"138px"} />
          </h5>
          <h6>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </h6>
          <p>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </p>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="contactGrid" style={{ paddingBottom: "20px" }}>
          <Skeleton color="#e5e9ef" height={"35px"} width={"35px"} />
          <h5>
            <Skeleton color="#e5e9ef" height={"21px"} width={"138px"} />
          </h5>
          <h6>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </h6>
          <p>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </p>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="contactGrid" style={{ paddingBottom: "20px" }}>
          <Skeleton color="#e5e9ef" height={"35px"} width={"35px"} />
          <h5>
            <Skeleton color="#e5e9ef" height={"21px"} width={"138px"} />
          </h5>
          <h6>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </h6>
          <p>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </p>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="contactGrid" style={{ paddingBottom: "20px" }}>
          <Skeleton color="#e5e9ef" height={"35px"} width={"35px"} />
          <h5>
            <Skeleton color="#e5e9ef" height={"21px"} width={"138px"} />
          </h5>
          <h6>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </h6>
          <p>
            <Skeleton color="#e5e9ef" height={"12px"} width={"120px"} />
          </p>
        </div>
      </div>
    </div>
  </>
);
