import React, { useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import crossIcon from "../../../../assets/img/closeModal.svg";
import dltIcon from "../../../../assets/img/trash-22.svg";
import info_btn from "../../../../assets/img/info_btn.svg";
import plusIcon from "../../../../assets/img/plusIconWhite.svg";
import googleIcon from "../../../../assets/img/no_mobile_number.svg";
// import { Button, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { useEffect } from "react";
import AsyncSelect from "react-select/async";
import { phoneInfo } from "../../../../validators/poneInfo";
import { areaCode } from "../../../../common/phonecode";
import { components } from "react-select";
import OTPInput from "otp-input-react";
import { PasswordSettingLoader } from "../../loader/HelpLoaders/PasswordSettingLoader";
import DeleteBackup from "./DeleteBackup";

function SmsOtp({ clientEdit, pageLoader, getProfile }) {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        Add your mobile number for additional authentication, it will help
        secure your account from unauthorised access.
      </Popover.Body>
    </Popover>
  );
  const [emailError, setEmailError] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [otp, setOtp] = useState(false);
  const [otpEdit, setOtpEdit] = useState(false);
  const [PhoneShow, setPhoneShow] = useState(false);
  const [backupPhone, setBackupPhone] = useState();
  const [countryCode, setCountryCode] = useState({
    label: "+353",
    value: "+353",
  });
  const [password, setPassword] = useState();
  const [deleteShow, setDeleteShow] = useState();
  const [deleteLoader, setDeleteLoader] = useState();
  const [deleteError, setDeleteError] = useState();

  const passwordChange = (event) => {
    const { value } = event.target;
    setPassword(value.trimLeft());
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="d-none" />
      </components.DropdownIndicator>
    );
  };

  const customStylesAsync = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "unset",
        border: "0px",
      },
      // overflow: "hidden",
      // whiteSpace: "nowrap",
      border: "0px",
      padding: "0px",
      height: "100%",
      backgroundColor: "transparent",
      boxShadow: "unset",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
    }),
    container: (provided, state) => ({
      ...provided,
      maxWidth: "50px",
      padding: "0px",
      backgroundColor: "transparent",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxWidth: "100%",
    }),
  };

  let codeData = [];
  areaCode.forEach((element) => {
    codeData.push({ label: element.dial_code, value: element.dial_code });
  });

  const loadAreaCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = codeData.filter((code) =>
        code.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  const onChangePhone = (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "secondPhone" && isNaN(value)) {
      return;
    }

    setBackupPhone(value.trimLeft());
  };

  const otpChange = (value) => {
    setOtpEdit(value);
  };

  const onCodeSelect = (label) => {
    if (label) {
      setCountryCode(label);
    }
  };

  const phoneSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      // secondPhone: backupPhone,
      countryCode: countryCode?.value,
      backupPhone: backupPhone,
    };
    const { isValid, errors } = phoneInfo(data);
    if (isValid) {
      setEmailError();
      setEmailLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ADD_CLIENT_EMAIL.service,
        ApiRoutes.ADD_CLIENT_EMAIL.url,
        ApiRoutes.ADD_CLIENT_EMAIL.method,
        ApiRoutes.ADD_CLIENT_EMAIL.authenticate,
        undefined,
        data
      );

      if (response && response.isError) {
        toast.error(response.messages[0]);
        setEmailLoader(false);
      } else {
        const data = response?.data?.data.otp;
        setOtp(data ? data : response.messages[0]);
        setEmailLoader(false);
        toast.success(response.messages[0]);
      }
    } else {
      setEmailLoader(false);
      setEmailError(errors);
    }
  };

  const phoneVerfiy = async (event) => {
    event && event.preventDefault();

    setEmailLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_EMAIL.service,
      ApiRoutes.VERIFY_EMAIL.url,
      ApiRoutes.VERIFY_EMAIL.method,
      ApiRoutes.VERIFY_EMAIL.authenticate,
      undefined,
      {
        countryCode: countryCode?.value,
        // secondPhone: backupPhone,
        backupPhone: backupPhone,
        otp: otpEdit,
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setOtpEdit();
      setEmailLoader(false);
    } else {
      setEmailLoader(false);
      setPhoneShow(false);
      setOtp();
      setOtpEdit();
      toast.success(response.messages[0]);
      getProfile();
      setCountryCode();
      setBackupPhone();
    }
  };

  const deleteMfa = async () => {
    if (password) {
      setDeleteLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_EMAIL.service,
        ApiRoutes.DELETE_EMAIL.url,
        ApiRoutes.DELETE_EMAIL.method,
        ApiRoutes.DELETE_EMAIL.authenticate,
        undefined,
        {
          backupPhone: deleteShow,
          password: password,
        }
      );
      setDeleteLoader(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        toast.success(response.messages[0]);
        setDeleteShow(false);
        setDeleteError();
        setPassword();
        getProfile();
      }
    } else {
      setDeleteError("Please enter password");
    }
  };

  const onPopupCancel = () => {
    setOtp();
  };

  const close = () => {
    setPhoneShow(false);
    setOtp();
    setBackupPhone();
    setCountryCode({
      label: "+353",
      value: "+353",
    });
  };
  const onDeleteClose = () => {
    setPassword();
    setDeleteShow(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings profileMainBox smsAuth"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>SMS Based OTP</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row">
              <div className="col-md-12">
                {pageLoader ? (
                  <PasswordSettingLoader />
                ) : (
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        Put a double security check by adding your phone number
                        with your account to avoid any unauthorized access
                      </p>

                      {!clientEdit?.backupPhone?.length > 0 ? (
                        <div className="d-flex align-items-center justify-content-center flex-column otp_form mt-4 qrCodeBox mb-4">
                          <img src={googleIcon} alt="" />
                          <h5 className="mb-2" style={{ fontWeight: "500" }}>
                            No Mobile Number Added Yet!
                          </h5>
                        </div>
                      ) : (
                        <ul className="listing mt-0 mb-0">
                          {clientEdit?.backupPhone?.map((info, index) => (
                            <li key={index}>
                              {info.countryCode} | {info.phone}
                              <Button
                                variant=""
                                onClick={() => setDeleteShow(info)}
                                className=""
                              >
                                <img src={dltIcon} />
                              </Button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-12 text-center">
                      <Button
                        className="addEmailBtn"
                        variant=""
                        onClick={() => setPhoneShow(true)}
                      >
                        <img src={plusIcon} /> Add Phone Number
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="emailAddresssModal phoneNumber"
        show={PhoneShow}
        onHide={close}
      >
        <Modal.Header>
          <Modal.Title>Add Phone Number</Modal.Title>
          <div>
            <Button variant="" onClick={otp ? onPopupCancel : close}>
              <img src={crossIcon} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p>You can use your Phone Number</p>
          {!otp ? (
            <>
              <form style={{ maxWidth: "455px" }}>
                <div className="form-group">
                  <div className="d-flex form-control group-input companyDeatilContactNum">
                    <AsyncSelect
                      cacheOptions
                      components={{ DropdownIndicator }}
                      loadOptions={loadAreaCodeData}
                      styles={customStylesAsync}
                      placeholder={"00"}
                      className="areaCode"
                      maxMenuHeight={200}
                      // defaultInputValue={+353}
                      width="200px"
                      defaultOptions={codeData}
                      value={countryCode}
                      name="countryCode"
                      onChange={(selectedOption) => {
                        onCodeSelect(selectedOption, "countryCode");
                      }}
                      isSearchable={true}
                    />
                    <InputTextFeild
                      name="backupPhone"
                      type="tel"
                      placeholder=""
                      value={backupPhone}
                      onChange={onChangePhone}
                      min="0"
                      maxLength={12}
                    />
                  </div>
                  <div className="text-danger">{emailError?.backupPhone}</div>
                  <div className="text-danger">{emailError?.countryCode}</div>
                  <Button
                    disabled={emailLoader}
                    onClick={phoneSubmit}
                    variant=""
                    className="otp_btn"
                  >
                    {emailLoader ? (
                      <>
                        <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                        Get OTP
                      </>
                    ) : (
                      <> Get OTP</>
                    )}
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <form>
              <p>Verify your phone</p>
              <div className="d-flex align-items-center justify-content-start otp_form">
                <OTPInput
                  value={otpEdit}
                  onChange={otpChange}
                  OTPLength={6}
                  otpType="number"
                  inputClassName="form-control p-0"
                />
              </div>
              <Button
                variant=""
                type="button"
                disabled={emailLoader}
                onClick={onPopupCancel}
                className="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                disabled={emailLoader}
                onClick={phoneVerfiy}
                variant=""
                className="otp_btn"
              >
                {emailLoader ? (
                  <>
                    <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                    Verify
                  </>
                ) : (
                  <> Verify</>
                )}
              </Button>
            </form>
          )}
        </Modal.Body>
      </Modal>

      <DeleteBackup
        password={password}
        deleteShow={deleteShow}
        deleteClose={onDeleteClose}
        passwordChange={passwordChange}
        error={deleteError}
        deleteMfa={deleteMfa}
        loader={deleteLoader}
        title={"Delete Phone Number"}
      />
    </>
  );
}

export default SmsOtp;
