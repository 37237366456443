import React, { useState } from "react";

function InputTextFeild(props) {
  // const handleOnChange = (event) => {
  //     setText(event.target.value);
  // }
  // const [text, setText] = useState();

  return (
    <>
      <div className="form-group mb-20px">
        <input
          type={props.type}
          className="form-control"
          id={props.id}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          min={props.min || ""}
          maxLength={props.maxLength || ""}
          disabled={props.disabled || ""}
        />
        <div className={"text-danger"}>{props.errorMessage}</div>
      </div>
    </>
  );
}

export default InputTextFeild;
