import React, { useEffect, useRef } from "react";
// import GoogleMapReact from 'google-map-react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
// import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker"; // Load worker code separately with worker-loader
// import markerIcon from '../../../assets/img/marker.png';
// import locmarkerIcon from '../../../assets/img/loc-marker.svg';
import locationIcon from "../../../assets/img/home_icon.svg";
import { AppConfig } from "../../../config";
// import 'mapbox-gl/dist/mapbox-gl.css';

// mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken = AppConfig.MAPBOX_ACCESS_TOKEN;
// const DetailComponent = ({ text }) => (
//   <div
//     style={{
//       width: '0px',
//       height: '0px',
//       left: '-15.0586px',
//       top: '-17.632px',
//       backgroundColor: 'transparent',
//       position: 'absolute',
//     }}
//     className={'map-icon-hover'}
//   >
//     <div className={'main-marker'}>
//       <img src={markerIcon} width={15} alt='' />
//     </div>
//     <div className={'map-icon-title'}>
//       <img src={locationIcon} alt='' /> {text}
//     </div>
//   </div>
// );

const SimpleMap = ({ latitude, longitude, storeLocation }) => {
  let mapContainer = useRef(null);
  const center = {
    lat: Number(latitude),
    lng: Number(longitude),
  };
  const zoom = 5;
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [center.lng, center.lat],
      zoom,
    });
    // new mapboxgl.Marker().setLngLat([center.lng, center.lat]).addTo(map);
    map.on("load", function () {
      map.resize();
      map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        // Add an image to use as a custom marker
        function (error, image) {
          if (error) throw error;
          map.addImage("custom-marker", image);

          map.addSource("places", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {
                    description: `<div><img src=${locationIcon} alt='' /> ${storeLocation}</div>`,
                  },
                  geometry: {
                    type: "Point",
                    coordinates: [center.lng, center.lat],
                  },
                },
              ],
            },
          });

          // Add a layer showing the places.
          map.addLayer({
            id: "places",
            type: "symbol",
            source: "places",
            layout: {
              "icon-image": "custom-marker",
              "icon-allow-overlap": true,
            },
          });
        }
      );

      // Create a popup, but don't add it to the map yet.
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.on("mouseenter", "places", function (e) {
        // Change the cursor style as a UI indicator.
        map.getCanvas().style.cursor = "pointer";

        let coordinates = e.features[0].geometry.coordinates.slice();
        let description = e.features[0].properties.description;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on("mouseleave", "places", function () {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });
    });
    // eslint-disable-next-line
  }, [latitude, longitude]);
  return (
    <div>
      <div ref={(el) => (mapContainer = el)} className="mapContainer" />
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: AppConfig.GOOGLE_API_KEY }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <DetailComponent text={storeLocation} />
      </GoogleMapReact> */}
    </div>
  );
};

export default SimpleMap;
