import React from "react";
import { Modal } from "react-bootstrap";
import modalClose2 from "../../../../assets/img/modalClose2.svg";
import InputTextField from "../../../components/form/InputTextFeild";
import SubmitButton from "../../../components/form/SubmitButton";

export default function ContactForm({
  show,
  close,
  handleSubmit,
  update,
  contactData,
  onChange,
  error,
}) {
  return (
    <Modal className="logoutModal Custom3" show={show} onHide={close}>
      <Modal.Header>
        <div
          className="d-flex align-items-center justify-content-between w-100"
          style={{
            marginBottom: "1em",
            paddingBottom: ".5em",
            borderBottom: "1px solid #e7e7e75c",
          }}
        >
          <Modal.Title>Contact</Modal.Title>
          <button onClick={close}>
            <img src={modalClose2} />
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className={`form-control-label`}>Name</label>
                <InputTextField
                  id="name"
                  label={"Name"}
                  className={"form-control"}
                  placeholder="Enter Name"
                  name="name"
                  value={contactData.name}
                  onChange={onChange}
                  errorMessage={error && error.name ? error.name : ""}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className={`form-control-label`}>Email</label>
                <InputTextField
                  id="email"
                  label={"Email"}
                  className={"form-control"}
                  placeholder="Enter Email"
                  name="email"
                  value={contactData.email}
                  onChange={onChange}
                  errorMessage={error && error.email ? error.email : ""}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className={`form-control-label`}>Message</label>
                <textarea
                  className="form-control form-control-user wt-resize"
                  cols="50"
                  id="message"
                  aria-describedby="message"
                  rows="4"
                  placeholder="Type here..."
                  name={"message"}
                  onChange={onChange}
                  value={contactData.message}
                  maxLength={50}
                />
                <div className="text-danger">
                  {error && error.message ? error.message : ""}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="d-flex justify-content-center modal-footer">
                <SubmitButton
                  className={"logout-btn mt-0"}
                  text={"Submit"}
                  isLoading={update}
                  onSubmit={handleSubmit}
                  disabled={update}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
