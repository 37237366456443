import React from "react";
import InputTextFeild from "../../form/InputTextFeild";
import editUserPic from "../../../../assets/img/editUserPic.svg";
import userProile from "../../../../assets/img/userProile.jpg";
import editBtn from "../../../../assets/img/editBtn.svg";
import { AppConfig, genderOptions } from "../../../../config";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../../helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { BatchViewLoader } from "../../loader/Resusable/BatchView";

function OwnerInfo({
  handleChange,
  clientEdit,
  onFileChange,
  isEdit,
  onEdit,
  onCancel,
  onSelect,
  allCountry,
  onDateChange,
  handleOwnerSubmit,
  error,
  dateError,
  clientLoader,
  pageLoader,
}) {
  const loadCountryData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
  };

  return (
    <>
      <div className="tableFilter overflow-hidden pl-0 mb-0 mt-0">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ margin: "0px 0 25px 0" }}
        >
          <h3 className="m-0">Owner Information</h3>
          <div className="d-flex align-items-center">
            {isEdit ? (
              <>
                <button className="editbtn_table mr-2" onClick={onCancel}>
                  Cancel
                </button>
                <button
                  disabled={clientLoader}
                  onClick={handleOwnerSubmit}
                  className="editbtn_table"
                >
                  {clientLoader ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                      Save
                    </>
                  ) : (
                    <>Save</>
                  )}
                </button>
              </>
            ) : (
              <button className="editbtn_table" onClick={onEdit}>
                <img src={editBtn} style={{ marginRight: "8px" }} alt="" /> Edit
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="productFormBox profileMainBox OwnerInformationCard">
        {pageLoader ? (
          <BatchViewLoader />
        ) : (
          <form className="profileForm">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 d-flex justify-content-center">
                <div
                  className="imgViewMainBox"
                  style={{ maxWidth: "264px", minWidth: "264px" }}
                >
                  <div className="imgViewBox">
                    <img
                      alt=""
                      className="userProfile"
                      src={
                        clientEdit?.profile_picture
                          ? clientEdit?.profile_picture.startsWith("https://")
                            ? clientEdit?.profile_picture
                            : `${AppConfig.FILES_ENDPOINT}${clientEdit?.profile_picture}`
                          : userProile
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = userProile;
                      }}
                    />
                    {isEdit && (
                      <div className="editUserPic">
                        <img src={editUserPic} alt="" />
                        <input
                          disabled={!isEdit}
                          type="file"
                          className="cursor-pointer"
                          accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                          name="profile_picture"
                          onChange={onFileChange}
                        />
                      </div>
                    )}
                  </div>
                  <h6 className="userName">
                    {[clientEdit?.first_name, clientEdit?.last_name]
                      .filter(Boolean)
                      .join(" ") || "-"}
                  </h6>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>First Name</label>
                      <InputTextFeild
                        name="first_name"
                        value={clientEdit?.first_name}
                        maxLength={30}
                        onChange={handleChange}
                        errorMessage={error?.first_name}
                        disabled={!isEdit}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>Last Name</label>
                      <InputTextFeild
                        name="last_name"
                        value={clientEdit?.last_name}
                        maxLength={30}
                        onChange={handleChange}
                        errorMessage={error?.last_name}
                        disabled={!isEdit}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    {!isEdit ? (
                      <div className="form-group position-relative">
                        <label className={`addBatch-Labels`}>Gender</label>
                        <div className="position-relative">
                          <div className="form-group">
                            <div className="form-control">
                              {clientEdit?.gender}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>Gender</label>
                        <AsyncSelect
                          placeholder={""}
                          className={"form-control"}
                          defaultOptions={genderOptions}
                          styles={customStyles}
                          value={{
                            label: clientEdit?.gender,
                            value: clientEdit?.gender,
                          }}
                          onChange={(selectedOption) =>
                            onSelect(selectedOption, "gender")
                          }
                        />
                        <div className="text-danger">{error?.gender}</div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    {!isEdit ? (
                      <div className="form-group position-relative">
                        <label className={`addBatch-Labels`}>
                          Date of Birth
                        </label>
                        <div className="position-relative">
                          <div className="form-group">
                            <div className="form-control">
                              {clientEdit?.birthDateFormat}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>
                          Date of Birth
                        </label>
                        <DatePicker
                          className={"form-control"}
                          placeholder={`Date of Birth(DD/MM/YYYY)`}
                          selected={clientEdit?.birth_date}
                          maxDate={moment().subtract(18, "y")._d}
                          onChange={onDateChange}
                          // dateFormat="dd/MM/yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          disabled={!isEdit}
                        />
                        {dateError && (
                          <div className="text-danger">
                            Please enter the birth date
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    {!isEdit ? (
                      <div className="form-group position-relative">
                        <label className={`addBatch-Labels`}>Nationality</label>
                        <div className="position-relative">
                          <div className="form-group">
                            <div className="form-control">
                              {clientEdit?.country}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>Nationality</label>
                        <AsyncSelect
                          className={"form-control"}
                          loadOptions={loadCountryData}
                          placeholder={"Select"}
                          styles={customStyles}
                          maxMenuHeight={200}
                          defaultOptions={allCountry}
                          value={{
                            label: clientEdit?.country,
                            value: clientEdit?.country,
                          }}
                          onChange={(selectedOption) =>
                            onSelect(selectedOption, "country", clientEdit)
                          }
                          noOptionsMessage={() =>
                            "No option found related to your search"
                          }
                        />
                        <div className="text-danger">{error?.country}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default OwnerInfo;
