import React from "react";
import { AppConfig } from "../../../config";
import dummyImage from "../../../assets/img/noimage.jpeg";
import InputCheckbox from "../form/InputCheckbox";
import "./product.css";
import editpro_icon from "../../../assets/img/editpro_icon.svg";
import deletepro_icon from "../../../assets/img/deletepro_icon.svg";
import editIcon from "../../../assets/img/editPen_Icon.svg";
import trashIcon from "../../../assets/img/trashIcon.svg";

const ProductRow = ({ productData, onExpand, onEdit, onDelete }) => {
  const {
    productName = "-",
    productNumber = "-",
    productDescription = "-",
    productImage = "-",
    isExpand = false,
    otherImages = [],
  } = productData;

  return (
    <tr className="btachRow">
      {/* <td>                    <InputCheckbox< 
                        id='customCheck'
                        name='islogged'
                        value=""
                    /></td> */}
      <td>
        <span className="textContol d-flex align-items-center position-relative">
          <span className="hoverBack">{otherImages.length > 0 ? "+"+otherImages.length : '' }</span>
          {productImage ? (
            <span
              className="list-img"
              style={{
                backgroundImage:
                  "url(" + (productImage
                    ? productImage.startsWith("https://")
                      ? productImage
                      : `${AppConfig.FILES_ENDPOINT}${productImage}`
                    : dummyImage) + ")",
              }}
            ></span>
          ) : (
            // <img
            //   className="list-img"
            //   src={
            //     productImage
            //       ? productImage.startsWith("https://")
            //         ? productImage
            //         : `${AppConfig.FILES_ENDPOINT}${productImage}`
            //       : dummyImage
            //   }
            //   // onError={(e) => {
            //   //   e.target.onerror = null;
            //   //   e.target.src = dummyImage;
            //   // }}
            //   alt=""
            // />
            <div className="tableTextSplit">
              {productName
                .split(" ")
                .slice(0, 2)
                .map((el) => el[0].toUpperCase())}
            </div>
          )}
          {productNumber}
        </span>
      </td>
      <td className="pname-desc">
        <span class="textContol"> {productName} </span>{" "}
      </td>
      <td className="pre-desc">
        <div className="d-flex align-items-center hoverEfect">
          <span class="textContol" style={{ opacity: "0.75", paddingRight: "105px" }}>
            {productDescription && productDescription.length <= 100 ? (
              productDescription
            ) : (
              <p className="mb-0" 
              // style={{ paddingRight: "105px" }}
              >
                {isExpand
                  ? productDescription
                  : productDescription.substr(0, 100)}
                <span
                  className="bold-desc cursor-pointer more-btn"
                  onClick={onExpand}
                >
                  {!isExpand ? " ...More" : "...Read less"}
                </span>
              </p>
            )}
            {/* {productDescription}
            <span className='bold-desc'> Read More</span> */}
          </span>
          <div className="d-flex align-item-center editProduct-btn">
            <div className="imgHover_effect">
              <img
                src={trashIcon}
                alt=""
                className="m-2 cursor-pointer"
                onClick={onDelete}
              />
            </div>
            <div className="imgHover_effect">
              <img
                src={editIcon}
                alt=""
                className="m-2 cursor-pointer"
                onClick={onEdit}
              />
            </div>
          </div>
        </div>
      </td>
      {/* <td>
        <span className='text-control'>
          <img
            className='list-img'
            src={productImage
              ? productImage.startsWith("https://")
                ? productImage
                : `${AppConfig.FILES_ENDPOINT}${productImage}`
              : dummyImage
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = dummyImage;
            }}
            alt=''
          />
        </span>
      </td>  */}
      {/* <td>
        <div className='d-flex align-items-center'>
          <button className='editBtn mr-3' onClick={onEdit}>
            <img className='mr-3' src={editpro_icon} />
            Edit Product
          </button>
          <button className='deleteBtn' onClick={onDelete}>
              <img className='mr-3' src={deletepro_icon} />
              Delete Product
          </button>
        </div>
      </td> */}
    </tr>
  );
};

export default ProductRow;
