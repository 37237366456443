import React, { useState } from "react";
import { ApiRoutes } from "../../../config";
import upcomingNodata from "../../../assets/img/upcomingNodata.svg";
import CreatePlanModal from "./CreatePlanModal";
import UpdatePlanModal from "./UpdatePlanModal";
import { CardLoaderEvent } from "../loader/Dashboard/CardLoaderEvent";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";

export default function UpcomingEvents({ events, submitTrigger, isLoading }) {
  const [planModalShow, setPlanModalShow] = useState(false);
  const [planModalShow2, setPlanModalShow2] = useState(false);
  const [eventDetail, setEventDetail] = useState();

  const createModal = () => {
    setPlanModalShow(true);
  };

  const getPlanById = async (id) => {
    console.log("id", id);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EVENT_DETAIL.service,
      ApiRoutes.EVENT_DETAIL.url.replace(":id", id),
      ApiRoutes.EVENT_DETAIL.method,
      ApiRoutes.EVENT_DETAIL.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      setPlanModalShow2(data);
      setEventDetail(data);
    }
  };

  return (
    <>
      <div
        className="mycard py-0 upcomingEvent"
        // style={{ paddingRight: "12px" }}
      >
        {isLoading ? (
          <CardLoaderEvent />
        ) : (
          <>
            <div className="cardHeader" style={{ padding: "20px 0 8px" }}>
              <h4>Upcoming Events</h4>
              <span
                className="addProductbtn no-background text-center px-0 d-flex align-items-center justify-content-center"
                style={{ minWidth: "102px", height: "40px", color: "#ffff" }}
                onClick={createModal}
              >
                {" "}
                Create Plan
              </span>
            </div>
            {events && events.length > 0 ? (
              <div
                className="cardBody"
                style={{ minHeight: "513px", maxHeight: "513px" }}
              >
                <div className="scrollBox" style={{ minHeight: "468px" }}>
                  {events &&
                    events.map((info, index) => (
                      <div
                        className="customCard"
                        key={index}
                        onClick={() => getPlanById(info._id)}
                      >
                        <div className="d-flex justify-content-between">
                          <h3>{info.title}</h3>
                          {/* "eventDate" */}
                          <h4
                            className={
                              index === 0
                                ? "eventDate"
                                : index === 1
                                ? "nextEventDate"
                                : "futureEventDate"
                            }
                          >
                            {info.dateFormat || "-"}
                          </h4>
                        </div>
                        <p>{info.eventDescription}</p>
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div
                className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                style={{ minHeight: "584px", padding: "0", marginTop: "-70px" }}
              >
                <img src={upcomingNodata} />
                <h5>No Upcoming Events!</h5>
                <p>
                  Start creating your plan by clicking on create plan button
                  below
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <CreatePlanModal
        planModalShow={planModalShow}
        planModalClose={() => setPlanModalShow(false)}
        getUpcomingEvent={events}
        submitTrigger={submitTrigger}
      />

      <UpdatePlanModal
        planModalShow={planModalShow2}
        planModalClose={() => setPlanModalShow2(false)}
        getUpcomingEvent={events}
        eventDetail={eventDetail}
        submitTrigger={submitTrigger}
      />
    </>
  );
}
