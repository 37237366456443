import React from "react";
import { TableLoader } from "../../components/loader/Resusable/Table";
import SubmitButton from "../form/SubmitButton";
import VideoRow from "./VideoRow";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";

export const ListVideoComponent = ({
  videos,
  name,
  link,
  errors,
  isFormOpen,
  isAdding,
  isLoading,
  onChange,
  onSubmit,
  onCancel,
  deleteVideo,
  onEdit,
  setFilter,
  sortBy,
  order,
  handleVideo,
}) => {
  return (
    <div className="tableCard card">
      <div className="tabel-outer">
        <div className="table-responsive fixed-height-table">
          {isLoading ? (
            <TableLoader columns={4} rows={10} />
          ) : (
            <table className="table batchTable" width="100%" cellSpacing="0">
              <thead className="pad-top">
                <tr className="batchesHeader">
                  {/* <th>
                  <InputCheckbox 
                    id='customCheck'
                    name='islogged'
                    value=""
                  />
                </th> */}
                  <th>
                    Name
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "name"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setFilter(
                            "name",
                            sortBy === "name"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>
                    Link
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "link"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setFilter(
                            "link",
                            sortBy === "link"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>
                    Uploaded on
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "created_at"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setFilter(
                            "created_at",
                            sortBy === "created_at"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {isFormOpen ? (
                  <tr className="form-row-table">
                    {/* <td>
                    <InputCheckbox 
                      id='customCheck'
                      name='islogged'
                      value=""
                    />
                  </td> */}
                    <td>
                      <input
                        type="text"
                        className={`addVideoFormInput ${
                          errors &&
                          (errors.name || errors.videoFile || errors.link)
                            ? "has-error"
                            : ""
                        }`}
                        placeholder="Enter Video Name"
                        name="name"
                        value={name}
                        onChange={onChange}
                        maxLength={30}
                      />
                      {errors &&
                      (errors.name || errors.videoFile || errors.link) ? (
                        <div className={"text-danger error-text-video"}>
                          {errors.name ? errors.name : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    <td colSpan={"2"}>
                      <input
                        type="text"
                        placeholder="Enter Video Link"
                        className={`addVideoFormInput w-100 ${
                          errors &&
                          (errors.name || errors.videoFile || errors.link)
                            ? "has-error"
                            : ""
                        }`}
                        name="link"
                        value={link}
                        onChange={onChange}
                      />
                      {errors &&
                      (errors.name || errors.videoFile || errors.link) ? (
                        <div className={"text-danger error-text-video"}>
                          {errors && (errors.videoFile || errors.link)
                            ? errors.videoFile || errors.link
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td></td> */}
                    <td>
                      <div className="d-flex align-items-center addeditVideo_btns">
                        <button className="deleteBtn" onClick={onCancel}>
                          {/* <img className='mr-2' src={crossIcon} /> */}{" "}
                          Cancel{" "}
                        </button>
                        {/* <button
                        className={'printButton ml-2'}
                        text={'Save'}
                        isLoading={isAdding}
                        onSubmit={onSubmit}
                      >
                        <img className='mr-2' src={publishIcon} />
                        {' '}
                        Save{' '}
                      </button> */}
                        <SubmitButton
                          className={"printButton ml-2"}
                          text={"Save"}
                          isLoading={isAdding}
                          onSubmit={onSubmit}
                        />
                      </div>
                      {errors &&
                      (errors.name || errors.videoFile || errors.link) ? (
                        <div
                          className={"text-danger error-text-video invisible"}
                        >
                          {errors && (errors.videoFile || errors.link)
                            ? errors.videoFile || errors.link
                            : ""}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ) : null}
                {videos && videos.length ? (
                  videos.map((e) => (
                    <VideoRow
                      videodata={e}
                      key={e.id}
                      deleteVideo={() => deleteVideo(e.id)}
                      editVideo={() => onEdit(e)}
                      isPublished={e.isPublished}
                      handleVideo={handleVideo}
                    />
                  ))
                ) : !isFormOpen ? (
                  <tr className="no-tr">
                    <td
                      colSpan={5}
                      className={
                        "text-center image-no-data-sm nodataTablePosition"
                      }
                      // style={{ height: "56vh" }}
                    >
                      <>
                        <img src={noData} alt="" style={{ width: "110px" }} />
                        <p>
                          The data you're looking is not available at this
                          moment
                        </p>
                      </>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
