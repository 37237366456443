import React, { useState } from "react";
import calender from "../../../assets/img/calenderIcon.svg";
import InputTextFeild from "../../components/form/InputTextFeild";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import rightnexticon from "../../../assets/img/rightnexticon.svg";
import EditIcon from "../../../assets/img/registerEdit.svg";
import { AppConfig } from "../../../config";

function Preview({
  ownerData,
  userRegisterData,
  handleSubmit,
  onFileChange,
  isEditStep,
}) {
  const [preview, setPreview] = useState(false);
  const [preview2, setPreview2] = useState(false);
  const [preview3, setPreview3] = useState(false);
  return (
    <>
      <div>
        <div className="row mb-2">
          <div className="col-md-6 text-left">
            <h5 className="Register_head">Owner Details</h5>
          </div>
          <div className="col-md-6 text-right">
            <Button
              className="registerEdit"
              variant=""
              onClick={() => isEditStep(1)}
            >
              <img src={EditIcon} alt="" className="mr-2" /> Edit
            </Button>
          </div>
        </div>
        <div className="row activeForm">
          <div className="col-md-6">
            <div className="form-group">
              <label>First Name</label>
              <p className="prefieldData">{ownerData.first_name}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Last Name</label>
              <p className="prefieldData">{ownerData.last_name}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Gender</label>
              <p className="prefieldData">{ownerData.gender}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Date of Birth</label>
              <p className="prefieldData">
                {ownerData.birth_date
                  ? moment(ownerData.birth_date).format("DD/MM/yyyy")
                  : "-"}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Nationality</label>
              <p className="prefieldData">{ownerData.country}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="uploadPhoto">Company Logo</label>
              {ownerData.profile_picture && (
                <div className="customChooseFile">
                  <span>
                    <InputTextFeild
                      name="profile_picture"
                      type="text"
                      placeholder=""
                      value={ownerData.profile_picture}
                      readOnly={true}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-md-6 text-left">
            <h5 className="Register_head">Company Details</h5>
          </div>
          <div className="col-md-6 text-right">
            <Button
              className="registerEdit"
              variant=""
              onClick={() => isEditStep(2)}
            >
              <img src={EditIcon} alt="" className="mr-2" /> Edit
            </Button>
          </div>
        </div>
        <div className="row activeForm">
          <div className="col-md-6">
            <div className="form-group">
              <label>Company Name</label>
              <p className="prefieldData">{userRegisterData.companyName}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Company ID</label>
              <p className="prefieldData">{userRegisterData?.companyId}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Website</label>
              <p className="prefieldData">{userRegisterData.website}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Inside USA & Canada?</label>
              <p className="prefieldData">
                {userRegisterData.insideUSACanada ? "Yes" : "No"}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Phone Number</label>
              <p className="prefieldData">
                {userRegisterData.countryCode} {userRegisterData.phone}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Email</label>
              <p className="prefieldData">{userRegisterData.email}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Business Registration Number</label>
              <div className="d-flex align-items-center">
                <p className="prefieldData number">
                  {userRegisterData.businessRegNo}
                </p>
                <button
                  className="previewState"
                  onClick={() =>
                    setPreview(
                      window.open(
                        `${AppConfig.FILES_ENDPOINT}${userRegisterData.businessRegNo_image}`
                      )
                    )
                  }
                >
                  Preview
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>VAT or EIN Number</label>
              <div className="d-flex align-items-center">
                <p className="prefieldData number">
                  {userRegisterData.VATNumber}
                </p>

                <button
                  className="previewState"
                  onClick={() =>
                    setPreview(
                      window.open(
                        `${AppConfig.FILES_ENDPOINT}${userRegisterData.VATNumber_image}`
                      )
                    )
                  }
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Trade Mark Certification Number*</label>
              <div className="d-flex align-items-center">
                <p className="prefieldData number">
                  {userRegisterData.VATNumber}
                </p>
                <button
                  className="previewState"
                  onClick={() =>
                    setPreview(
                      window.open(
                        `${AppConfig.FILES_ENDPOINT}${userRegisterData.TradeMarkCertificateNo_image}`
                      )
                    )
                  }
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Country</label>
              <p className="prefieldData">{userRegisterData.country}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>State</label>
              <p className="prefieldData">{userRegisterData.state}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Address Line 1</label>
              <p className="prefieldData">{userRegisterData.address1}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Address Line 2</label>
              <p className="prefieldData">{userRegisterData.address2}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Post Code</label>
              <p className="prefieldData">{userRegisterData.postCode}</p>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="TradeMarkCertificateNo_image">Company Logo</label>
              {userRegisterData.storeLogo && (
                <div className="customChooseFile">
                  <span>
                    <InputTextFeild
                      name="VATNumber_image"
                      type="text"
                      placeholder=""
                      value={userRegisterData.storeLogo}
                      readOnly={true}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Preview;
