import Validator from 'js-object-validation';
import { message } from '../common';

export const permissionValidator = data => {
  const validations = {
    selectedModule: {
      required: true,
    },
    subModules: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    selectedModule: {
      required: message.Required.replace(':item', 'Module'),
    },
    subModules: {
      required: message.Required.replace(':item', 'Sub Modules'),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
