import React from "react";
import Skeleton from "react-skeleton-loader";

export const PreferencesLoader = () => (
  <>
    <div className="row">
      <div className="col-md-12">
        <div className="row" style={{ marginTop: "30px" }}>
          <div className="col-md-12">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
                  </label>
                  <Skeleton color="#e5e9ef" height={"45px"} width={"100%"} />
                </div>
                <div className="col-md-4">
                  <label>
                    <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
                  </label>
                  <Skeleton color="#e5e9ef" height={"45px"} width={"100%"} />
                </div>
                <div
                  className="col-md-12 d-flex justify-content-center"
                  style={{ marginTop: "24px" }}
                >
                  <div style={{ maxWidth: "192px", minWidth: "192px" }}>
                    <Skeleton color="#e5e9ef" height={"48px"} width={"230px"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </>
);
