/** @format */
import React from "react";
import "./batches.css";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";

export const ProductTable = ({
  thead,
  tbody,
  loading,
  setFilter,
  sortBy,
  order,
}) => {
  return loading ? (
    <TableLoader columns={thead.length} />
  ) : (
    <table
      width="100%"
      cellSpacing="0"
      className="table batchTable intableCheckbox"
    >
      <thead className="pad-topp">
        <tr className="batchesHeader">
          <th className="table_header">
            Product Number
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "productNumber"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "productNumber",
                    sortBy === "productNumber"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            Product Name
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "productName"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "productName",
                    sortBy === "productName"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            Created On
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "createdAt"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "createdAt",
                    sortBy === "createdAt"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {tbody && tbody.length ? (
          tbody.map((th, index) => (
            <tr key={index} className="btachRow history">
              <td>
                <span className="textContol">{th.productNumber}</span>
              </td>
              <td>
                <span className="textContol">{th.productName}</span>
              </td>
              <td>
                <span className="textContol">
                  {`${th.createdAtDate} | ${th.createdAtTime}`}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={thead.length}
              className="text-center image-no-data-sm nodataTablePosition"
              // style={{ paddingTop: "150px" }}
            >
              <>
                <img style={{ width: "110px" }} src={noData} alt="" />
                <p>The data you're looking is not available at this moment</p>
              </>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
