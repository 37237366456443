import React from "react";
import plan_user from "../../../../assets/img/plan_user.svg";
import plan_awards from "../../../../assets/img/plan_awards.svg";
import plan_printer from "../../../../assets/img/plan_printer.svg";
import plan_www from "../../../../assets/img/plan_www.svg";
import plan_tick from "../../../../assets/img/plan_tick.svg";
import plan_cross from "../../../../assets/img/plan_cross.svg";
import arrowDown_upgradePlan from "../../../../assets/img/arrowDown_upgradePlan.svg";
import Slider from "react-slick";
import { useState } from "react";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { LoaderSubscriptionSlider } from "../../../components/loader/Resusable/LoaderSubscriptionSlider.jsx";
import { TableLoader } from "../../../components/loader/Resusable/Table";
import noData from "../../../../assets/img/noData.svg";
import { useRef } from "react";
import { useHistory } from "react-router";
import SuccessPopup from "./SuccessPopup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UpgradePopup from "./UpgradePopup";
import DowngradePopup from "./DowngradePopup";
import DowngradeSuccess from "./DowngradeSuccess";
import CardsDetail from "./CardsDetail";
import DowngradedTolltip from "./DowngradedTolltip";
import moment from "moment";
import ContactForm from "./ContactForm";
import { contactValidate } from "../../../../validators/contactValidate";

export default function Purchase() {
  const [upgrade, setUpgrade] = useState(false);
  const [downgrade, setDowngrade] = useState(false);
  const [downgradeSuccess, setDowngradeSuccess] = useState(false);
  const [downgradeTooltip, setDowngradeTooltip] = useState(false);
  const [planChange, setplanchange] = useState(false);
  const [show, setShow] = useState(false);
  const tableRef = useRef(null);
  const sliderRef = useRef(null);
  const [subscription, setSubscriptionData] = useState();
  const [comparison, setComparison] = useState();
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState();
  const [userCards, setUserCards] = useState();
  const [planDuration, setPlanDuration] = useState();
  const [duration, setDuration] = useState();
  const [successShow, setSuccessShow] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [discount, setDiscount] = useState();
  const [amount, setAmountDue] = useState();
  const [primaryCards, setPrimaryCards] = useState();
  const [cardModal, setCardModal] = useState(false);
  const [cardData, setCardData] = useState();
  const [futurePlan, setFuturePlan] = useState();
  const [showMethod, setShowMethod] = useState(false);
  const [contactShow, setContactShow] = useState(false);
  const [contactData, setContactData] = useState(false);
  const [contactupdate, setContactUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [planAmount, setPlanAmount] = useState();
  const handleContactShow = (data) => {
    // setContactData(data);
    setContactShow(true);
  };

  const handleShow = () => {
    if (tableRef) {
      setShow(!show);
      setTimeout(() => {
        tableRef.current?.scrollIntoView({ bottom: "0", behavior: "smooth" });
      }, 300);
    }
  };

  var slide;

  if (subscription) {
    let index = subscription.findIndex((e) => e.status === "Active");
    slide = index;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll:
      subscription?.length > 2
        ? 3
        : subscription?.length === 2
        ? 2
        : subscription?.length === 1
        ? 1
        : 3,

    // swipeToSlide: true,
    initialSlide: slide,

    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const history = useHistory();
  useEffect(() => {
    getCards();
  }, []);

  const getClientId = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setLoading(false);

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user;
      setId(data);
    }
  };

  const getCards = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CARDS.service,
      ApiRoutes.GET_CARDS.url,
      ApiRoutes.GET_CARDS.method,
      ApiRoutes.GET_CARDS.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.data;
      const defaultCard = response.data?.data?.defaultCard;
      getSubscription();
      setUserCards(data);
      setPrimaryCards(defaultCard);
    }
  };

  const handleCardChange = (id) => {
    setPrimaryCards(id);
  };

  const getSubscription = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBSCRIPTION_DASHBOARD.service,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.url,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.method,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const durationData = response?.data?.subscriptionList[0]?.planDuration;
      setPlanDuration(durationData);
      setDuration(durationData == "Monthly" ? "Month" : durationData);
      getPlans(durationData);
    }
  };

  const getPlans = async (value) => {
    setLoading(true);
    const payload = value ? value : "Month";
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHOOSE_SUBSCRIPTION.service,
      ApiRoutes.CHOOSE_SUBSCRIPTION.url + "?type=" + payload,
      ApiRoutes.CHOOSE_SUBSCRIPTION.method,
      ApiRoutes.CHOOSE_SUBSCRIPTION.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      const discountData = response?.data?.discount;
      const comparisonData = response?.data?.comparison;
      const futureData = response?.data?.subscribedPlan;
      setSubscriptionData(data);
      setComparison(comparisonData);
      getClientId();
      // getSubscription();
      setDiscount(discountData);
      setFuturePlan(futureData);
    }
  };

  const getUpcomingInvoice = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_UPCOMING_INVOICE.service,
      ApiRoutes.GET_UPCOMING_INVOICE.url.replace(":id", id),
      ApiRoutes.GET_UPCOMING_INVOICE.method,
      ApiRoutes.GET_UPCOMING_INVOICE.authenticate,
      undefined
    );
    setUpdate(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data.data;
      setPlanAmount(data);
      await setAmountDue(data);
      return data;
    }
  };

  const handlePurchase = async (data) => {
    setplanchange(data);
    setCardData(data);
    var amountHold = amount;
    setUpdate(data.id);
    // if (subscriptionId !== data.id) {
    amountHold = await getUpcomingInvoice(
      duration === "Month" ? data.stripeMonthPrice : data.stripeAnnualPrice
    );
    // }
    setUpdate(false);
    if (amountHold && amountHold.status === "downgrade") {
      setDowngrade(data);
    } else if (
      (amountHold && amountHold.status === "upgrade") ||
      (amountHold && amountHold.status === "change")
    ) {
      setUpgrade(data);
    }
  };

  const changeSubscription = async (stripe = null) => {
    let paylaod = {
      clientId: id.id,
      subId: planChange.id,
      planDuration: duration === "Month" ? duration + "ly" : duration,
      billingCycle: duration === "Month" ? duration + "ly" : duration,
      amount: planChange.price,
      priceId:
        duration === "Month"
          ? planChange.stripeMonthPrice
          : planChange.stripeAnnualPrice,
      stripeCardId: primaryCards,
    };
    setUpdate(planChange.id);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHOOSE_CLIENT_SUBSCRIPTION.service,
      ApiRoutes.CHOOSE_CLIENT_SUBSCRIPTION.url,
      ApiRoutes.CHOOSE_CLIENT_SUBSCRIPTION.method,
      ApiRoutes.CHOOSE_CLIENT_SUBSCRIPTION.authenticate,
      undefined,
      paylaod
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setUpdate(false);
      recallAPIS();
    } else {
      if (downgrade !== false) {
        setDowngradeSuccess(true);
        setSuccessShow(false);
        setUpdate(false);
        recallAPIS();
      } else {
        const client_secret =
          response?.data?.data?.paymentIntent?.client_secret;
        if (client_secret) {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            client_secret
          );
          setUpdate(false);
          setSuccessShow(true);
          if (error) {
            setPaymentType("failure");
          } else if (
            (paymentIntent && paymentIntent.status === "succeeded") ||
            !paymentIntent
          ) {
            setPaymentType("success");
          }
          recallAPIS();
        } else {
          recallAPIS();
        }
      }
    }
  };

  const recallAPIS = () => {
    setTimeout(() => {
      getPlans(duration);
      setDowngrade(false);
      setCardModal(false);
      setUpdate(false);
    }, 3000);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDuration(value);
    getPlans(value);
  };

  const handleCard = () => {
    setCardModal(true);
    if (primaryCards) {
      setShowMethod(false);
    } else {
      setShowMethod(true);
    }
    setUpgrade(false);
  };

  const handleDowngraded = () => {
    setDowngradeTooltip(true);
  };

  const handleConatctChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setContactData({
      ...contactData,
      [name]: value.trimLeft(),
    });
  };
  const contactAdmin = async (data) => {
    setContactUpdate(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CONTACT_ADMIN.service,
      ApiRoutes.CONTACT_ADMIN.url,
      ApiRoutes.CONTACT_ADMIN.method,
      ApiRoutes.CONTACT_ADMIN.authenticate,
      undefined,
      data
    );
    if (response && response.isError) {
      setContactUpdate(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      setContactUpdate(false);
      setContactShow(false);
      setContactData(false);
      getPlans();
    }
  };
  const handleSubmit = (event) => {
    event && event.preventDefault();
    var payload = {
      email: contactData.email,
      name: contactData.name,
      message: contactData.message,
    };
    const { isValid, errors } = contactValidate(payload);
    if (isValid) {
      setError(false);
      contactAdmin(payload);
    } else {
      setError(errors);
      return;
    }
  };
  return (
    <>
      <div className="container-fluid fluid-pd-2 purchasePage mb-5">
        <div
          className="productFormBox"
          style={{ marginTop: "42px", paddingTop: "33px" }}
        >
          <h3>Upgrade Plan</h3>
          <p>Start your business with PHL</p>
          <div className="d-flex align-items-center justify-content-center">
            <div className="switchBox">
              <label className="switch">
                <input
                  type="radio"
                  onChange={handleChange}
                  checked={duration === "Month"}
                  value="Month"
                  name="duration"
                />
                <span className="slider round"></span>
              </label>
              <p>Monthly</p>
            </div>
            <div className="switchBox" style={{ marginLeft: "40px" }}>
              <label className="switch">
                <input
                  type="radio"
                  onChange={handleChange}
                  checked={duration === "Annual"}
                  value="Annual"
                  name="duration"
                />
                <span className="slider round"></span>
              </label>
              <p>Annual (Save {discount ? discount : 0}%)</p>
            </div>
          </div>
          {loading ? (
            <>
              <LoaderSubscriptionSlider />
            </>
          ) : (
            <div>
              {" "}
              {subscription?.length > 0 ? (
                <Slider {...settings} ref={sliderRef}>
                  {subscription &&
                    subscription?.map((info, index) => (
                      <div key={index}>
                        <div className="col-md-12">
                          <div
                            className={
                              info.status === "Active"
                                ? "planGrid active"
                                : "planGrid"
                            }
                          >
                            {info.planType === "Contact" ? (
                              <>
                                <h3 className="text-capitalize">{info.name}</h3>
                                <p>Enterprise</p>
                                <ul className="mt-4">
                                  <li>
                                    <span>{info.description}</span>
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <>
                                <h3 className="text-capitalize">{info.name}</h3>
                                <p>{info.description}</p>
                                <h4>$ {info.price}</h4>
                                <ul>
                                  <li>
                                    <img alt="" src={plan_user} />{" "}
                                    <span>{info.users} User</span>
                                  </li>
                                  {info.BrandAndCertificate && (
                                    <li>
                                      <img alt="" src={plan_awards} />{" "}
                                      <span>Add brands and Certificates</span>
                                    </li>
                                  )}

                                  <li>
                                    <img alt="" src={plan_printer} />{" "}
                                    <span>Print {info.printCodes} codes</span>
                                  </li>
                                  {info.domainName && (
                                    <li>
                                      <img alt="" src={plan_www} />{" "}
                                      <span>Set your Domain</span>
                                    </li>
                                  )}
                                </ul>
                              </>
                            )}

                            <div
                              className="btn_wrap"
                              // style={{ padding: "0 11px", marginTop: "40px" }}
                            >
                              {info.price > 0 && (
                                <>
                                  {info.status === "Next" ? (
                                    <OverlayTrigger
                                      delay={{ show: 50, hide: 400 }}
                                      placement="bottom"
                                      overlay={
                                        <Tooltip>
                                          Your plan will be activated from the
                                          next month on{" "}
                                          {moment(
                                            futurePlan && futurePlan.expireDate
                                          ).format("DD/MM/YYYY")}
                                        </Tooltip>
                                      }
                                    >
                                      <button
                                        onClick={handleDowngraded}
                                        className="addProductbtn w-100"
                                      >
                                        Downgraded
                                      </button>
                                    </OverlayTrigger>
                                  ) : (
                                    <>
                                      {info.status === "Cancel" ? (
                                        <button
                                          disabled={true}
                                          className="addProductbtn w-100"
                                        >
                                          Cancelled
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() => handlePurchase(info)}
                                          className="addProductbtn w-100"
                                          disabled={
                                            update === info.id
                                              ? true
                                              : info.status === "Active"
                                              ? planDuration ===
                                                info.planDuration
                                              : false
                                          }
                                        >
                                          {update === info.id ? (
                                            <>
                                              <i className="fa fa-spinner fa-spin"></i>{" "}
                                              Purchase
                                            </>
                                          ) : info.status === "Active" ? (
                                            "Existing"
                                          ) : (
                                            <>Purchase</>
                                          )}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </>
                              )}

                              {info.planType === "Contact" && (
                                <button
                                  onClick={() => handleContactShow(info)}
                                  className="addProductbtn w-100"
                                >
                                  Contact
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              ) : (
                <div className="col-md-12">
                  <div className="planGrid nodataGrid">
                    <img src={noData} alt="" />
                    <p>
                      No plan has been added <br />
                      by the admin yet
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          <div
            className="d-flex align-items-center flex-column"
            style={{ marginTop: "68px" }}
          >
            {loading ? (
              <>
                <TableLoader columns={5} rows={10} />
              </>
            ) : (
              <>
                <button
                  class="hideComparison"
                  onClick={handleShow}
                  style={{ width: "238px", height: "48px" }}
                >
                  {show ? "Hide Comparison" : "See Comparison"}
                  <img
                    alt=""
                    className={show ? "rotate" : "rotateAgain"}
                    src={arrowDown_upgradePlan}
                  />
                </button>
                {show && (
                  <div
                    ref={tableRef}
                    className={
                      show ? "comparisonShow  w-100" : " comparisonHide  w-100"
                    }
                    id=""
                  >
                    <div
                      className="tableCard card mb-4"
                      style={{ marginTop: "35px" }}
                    >
                      <div className="tabel-outer pb-0">
                        <div
                          className="table-responsive fixed-height-table"
                          style={{ height: "376px" }}
                        >
                          <table
                            className="table batchTable billingTable purchase_Subscriptiontable mb-0"
                            width="100%"
                            cellSpacing="0"
                          >
                            {subscription?.length > 0 ? (
                              <>
                                <thead className="pad-top">
                                  <tr className="batchesHeader scan-head">
                                    <th>Features</th>
                                    {subscription &&
                                      subscription.map((info, index) => (
                                        <th key={index}>{info.name}</th>
                                      ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className="scan createPlanRow">
                                    <td>
                                      <span className="textContol">Users</span>
                                    </td>
                                    {comparison &&
                                      comparison.users &&
                                      comparison.users.map((info, index) => (
                                        <td key={index}>
                                          <span
                                            className="textContol"
                                            style={{
                                              maxWidth: "auto",
                                              width: "auto",
                                            }}
                                          >
                                            {info}
                                          </span>
                                        </td>
                                      ))}
                                  </tr>
                                  <tr className="scan createPlanRow">
                                    <td>
                                      <span className="textContol">
                                        Brand and Certificate
                                      </span>
                                    </td>
                                    {comparison &&
                                      comparison.BrandAndCertificate &&
                                      comparison.BrandAndCertificate.map(
                                        (info, index) => (
                                          <td key={index}>
                                            <span
                                              className="textContol"
                                              style={{
                                                maxWidth: "auto",
                                                width: "auto",
                                              }}
                                            >
                                              <img
                                                alt=""
                                                src={
                                                  info ? plan_tick : plan_cross
                                                }
                                              />
                                            </span>
                                          </td>
                                        )
                                      )}
                                  </tr>
                                  <tr className="scan createPlanRow">
                                    <td>
                                      <span className="textContol">
                                        Code Printing
                                      </span>
                                    </td>
                                    {comparison &&
                                      comparison.codePrinting &&
                                      comparison.codePrinting.map(
                                        (info, index) => (
                                          <td key={index}>
                                            <span
                                              className="textContol"
                                              style={{
                                                maxWidth: "auto",
                                                width: "auto",
                                              }}
                                            >
                                              {info}
                                            </span>
                                          </td>
                                        )
                                      )}
                                  </tr>
                                  <tr className="scan createPlanRow">
                                    <td>
                                      <span className="textContol">
                                        Domain Setup
                                      </span>
                                    </td>
                                    {comparison &&
                                      comparison.domainSetup &&
                                      comparison.domainSetup.map(
                                        (info, index) => (
                                          <td key={index}>
                                            <span
                                              className="textContol"
                                              style={{
                                                maxWidth: "auto",
                                                width: "auto",
                                              }}
                                            >
                                              <img
                                                alt=""
                                                src={
                                                  info ? plan_tick : plan_cross
                                                }
                                              />
                                            </span>
                                          </td>
                                        )
                                      )}
                                  </tr>
                                </tbody>
                              </>
                            ) : (
                              <>
                                <div className="no-tr">
                                  <div
                                    className={
                                      "text-center image-no-data-xs nodataTablePosition paymentCardNoData upgradePlan"
                                    }
                                  >
                                    <img src={noData} alt="" />
                                    <p>
                                      The data you're looking is not available
                                      at this moment
                                    </p>
                                  </div>
                                </div>
                              </>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <SuccessPopup
        successShow={successShow}
        paymentType={paymentType}
        close={() => setSuccessShow(false)}
      />
      <UpgradePopup
        show={upgrade}
        update={update}
        close={() => setUpgrade(false)}
        changeSubscription={handleCard}
        planAmount={planAmount}
      />
      <DowngradePopup
        showDowngrade={downgrade}
        closeDowngrade={() => setDowngrade(false)}
        update={update}
        changeSubscription={changeSubscription}
      />
      <DowngradeSuccess
        futureData={futurePlan}
        downgradeData={subscription}
        showSuccess={downgradeSuccess}
        closeSuccess={() => setDowngradeSuccess(false)}
      />
      <DowngradedTolltip
        showSuccess={downgradeTooltip}
        futurePlan={futurePlan}
        closeSuccess={() => setDowngradeTooltip(false)}
      />
      <CardsDetail
        show={cardModal}
        cardData={cardData}
        close={() => setCardModal(false)}
        userCards={userCards}
        update={update}
        primaryCards={primaryCards}
        changeSubscription={changeSubscription}
        handleChange={handleCardChange}
        priceData={amount}
        fetchCards={getCards}
        showMethod={showMethod}
        setShowMethod={setShowMethod}
        planAmount={planAmount}
      />
      <ContactForm
        close={() => [
          setError(false),
          setContactShow(false),
          setContactData(false),
        ]}
        show={contactShow}
        contactData={contactData}
        update={contactupdate}
        handleSubmit={handleSubmit}
        onChange={handleConatctChange}
        error={error}
      />
    </>
  );
}
