import React from "react";
import Alerts from "../../components/accountSetting/profile/Alerts";
import DeleteAccount from "../../components/accountSetting/profile/DeleteAccount";
import PreferencesForm from "../../components/accountSetting/profile/PreferencesForm";
import SocialMedia from "../../components/accountSetting/profile/SocialMedia";

function Preferences(props) {
  return (
    <div className="container-fluid fluid-pd-2">
      <div className="row">
        <div className="col-md-12">
          <PreferencesForm />
        </div>
        <div className="col-md-12">
          <Alerts />
        </div>
        <div className="col-md-12">
          <SocialMedia />
        </div>
        <div className="col-md-12">
          <DeleteAccount />
        </div>
      </div>
    </div>
  );
}

export default Preferences;
