import React, { useState } from "react";
import info_btn from "../../../../assets/img/info_btn.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { ApiHelper, Logout } from "../../../../helpers";
import { ApiRoutes } from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";
import SweetAlertComponent from "../../sweetAlertComponent";
import { SessionLoader } from "../../loader/Session/SessionLoader";

function ActiveSession(props) {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>View and manage all of your active sessions.</Popover.Body>
    </Popover>
  );
  const [deviceDetail, setDeviceDetail] = useState();
  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    getSessionDetail();
  }, []);
  const getSessionDetail = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SIGNIN_DETAIL.service,
      ApiRoutes.GET_SIGNIN_DETAIL.url,
      ApiRoutes.GET_SIGNIN_DETAIL.method,
      ApiRoutes.GET_SIGNIN_DETAIL.authenticate,
      undefined
    );
    setPageLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
      Logout();
    } else {
      const data = response?.data?.data?.devices;
      setDeviceDetail(data);
    }
  };

  const deleteDetail = async (data) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure you want to terminate this session?`,
    });
    if (!value) {
      return;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_USER_SESSION.service,
      ApiRoutes.DELETE_USER_SESSION.url.replace(":id", data.id),
      ApiRoutes.DELETE_USER_SESSION.method,
      ApiRoutes.DELETE_USER_SESSION.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      getSessionDetail();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings SocialMediaCard"
            style={{ marginBottom: "30px", marginTop: "55px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Active Session</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-12">
                    {/* <p>You haven’t linked your social media account yet</p> */}

                    {pageLoader ? (
                      <SessionLoader />
                    ) : (
                      <table className="table SocialloginDetails activeSession">
                        <tbody>
                          {deviceDetail?.map((info, index) => (
                            <tr key={index}>
                              <td>
                                <div className="d-flex align-items-center socialProfile">
                                  <div>
                                    <h6>{info.browser}</h6>
                                    <p>Active since {info.signInTimeDate}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="activeSession_textlimit">
                                  {info.city}, {info.state}, {info.country}
                                </div>
                              </td>
                              <td
                                className="cursor-pointer"
                                onClick={() => deleteDetail(info)}
                              >
                                <span className="text_red">Terminate</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActiveSession;
