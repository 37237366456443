import Validator from 'js-object-validation';
import { message } from '../common';

export const setPasswordValidator = data => {
  const validations = {
    newpassword: {
      required: true,
      // minlength: 6,
      password:true
    },
    cnfpassword: {
      required: true,
      // equal:data.password,
    },
  };
  // Error messages
  const messages = {
    newpassword: {
      required: message.Required.replace(':item', 'password'),
      // minlength: "Password should be at least 6 charater long",
      password: message.Password.replace(':item', 'password'),
    },
    cnfpassword: {
      required: message.Required.replace(':item', 'confirm password'),
      // equal:message.PasswordMatch
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
