import React, { useState } from "react";
import Slider from "react-slick";
import { SaveCardsAccountLoader } from "../loader/Resusable/SaveCardsAccountLoader";
import rectangle from "../../../assets/img/cards/visaCard-rectangle.svg";
import cardName from "../../../assets/img/cards/visaText.svg";
import visaEllips from "../../../assets/img/cards/visaEllips.svg";
import masterCardLogo from "../../../assets/img/cards/masterCardLogo.svg";
import ellipsTop from "../../../assets/img/cards/masterEllips.svg";
import msaterTrangle from "../../../assets/img/cards/msaterTrangle.svg";
import american from "../../../assets/img/cards/american.svg";
import noData from "../../../assets/img/noData.svg";
import cardEdit from "../../../assets/img/cardEdit.svg";
import cardDelete from "../../../assets/img/cardDelete.svg";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import SetCard from "./SetCard";

export default function PaymentCards({
  loading,
  userCards,
  deleteCard,
  primaryCards,
  setModalOpen,
  defaultCard,
  handleChange,
}) {
  var settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    // variableWidth: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2.9,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 0.1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="col-md-12">
        <div className="exportRow">
          <div className="d-flex align-items-center">
            <h4>Saved Cards & Accounts</h4>
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <SaveCardsAccountLoader />
        </>
      ) : (
        <>
          <div className="col-md-12">
            <div className="innerHolder paymentRow">
              {/* <p className="listHeading">
                <label className="chechboxLabel">
                  <input
                    name="stauts"
                    // onChange={handleChange}
                    // checked={notifyData?.counterfeit}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  <span className="select">Primary Card</span>
                </label>
              </p> */}
              {userCards && userCards.length > 0 ? (
                <div className="sliderHolder purchasePage">
                  <Slider {...settings}>
                    {userCards &&
                      userCards.map((info, index) => (
                        <>
                          <div key={index} className="cardHolder">
                            {/* {index > 0 && ( */}
                            <div className="cardEditor">
                              <label className="chechboxLabel">
                                <input
                                  name="primaryCards"
                                  onChange={() => setModalOpen(info.id)}
                                  checked={primaryCards === info?.id}
                                  type="checkbox"
                                />
                                <span className="checkmark"></span>
                                <span className="select mr-2">
                                  Primary Card
                                </span>
                              </label>
                              <img
                                src={cardDelete}
                                onClick={() => deleteCard(info?.id)}
                                alt=""
                              />
                            </div>
                            {/* )} */}

                            <div className="col-md-12 d-flex banksCardRow">
                              <div
                                className={
                                  info?.card?.brand === "amex"
                                    ? "bankCard americanExpress"
                                    : info?.card?.brand === "diners"
                                    ? "bankCard dinersClub"
                                    : info?.card?.brand === "discover"
                                    ? "bankCard discoverCard"
                                    : info?.card?.brand === "unionpay"
                                    ? "bankCard unionPay"
                                    : info?.card?.brand === "jcb"
                                    ? "bankCard jcbCard"
                                    : info?.card?.brand === "visa"
                                    ? "bankCard visaCard"
                                    : info?.card?.brand === "mastercard"
                                    ? "bankCard MasterCard"
                                    : "bankCard"
                                }
                              >
                                <p className="code">****</p>
                                <p className="validupto">
                                  {info?.card?.exp_month}/{info?.card?.exp_year}
                                </p>
                                <p className="cardNumber">
                                  **** **** **** {info?.card?.last4}
                                </p>
                                <p className="cardName">{info?.card?.name}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </Slider>
                </div>
              ) : (
                <div className="no-tr pt-4">
                  <div
                    className={
                      "text-center image-no-data-xs nodataTablePosition paymentCardNoData"
                    }
                  >
                    <img src={noData} alt="" />
                    <p>You don't have added any card yet</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
          </Button> */}

          <SetCard
            show={defaultCard}
            close={() => setModalOpen(false)}
            handleChange={handleChange}
          />
        </>
      )}
    </>
  );
}
