import React from "react";
import Skeleton from "react-skeleton-loader";

export const TutorialsLoader = () => (
  <>
    <div className="row">
      <div className="col-md-12 titleRow">
        <h4>
          <Skeleton color="#e5e9ef" height={"35px"} width={"20%"} />
        </h4>
        <p>
          <Skeleton color="#e5e9ef" height={"20px"} width={"30%"} />
        </p>
      </div>
      <div className="col-md-6 col-lg-4">
        <div className="videoCard">
          <h3>
            <Skeleton color="#e5e9ef" height={"17px"} width={"70%"} />
          </h3>
          <p>
            <Skeleton color="#e5e9ef" height={"15px"} width={"70%"} />
            <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          </p>
          <div className="cardFooter">
            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"13px"} width={"58px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"24px"} width={"26px"} />
              </div>
            </div>

            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"15px"} width={"70px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"18px"} width={"60px"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4">
        <div className="videoCard">
          <h3>
            <Skeleton color="#e5e9ef" height={"17px"} width={"70%"} />
          </h3>
          <p>
            <Skeleton color="#e5e9ef" height={"15px"} width={"70%"} />
            <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          </p>
          <div className="cardFooter">
            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"13px"} width={"58px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"24px"} width={"26px"} />
              </div>
            </div>

            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"15px"} width={"70px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"18px"} width={"60px"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="videoCard">
          <h3>
            <Skeleton color="#e5e9ef" height={"17px"} width={"70%"} />
          </h3>
          <p>
            <Skeleton color="#e5e9ef" height={"15px"} width={"70%"} />
            <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          </p>
          <div className="cardFooter">
            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"13px"} width={"58px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"24px"} width={"26px"} />
              </div>
            </div>

            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"15px"} width={"70px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"18px"} width={"60px"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="videoCard">
          <h3>
            <Skeleton color="#e5e9ef" height={"17px"} width={"70%"} />
          </h3>
          <p>
            <Skeleton color="#e5e9ef" height={"15px"} width={"70%"} />
            <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          </p>
          <div className="cardFooter">
            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"13px"} width={"58px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"24px"} width={"26px"} />
              </div>
            </div>

            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"15px"} width={"70px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"18px"} width={"60px"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="videoCard">
          <h3>
            <Skeleton color="#e5e9ef" height={"17px"} width={"70%"} />
          </h3>
          <p>
            <Skeleton color="#e5e9ef" height={"15px"} width={"70%"} />
            <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          </p>
          <div className="cardFooter">
            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"13px"} width={"58px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"24px"} width={"26px"} />
              </div>
            </div>

            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"15px"} width={"70px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"18px"} width={"60px"} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4">
        <div className="videoCard">
          <h3>
            <Skeleton color="#e5e9ef" height={"17px"} width={"70%"} />
          </h3>
          <p>
            <Skeleton color="#e5e9ef" height={"15px"} width={"70%"} />
            <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          </p>
          <div className="cardFooter">
            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"13px"} width={"58px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"26px"} width={"26px"} />
              </div>
            </div>

            <div>
              <h5>
                <Skeleton color="#e5e9ef" height={"12px"} width={"70px"} />
              </h5>
              <div className="d-flex align-items-center">
                <Skeleton color="#e5e9ef" height={"12px"} width={"60px"} />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </>
);
