import React from "react";
import BatchRow from "./batchRow.jsx";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../config";
import addIcon from "../../../assets/img/addIcon.svg";

export const ListComponent = ({
  batches,
  isFilterApplied,
  isLoading,
  onDelete,
  onPrint,
  onView,
  setFilter,
  sortBy,
  order,
  addBatch,
}) => {
  return (
    <div className="tabel-outer">
      <div className="table-responsive fixed-height-table">
        {isLoading ? (
          <TableLoader columns={5} rows={10} />
        ) : (
          <table
            className="table batchTable intableCheckbox manageBatchTable"
            width="100%"
            cellSpacing="0"
          >
            <thead className="pad-top">
              <tr className="batchesHeader">
                <th className="table_header">
                  Batch Number
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "batchNumber"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "batchNumber",
                          sortBy === "batchNumber"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th className="table_header_Name">
                  Product Number
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "productNumber"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "productNumber",
                          sortBy === "productNumber"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th>
                  Name
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "productName"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "productName",
                          sortBy === "productName"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "190px" }} className="unitAvailable">
                  Available Units
                </th>
              </tr>
            </thead>
            <tbody>
              {batches && batches.length ? (
                batches.map((batch) => (
                  <BatchRow
                    batchData={batch}
                    key={batch._id}
                    onDelete={() => onDelete(batch._id, batch)}
                    onView={() => onView(batch._id)}
                    onPrint={() => onPrint(batch._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td
                    colSpan={8}
                    className={
                      "text-center image-no-data-xs nodataTablePosition"
                    }
                  >
                    <img src={noData} alt="" />
                    <p>
                      No Batch Found,{" "}
                      <span onClick={addBatch} className="text-decoration-none">
                        Click here
                      </span>{" "}
                      to Add New Batch
                    </p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
