import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const CardLoaderEvent = () => (
  <>
    <div className="cardHeader">
              <h4><Skeleton color='#e5e9ef' height={'18px'} width={'90px'} /></h4>
              {/* <span>This Week</span> */}
              <Skeleton color='#e5e9ef' height={'18px'} width={'80px'} />
            </div>
              <div className="cardBody text-center" style={{ minHeight: "513px", maxHeight: "513px" }}>

                <Skeleton color='#e5e9ef' height={'85px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'85px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'85px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'85px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'85px'} width={'100%'} />


                {/* [Total Users Graph] */}
              </div>
  </>
);
