import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import totalUsers from "../../../assets/img/totalUsers.svg";
import totalStaff from "../../../assets/img/totalStaff.svg";
import graph_up from "../../../assets/img/graph_up.svg";
import graph_down from "../../../assets/img/graph_down.svg";
import graph_up_arrow from "../../../assets/img/graph_up_arrow.svg";
import graph_down_arrow from "../../../assets/img/graph_down_arrow.svg";
import { SmallCardMini } from "../../components/loader/Resusable/SmallCardMini";

export default function MemberData() {
  useEffect(() => {
    getMemberData();
  }, []);
  const [memberData, setMemberData] = useState();
  const [loading, setLoading] = useState(false);
  const getMemberData = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.service,
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.url,
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.method,
      ApiRoutes.DASHBOARD_MEMEBR_STATISTICS.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data;
      setMemberData(data);
    }
  };

  return (
    <>
      <div className="col-md-6 mb-4 mb-md-0">
        <div
          className="mycard minicards d-flex align-items-center justify-content-between"
          // style={{ paddingRight: "12px" }}
        >
          {loading ? (
            // <DashboardWidgetLoader />
            <SmallCardMini />
          ) : (
            <>
              <div className="d-flex align-items-center">
                <div>
                  <img alt="" src={totalUsers} />
                </div>
                <div className="valueBox">
                  <h5>
                    {memberData && memberData.totalUsers
                      ? memberData.totalUsers
                      : "00"}
                  </h5>
                  <p>Total Users</p>
                </div>
              </div>
              <div className="graphBox">
                {memberData &&
                memberData.userStatisticsIntermsOfLastWeek &&
                memberData.userStatisticsIntermsOfLastWeek > 0 ? (
                  <>
                    <div>
                      <img alt="" src={graph_up} />
                    </div>
                    <div>
                      <img alt="" src={graph_up_arrow} />
                      <span className="successText">
                        {memberData &&
                        memberData.userStatisticsIntermsOfLastWeek
                          ? memberData.userStatisticsIntermsOfLastWeek
                          : "0"}
                      </span>
                    </div>
                  </>
                ) : (
                  memberData &&
                  memberData.totalUsers > 0 && (
                    <>
                      <div>
                        <img alt="" src={graph_down} />
                      </div>
                      <div>
                        <img alt="" src={graph_down_arrow} />
                        <span className="dangerText">
                          {memberData &&
                          memberData.userStatisticsIntermsOfLastWeek
                            ? memberData.userStatisticsIntermsOfLastWeek
                            : "0"}
                        </span>
                      </div>
                    </>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div
          className="mycard minicards d-flex align-items-center justify-content-between"
          // style={{ paddingRight: "12px" }}
        >
          {loading ? (
            // <DashboardWidgetLoader />
            <SmallCardMini />
          ) : (
            <>
              <div className="d-flex align-items-center">
                <div>
                  <img alt="" src={totalStaff} />
                </div>
                <div className="valueBox">
                  <h5>
                    {memberData && memberData.totalStaff
                      ? memberData.totalStaff
                      : "00"}
                  </h5>
                  <p>Total Staff</p>
                </div>
              </div>
              <div className="graphBox">
                {memberData &&
                memberData.staffStatisticsIntermsOfLastWeek &&
                memberData.staffStatisticsIntermsOfLastWeek > 0 ? (
                  <>
                    <div>
                      <img alt="" src={graph_up} />
                    </div>
                    <div>
                      <img alt="" src={graph_up_arrow} />
                      <span className="successText">
                        {memberData &&
                        memberData.staffStatisticsIntermsOfLastWeek
                          ? memberData.staffStatisticsIntermsOfLastWeek
                          : "0"}
                      </span>
                    </div>
                  </>
                ) : (
                  memberData &&
                  memberData.totalStaff > 0 && (
                    <>
                      <div>
                        <img alt="" src={graph_down} />
                      </div>
                      <div>
                        <img alt="" src={graph_down_arrow} />
                        <span className="dangerText">
                          {memberData &&
                          memberData.staffStatisticsIntermsOfLastWeek
                            ? memberData.staffStatisticsIntermsOfLastWeek
                            : "0"}
                        </span>
                      </div>
                    </>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
