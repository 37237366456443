import React from "react";
import { Button, Modal } from "react-bootstrap";
import crossIcon from "../../../../assets/img/closeModal.svg";
import InputTextFeild from "../../form/InputTextFeild";

export default function DeleteBackup({
  password,
  deleteShow,
  deleteClose,
  passwordChange,
  error,
  deleteMfa,
  loader,title
}) {
  return (
    <Modal
      className="emailAddresssModal regenerateKeyModal"
      show={deleteShow}
      onHide={deleteClose}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <div>
          <Button variant="" onClick={deleteClose}>
            <img src={crossIcon} />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <h5>Enter Password</h5>
        <p style={{ marginTop: "0", textTransform: "unset" }}>
          This action seems sensitive please enter your password to let the
          system know its you.
        </p>
        <form>
          <InputTextFeild
            placeholder="Enter Password to verify"
            value={password}
            type="password"
            onChange={passwordChange}
            errorMessage={error}
          />
          <Button
            variant=""
            disabled={loader}
            className="otp_btn"
            onClick={deleteMfa}
          >
            {loader ? (
              <>
                <i className="fa fa-spinner fa-spin test-spin"></i>
                Submit
              </>
            ) : (
              <>Submit</>
            )}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
}
