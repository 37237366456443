import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import PaymentCards from "../../../components/manageSubscription/PaymentCards";
import PaymentMethod from "../../../components/manageSubscription/PaymentMethod";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import "./index.css";
export default function Payment() {
  const [loading, setLoading] = useState(false);
  const [userCards, setUserCards] = useState();
  const [primaryCards, setPrimaryCards] = useState();
  const [defaultCard, setDefaultCard] = useState(false);

  useEffect(() => {
    getCards();
  }, []);

  const getCards = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CARDS.service,
      ApiRoutes.GET_CARDS.url,
      ApiRoutes.GET_CARDS.method,
      ApiRoutes.GET_CARDS.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.data;
      const defaultCard = response.data?.data?.defaultCard;
      setUserCards(data);
      setPrimaryCards(defaultCard);
    }
  };

  const deleteCard = async (id) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this card?`,
    });
    if (!value) {
      return;
    }
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_CARDS.service,
      ApiRoutes.DELETE_CARDS.url.replace(":id", id),
      ApiRoutes.DELETE_CARDS.method,
      ApiRoutes.DELETE_CARDS.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.messages[0]);
      getCards();
    }
  };

  const setModalOpen = (data) => {
    setDefaultCard(data);
    // handleChange(data);
  };

  const handleChange = async () => {
    // const { value } = await SweetAlertComponent({
    //   text: `Are you sure, you want to make this card primary?`,
    // });
    // if (!value) {
    //   return;
    // }
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRIMARY_CARDS.service,
      ApiRoutes.PRIMARY_CARDS.url.replace(":id", defaultCard),
      ApiRoutes.PRIMARY_CARDS.method,
      ApiRoutes.PRIMARY_CARDS.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      setPrimaryCards(defaultCard);
      setDefaultCard(false);
      getCards();
      toast.success(response?.data?.message);
    }
  };

  return (
    <div className="container-fluid fluid-pd-2">
      <div
        className="productFormBox paymentSection reportBatches settings billings"
        style={{ marginTop: "43px" }}
      >
        <div className="row">
          <PaymentCards
            loading={loading}
            userCards={userCards}
            deleteCard={deleteCard}
            primaryCards={primaryCards}
            handleChange={handleChange}
            defaultCard={defaultCard}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>
      <div
        className="productFormBox methodRow reportBatches settings billings"
        style={{ marginTop: "35px" }}
      >
        <div className="row">
          <PaymentMethod loading={loading} fetchCards={getCards} />
        </div>
      </div>
    </div>
  );
}
