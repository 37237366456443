import React from 'react';
import avtar from '../../../assets/img/avtar.jfif';
import home_icon from '../../../assets/img/home_icon.svg';
import manage_clarity_icon from '../../../assets/img/manage_clarity_icon.svg';
import batches_icon from '../../../assets/img/batches_icon.svg';
import manage_staff_icon from '../../../assets/img/manage_staff_icon.svg';
import reports_icon from '../../../assets/img/reports_icon.svg';
import manage_client_icon from '../../../assets/img/manage_client_icon.svg';
import cms_icon from '../../../assets/img/cms_icon.svg';
import settings_icon from '../../../assets/img/settings_icon.svg';
import dropdown_down_icon from '../../../assets/img/dropdown_down_icon.svg';
import product_icon from '../../../assets/img/product_icon.svg';

function Sidebar(props) {
    return (
        <>
            <div id="sidebar">
                <div className='d-flex align-items-center justify-content-center flex-column mainAvtarBox'>
                    <div className='avtarBox'>
                        <img src={avtar} />
                    </div>
                    <h3>Administrator</h3>
                    <p>Client ID: THD-0546</p>
                    <span>Super Admin</span>
                </div>

                <ul className='sidebarOptions'>
                    <li>
                        <a href="#" className='btn active'>
                            <img className='optionIcon' src={home_icon} />
                            Dashboard
                        </a>
                    </li>
                    
                    
                    <li>
                        <a className='btn collapseBtn' data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                            <img className='optionIcon' src={manage_clarity_icon} />
                            Manage Clarity
                            <img className='dropdown_down_icon' src={dropdown_down_icon} />
                        </a>
                        <div className="collapse collapseBtnDropdown" id="collapseExample">
                            <ul>
                                <li>
                                    <a href='#'>Counterfeits</a>
                                </li>
                                <li>
                                    <a href='#'>Scans</a>
                                </li>
                                <li>
                                    <a href='#'>Manage Users</a>
                                </li>
                                <li>
                                    <a href='#'>Manage Videos</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a href="#" className='btn'>
                            <img className='optionIcon' src={batches_icon} />
                            Batches
                        </a>
                    </li>

                    <li>
                        <a href="#" className='btn'>
                            <img className='optionIcon' src={product_icon} />
                            Manage Products
                        </a>
                    </li>

                    
                    <li>
                        <a className='btn collapseBtn' data-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample3">
                            <img className='optionIcon' src={manage_staff_icon} />
                            Manage Staff
                            <img className='dropdown_down_icon' src={dropdown_down_icon} />
                        </a>
                        <div className="collapse collapseBtnDropdown" id="collapseExample3">
                            <ul>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    
                    <li>
                        <a className='btn collapseBtn' data-toggle="collapse" href="#collapseExample5" role="button" aria-expanded="false" aria-controls="collapseExample5">
                            <img className='optionIcon' src={manage_client_icon} />
                            Manage Client
                            <img className='dropdown_down_icon' src={dropdown_down_icon} />
                        </a>
                        <div className="collapse collapseBtnDropdown" id="collapseExample5">
                            <ul>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a className='btn collapseBtn' data-toggle="collapse" href="#collapseExample4" role="button" aria-expanded="false" aria-controls="collapseExample4">
                            <img className='optionIcon' src={reports_icon} />
                            Reports
                            <img className='dropdown_down_icon' src={dropdown_down_icon} />
                        </a>
                        <div className="collapse collapseBtnDropdown" id="collapseExample4">
                            <ul>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a className='btn collapseBtn' data-toggle="collapse" href="#collapseExample6" role="button" aria-expanded="false" aria-controls="collapseExample6">
                            <img className='optionIcon' src={cms_icon} />
                            CMS
                            <img className='dropdown_down_icon' src={dropdown_down_icon} />
                        </a>
                        <div className="collapse collapseBtnDropdown" id="collapseExample6">
                            <ul>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li>
                        <a className='btn collapseBtn' data-toggle="collapse" href="#collapseExample7" role="button" aria-expanded="false" aria-controls="collapseExample7">
                            <img className='optionIcon' src={settings_icon} />
                            Settings
                            <img className='dropdown_down_icon' src={dropdown_down_icon} />
                        </a>
                        <div className="collapse collapseBtnDropdown" id="collapseExample7">
                            <ul>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                                <li>
                                    <a href='#'>demo</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    

                    
                    
                </ul>

            </div>
        </>
    );
}

export default Sidebar;