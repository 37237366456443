import React from "react";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import termsPDF from "../../../../assets/img/terms/terms&condition.pdf";

export default function DowngradePopup({
  closeDowngrade,
  showDowngrade,
  update,
  changeSubscription,
}) {
  const [checkTerms, setCheckTerms] = useState(false);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value);

    if(!checkTerms){
      setCheckTerms(value);
    }else{
      setCheckTerms(false);
    }
  };
  return (
    <Modal
      show={showDowngrade}
      onHide={closeDowngrade}
      className="logoutModal Custom2"
    >
      <Modal.Header>
        <span className="logoutAlert">!</span>
      </Modal.Header>
      <Modal.Body>
        <h3>Are you sure you want to downgrade current Plan?</h3>
        <p>
          The downgraded plan and the related charges will applicable once your
          current plan wiil be expired
        </p>
        <label className="checkBoxRadio">
          <input
            name="status"
            onChange={handleChange}
            checked={checkTerms}
            value="Cancel"
            type="radio"
          />
          <span className="checkmark"></span>
          <span>
            I accept{" "}
            <b
              // href="#"
              onClick={() => window.open(`${termsPDF}`, "_blank")}
            >
              Terms and Conditions
            </b>
          </span>
        </label>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={closeDowngrade} className="logout-cancel">
          Cancel
        </button>
        <button
          onClick={changeSubscription}
          disabled={update || !checkTerms}
          className="logout-btn"
        >
          {update ? (
            <>
              <i className="fa fa-spinner fa-spin"></i> Downgrade
            </>
          ) : (
            "Downgrade"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
