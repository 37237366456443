import React from "react";
import info_btn from "../../../../assets/img/info_btn.svg";
import deleteAccount from "../../../../assets/img/deleteAccount.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../sweetAlertComponent";
import { useState } from "react";
import { useEffect } from "react";

function DeleteAccount() {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        Permanently delete all account information and service data.
      </Popover.Body>
    </Popover>
  );
  const [loader, setLoader] = useState();
  const [isDeleted, setIsDeleted] = useState();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.id;
      getClientProfile(data);
    }
  };

  const getClientProfile = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ACCOUNT_SETTINGS.service,
      ApiRoutes.GET_ACCOUNT_SETTINGS.url.replace(":id", id),
      ApiRoutes.GET_ACCOUNT_SETTINGS.method,
      ApiRoutes.GET_ACCOUNT_SETTINGS.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.isDeleted;
      setIsDeleted(data);
    }
  };

  const accountDelete = async (data) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure you want to delete this account ?`,
    });
    if (!value) {
      return;
    }
    setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_ACCOUNT.service,
      ApiRoutes.DELETE_ACCOUNT.url,
      ApiRoutes.DELETE_ACCOUNT.method,
      ApiRoutes.DELETE_ACCOUNT.authenticate,
      undefined
    );
    setLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings deleteAccountBox"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Delete Account</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-12">
                    <p>
                      This will lead to delete your account permanently and you
                      will no longer be able to access your PHL client account
                      anymore!
                    </p>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <form>
                      <div style={{ maxWidth: "192px", minWidth: "192px" }}>
                        <Button
                          variant=""
                          onClick={accountDelete}
                          className="customSubmitBtn"
                          disabled={loader || isDeleted}
                        >
                          {loader ? (
                            <i
                              style={{ marginRight: "8px" }}
                              className="fa fa-spinner fa-spin test-spin"
                            ></i>
                          ) : (
                            <img
                              style={{ marginRight: "8px" }}
                              src={deleteAccount}
                            />
                          )}
                          Delete Account
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
