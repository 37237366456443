import React, { useState } from "react";
import RequestRow from "./RequestRow";
import { TableLoader } from "../../loader/Resusable/Table";
import { AddStaffFormComponent } from "../AddStaffFormComponent";
import dropDown from "../../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../../assets/img/dropDown-long_icon_up_active.svg";
import noData from "../../../../assets/img/noData.svg";
import { Link } from "react-router-dom";
import AddStaff from "../../../containers/manage-staff/Request/AddStaff";

const StaffListRequestComponent = ({
  requests,
  isLoading,
  isUpdating,
  activeId,
  viewHandler,
  updateStatus,
  resendInvitation,
  setSorting,
  order,
  sortBy,
  handleShow,
}) => {
  return (
    <>
      <div className="tableCard card">
        <div className="tabel-outer">
          <div className="table-responsive fixed-height-table">
            {isLoading ? (
              <TableLoader columns={6} rows={10} />
            ) : (
              <table
                className="table batchTable staffTableNew invitedStaffList"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr className="batchesHeader">
                    <th>
                      Name
                      <span className={"cursor-pointer"}>
                        <img
                          src={
                            sortBy === "name"
                              ? order === "desc"
                                ? dropDownActive
                                : dropUpActive
                              : dropDown
                          }
                          alt=""
                          onClick={() =>
                            setSorting(
                              "name",
                              sortBy === "name"
                                ? order === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc"
                            )
                          }
                        />
                      </span>
                    </th>
                    <th>
                      STAFF ID
                      <span className={"cursor-pointer"}>
                        <img
                          src={
                            sortBy === "employee_id"
                              ? order === "desc"
                                ? dropDownActive
                                : dropUpActive
                              : dropDown
                          }
                          alt=""
                          onClick={() =>
                            setSorting(
                              "employee_id",
                              sortBy === "employee_id"
                                ? order === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc"
                            )
                          }
                        />
                      </span>
                    </th>
                    <th>
                      ROLE
                      <span className={"cursor-pointer"}>
                        <img
                          src={
                            sortBy === "roleName"
                              ? order === "desc"
                                ? dropDownActive
                                : dropUpActive
                              : dropDown
                          }
                          alt=""
                          onClick={() =>
                            setSorting(
                              "roleName",
                              sortBy === "roleName"
                                ? order === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc"
                            )
                          }
                        />
                      </span>
                    </th>
                    <th>
                      Email
                      <span className={"cursor-pointer"}>
                        <img
                          src={
                            sortBy === "email"
                              ? order === "desc"
                                ? dropDownActive
                                : dropUpActive
                              : dropDown
                          }
                          alt=""
                          onClick={() =>
                            setSorting(
                              "email",
                              sortBy === "email"
                                ? order === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc"
                            )
                          }
                        />
                      </span>
                    </th>
                    <th>
                      Status
                      <span className={"cursor-pointer"}>
                        <img
                          src={
                            sortBy === "status"
                              ? order === "desc"
                                ? dropDownActive
                                : dropUpActive
                              : dropDown
                          }
                          alt=""
                          onClick={() =>
                            setSorting(
                              "status",
                              sortBy === "status"
                                ? order === "asc"
                                  ? "desc"
                                  : "asc"
                                : "asc"
                            )
                          }
                        />
                      </span>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {requests && requests.length ? (
                    requests.map((request, index) => (
                      <RequestRow
                        key={index}
                        requestData={request}
                        isUpdating={isUpdating && activeId === request.id}
                        viewUser={() => viewHandler(request.id)}
                        updateStatus={(status, message) =>
                          updateStatus(request.id, status, message)
                        }
                        resendInvitation={() => resendInvitation(request.id)}
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={8}
                        className={
                          "text-center image-no-data-sm nodataTablePosition"
                        }
                        style={{ height: "400px" }}
                      >
                        <>
                          <img src={noData} alt="" />
                          <p>
                            No Staff Found,{" "}
                            <span
                              onClick={handleShow}
                              className="text-decoration-none"
                            >
                              Click here
                            </span>{" "}
                            to Invite New Staff
                          </p>
                        </>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffListRequestComponent;
