import React from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import logov2 from "../../../assets/img/logo-v2.svg";
import trackRequest from "../../../assets/img/trackRequest.svg";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import InputTextFeild from "../form/InputTextFeild";

function TrackRequest() {
  const queryParams = new URLSearchParams(window.location.search);
  const applicationNumber = queryParams.get("applicationNumber") || "";

  // console.log(queryParams);
  // console.log(applicationNumber);
  const [status, setStatus] = useState({
    applicationNumber: applicationNumber ? applicationNumber : "",
  });

  const [loading, setLoading] = useState(false);

  const getStatus = async (data) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TRACK_STATUS.service,
      ApiRoutes.GET_TRACK_STATUS.url,
      ApiRoutes.GET_TRACK_STATUS.method,
      ApiRoutes.GET_TRACK_STATUS.authenticate,
      undefined,
      data
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
    }
  };
  const handleSubmit = async (event) => {
    event && event.preventDefault();

    let data = {
      applicationNumber: status?.applicationNumber,
    };
    getStatus(data);
  };

  const onChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setStatus({
      ...status,
      [name]: value.trimLeft(),
    });
  };
  const onCancel = () => {
    setStatus({
      applicationNumber: "",
    });
  };
  return (
    <>
      <section className="invite_section">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center mt-md-5 pt-md-5">
            <div className="col-sm-12 col-md-11 col-lg-11 col-xl-11 col-xxl-11 customGrid-xxl  mt-md-5">
              <div className="formCard w-100 expiredCard">
                <div className="cardHeader" style={{ paddingTop: "70px" }}>
                  <img style={{ width: "300px" }} src={logov2} />
                </div>
                <div
                  className="cardBody TrackRequest"
                  style={{ paddingTop: "58px", paddingBottom: "78px" }}
                >
                  <img src={trackRequest} />
                  <p style={{ fontWeight: "700", fontSize: "22px" }}>
                    Track Application Request
                  </p>

                  <div className="formCard">
                    <form onSubmit={handleSubmit} className="formStepsBox">
                      <div className="form-group">
                        <label>Enter Application Number</label>
                        <InputTextFeild
                          name="applicationNumber"
                          type="text"
                          placeholder="First Name"
                          value={status?.applicationNumber}
                          onChange={onChange}
                        />
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <button
                          onClick={onCancel}
                          type="button"
                          className="cancel_btn"
                        >
                          CANCEL
                        </button>
                        <button
                          type="submit"
                          disabled={!status?.applicationNumber || loading}
                          className="track_btn"
                        >
                          {loading ? (
                            <>
                              <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                              TRACKING
                            </>
                          ) : (
                            <>TRACK</>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TrackRequest;
