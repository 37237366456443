import Validator from 'js-object-validation';
import { message } from '../common';

export const resetPasswordValidator = data => {
  const validations = {
    password: {
      required: true,
      minlength: 6,
    },
    cnfpassword: {
      required: true,
      // equal:data.password,
    },
  };
  // Error messages
  const messages = {
    password: {
      required: message.Required.replace(':item', 'password'),
      minlength: "Password should be at least 6 charater long",
    },
    cnfpassword: {
      required: message.Required.replace(':item', 'confirm password'),
      // equal:message.PasswordMatch
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
