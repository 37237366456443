/** @format */

import * as qs from "query-string";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { message } from "../../../common";
import {
  ApiRoutes,
  AppRoutes,
  youTubeVideoRegex,
  videoNameRegex,
} from "../../../config";
import Pagination from "../../components/pagination";
import { ApiHelper } from "../../../helpers";
import { videoValidator } from "../../../validators";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ListVideoComponent } from "../../components/video";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import addproduct_icon from "../../../assets/img/addIcon.svg";

class ManageVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
      id: "",
      name: "",
      link: "",
      categoryId: "",
      categoryName: "",
      errors: null,
      isLoading: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      isFormOpen: false,
      categoryLoading: true,
      sortBy: "created_at",
      isFilterApplied: false,
      order: "desc",
    };
  }
  componentDidMount() {
    this.handleQueryParams();
    this.getCategoryDetails();
  }
  getCategoryDetails = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { data, isError } = await new ApiHelper().FetchFromServer(
      "/category",
      `/${id}`,
      "GET",
      true
    );
    if (!isError) {
      this.setState({
        categoryName: data.data.cat_name,
        categoryLoading: false,
      });
    }
  };
  handleQueryParams = () => {
    const {
      location: { search },
      match: {
        params: { id },
      },
    } = this.props;

    const query = qs.parse(search);
    if (!id) {
      this.props.history.push(AppRoutes.MANAGE_VIDEO_CATEGORY);
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        categoryId: id,
        sortBy: query.sort || "created_at",
        order: query.order || "desc",
        isFilterApplied: query.sort ? true : false,
      },
      () => this.fetchVideos()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchVideos = async () => {
    const { currentPage, categoryId, limit, sortBy, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      order,
      sortBy,
      cat_id: categoryId,
    };
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIDEOS_LIST.service,
      ApiRoutes.VIDEOS_LIST.url,
      ApiRoutes.VIDEOS_LIST.method,
      ApiRoutes.VIDEOS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: { videos, totalRecords },
      } = response.data;
      this.setState({
        videos,
        totalRecords,
      });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
      errors: null,
      name: "",
      link: "",
    }));
  };
  handleChange = (event) => {
    console.log("dsd");
    const {
      target: { name, value, id },
    } = event;

    if (name === "isPublished") {
      const isPublished = value;
      this.updateVideoStatus({ isPublished }, id);
    }
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: "" },
    });
  };
  handleVideo = async (event) => {
    const { target } = event;
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to ${
        target.value ? "Publish" : "Unpublish"
      } the video?`,
    });
    if (!value) {
      return;
    }
    if (target.name === "isPublished") {
      const isPublished = target.value;
      this.updateVideoStatus({ isPublished }, target.id);
    } else {
      this.setState({
        [target.name]: target.value,
        errors: { ...this.state.errors, [target.name]: "" },
      });
      return;
    }
  };
  addVideo = async (data) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_VIDEO.service,
      ApiRoutes.ADD_VIDEO.url,
      ApiRoutes.ADD_VIDEO.method,
      ApiRoutes.ADD_VIDEO.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        name: "",
        link: "",
        isFormOpen: false,
      });
      this.fetchVideos();
    }
  };
  updateVideo = async (data, id) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_VIDEO.service,
      ApiRoutes.UPDATE_VIDEO.url.replace(":id", id),
      ApiRoutes.UPDATE_VIDEO.method,
      ApiRoutes.UPDATE_VIDEO.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        name: "",
        link: "",
        isFormOpen: false,
        id: "",
      });
      this.fetchVideos();
    }
  };
  updateVideoStatus = async (data, id) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_VIDEO_STATUS.service,
      ApiRoutes.UPDATE_VIDEO_STATUS.url.replace(":id", id),
      ApiRoutes.UPDATE_VIDEO_STATUS.method,
      ApiRoutes.UPDATE_VIDEO_STATUS.authenticate,
      undefined,
      data
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        this.setState({
          errors: response.validationErrors,
        });
      }
    } else {
      toast.success(response.messages[0]);
      this.setState({
        errors: null,
        name: "",
        link: "",
        // isPublished: !this.state.isPublished,
        isFormOpen: false,
        id: "",
      });
      this.fetchVideos();
    }
  };
  handleSubmit = () => {
    const { name, link, categoryId, id } = this.state;
    const data = { name, link, categoryId };
    let { isValid, errors } = videoValidator(data);
    // To validate youtube video link
    if (link && !youTubeVideoRegex.test(link.trim())) {
      isValid = false;
      errors = {
        ...errors,
        link: message.ValidLink,
      };
    }
    if (!videoNameRegex.test(name.trim())) {
      isValid = false;
      errors = {
        ...errors,
        name: message.InvalidVideoName,
      };
    }
    if (isValid) {
      if (id) {
        this.updateVideo({ name, link }, id);
      } else {
        this.addVideo({ name, link, cat_id: categoryId });
      }
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };
  onCancel = () => {
    this.setState({
      errors: null,
      name: "",
      link: "",
      isFormOpen: false,
    });
  };
  onEdit = async (data) => {
    console.log("onEdit", data);

    const { name = "", link = "", id = "" } = data || {};
    this.setState({ name, link, id, isFormOpen: true });
  };
  deleteVideo = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to delete this video?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_VIDEO.service,
      ApiRoutes.DELETE_VIDEO.url.replace(":id", id),
      ApiRoutes.DELETE_VIDEO.method,
      ApiRoutes.DELETE_VIDEO.authenticate,
      undefined,
      undefined
    );
    this.setState({
      id: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchVideos();
      toast.success(response.messages[0]);
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  customBreadCrumb = () => {
    const { categoryLoading, categoryName } = this.state;
    return categoryLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      categoryName
    );
  };
  render() {
    const {
      videos,
      totalRecords,
      currentPage,
      limit,
      name,
      link,
      isPublished,
      errors,
      isFormOpen,
      isLoading,
      isAdding,
      sortBy,
      order,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
              search={state ? state.search : ""}
            />
          </h3>

          <div>
            <button
              type="button"
              className="addProductbtn ml-3 addvideobtn"
              id="add-cat-btn"
              onClick={this.onAddBtnClick}
            >
              <img className="mr-2" src={addproduct_icon} alt="" />
              Add Video
            </button>
          </div>
        </div>

        <div>
          <ListVideoComponent
            videos={videos}
            totalRecords={totalRecords}
            limit={limit}
            errors={errors}
            isLoading={isLoading}
            isAdding={isAdding}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onCancel={this.onCancel}
            name={name}
            link={link}
            isPublished={isPublished}
            isFormOpen={isFormOpen}
            deleteVideo={this.deleteVideo}
            onEdit={this.onEdit}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
            handleVideo={this.handleVideo}
          />
        </div>
        <div className="footer-pg px-0 px-lg-3">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ManageVideo;
