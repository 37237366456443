import React from "react";

export const ScanRow = ({
  scanData,
  userColumn = true,
  batchViewHandler,
  userViewHandler,
}) => {
  const {
    scanId = "",
    batch = {},
    product = {},
    user = {},
    createdAtDate,
    createdAtTime,
  } = scanData;
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const { first_name = "", other_info = {} } = user || {};
  return (
    <tr className="scan btachRow history counter_feit">
      <td>
        <span className="textContol">{scanId}</span>
      </td>
      <td
        className={"cursor-pointer"}
        onClick={batchViewHandler ? batchViewHandler : null}
      >
        <span className="textContol">{batchNumber}</span>
      </td>
      <td className="pname_scan">
        <span className="textContol">{productName}</span>
      </td>
      {userColumn ? (
        <td
          className={"cursor-pointer"}
          onClick={userViewHandler ? userViewHandler : null}
        >
          <div className="d-flex align-items-center">
            <div
              style={{
                width: "36px",
                height: "36px",
                marginRight: "12px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <span
                className="profile_holder"
                style={{ textTransform: "uppercase" }}
              >
                {first_name ? first_name.substring(0, 1) : "-"}
              </span>
            </div>
            <span className="textContol">{first_name || "-"}</span>
          </div>
        </td>
      ) : null}
      <td className="ploc_scan">
        <span className="textContol" style={{ opacity: "0.75" }}>
          {other_info && other_info.address1 ? other_info.address1 : "-"}
        </span>
      </td>
      <td>
        <span className="textContol text-nowrap">{`${createdAtTime} | ${createdAtDate}`}</span>
      </td>
    </tr>
  );
};
