/** @format */
import React from "react";
import "./batches.css";
import { ReportCounterfeitCard } from "../../components/loader/Resusable/ReportCounterfeitCard";
import upIcon from "../../../assets/img/up-icon.svg";
import downIcon from "../../../assets/img/down-icon.svg";
import chart1 from "../../../assets/img/chart1.svg";
import chart2 from "../../../assets/img/chart2.svg";

export const ReportCard = ({
  title,
  size,
  loading,
  total,
  prevString,
  perc,
}) => {
  return (
    <div className={size}>
      {loading ? (
        <ReportCounterfeitCard />
      ) : (
        <div className="graphDataCard">
          <div>
            <p>{title}</p>
            <h5>{total ? total : "0"}</h5>
            {prevString !== "" ? (
              <div className="arrowBox">
                {perc > 0 ? (
                  <img
                    src={upIcon}
                    className="card-image"
                    id="card-img-warning"
                    alt=""
                  />
                ) : (
                  <img
                    src={downIcon}
                    className="card-image"
                    id="card-img-warning"
                    alt=""
                  />
                )}
                <span>
                  {Math.abs(perc).toFixed(0)}
                  {prevString}
                </span>
              </div>
            ) : (
              <div className="arrowBox">&nbsp;</div>
            )}
          </div>
          {prevString !== "" ? (
            <div className="chartIcon">
              {perc > 0 ? (
                <img
                  src={chart1}
                  className="card-image"
                  id="card-img-warning"
                  alt=""
                />
              ) : (
                <img
                  src={chart2}
                  className="card-image"
                  id="card-img-warning"
                  alt=""
                />
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
