import React from "react";
import invoice from "../../../assets/img/invoiceDark.svg";
import planName from "../../../assets/img/planName.svg";
import billingCycle from "../../../assets/img/billingCycle.svg";
import billingStatus from "../../../assets/img/billingStatus.svg";
import billingUser from "../../../assets/img/billingUser.svg";
import billingPrice from "../../../assets/img/billingPrice.svg";
import billingRenewal from "../../../assets/img/billingRenewal.svg";
import StatusModal from "./StatusModal";
import { useState } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import noData from "../../../assets/img/noData.svg";
import { BillingLoader } from "../loader/Resusable/BillingLoader";

export default function BillingsInvoice() {
  const [showStatus, setShowStatus] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [subscription, setSubscriptionData] = useState();
  const [clientData, setClientData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSubscription();
  }, []);

  const getSubscription = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.service,
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.url,
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.method,
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      setSubscriptionData(data);
    }
  };

  const getInvoice = async (value) => {
    setUpdating(value._id);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_INVOICE.service,
      ApiRoutes.GET_INVOICE.url.replace(":id", value?.stripeInvoiceId),
      ApiRoutes.GET_INVOICE.method,
      ApiRoutes.GET_INVOICE.authenticate,
      undefined
    );
    setUpdating(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      window.location.href = data;
    }
  };
  const handleShow = (data) => {
    setShowStatus(true);
    setClientData(data);
  };
  return (
    <>
      <div className="col-md-12">
        <div className="exportRow">
          <div className="d-flex align-items-center">
            <h4>Your Subscriptions</h4>
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <BillingLoader />
        </>
      ) : (
        <>
          <div className="col-md-12">
            <div className="innerHolder">
              <p className="listHeading titleCard">
                All your paid Clarity subscription are listed below
              </p>

              {subscription && subscription.length ? (
                subscription.map((info, index) => (
                  <div
                    key={index}
                    className={
                      index === 0
                        ? "billingInviceCard active"
                        : "billingInviceCard"
                    }
                  >
                    <div className="CardHeader">
                      <h4>Renewed On : {info.purchaseDateFormat}</h4>
                      <div className="rightSection">
                        <div className="buttonHolder">
                          <button
                            className=""
                            disabled={
                              updating === info._id || !info?.stripeInvoiceId
                            }
                            onClick={() => getInvoice(info)}
                          >
                            {updating === info._id ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                Invoice
                              </>
                            ) : (
                              <>
                                <img src={invoice} alt="" /> Invoice
                              </>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="CardBody">
                      <div className="row">
                        <div className="col-md-5">
                          <ul className="invoice_list">
                            <li>
                              <div className="image_holder">
                                <img src={planName} alt="" />
                              </div>
                              <div className="detail text-capitalize">
                                <span>Plan Name</span>
                                <p>
                                  {info.subscription && info.subscription.name}
                                </p>
                              </div>
                              {index === 0 && info.status !== "Cancel" && (
                                <Link
                                  to={AppRoutes.Purchase}
                                  className="change"
                                >
                                  <span>Change</span>
                                </Link>
                              )}
                            </li>
                            <li>
                              <div className="image_holder">
                                <img src={billingCycle} alt="" />
                              </div>
                              <div className="detail">
                                <span>Billing Cycle</span>
                                <p>{info.billingCycle}</p>
                              </div>
                              {index === 0 && info.status !== "Cancel" && (
                                <Link
                                  to={AppRoutes.Purchase}
                                  className="change"
                                >
                                  <span>Change</span>
                                </Link>
                              )}
                            </li>
                            <li>
                              <div className="image_holder">
                                <img src={billingStatus} alt="" />
                              </div>
                              <div className="detail">
                                <span>Status</span>
                                <p>{info.status}</p>
                              </div>
                              {index === 0 && info.status !== "Cancel" && (
                                <div
                                  className="change"
                                  onClick={() => handleShow(info)}
                                >
                                  <span>Change</span>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-7">
                          <ul className="invoice_list">
                            <li>
                              <div className="image_holder">
                                <img src={billingUser} alt="" />
                              </div>
                              <div className="detail">
                                <span>Users</span>
                                <p>
                                  {info.subscription && info.subscription.users}
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="image_holder">
                                <img src={billingPrice} alt="" />
                              </div>
                              <div className="detail">
                                <span>Pricing</span>
                                <p>${info.amount} USD</p>
                              </div>
                            </li>
                            <li>
                              <div className="image_holder">
                                <img src={billingRenewal} alt="" />
                              </div>
                              <div className="detail">
                                <span>Expiry Date</span>
                                <p>
                                  {info.expireDateFormat}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-tr pt-5" style={{ borderTop: "0" }}>
                  <div
                    className={
                      "text-center image-no-data-xs nodataTablePosition paymentCardNoData"
                    }
                  >
                    <img src={noData} alt="" />
                    <p>
                      The data you're looking is not available at this moment
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <StatusModal
        showStatus={showStatus}
        clientData={clientData}
        closeStatus={() => setShowStatus(false)}
        getSubscription={getSubscription}
      />
    </>
  );
}
