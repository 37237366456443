import React from 'react';
import { Form } from 'react-bootstrap';
import logo from '../../../assets/img/clarity_logo.png';
import vector from '../../../assets/img/forgotPass_vector.svg'
import InputTextFeild from '../form/InputTextFeild';
import SubmitButton from '../form/SubmitButton';
const ForgotPasswordFormComponent = ({
    onChange,
    onSubmit,
    email,
    errors,
    isLoading,
}) => {
    return (
        <>
            <section className='formLeftSection'>
                <div className='glassEffects nextonboarding'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-6">
                                <img className='logo' src={logo} />
                                <h2>Forgot Password</h2>
                                <p>You can get a reset password link by entering the below details</p>
                                <Form>
                                    
                                    <label>Email</label>
                                    <InputTextFeild
                                        name="email" 
                                        type="email" 
                                        placeholder="Your Email" 
                                        value={email} 
                                        onChange={onChange} 
                                        errorMessage={errors && errors.email ? errors.email : ''}
                                    />
                                    <div style={{marginTop:"15px", display:"inline-block",width:"100%"}}>                     
                                    <SubmitButton
                                        text={'Send me reset link'}
                                        isLoading={isLoading}
                                        onSubmit={onSubmit}
                                        className={'customSubmitBtn mt-0'}
                                    >                                            
                                    </SubmitButton>
                                    </div>      

                                </Form>
                            </div>
                            <div className="col-md-6 d-none d-md-block image-loader">
                                <div className='h-100 d-flex align-items-center justify-content-center'>
                                    <img className='w-100' src={vector} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>         
        </>
    );
}

export default ForgotPasswordFormComponent;