import React from "react";
import UserRow from "./UserRow";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";

export const ListComponent = ({
  users,
  isFilterApplied,
  isLoading,
  isUpdating,
  activeId,
  viewHandler,
  blockUser,
  unBlockUser,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="tabel-outer">
      <div className="table-responsive fixed-height-table">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table
            className="table batchTable counterfeitTable"
            width="100%"
            cellSpacing="0"
          >
            <thead className="pad-top">
              <tr className="batchesHeader">
                <th style={{ minWidth: "171px" }}>
                  Name
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "name"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "name",
                          sortBy === "name"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>{" "}
                </th>
                <th style={{ minWidth: "148px" }}>
                  Email
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "email"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "email",
                          sortBy === "email"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "153px" }}>
                  Scan
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "scans"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "scans",
                          sortBy === "scans"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "120px" }}>
                  Counterfeits
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "counterfeits"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "counterfeits",
                          sortBy === "counterfeits"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "124px" }}>
                  STARTED AT
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "created_at"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "created_at",
                          sortBy === "created_at"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "181px" }}>Action</th>
              </tr>
            </thead>

            <tbody>
              {users && users.length ? (
                users.map((e, index) => (
                  <UserRow
                    key={index}
                    userData={e}
                    isUpdating={isUpdating && activeId === e.id}
                    viewUser={() => viewHandler(e.id)}
                    blockUser={() => blockUser(e.id)}
                    unBlockUser={() => unBlockUser(e.id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td
                    colSpan={6}
                    className={
                      "text-center image-no-data-xs nodataTablePosition"
                    }
                  >
                    {isFilterApplied ? (
                      <>
                        <img src={noData} alt="" />
                        <p>
                          The data you're looking is not available at this
                          moment
                        </p>
                      </>
                    ) : (
                      <>
                        <img src={noData} alt="" />
                        <p>
                          The data you're looking is not available at this
                          moment
                        </p>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
        {/* {!isLoading && totalRecords > limit ? (
          <div className="float-right d-flex align-items-center">
            <button className="paginationArrows">
              <img src={leftPageArrow} />
            </button>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
            <button className="paginationArrows">
              <img src={rightPageArrow} />
            </button>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
