import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import ReactApexChart from "react-apexcharts";
import no_data from "../../../assets/img/no_data.svg";
import { Dropdown } from "react-bootstrap";
import arrow from "../../../assets/img/thisWeekDownIcon.svg";
import { DashboardWidgetLoader } from "../../components/loader/Resusable/DashboardWidget";
import { CardLoader } from "../../components/loader/Dashboard/CardLoader";

export default function ProductStatics() {
  useEffect(() => {
    fetchGraphData();
  }, []);
  const [topScan, setTopScan] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState("Week");

  const fetchGraphData = (selectedTime) => {
    const payload = {
      selectedTime,
    };
    getTopScans(payload);
  };
  const getTopScans = async (data) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.service,
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.url,
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.method,
      ApiRoutes.DASHBOARD_TOP_SCANNED_PRODUCT.authenticate,
      data,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data;
      setTopScan(data);
    }
  };

  let value = topScan && topScan.dataset && topScan.dataset.value;

  const series = [
    {
      name: "Scanned",
      data: topScan && topScan.dataset && topScan.dataset.value,
    },
  ];

  const options = {
    chart: {
      type: "bar",
      // height: 20,
      toolbar: {
        show: false,
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },

      show: false,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        horizontal: true,
        barHeight: 49,
        colors: {
          ranges: [
            {
              from: 0,
              to: 100,
              color: "#00ACB9",
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: topScan && topScan.dataset && topScan.dataset.labels,
      // min: 0,
      // max: 50,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      lines: {
        show: false,
      },
      labels: {
        align: "left",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#8a94a6"],
        },
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
      axisBorder: {
        show: true,
        color: "red",
      },
      labels: {
        show: true,
        align: "left",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#1D2524"],
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "15px",
        },
      },
    },
  };

  if (topScan && topScan.dataset && topScan.dataset.labels.length < 2) {
    options.plotOptions.barHeight = 20;
  }

  return (
    <>
      <div className="mycard py-0 TotalScannedProduct">
        {loading ? (
          <CardLoader />
        ) : (
          <>
            <div className="cardHeader">
              <h4>Total Scanned Product</h4>
              <Dropdown className="customDropdownBox">
                <Dropdown.Toggle className="span" id="dropdown-basic">
                  {selectedTime === "Week"
                    ? "This Week"
                    : "" || selectedTime === "LastWeek"
                    ? "Last Week"
                    : "" || selectedTime === "Month"
                    ? "This Month"
                    : "" || selectedTime === "Year"
                    ? "This Year"
                    : ""}
                  <img src={arrow} style={{ marginLeft: "10.72px" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Week");
                      fetchGraphData("Week");
                    }}
                  >
                    This Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("LastWeek");
                      fetchGraphData("LastWeek");
                    }}
                  >
                    Last Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Month");
                      fetchGraphData("Month");
                    }}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Year");
                      fetchGraphData("Year");
                    }}
                  >
                    This Year
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {topScan &&
            topScan.totalTopScannedProduct &&
            topScan.totalTopScannedProduct ? (
              <div className="cardBody">
                <h4>{topScan && topScan.totalTopScannedProduct}</h4>

                {selectedTime && (
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height={"80%"}
                  />
                )}
              </div>
            ) : (
              <div
                className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                style={{ minHeight: "415px", padding: "0", marginTop: "-65px" }}
              >
                <img src={no_data} />
                <h5>No Data Yet</h5>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
