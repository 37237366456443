import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import crossIcon from "../../../../assets/img/closeModal.svg";
import AsyncSelect from "react-select/async";
import InputTextFeild from "../../form/InputTextFeild";
import { ApiHelper } from "../../../../helpers";

export default function AddAddressModal({
  submitAddress,
  onChange,
  addressError,
  onSelectOption,
  addressDataEdit,
  allCountry,
  statesOption,
  show,
  handleClose,
  clientLoader,
  isAddressEdit,
}) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };
  const loadStateData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = statesOption.filter(
        (state) =>
          state.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  const loadCountryData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };
  return (
    <Modal
      className="emailAddresssModal addressModalbox"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header style={{ marginBottom: "30px" }}>
        <Modal.Title>Add Address</Modal.Title>
        <div>
          <Button variant="" onClick={handleClose}>
            <img src={crossIcon} />
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="addressForm customchanges">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Address Label*</label>
                <InputTextFeild
                  id="address"
                  name="addressType"
                  value={addressDataEdit?.addressType}
                  onChange={onChange}
                  errorMessage={addressError?.addressType}
                  placeholder="Enter address label (will be used to identify the address type"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Address Line 1*</label>
                <InputTextFeild
                  name="addressLine1"
                  placeholder="Enter address"
                  value={addressDataEdit?.addressLine1}
                  onChange={onChange}
                  errorMessage={addressError?.addressLine1}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Address Line 2</label>
                <InputTextFeild
                  name="addressLine2"
                  placeholder="Enter address"
                  value={addressDataEdit?.addressLine2}
                  onChange={onChange}
                  errorMessage={addressError?.addressLine2}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Country*</label>
                <AsyncSelect
                  loadOptions={loadCountryData}
                  placeholder={"--Select--"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allCountry}
                  value={{
                    label: addressDataEdit?.country,
                    value: addressDataEdit?.country,
                  }}
                  onChange={(selectedOption) =>
                    onSelectOption(selectedOption, "country")
                  }
                  noOptionsMessage={() =>
                    "No option found related to your search"
                  }
                />
                <div className="text-danger">{addressError?.country}</div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label className={`addBatch-Labels`}>State*</label>
                <AsyncSelect
                  loadOptions={loadStateData}
                  placeholder={"--Select--"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  value={{
                    label: addressDataEdit?.state,
                    value: addressDataEdit?.state,
                  }}
                  defaultOptions={statesOption}
                  onChange={(selectedOption) =>
                    onSelectOption(selectedOption, "state")
                  }
                  noOptionsMessage={() => {
                    return addressDataEdit?.country
                      ? "No states found"
                      : "First select country";
                  }}
                />
                <div className="text-danger">{addressError?.state}</div>
              </div>
            </div>
            
            <div className="col-md-4">
              <div className="form-group">
                <label className={`addBatch-Labels`}>Post Code*</label>
                <InputTextFeild
                  placeholder="Enter Post Code"
                  // type="number"
                  name="postCode"
                  min="0"
                  // maxLength={6}
                  value={addressDataEdit?.postCode}
                  onChange={onChange}
                  errorMessage={addressError?.postCode}
                />
              </div>
            </div>
            <div className="col-md-12 text-center">
              <Button
                disabled={clientLoader}
                onClick={submitAddress}
                variant=""
                className="otp_btn"
              >
                {clientLoader ? (
                  <>
                    <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                    {isAddressEdit ? "Update" : "Save"}
                  </>
                ) : (
                  <>{isAddressEdit ? "Update" : "Save"}</>
                )}
              </Button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
