import React from "react";
import { useState } from "react";
import MethodUp from "../../../assets/img/MethodUp.svg";
import methodDown from "../../../assets/img/methodDown.svg";
import SubmitButton from "../form/SubmitButton";
import DatePicker from "react-datepicker";
import InputTextFeild from "../form/InputTextFeild";
import { SaveCardsAccountLoader2 } from "../loader/Resusable/SaveCardsAccountLoader2";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./StripeForm";
import { AppConfig } from "../../../config";
const stripePromise = loadStripe(AppConfig.STRIPE_KEY);

export default function PaymentMethod({ loading, fetchCards }) {
  const [isActive, setIsActive] = useState(true);
  // const [loading, setLoading] = useState(false);
  const handleAccordion = () => {
    setIsActive(!isActive);
  };
  const accordionOptions = [
    {
      title: "Credit Card / Debit Card",
    },
  ];
  return (
    <>
      <div className="col-md-12">
        <div className="exportRow">
          <div className="d-flex align-items-center">
            <h4>Add New Payment Method</h4>
          </div>
        </div>
      </div>
      {loading ? (
        <>
          <SaveCardsAccountLoader2 />
        </>
      ) : (
        <div className="col-md-12 accrordianHolder">
          <div className="rightAccordion ">
            {accordionOptions.map((info, index) => (
              <div key={index} className="accordion-item">
                <div
                  className="accordion-title d-flex"
                  onClick={handleAccordion}
                >
                  <div className="">
                    <span className="">
                      <img
                        src={methodDown}
                        className={isActive ? "active iconDrop" : "iconDrop"}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className={isActive ? "title active" : "title"}>
                    {info.title}
                  </div>
                </div>
                <div
                  className={
                    isActive
                      ? "accordion-content show"
                      : "accordion-content hide"
                  }
                >
                  <div className="row">
                    <div className="col-md-12 col-lg-8 col-xl-6">
                      <div className="innerCard">
                        <div className="CardHeader">
                          <h4>Add Payment Method</h4>
                          <p>Enter your debit card details</p>
                        </div>
                        <div className="CardBody">
                          {/* <form>
                          <div className="row">
                            <div className="col-md-12">
                              <label>Card Number</label>
                              <InputTextFeild
                                placeholder="XXXX XXXX XXXX XXXX"
                                name="title"
                                // value={planData.title}
                                // onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Card Holder Name</label>
                              <InputTextFeild
                                placeholder="Enter name"
                                name="title"
                                // value={planData.title}
                                // onChange={handleChange}
                              />
                            </div>
                            <div className="col-md-6">
                              <label>Expiry Date</label>
                              <DatePicker
                                className="form-control"
                                placeholder={"Date of Event(DD/MM/YYYY)"}
                                dateFormat="dd/MM/yyyy"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                minDate={new Date()}
                                // selected={planData.eventDate}
                                // onChange={handleDateChange}
                              />
                            </div>

                            <div className="col-md-6 mt-sm-0 mt-md-4">
                              <span
                                className={
                                  "customCancelBtn mt-0 btn mt-4 d-flex align-items-center justify-content-center"
                                }
                              >
                                Cancel
                              </span>
                            </div>
                            <div className="col-md-6 mt-sm-0 mt-md-4">
                              <SubmitButton
                                className={"customSubmitBtn mt-4"}
                                text={"Update"}
                                // isLoading={isAdding}
                                // onSubmit={handleSubmit}
                              />
                            </div>
                          </div>
                        </form>
                         */}
                          <Elements stripe={stripePromise}>
                            <StripeForm
                              fetchCards={fetchCards}
                              handleAccordion={handleAccordion}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
