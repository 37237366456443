import React from 'react';
import SubmitButton from '../form/SubmitButton';

export const AddCategoryComponent = ({
  categoryName,
  errors,
  isLoading,
  onChange,
  onSubmit,
  onCancel,
}) => {
  return (
    <form className='cat_add' onSubmit={onSubmit}>
      <div className='cat_head'>
        <div className='form-group'>
          <label htmlFor='category_name' className='form-control-label addlablebox'>
            Enter Category Name
          </label>
          <textarea
            className='form-control form-control-user wt-resize'
            cols='50'
            id='category_name'
            aria-describedby='category_nameHelp'
            rows='4'
            name={'categoryName'}
            value={categoryName}
            onChange={onChange}
            maxLength={30}
          />
          {errors && errors.categoryName ? (
            <div className={'text-danger error-text-block'} style={{fontSize: "12px"}}>
              {/* {errors.categoryName} */}
              Please enter category name
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='cat_desc_btn d-flex align-items-center justify-content-between'>
          <div className='num_head w-100'>
            {errors && errors.categoryName ? (
              <button className='btn can-btn can-btn-error' onClick={onCancel}>
                {' '}
                Cancel{' '}
              </button>
            ) : (
              <button className='btn can-btn' onClick={onCancel}>
                {' '}
                Cancel{' '}
              </button>
            )}
          </div>
          <div className='date_head  ml-0'>
            {errors && errors.categoryName ? (
              <SubmitButton
                className={'save-btn save-btn-error'}
                text={'Save'}
                isLoading={isLoading}
                onSubmit={onSubmit}
              />
            ) : (
              <SubmitButton
                className={'save-btn'}
                text={'Save'}
                isLoading={isLoading}
                onSubmit={onSubmit}
              />
            )}

            {/* <button type='button' className='btn save-btn'>
              {' '}
              Save{' '}
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
};
