import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router";
// import noData from "../../../assets/img/no-data.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import PHLContext from "../../../PHLContext";
import {
  ApiRoutes,
  // DEFAULT_DATE_FORMAT,
  // DEFAULT_TIME_FORMAT,
} from "../../../config";
import { useLocation, matchPath } from "react-router-dom";
import { routesArray } from "./../../../router/routes";
// import moment from "moment";
import { ApiHelper, Logout } from "../../../helpers";
import Swal from "sweetalert2";
// import { Dropdown } from "react-bootstrap";
import "./index.css";
// import logo_black from "../../../assets/img/logo_black.png";
// import messageIcon from "../../../assets/img/chat.svg";
// import notificationIcon from "../../../assets/img/notification.svg";
// import logoutIcon from "../../../assets/img/logout.png"
import toggle from "../../../assets/img/sidebarToggle.svg";

import { SocketEvents } from "../../../config";

function Header(props) {
  const [notificationCount, setNotificationCount] = useState();
  const [notifications, setNotifications] = useState([]);
  const { socket } = useContext(PHLContext);
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);

  let history = useHistory();
  const { location } = history;
  const { pathname } = location;

  const route = useLocation();
  // console.log(route);
  useEffect(() => {
    // const currentRoute = routesArray.find((rout) => {
    //   console.log("rout", rout);
    //   const currentRoute = route.pathname.split("/");
    //   console.log("currentRoute", currentRoute);
    //   const mainPath = `/${currentRoute[1]}`;
    //   const match = matchPath(mainPath, rout);
    //   if (match !== null) {
    //     console.log("match", mainPath, match);
    //   }
    // });
    const currentLocation = route.pathname.split("/");
    const mainPath = `/${currentLocation[1]}`;
    const currentRoute = routesArray.find((elem) => {
      return elem.path === mainPath;
    });

    if (currentRoute) {
      setTitle(currentRoute.name);
    } else {
      setTitle(
        `${route.pathname
          .replace("/", "")
          .toLowerCase()
          .charAt(0)
          .toUpperCase()}${route.pathname.replace("/", "").slice(1)}`
      );
    }
  }, [route.pathname]);

  const curroute = window.location.pathname.split("/");
  let myTitle = curroute[curroute.length - 1];

  const fetchNotifications = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NOTIFICATIONS.service,
      ApiRoutes.GET_NOTIFICATIONS.url,
      ApiRoutes.GET_NOTIFICATIONS.method,
      ApiRoutes.GET_NOTIFICATIONS.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      setNotificationCount(response.data.count);
      setNotifications(data);
    }
  };
  useEffect(() => {
    async function fetchData() {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_PROFILE.service,
        ApiRoutes.GET_PROFILE.url,
        ApiRoutes.GET_PROFILE.method,
        ApiRoutes.GET_PROFILE.authenticate,
        undefined,
        undefined
      );
      if (response && response.isError) {
        Logout();
      } else {
        const { data } = response.data;
        const { notification_count = 0 } = data || {};
        setNotificationCount(notification_count);
      }
    }
    fetchData();
    fetchNotifications();
    if (socket) {
      // socket.emit('online', { userId: '5f4428f30fa9c11ef89bb084' });
      socket.on(SocketEvents.NOTIFICATION, (data) => {
        setNotificationCount((prevCount) => prevCount + 1);
        setNotifications((prevNotifications) => [data, ...prevNotifications]);
      });
    }
  }, []);

  const clearAllNotifications = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.service,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.url,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.method,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      setNotifications([]);
    }
  };
  const deleteNotification = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_NOTIFICATION.service,
      ApiRoutes.DELETE_NOTIFICATION.url.replace(":id", id),
      ApiRoutes.DELETE_NOTIFICATION.method,
      ApiRoutes.DELETE_NOTIFICATION.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      fetchNotifications();
      setShow(true);
    }
  };
  const notificationRead = async (notification) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.NOTIFICATION_MARK_READ.service,
      ApiRoutes.NOTIFICATION_MARK_READ.url.replace(":id", notification.id),
      ApiRoutes.NOTIFICATION_MARK_READ.method,
      ApiRoutes.NOTIFICATION_MARK_READ.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      fetchNotifications();
      history.push(notification.redirectionURL);
    }
  };

  const handleclick = () => {
    Swal.fire({
      title: "Are you sure, you want to logout?",
      //   icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0f51a1",
      cancelButtonColor: "#bfbec3",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "logoutAlertPopup",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        history.push("/");
      }
    });
  };

  return (
    <>
      {/* <Navbar className="dashboardHeader" bg="" expand="lg">
        <Container
          fluid
          className="p-0 d-flex align-items-center justify-content-between"
        >
          <Navbar.Brand as="div">
            <h3>Manage {pathname.split("/")[1]}</h3>
            <p>Manage {pathname.split("/")[1]}</p>
          </Navbar.Brand>
          <div className="middleLogo">
            <img alt="img" src={logo_black} />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="mr-4" id="basic-navbar-nav">
            <Nav className="me-auto"> */}
      {/* <div className="dropdown customDropdownBox">
                <button
                  className="btn dropdown-toggle caret-off p-0 position-relative"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                >
                  <img alt="img" src={messageIcon} />
                  <span className="counter">84</span>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#nogo">
                    no data
                  </a>
                </div>
              </div> */}
      {/* <div className="dropdown customDropdownBox ml-4">
                <button
                  className="btn dropdown-toggle caret-off p-0 position-relative"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                >
                  <img alt="img" src={notificationIcon} />
                  <span className="counter">2</span>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a className="dropdown-item" href="#nogo">
                    no data
                  </a>
                </div>
              </div>
               
              <Dropdown
            className={"dropdown customDropdownBox ml-4"}
            // onClick={resetNotificationCount}
            autoClose={false}
            show={show}
            onToggle={() => {
              setShow(!show);
            }}
          >
            <Dropdown.Toggle
              as={"a"}
              href="javascript:void(0);"
              id="alertsDropdown1"
              className={"nav-link"}
            >
              <img src={notificationIcon} className="Notification" alt="" />
            <span className="counter"> {notificationCount} </span>  
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right shadow">
              <Dropdown.Header className="d-flex">
                <h5>Notification</h5>
                <div onClick={clearAllNotifications} className="clear-all">
                  (Clear All)
                </div>
              </Dropdown.Header>
              <div className="notifyBox">
                {notifications.length === 0 ? (
                  <div className="nonotify" style={{ textAlign: "center", marginTop: "-10px" }}>
                    <div style={{height: "130px", overflow: "hidden", zIndex: "-1"}}>
                      <img style={{ alignItems: "center" }} src={noData} alt="" />
                    </div>
                    <h4>No new notification found!</h4>
                  </div>
                ) : (
                  notifications.map((notification) => {
                    return (
                      <Fragment key={notification.id}>
                        <Dropdown.Item
                          as="div"
                          className={notification.isRead ? "read" : "unread"}
                        >
                          <div onClick={() => notificationRead(notification)}>
                            <div>{notification.title}</div>
                            <div>
                              {moment(notification.createdAt).format(
                                `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`
                              )}
                              hrs
                            </div>
                          </div>
                          <div
                            className="remove-notification"
                            onClick={() => {
                              deleteNotification(notification.id);
                            }}
                          >
                            X
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                      </Fragment>
                    );
                  })
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>


                            <div className="dropdown customDropdownBox ml-4">
                                <button 
                                    className="btn dropdown-toggle caret-off p-0 position-relative" 
                                    type="button" 
                                    id="dropdownMenuButton" 
                                    data-toggle="dropdown"
                                    aria-haspopup="true">
                                    <img alt="img" style={{width: '23px', opacity: '.75'}} src={logoutIcon} />
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{left: '-130px'}}>
                                    <a className="dropdown-item" onClick={handleclick}>Logout</a>
                                </div>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
        <Navbar className={title !== "Dashboard" ? "" : "dashboard_header"}>
          <div className="container-fluid">
            <div className="pageHeaderRow d-flex w-100">
              <h3>{title}</h3>
              <img
                src={toggle}
                alt=""
                onClick={props.show}
                className="ml-auto d-lg-none"
              />
              {/* <h4>Here you can manage your {myTitle} listing</h4> */}
            </div>
          </div>
        </Navbar>
    </>
  );
}

export default Header;
