import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import ReactApexChart from "react-apexcharts";
import no_data from "../../../assets/img/no_data.svg";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import { CardLoader } from "../../components/loader/Dashboard/CardLoader";
import { Dropdown } from "react-bootstrap";
import arrow from "../../../assets/img/thisWeekDownIcon.svg";

export default function CounterfietStatic() {
  useEffect(() => {
    fetchGraphData();
  }, []);
  const [counterfietStatic, setCounterStatic] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState("Week");

  const fetchGraphData = (selectedTime) => {
    const payload = {
      selectedTime,
    };
    getCounterStatics(payload);
  };
  const getCounterStatics = async (data) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.service,
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.url,
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.method,
      ApiRoutes.DASHBOARD_Counterfeits_STATISTICS.authenticate,
      data,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data;
      setCounterStatic(data);
    }
  };
  const counterOptions = {
    fill: {
      colors: ["#246A18", "#F49E1C", "#C60F0F", "#344054"],
    },
    labels: ["Pending", "Completed", "Under Review", "Acknowledge"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        left: -15,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
  };
  const counterSeries = [
    counterfietStatic && counterfietStatic.totalUnderProcess,
    counterfietStatic && counterfietStatic.totalCompleted,
    counterfietStatic && counterfietStatic.totalUnderReview,
    counterfietStatic && counterfietStatic.totalAcknowldeged,
  ]; //our data
  return (
    <>
      <div className="mycard py-0">
        {loading ? (
          <CardLoader />
        ) : (
          <>
            <div className="cardHeader">
              <h4>Total Counterfeits</h4>
              <Dropdown className="customDropdownBox">
                <Dropdown.Toggle className="span" id="dropdown-basic">
                  {selectedTime === "Week"
                    ? "This Week"
                    : "" || selectedTime === "LastWeek"
                    ? "Last Week"
                    : "" || selectedTime === "Month"
                    ? "This Month"
                    : "" || selectedTime === "Year"
                    ? "This Year"
                    : ""}
                  <img src={arrow} style={{ marginLeft: "10.72px" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Week");
                      fetchGraphData("Week");
                    }}
                  >
                    This Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("LastWeek");
                      fetchGraphData("LastWeek");
                    }}
                  >
                    Last Week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Month");
                      fetchGraphData("Month");
                    }}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Year");
                      fetchGraphData("Year");
                    }}
                  >
                    This Year
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {counterfietStatic &&
            counterfietStatic.totalCounterfeits &&
            counterfietStatic.totalCounterfeits > 0 ? (
              <div className="cardBody card_overflow">
                <div className="d-flex">
                  <div className="">
                    <h4>
                      {counterfietStatic && counterfietStatic.totalCounterfeits
                        ? counterfietStatic.totalCounterfeits
                        : "0"}
                    </h4>
                    <h5 className="pb-4">
                      Today{" "}
                      {counterfietStatic && counterfietStatic.todayCounterfeits
                        ? counterfietStatic.todayCounterfeits
                        : "0"}{" "}
                      product reported
                    </h5>
                    {selectedTime && (
                      <ReactApexChart
                        options={counterOptions}
                        series={counterSeries}
                        type="donut"
                        width="90%"
                      />
                    )}
                  </div>
                  <div>
                    <ul className="dount_chart">
                      <li>
                        <span className="pending"></span>
                        <span className="chart_legends">
                          {counterfietStatic &&
                            counterfietStatic.totalUnderProcess}{" "}
                          Pending
                        </span>

                        <p>
                          {counterfietStatic &&
                            counterfietStatic.totalUnderProcessPercentage}
                          %
                        </p>
                      </li>
                      <li>
                        <span className="Completed"></span>
                        <span className="chart_legends">
                          {counterfietStatic &&
                            counterfietStatic.totalCompleted}{" "}
                          Completed
                        </span>
                        <p>
                          {counterfietStatic &&
                            counterfietStatic.totalCompletedPercentage}
                          %
                        </p>
                      </li>
                      <li>
                        <span className="Review"></span>
                        <span className="chart_legends">
                          {counterfietStatic &&
                            counterfietStatic.totalUnderReview}{" "}
                          Under Review
                        </span>
                        <p>
                          {counterfietStatic &&
                            counterfietStatic.totalUnderReviewPercentage}
                          %
                        </p>
                      </li>
                      <li>
                        <span className="Acknowledge"></span>
                        <span className="chart_legends">
                          {counterfietStatic &&
                            counterfietStatic.totalAcknowldeged}{" "}
                          Acknowledge
                        </span>
                        <p>
                          {counterfietStatic &&
                            counterfietStatic.totalAcknowldegedPercentage}
                          %
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                style={{ minHeight: "415px", padding: "0", marginTop: "-65px" }}
              >
                <img src={no_data} />
                <h5>No Data Yet</h5>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
