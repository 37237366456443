import React from "react";
import Skeleton from "react-skeleton-loader";

export const BatchViewLoader = () => (
  <>
    {" "}
    

    {/* <div className='tableFilter d-flex align-items-center justify-content-between pl-4 mb-4'>
                <h3>
                <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                </h3>
                <div className='d-flex align-items-center'>
                <div className='side-col dp-right'>
                <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                
                  <div className='side-col dp-right mx-3'>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>

                  <div className='side-col dp-right'>
                  <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
                  </div>
                  
                  </div>
            </div> */}
    
<div className='productFormBox viewStaff_page mb-5'>
    <div className='row'>
              <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 order-2 order-lg-0 mt-5 mt-lg-0'>
                <div className='row mtblf-5'>
                <div className='col-12 col-md-6 col-lg-6 col-xl-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"45px"} width={"100%"}/>
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 col-xl-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"45px"} width={"100%"}/>
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 col-xl-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"45px"} width={"100%"}/>
                  </div>
                </div>
                <div className='row mtblf-5'>
                <div className='col-12 col-md-6 col-lg-6 col-xl-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"45px"} width={"100%"}/>
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 col-xl-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"45px"} width={"100%"}/>
                  </div>
                  <div className='col-12 col-md-6 col-lg-6 col-xl-4 skeleton-bottom'>
                    <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                      <Skeleton color ="#e5e9ef" height={"45px"} width={"100%"}/>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                                    <div className='img-col-product-view'>
                                      <Skeleton color ="#e5e9ef" height={"250px"} width={"100%"}/>
                                    </div>
              </div>
            </div>
            </div>


    
  </>
);
