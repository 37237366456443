import React from "react";
import { ScanRow } from "./ScanRow";
import { TableLoader } from "../loader/Resusable/Table";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import nodataList from "../../../assets/img/no_data.svg";

export const ListComponentDashboard = ({
  scans,
  sortBy,
  order,
  setFilter,
  isLoading,
  userViewHandler,
  batchViewHandler,
}) => {
  return (
    <div className="tabel-outer">
      <div
        className="table-responsive fixed-height-table"
        style={{ height: "501px" }}
      >
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table
            className="table batchTable scanTable"
            width="100%"
            cellSpacing="0"
          >
            <thead className="pad-top">
              <tr className="batchesHeader scan-head">
                <th style={{ minWidth: "171px" }}>
                  Scan ID{" "}
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "scan_id"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "scan_id",
                          sortBy === "scan_id"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>{" "}
                </th>
                <th style={{ minWidth: "148px" }}>
                  Batch Number{" "}
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "batch_number"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "batch_number",
                          sortBy === "batch_number"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "153px" }}>
                  Product Name{" "}
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "product_name"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "product_name",
                          sortBy === "product_name"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "120px" }}>
                  User Name{" "}
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "user_name"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "user_name",
                          sortBy === "user_name"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "124px" }}>
                  Location{" "}
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "location"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "location",
                          sortBy === "location"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "181px" }}>
                  Scan Time{" "}
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "created_at"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "created_at",
                          sortBy === "created_at"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {scans && scans.length > 0 ? (
                scans.map((scan, index) => (
                  <ScanRow
                    key={index}
                    scanData={scan}
                    batchViewHandler={() =>
                      batchViewHandler(scan.batch ? scan.batch._id : "")
                    }
                    userViewHandler={() =>
                      userViewHandler(
                        scan.user && scan.user.length ? scan.user[0]._id : ""
                      )
                    }
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td
                    colSpan={6}
                    className={
                      "text-center image-no-data-xs nodataTablePosition"
                    }
                  >
                    <div className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column">
                      <img src={nodataList} alt="" />
                      <h5
                        className="nodataRecentScanHome"
                        style={{ marginTop: "18px" }}
                      >
                        No Recent Scans
                      </h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
