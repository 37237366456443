/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { categoryValidator } from "../../../validators";
// import AddCategoryComponent from "../../components/category/AddCategoryComponent";
// import ListCategoryComponent from "../../components/category/ListCategoryComponent";
import addproduct_icon from "../../../assets/img/addIcon.svg";
import {
  AddCategoryComponent,
  ListCategoryComponent,
} from "../../components/category/index";
import { CategoryCard } from "../../components/loader/Resusable/CategoryCard";
import Pagination from "../../components/pagination";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import noData from "../../../assets/img/noData.svg";
import "./manage-video-category.css";
import { Link } from "react-router-dom";

class ManageVideoCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryName: "",
      errors: null,
      isLoading: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      isFormOpen: false,
      isUpdating: false,
      activeId: "",
      id: "",
    };
  }
  componentDidMount() {
    this.handleQueryParams();
  }
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
      },
      () => this.fetchCategories()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchCategories = async (loading = true) => {
    const { currentPage, limit } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CATEGORIES_LIST.service,
      ApiRoutes.CATEGORIES_LIST.url,
      ApiRoutes.CATEGORIES_LIST.method,
      ApiRoutes.CATEGORIES_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isUpdating: false,
      isAdding: false,
      categoryName: "",
      activeId: "",
      isLoading: false,
      isFormOpen: false,
    });
    console.log("response", response);
    if (response && response.isError) {
      // toast.error(response.messages[0]);
    } else {
      const {
        data: { categories, totalRecords },
      } = response.data;
      this.setState({ categories, totalRecords });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
      errors: null,
      categoryName: "",
    }));
  };
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({
      [name]: value,
      errors: { ...this.state.errors, [name]: "" },
    });
  };
  addCategory = async (categoryName) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CATEGORY.service,
      ApiRoutes.ADD_CATEGORY.url,
      ApiRoutes.ADD_CATEGORY.method,
      ApiRoutes.ADD_CATEGORY.authenticate,
      undefined,
      { cat_name: categoryName }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        isAdding: false,
        errors: {
          categoryName:
            response.validationErrors && response.validationErrors.cat_name,
        },
      });
    } else {
      this.setState({
        errors: null,
      });
      await this.fetchCategories(false);
      toast.success(response.messages[0]);
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { categoryName } = this.state;
    const { isValid, errors } = categoryValidator({ categoryName });
    if (isValid) {
      this.addCategory(categoryName);
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };
  onCancel = () => {
    this.setState({
      errors: null,
      categoryName: "",
      isFormOpen: false,
    });
  };
  deleteCategory = async (id, name) => {
    console.log("deleteCategory", id);
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to delete category?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_CATEGORY.service,
      ApiRoutes.DELETE_CATEGORY.url.replace(":id", id),
      ApiRoutes.DELETE_CATEGORY.method,
      ApiRoutes.DELETE_CATEGORY.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchCategories(false);
      toast.success(response.messages[0]);
    }
  };
  render() {
    const {
      categories,
      categoryName,
      errors,
      isAdding,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      isFormOpen,
      isUpdating,
      activeId,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden btachMain add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>
        <div>
          <div className="tableFilter d-flex align-items-center justify-content-between mb-0 mb-lg-4 w-100 mb-4 mb-lg-4 flex-row">
            <h3 className="m-0">Manage Video Category</h3>
            <button
              type="button"
              className="addProductbtn"
              id="add-cat-btn"
              onClick={this.onAddBtnClick}
              style={{ width: "174.8px", paddingLeft: "40px" }}
            >
              <img className="mr-1" alt="" src={addproduct_icon} />
              Add Category
            </button>
          </div>
        </div>
        <div className="">
          <div className="productFormBox manageVideosGrid manageVideoMainCard_height">
            <div className="row">
              {isFormOpen ? (
                <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-vd mb-4 addCategoryBox">
                  <AddCategoryComponent
                    categoryName={categoryName}
                    errors={errors}
                    isLoading={isAdding}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                    onCancel={this.onCancel}
                  />
                </div>
              ) : null}
              {isLoading ? (
                <CategoryCard />
              ) : categories && categories.length ? (
                <ListCategoryComponent
                  categories={categories}
                  deleteCategory={this.deleteCategory}
                  isUpdating={isUpdating}
                  activeId={activeId}
                  fetchCategories={this.fetchCategories}
                  {...this.props}
                />
              ) : !isFormOpen ? (
                <div
                  className="col-md-12 col-vd-nodata image-no-data-sm"
                  // style={{ padding: "11em 0" }}
                >
                  {/* <p>No Video Category Found</p> */}
                  <>
                    <img src={noData} alt="" style={{ width: "120px" }} />
                    {/* <p>The data you're looking is not available at this moment</p> */}
                    <p>
                      No Data Found,{" "}
                      <Link
                        // to={AppRoutes.ADD_ROLE}
                        onClick={this.onAddBtnClick}
                        className="text-decoration-none"
                      >
                        Click here
                      </Link>{" "}
                      to Add Category
                    </p>
                  </>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="footer-pg px-0 px-lg-3 mt-3">
          {!isLoading ? (
            <>
              {/* <div className="float-left mt-2">
                <p className="page-desc-left">
                  Total: <span className="bold-black">{totalRecords}</span>
                </p>
              </div> */}
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                {/* <p className="page-desc ml-2 float-right">Table View</p> */}

                {/* <Dropdown className="no-arrow dp-right ml-2">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={'filter-btn'}
                  >
                    {' '}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{' '}
                    <img src={filterarrowIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                    {pagesOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.onLimitChange(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      {/* <button className="paginationArrows">
                        <img src={leftPageArrow} />
                      </button> */}
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                      {/* <button className="paginationArrows">
                        <img src={rightPageArrow} />
                      </button> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ManageVideoCategory;
