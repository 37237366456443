import Validator from "js-object-validation";
import { message } from "../common";

export const idFormat = (data) => {
  const validations = {
    scan: {
      required: true,
      alpha: true,
    },
    counterfeit: {
      required: true,
      alpha: true,
    },
    employee: {
      required: true,
      alpha: true,
    },
  };
  // Error messages
  const messages = {
    scan: {
      required: message.Required.replace(":item", "scan id"),
      alpha: message.InvalidName.replace(":item", "Scan id"),
    },
    counterfeit: {
      required: message.Required.replace(":item", "counterfeit id"),
      alpha: message.InvalidName.replace(":item", "Counterfeit id"),
    },
    employee: {
      required: message.Required.replace(":item", "employee id"),
      alpha: message.InvalidName.replace(":item", "Employee id"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
