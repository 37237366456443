import React from "react";
import { Button } from "react-bootstrap";
import { AppConfig } from "../../../config";
import InputTextFeild from "../form/InputTextFeild";
import SubmitButton from "../form/SubmitButton";
// import productimg from '../../../assets/img/product_item.png'
import uploadProductImage from "../../../assets/img/ph_product.jpg";

import attachfile from "../../../assets/img/attachfile.svg";

const AddProductFormComponent = ({
  productNumber,
  onChange,
  errors,
  totalBatches,
  isEditMode,
  productDescription,
  isAdding,
  handleSubmit,
  handleCancel,
  onFileChange,
  productName,
  productImage,
  otherImages,
  openGallery,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="productFormBox">
            <form>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8 order-2 order-lg-0">
                  <div className="row">
                    <div className="col-md-6">
                      <label>Product Number</label>
                      <InputTextFeild
                        name="productNumber"
                        type="text"
                        placeholder="Enter Product Number"
                        value={productNumber}
                        onChange={onChange}
                        disabled={totalBatches === 0 ? false : isEditMode}
                        errorMessage={
                          errors && errors.productNumber
                            ? errors.productNumber
                            : ""
                        }
                        maxLength={50}
                      />
                    </div>
                    <div className="col-md-6">
                      <label>Product Name</label>
                      <InputTextFeild
                        id="product_name"
                        placeholder="Enter Product Name"
                        className={"form-control-product"}
                        name="productName"
                        value={productName}
                        onChange={onChange}
                        disabled={totalBatches === 0 ? false : isEditMode}
                        errorMessage={
                          errors && errors.productName ? errors.productName : ""
                        }
                        maxLength={50}
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Product Description</label>
                      <textarea
                        className="form-control"
                        id="product_description"
                        placeholder="Enter Product Description"
                        aria-describedby="category_nameHelp"
                        rows="6"
                        name={"productDescription"}
                        value={productDescription}
                        onChange={onChange}
                        style={{ padding: "11px 16px" }}
                      />
                      <div className={"text-danger"}>
                        {errors && errors.productDescription
                          ? errors.productDescription
                          : ""}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="d-flex align-items-center"
                        style={{ marginTop: "30px" }}
                      >
                        <Button
                          className="customCancelBtn mt-0"
                          variant=""
                          type="button"
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>

                        <SubmitButton
                          className={"customSubmitBtn mt-0"}
                          text={isEditMode ? "UPDATE" : "ADD"}
                          isLoading={isAdding}
                          onSubmit={handleSubmit}
                          disabled={
                            !productNumber ||
                            !productName ||
                            !productDescription
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
                  <div className="d-flex align-items-start">
                    <div className="w-100">
                      <div className="imageUploadBox w-100">
                        <div className="imageUploadView">
                          {productImage ? (
                            <img
                              // src={
                              //   productImage
                              //     ? `${AppConfig.FILES_ENDPOINT}${productImage}`
                              //     : uploadProductImage
                              // }
                              src={
                                productImage
                                  ? productImage.startsWith("https://")
                                    ? productImage
                                    : `${AppConfig.FILES_ENDPOINT}${productImage}`
                                  : uploadProductImage
                              }
                              alt="Product Img"
                              onClick={openGallery}
                            />
                          ) : (
                            <span className="noImage_text">No Image</span>
                          )}
                        </div>
                        <div className={"text-danger"}>
                          {errors && errors.productImage
                            ? errors.productImage
                            : ""}
                        </div>
                      </div>
                      <div className="d-flex" style={{ marginTop: "30px" }}>
                        <div className="d-flex align-items-center w-100">
                          {/* <Button
                            className="customCancelBtn choosebtb mt-0 mr-3"
                            variant=""
                            type="button"
                            name="profile_picture_btn"
                            accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                            onChange={onFileChange}
                          >
                            Upload Image
                          </Button> */}
                          <label className="customCancelBtn attachmentbtn mt-0 mr-1 m-0 w-100 d-flex align-items-center justify-content-center">
                            {" "}
                            <span className="mr-0" style={{ color: "#00ACB9" }}>
                              Upload Image
                            </span>
                            {/* { 'Choose Image'} */}
                            <input
                              className="imgUpload img_up"
                              name="profile_picture"
                              type="file"
                              multiple
                              accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                              onChange={onFileChange}
                            />
                          </label>
                        </div>
                        <div className="mt-0" style={{ width: "56px" }}>
                          {/* <input
                                                        className="imgUpload img_up"
                                                        name="profile_picture"
                                                        type="file"
                                                        multiple
                                                        accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                                                        // onChange={onFileChange}
                                                    /> */}
                          {/* <label className='mt-0 m-0 w-100 d-flex align-items-center justify-content-center' style={{color:'red'}}>
                                                        Only .jpg, .jpeg, .png are allowed
                                                    </label> */}

                          <label className="customCancelBtn attachmentbtn mt-0 m-0 w-100 d-flex align-items-center justify-content-center">
                            {" "}
                            <img
                              className="mr-0"
                              src={attachfile}
                              alt="Product"
                            />
                            {/* { 'Choose Image'} */}
                            <input
                              className="imgUpload img_up"
                              name="profile_picture"
                              type="file"
                              multiple
                              accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                              onChange={onFileChange}
                            />
                          </label>
                        </div>
                      </div>
                      <p className="uploadImgNote">
                        You can upload upto 10 images
                      </p>
                    </div>
                    {otherImages && otherImages.length ? (
                      <div className="ml-3">
                        <div className="prodductItems">
                          <img
                            // src={
                            //   otherImages[0]
                            //     ? `${AppConfig.FILES_ENDPOINT}${otherImages[0]}`
                            //     : uploadProductImage
                            // }
                            
                            src={
                              otherImages[0]
                                ? otherImages[0].startsWith("https://")
                                  ? otherImages[0]
                                  : `${AppConfig.FILES_ENDPOINT}${otherImages[0]}`
                                : uploadProductImage
                            }
                            onClick={openGallery}
                            alt=""
                          />
                        </div>
                        {otherImages.length > 1 ? (
                          <div
                            className="prodductItems mt-2 d-flex align-items-center justify-content-center"
                            onClick={openGallery}
                          >
                            <span>+{otherImages.length - 1}</span>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProductFormComponent;
