import React from "react";
import moment from "moment";
import {
  AppConfig,
  DEFAULT_DATE_FORMAT,
  DB_ACCPTABLE_FORMAT,
} from "../../../config";
import staff_menu from "../../../assets/img/staff_menu.svg";
import staff_user from "../../../assets/img/staff_user.svg";
import staff_data from "../../../assets/img/staff_data.svg";
// import minusCircleIcon from '../../../assets/img/minus-circle.svg';
import staff_call from "../../../assets/img/staff_call.svg";
import staff_sms from "../../../assets/img/staff_sms.svg";
import dummyclient from "../../../assets/img/staffuser.png";

export const GridStaffComponent = ({ staff, viewHandler }) => {
  return staff.map((staffData) => {
    const {
      first_name = "",
      last_name = "",
      employee_id = "",
      email = "",
      phone = "",
      birth_date = "",
      joining_date = "",
      profile_picture = "",
      _id = "",
    } = staffData;
    console.log("staffData", staffData);
    let dateDifference = moment(new Date(), DB_ACCPTABLE_FORMAT).diff(
      moment(joining_date, DB_ACCPTABLE_FORMAT),
      "days"
    );

    console.log("dateDifference", dateDifference);
    return (
      <>
        <div className="col-md-3 mb-4">
          <div className="staffGrid">
            {dateDifference < 15 && <div className="newCorner">New</div>}
            {profile_picture ? (
              <div className="userPic">
                <img
                  // src={
                  //   profile_picture
                  //     ? profile_picture.startsWith("https://")
                  //       ? profile_picture
                  //       : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                  //     : profile_picture
                  // }
                  src={
                    profile_picture
                      ? profile_picture.startsWith("https://")
                        ? profile_picture
                        : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                      : profile_picture
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = dummyclient;
                  }}
                  alt=""
                />
              </div>
            ) : (
              <div className="img_placeholder">
                {first_name ? first_name.substring(0, 1) : ""}
                {last_name ? last_name.trim().substring(0, 1) : ""}
              </div>
            )}
            <h5> {[first_name, last_name].filter(Boolean).join(" ") || "-"}</h5>
            <div className="card_id">{employee_id || "-"}</div>
            <div className="contact_info">
              <div className="d-flex align-items-center mb-20px">
                <div className="imgbox">
                  <img src={staff_sms} />
                </div>
                {email ? <a href={`mailto:${email}`}>{email}</a> : "N/A"}
              </div>
              <div className="d-flex align-items-center mb-20px">
                <div className="imgbox">
                  <img src={staff_call} />
                </div>
                <a onClick={() => window.open(`tel:${phone}`)}>
                  {phone || "N/A"}
                </a>
              </div>
              <div className="d-flex align-items-center mb-20px">
                <div className="imgbox">
                  <img src={staff_data} />
                </div>
                <p>{staffData.roleName || "N/A"}</p>
              </div>
            </div>
            <div className="buttonsBox">
              <button
                onClick={() => viewHandler(staffData._id)}
                className="view_btn"
              >
                {" "}
                <img src={staff_menu} alt="" /> View{" "}
              </button>

              <button
                className="contact_btn"
                disabled={!email}
                onClick={() => window.open(`mailto:${email}`)}
              >
                {" "}
                <img src={staff_user} alt="" /> Contact{" "}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  });
};
