import React, { useEffect,useState } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { getUserData } from '../../../helpers/userData';
import { ApiRoutes, AppRoutes } from '../../../config';
import { ApiHelper } from '../../../helpers';
import SetPasswordFormComponent from '../../components/SetPassword';
import { setPasswordValidator } from '../../../validators';

// import mainmob3xImg from '../../../assets/img/main_mob@3x.png';

const SetPassword =()=>{
  let history = useHistory();

  const [userSetPassword, setUserSetPassword] = useState({
    newpassword: '',
    cnfpassword: '',
    sub:'',
    errors: null,
    islogged: false,
    isLoading: false,
  });


  // componentWillMount() {
  //   const data = getCheck();
  //   if (data) {
  //     const { email, password } = data;
  //     this.setState({ email, password, islogged: true });
  //   }
  // }

  // componentDidMount() {
  //   document.body.classList.add('bg-gradient-primary');
  //   const isLoggedInUser = isLogin();
  //   // Redirect to dashboard if admin already loggedin
  //   if (isLoggedInUser) {
  //     this.props.history.push(AppRoutes.DASHBOARD);
  //   }
  // }

  useEffect(()=>{
    document.body.classList.add('bg-gradient-primary');
    // const isLoggedInUser = isLogin();
    // Redirect to dashboard if admin already loggedin
    // if (isLoggedInUser) {
    //   this.props.history.push(AppRoutes.DASHBOARD);
    // }

    const {id} = getUserData()
    setUserSetPassword({
      ...userSetPassword,
      sub:id
    });
  },[])

  const handleChange = event => {
    const {
      target: { type, value, name, checked },
    } = event;
    var regexp = /^\S*$/;
    if ((name === 'newpassword' || name === 'cnfpassword') && !regexp.test(value)) {
        setUserSetPassword({
          ...userSetPassword,
          errors: { [name]: 'Space not allowed' }
        });
      } else {
      setUserSetPassword({
        ...userSetPassword,
        [name]: type === 'checkbox' ? checked : value,
        errors: { ...userSetPassword.errors, [name]: '' },
      });
    }
  };

  const handleSubmit = async event => {
    event && event.preventDefault();
    const { newpassword, sub } = userSetPassword;
    let data = {
        sub,
        newpassword,
    };
    let { isValid, errors } = setPasswordValidator({newpassword,cnfpassword});
    console.log(errors);
    if (isValid) {
      if(newpassword !== cnfpassword){
        setUserSetPassword({
          ...userSetPassword,
          errors: {'cnfpassword':'Password and confirm password didn\'t match'},
        });
        return;
      }
      setUserSetPassword({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.SET_PASSWORD.service,
        ApiRoutes.SET_PASSWORD.url,
        ApiRoutes.SET_PASSWORD.method,
        ApiRoutes.SET_PASSWORD.authenticate,
        undefined,
        data,
      );
      setUserSetPassword({
        isLoading: false,
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        const { data } = response.data;
        console.log(data);
        if(data){
          toast.success(response.messages[0]);
          history.push(AppRoutes.DASHBOARD)
        }
        // setUserSetPassword(data);
        // const { email, password } = userSetPassword;
        // islogged ? setCheck(email, password) : clearCheck();
        // data.is_first_login == true ? history.push(AppRoutes.SET_PASSWORD):''
        // this.props.history.push(AppRoutes.DASHBOARD);
        // if(data.is_first_login){
        //   history.push(AppRoutes.SET_PASSWORD) 
        // }
      }
    } else {
      setUserSetPassword({
      ...userSetPassword,
        errors,
      });
      return;
    }
  };

  // getCheckbox = () => {
  //   setUserSetPassword({ islogged: !userSetPassword.islogged });
  // };

  // componentWillUnmount = () => {
  //   document.body.classList.remove('bg-gradient-primary');
  // };
  // render() {
    const { newpassword, cnfpassword, errors, islogged, isLoading } = userSetPassword;
    return (
      <>
        {/* <div className='container'>
          <div className='row login-row justify-content-center'>
            <div className='col-xl-5 col-lg-5 col-md-6'>
              <div className='card o-hidden border-0 shadow-lg my-7'>
                <div className='card-body p-0'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='p-5'>
                        <div className='text-center'>
                          <img
                            src={mainImg}
                            srcSet={`${main2xImg} 2x,
                             ${main3xImg} 3x`}
                            className='main-blue'
                            alt=''
                          />

                          <img
                            src={mainmobImg}
    console.log("hjhjh");
                            srcSet={`${mainmob2xImg} 2x,
                             ${mainmob3xImg}.png 3x`}
                            className='main-blue-mob'
                            alt=''
                          />
                          <h1 className='h4 head-top mbt-3'>Login</h1>
                        </div> */}
                        <SetPasswordFormComponent
                          newpassword={newpassword}
                          cnfpassword={cnfpassword}
                          errors={errors}
                          islogged={islogged}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
                      {/* </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <Footer />  */}
      </>
    );
  // }
}

export default withRouter(SetPassword);
