/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
// import { Dropdown } from "react-bootstrap";
import Pagination from "../../components/pagination";
import debounce from "lodash.debounce";
// import filterarrowIcon from "../../../assets/img/counterDrop.svg";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { ListComponent } from "../../components/counterfeit/ListComponent";
import SearchFilter from "../../components/filters/SearchFilter";
// import FilterComponent from "../../components/filters/filterComponent";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
// import searchMobIcon from "../../../assets/img/home_icon.svg";
// import filterMobIcon from "../../../assets/img/home_icon.svg";
import PHLContext from "../../../PHLContext";
// import rightPageArrow from "../../../assets/img/rightPageArrow.svg";
// import leftPageArrow from "../../../assets/img/leftPageArrow.svg";
import "./counterfeit.css";
// import FilterDropdown from "../../components/filters/FilterDropdown";
import { FilterComponent } from "../../components/filters/filterComponent";
import filterarrowIcon from "../../../assets/img/counterDrop.svg";
import { Dropdown } from "react-bootstrap";

class Counterfeit extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      counterfeits: [],
      staffList: [],
      clientList: [],
      isLoading: false,
      isUpdating: false,
      limit: 10,
      totalRecords: 0,
      currentPage: 1,
      activeId: "",
      search: "",
      assignedTo: "all",
      sortByClient: "all",
      sortByStatus: "all",
      sortBy: "created_at",
      order: "desc",
      isFilterApplied: false,
      dropdownOpen: false,
      dropdownId: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    let { socket } = this.context;
    if (socket) {
      socket.on(SocketEvents.NEW_COUNTERFEIT_ADDED, (data) => {
        let newData = [...this.state.counterfeits];
        const alreadyExistIndex = newData.findIndex(
          (item) => item._id === data[0]._id
        );
        if (alreadyExistIndex > -1) {
          newData.splice(alreadyExistIndex, 1);
          newData.unshift(...data);
        } else {
          newData.splice(-1, 1);
          newData.unshift(...data);
        }
        console.log("event emitted", data, newData);
        this.setState({ counterfeits: newData });
      });
    }
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order ? query.order : "desc",
        search: query.search || "",
        sortByStatus: query.status || "all",
        isFilterApplied: query.status || query.search ? true : false,
        assignedTo: query.assignedTo || "all",
      },
      () => {
        this.fetchActiveStaffList();
        this.fetchClientList();
        this.fetchCounterfeitRecords();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchCounterfeitRecords = async () => {
    const {
      currentPage,
      limit,
      sortByStatus,
      search,
      order,
      sortBy,
      assignedTo,
    } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      order,
      sortBy,
      sortByStatus,
      search,
      assignedTo,
    };
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.COUNTERFEIT_LIST.service,
      ApiRoutes.COUNTERFEIT_LIST.url,
      ApiRoutes.COUNTERFEIT_LIST.method,
      ApiRoutes.COUNTERFEIT_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { counterfeits, totalRecords },
      } = response.data;
      this.setState({ counterfeits, totalRecords });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
    this.setState({
      dropdownOpen: false,
      dropdownId: "",
    });
  };
  setSortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onToggle = (id) => {
    this.setState({
      dropdownOpen: true,
      dropdownId: id,
    });
  };

  viewHandler = (id) => {
    const {
      location: { search },
    } = this.props;
    this.props.history.push({
      pathname: AppRoutes.COUNTERFEIT_VIEW.replace(":id", id),
      state: {
        search,
      },
    });
  };
  fetchActiveStaffList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_OPTION_LIST.service,
      ApiRoutes.GET_STAFF_OPTION_LIST.url,
      ApiRoutes.GET_STAFF_OPTION_LIST.method,
      ApiRoutes.GET_STAFF_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        staffList: [
          {
            label: "All",
            value: "all",
          },
          ...data,
        ],
      });
    }
  };
  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          ...data,
        ],
      });
    }
  };

  render() {
    const {
      counterfeits,
      staffList,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      search,
      order,
      sortBy,
      sortByStatus,
      dropdownId,
      assignedTo,
      showImage = "true",
      label = "Staff",
      selectedFilter,
    } = this.state;
    console.log("dropdownId", dropdownId);
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];

    const filtersOptionStatus = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "In Review",
        value: "In Review",
      },
      {
        label: "Completed",
        value: "Completed",
      },
      {
        label: "Acknowledged",
        value: "Acknowledged",
      },
      {
        label: "In Process",
        value: "In Process",
      },
      {
        label: "Reopened",
        value: "Reopened",
      },
      {
        label: "Cancelled",
        value: "Cancelled",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden btachMain add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              // customBreadCrumb={this.customBreadCrumb}
            />
          </h3>
        </div>
        <div className="tableFilter d-flex justify-content-between mb-0 mb-lg-4 filter-row ">
          {/* <h3>
            <Breadcrumbs pathname={pathname} />
          </h3> */}
          <SearchFilter
            placeholder={"Search by Scan/Report ID, Batch Number..."}
            search={search}
            onChange={this.handleChange}
            onSearch={this.onSearch}
          />
          <div className="d-flex filter_holder">
            <FilterComponent
              label={"Staff"}
              options={staffList}
              selectedFilter={assignedTo}
              onFilterChange={(selectedValue) =>
                this.setFilter(selectedValue, "assignedTo")
              }
            />
            <FilterComponent
              label={"Status"}
              options={filtersOptionStatus}
              selectedFilter={sortByStatus}
              onFilterChange={(selectedValue) =>
                this.setFilter(selectedValue, "status")
              }
            />
            {/* <Dropdown className="customDropdownBox">
              <Dropdown.Toggle
                variant=""
                className="no-arrow dropdown-custom d-flex align-items-center justify-content-between ml-3"
              >
                {" Staff "}
                <div className="d-flex">
                  <span className="staff-value">
                    {
                      (
                        pagesOption.filter(
                          (filter) => filter.value === selectedFilter
                        )[0] || {}
                      ).label
                    }
                  </span>
                  {showImage ? <img src={filterarrowIcon} alt="" /> : ""}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {clientList.map(({ label, value, order }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.setFilter(value, order)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="customDropdownBox">
              <Dropdown.Toggle
                variant=""
                className="no-arrow dropdown-custom d-flex align-items-center justify-content-between ml-3"
              >
                Status
                <div className="d-flex">
                  <span className="staff-value">
                    {
                      (
                        pagesOption.filter(
                          (filter) => filter.value === selectedFilter
                        )[0] || {}
                      ).label
                    }
                  </span>
                  {showImage ? <img src={filterarrowIcon} alt="" /> : ""}
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {filtersOptionStatus.map(({ label, value, order }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.setFilter(value, order)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>

          {/* <div className="">
                      <FilterComponent
                        label={"Status"}
                        options={filtersOptionStatus}
                        selectedFilter={sortByStatus}
                        onFilterChange={(selectedValue) =>
                          this.setFilter(selectedValue, "status")
                        }
                      />
                      <FilterComponent
                        label={"Client"}
                        options={clientList}
                        selectedFilter={sortByClient}
                        onFilterChange={(selectedValue) =>
                          this.setFilter(selectedValue, "assignedTo")
                        }
                      />
                      <FilterComponent
                        label={"Staff"}
                        options={staffList}
                        selectedFilter={sortByStaff}
                        onFilterChange={(selectedValue) =>
                          this.setFilter(selectedValue, "assignedTo")
                        }
                      />
                      
                  </div> */}
        </div>
        <div className="card tableCard">
          <ListComponent
            counterfeits={counterfeits}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            viewHandler={this.viewHandler}
            setFilter={this.setSortFilter}
            order={order}
            sortBy={sortBy}
          />
        </div>
        <div className="footer-pg">
          <div className="float-left mt-2">
            <div>
              <p className="totalRecords">
                Page {totalRecords > 0 ? currentPage : 0} of{" "}
                {totalRecords % limit > 0
                  ? parseInt(totalRecords / limit) + 1
                  : parseInt(totalRecords / limit)}{" "}
              </p>
            </div>
          </div>
          <div className="float-right mt-0">
            {/* <p className="page-desc ml-2 float-right">Table View</p>

            <Dropdown className="no-arrow dp-right ml-2">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn"}
              >
                {" "}
                <span className="staff-value">
                  {
                    (
                      pagesOption.filter(
                        (filter) => filter.value === limit
                      )[0] || {}
                    ).label
                  }
                </span>{" "}
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                {pagesOption.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => this.onLimitChange(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <div className="d-flex align-items-center tableFooter">
              {!isLoading && totalRecords > limit ? (
                <div className="float-right d-flex align-items-center">
                  {/* <button className="paginationArrows">
                        <img src={leftPageArrow} />
                      </button> */}
                  <Pagination
                    totalRecords={totalRecords}
                    currentPage={currentPage}
                    pageLimit={limit}
                    onPageChanged={this.onPageChanged}
                  />
                  {/* <button className="paginationArrows">
                        <img src={rightPageArrow} />
                      </button> */}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Counterfeit;
