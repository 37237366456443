import React, { useState } from "react";
import playBtn from "../../../../assets/img/playBtn.svg";
import { Link } from "react-router-dom";

function VideoCard({ video, index }) {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      <div key={index} className="videoCard">
        <h3>{video?.videoName}</h3>
        <p>
          {/* {video?.videoDescription} */}
          {video?.videoDescription?.length < 90 ? (
            <>{video?.videoDescription}</>
          ) : (
            <>
              {isReadMore
                ? video?.videoDescription?.slice(0, 90)
                : video?.videoDescription}
              <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? " ..." : ".."}
              </span>
            </>
          )}
        </p>
        <div className="cardFooter">
          <div>
            <h5>See Video</h5>
            <div className="d-flex align-items-center">
              <a target="_blank" href={video?.videoLink} className="seeVideo">
                <img src={playBtn} />
                {video?.videoLength}
              </a>
            </div>
          </div>

          {/* <div>
            <h5>Read Article</h5>
            <div className="d-flex align-items-center">
              <Link className="click">Click Here</Link>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default VideoCard;
