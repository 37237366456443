import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { BatchViewLoader } from "../../loader/Resusable/BatchView";
import {
  accessTypesOption,
  permissionModules,
  staffPermissionModules,
} from "../../../../config";
import { Form } from "react-bootstrap";
import SubmitButton from "../../form/SubmitButton";
import Select from "react-select";
import { ManagePermissionLoader } from "../../loader/Resusable/ManagePermissionLoader";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),

  control: (provided, state) => ({
    ...provided,
    overflow: "hidden",
    whiteSpace: "nowrap",
    border: "0px",
  }),
};

export const PermissionFormComponent = ({
  isLoading,
  isAdding,
  errors,
  handlePermissionSelection,
  onSubmit,
  newModule,
}) => {
  const [isSubModule, setIsSubModule] = useState();
  const onModule = (event) => {
    var filterModuleList = permissionModules.filter(
      (x) => x.module.label === event.value
    );
    if (filterModuleList.length > 0) {
      setIsSubModule(filterModuleList[0].subModules);
    }
    handlePermissionSelection(event, "module");
  };

  let moduleValue = [];
  permissionModules.forEach((element) => {
    moduleValue.push({
      value: element.module.value,
      label: element.module.label,
    });
  });

  return (
    <div className="">
      {isLoading ? (
        <ManagePermissionLoader />
      ) : (
        <>
          <div className="permission_holder">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book">Manage Permissions</h6>
              </div>
            </div>
          </div>
          <div className="card permission-card mt-3">
            <form className="user_add m-0" onSubmit={onSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row overflow">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className={`form-control-label`}>Module</label>
                        {/* <Form.Select
                          className="form-control"
                          name="role_id"
                          onChange={(selectedOption) => {
                            onModule(selectedOption, "module");
                          }}
                        >
                          <option value="">Select Module</option>
                          {permissionModules &&
                            permissionModules.map((info, index) => (
                              <option key={index} value={info.module.value}>
                                {info.module.label}
                              </option>
                            ))}
                        </Form.Select> */}
                        <AsyncSelect
                          closeMenuOnSelect={false}
                          name="module"
                          className="form-control h-100"
                          defaultOptions={moduleValue}
                          onChange={(selectedOption) => {
                            onModule(selectedOption, "module");
                          }}
                          // styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#00bdcc33",
                              primary: "#00acb9",
                            },
                          })}
                        />
                        <div className={"text-danger error-text"}>
                          {errors && errors.selectedModule
                            ? errors.selectedModule
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Sub Module
                        </label>
                        <AsyncSelect
                          isMulti
                          closeMenuOnSelect={false}
                          name="subModules"
                          className="form-control h-100"
                          defaultOptions={isSubModule}
                          onChange={(selectedOption) => {
                            handlePermissionSelection(
                              selectedOption,
                              "subModules"
                            );
                          }}
                          // styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#00bdcc33",
                              primary: "#00acb9",
                            },
                          })}
                        />
                        <div className={"text-danger error-text"}>
                          {errors && errors.subModules ? errors.subModules : ""}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Access Type
                        </label>

                        <AsyncSelect
                          closeMenuOnSelect={false}
                          name="subModules"
                          className="form-control h-100"
                          defaultOptions={accessTypesOption}
                          onChange={(selectedOption) => {
                            handlePermissionSelection(
                              selectedOption,
                              "accessType"
                            );
                          }}
                          // styles={customStyles}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary25: "#00bdcc33",
                              primary: "#00acb9",
                            },
                          })}
                        />
                        {/* <Form.Select
                          className="form-control"
                          name="role_id"
                          styles={customStyles}
                          onChange={(selectedOption) => {
                            handlePermissionSelection(
                              selectedOption,
                              "accessType"
                            );
                          }}
                        >
                          <option value="">Select Access</option>
                          {accessTypesOption &&
                            accessTypesOption.map((info, index) => (
                              <option key={index} value={info.value}>
                                {info.label}
                              </option>
                            ))}
                        </Form.Select> */}
                        <div className={"text-danger error-text"}>
                          {errors && errors.subModules ? errors.subModules : ""}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <SubmitButton
                        className={"customSubmitBtn mt-27px"}
                        text={"Add Permission"}
                        isLoading={isAdding}
                        onSubmit={onSubmit}
                        disabled={
                          !newModule.module ||
                          // !newModule.subModules ||
                          !newModule.accessType
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
