import React, { useState } from "react";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import { RoleRow } from "./RoleRow";
import { AppRoutes } from "../../../config";
import { Link } from "react-router-dom";
import AddRole from "../../containers/manage-staff-role/AddRole";

export const ListComponent = ({
  isLoading,
  role,
  isFilterApplied,
  setFilter,
  sortBy,
  order,
  onDelete,
  refreshAPI,
  handleModal,
}) => {
  return (
    <div className="tabel-outer">
      <div className="table-responsive fixed-height-table">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table
            className="table batchTable batchHistoryTable"
            width="100%"
            cellSpacing="0"
          >
            <thead className="pad-top">
              <tr className="batchesHeader scan-head">
                <th style={{ minWidth: "171px" }}>
                  Role
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "roleName"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "roleName",
                          sortBy === "roleName"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "148px" }}>
                  ONBOARDED staff
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "onBordedStaff"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "onBordedStaff",
                          sortBy === "onBordedStaff"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "153px" }}>
                  Updated on
                  <span className={"cursor-pointer"}>
                    <img
                      src={
                        sortBy === "updatedAt"
                          ? order === "desc"
                            ? dropDownActive
                            : dropUpActive
                          : dropDown
                      }
                      alt=""
                      onClick={() =>
                        setFilter(
                          "updatedAt",
                          sortBy === "updatedAt"
                            ? order === "asc"
                              ? "desc"
                              : "asc"
                            : "asc"
                        )
                      }
                    />
                  </span>
                </th>
                <th style={{ minWidth: "120px" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {role && role.length ? (
                role.map((element, index) => (
                  <RoleRow
                    key={index}
                    roleData={element}
                    onDelete={onDelete}
                    refreshAPI={refreshAPI}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td
                    colSpan={8}
                    className={
                      "text-center image-no-data-xs nodataTablePosition"
                    }
                  >
                    <img src={noData} alt="" />
                    <p>
                      No Role Found,{" "}
                      <Link
                        onClick={handleModal}
                        // to={AppRoutes.ADD_ROLE}
                        className="text-decoration-none"
                      >
                        Click here
                      </Link>{" "}
                      to Add New Role
                    </p>
                  </td>
                </tr>
              )}

              {/* <tr className="no-tr">
                  <td colSpan={6} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr> */}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
