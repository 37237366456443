import Validator from "js-object-validation";
import { message } from "../common";
import { replaceAll } from "../helpers";

export const addressValidator = (data) => {
  const validations = {
    addressType: {
      required: true,
    },
    addressLine1: {
      required: true,
    },
    addressLine2: {
      required: true,
    },
    state: {
      required: true,
    },
    country: {
      required: true,
    },
    postCode: {
      required: true,
      maxlength: 6,
      numeric: true,
    },
  };
  // Error messages
  const messages = {
    addressType: {
      required: message.Required.replace(":item", "address type"),
    },
    addressLine1: {
      required: message.Required.replace(":item", "address line1"),
    },
    addressLine2: {
      required: message.Required.replace(":item", "address line2"),
    },
    state: {
      required: message.Required.replace(":item", "state"),
    },
    country: {
      required: message.Required.replace(":item", "country"),
    },
    postCode: {
      required: message.Required.replace(":item", "post code"),
      numeric: message.InvalidItemCount.replace(":item", "post code"),
      maxlength: replaceAll(message.MaxLengthError, {
        ":item": "post code",
        ":length": 6,
      }),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
