import React from 'react';

function InputCheckbox(props) {
    return (
        <div>
            <div className='form-group mb-0'>
                <div className="custom-control custom-checkbox mb-0">
                    <input 
                    type="checkbox" 
                    className="custom-control-input" 
                    id={props.id} 
                    name={props.name}
                    />
                    <label className="custom-control-label" htmlFor="customCheck"> {props.label}</label>
                </div>
            </div>
        </div>
    );
}

export default InputCheckbox;