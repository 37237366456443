import React, { useState, useEffect } from "react";
import InputTextField from "../../components/form/InputTextFeild";
import { Modal } from "react-bootstrap";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { roleValidator } from "../../../validators/roleValidator";
import SubmitButton from "../../components/form/SubmitButton";
import { useHistory } from "react-router";

export default function AddRole({
  modalShow,
  modalClose,
  refreshAPI,
  btn,
  placeholder,
  rolePrevData,
}) {
  const [roleData, setRoleData] = useState({
    errors: null,
    roleName: "",
  });
  const history = useHistory();

  const [isAdding, setIsAdding] = useState(false);
  const [roleId, setRoleId] = useState(null);

  useEffect(() => {
    if (rolePrevData && rolePrevData.id) {
      setRoleId(rolePrevData.id);
      setRoleData({ roleName: rolePrevData.roleName, errors: null });
    }
  }, [rolePrevData]);

  const handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    setRoleData({
      ...roleData,
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      errors: { ...roleData.errors, [name]: "" },
    });
  };
  const addRole = async (payload) => {
    setIsAdding(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_ROLE.service,
      ApiRoutes.ADD_ROLE.url,
      ApiRoutes.ADD_ROLE.method,
      ApiRoutes.ADD_ROLE.authenticate,
      undefined,
      payload
    );
    setIsAdding(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      history.push(AppRoutes.MANAGE_STAFF_ROLE);
      refreshAPI();
      setRoleData({
        errors: null,
        roleName: "",
      });
    }
  };

  const editRole = async (payload) => {
    setIsAdding(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EDIT_ROLE.service,
      ApiRoutes.EDIT_ROLE.url.replace(":id", roleId),
      ApiRoutes.EDIT_ROLE.method,
      ApiRoutes.EDIT_ROLE.authenticate,
      undefined,
      payload
    );
    setIsAdding(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      refreshAPI();
      history.push(AppRoutes.MANAGE_STAFF_ROLE);
    }
  };

  const handleSubmit = async (event) => {
    event && event.preventDefault();
    const { roleName } = roleData;
    let data = { roleName };

    let { isValid, errors } = roleValidator(data);

    if (isValid) {
      if (roleId) {
        editRole(data);
      } else {
        addRole(data);
      }
    }
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={modalClose}
        className="modal fade invitestaffmodal"
        role="dialog"
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <label className={`form-control-label`}>Role</label>

                <InputTextField
                  id="employee_id"
                  placeholder={placeholder}
                  className={"form-control-user disable-write"}
                  name="roleName"
                  value={roleData.roleName}
                  maxLength={50}
                  onChange={handleChange}
                  // errorMessage={
                  //   errors && errors.first_name ? errors.first_name : ""
                  // }
                />
              </div>

              <div className="col-md-12">
                <div className="row" style={{ marginTop: "14px" }}>
                  <div className="col-md-6">
                    <span className="customCancelBtn m-0" onClick={modalClose}>
                      Cancel
                    </span>
                  </div>
                  <div className="col-md-6">
                    <SubmitButton
                      className={"customSubmitBtn mt-0"}
                      text={rolePrevData && rolePrevData.id ? "UPDATE" : "ADD"}
                      isLoading={isAdding}
                      onSubmit={handleSubmit}
                      disabled={!roleData.roleName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
