import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import Pagination from "../../../components/pagination";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import SearchFilter from "../../../components/filters/SearchFilter";
import StaffListRequestComponent from "../../../components/staffs/Request";
import AddStaff from "./AddStaff";
import { FilterComponent } from "../../../components/filters";
import { Button, Modal } from "react-bootstrap";

// import "../manage-staff.css";

class ManageStaffRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requests: [],
      errors: null,
      isLoading: false,
      isUpdating: false,
      isAdding: false,
      activeId: "",
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      sortBy: "createdAt",
      sortByStatus: "all",
      search: "",
      isFormOpen: false,
      order: "desc",
      modalShow: false,
      role: [],
      showStaffSuccess: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }

  onStaffModal = () => {
    this.setState({ modalShow: true });
  };

  onCloseModal = () => {
    this.setState({ modalShow: false });
  };

  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sort || "createdAt",
        sortByStatus: query.status || "all",
        order: query.order ? query.order : "desc",
      },
      () => {
        this.fetchStaffReq();
        this.fetchRoleData();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchStaffReq = async (loading = true) => {
    const { currentPage, limit, sortBy, sortByStatus, search, order } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      sortByStatus,
      // order: sortBy === "createdAt" ? "desc" : "asc",
      order: order,
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_REQUEST.service,
      ApiRoutes.GET_STAFF_REQUEST.url,
      ApiRoutes.GET_STAFF_REQUEST.method,
      ApiRoutes.GET_STAFF_REQUEST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { staff, totalRecords },
      } = response.data;
      this.getProfile();
      this.setState({
        requests: staff,
        totalRecords,
      });
    }
  };
  updateStaffStatus = async (id, status, message) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: message,
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_STATUS.service,
      ApiRoutes.UPDATE_STAFF_STATUS.url.replace(":id", id),
      ApiRoutes.UPDATE_STAFF_STATUS.method,
      ApiRoutes.UPDATE_STAFF_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchStaffReq(false);
      toast.success(response.messages[0]);
    }
  };
  resendInvitation = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: "Are you sure, you want to resend invitation?",
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESEND_INVITATION_TO_STAFF.service,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.url.replace(":id", id),
      ApiRoutes.RESEND_INVITATION_TO_STAFF.method,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchStaffReq(false);
      toast.success(response.messages[0]);
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  setFilter = (sortBy, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      if (sortBy === "name") {
        params.sort = sortBy;
        params.order = "asc";
      } else {
        delete query.sort;
        params.status = sortBy;
      }
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  setSorting = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    // params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }

    // this.setState({
    //   requests: {
    //     ...this.state.requests,
    //     sortBy,
    //     order,
    //     errors: {
    //       ...this.state.requests.errors,
    //       joining_date: "",
    //     },
    //   },
    // });

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onAddBtnClick = () => {
    this.setState((prevState) => ({
      isFormOpen: !prevState.isFormOpen,
    }));
  };
  onCancel = async (newAdded) => {
    // To fetch request after adding new staff
    if (newAdded) {
      await this.fetchStaffReq(false);
    }
    this.setState({
      isFormOpen: false,
    });
  };

  viewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_STAFF_REQUEST.replace(":id", id));
  };
  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  fetchRoleData = async () => {
    let data = {};
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ROLE.service,
      ApiRoutes.GET_ROLE.url,
      ApiRoutes.GET_ROLE.method,
      ApiRoutes.GET_ROLE.authenticate,
      data,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { role },
      } = response.data;
      this.setState({
        role,
      });
    }
  };

  reloadAPI = () => {
    this.onCloseModal();
    this.fetchStaffReq();
  };
  getProfile = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.idFormat;
      this.setState({
        idFormat: data,
      });
    }
  };

  addStaff = async () => {
    const { idFormat } = this.state;
    if (!idFormat.employee) {
      this.setState({
        showStaffSuccess: true,
      });
    } else {
      this.onStaffModal();
    }
    //
  };

  setStaffRouter = () => {
    return this.props.history.push({
      pathname: AppRoutes.IDFORMATS,
      search: "?type=Employee", // query string
      state: {
        // location state
        type: "Employee",
      },
    });
  };
  closeSuccess = () => {
    this.setState({
      showStaffSuccess: false,
    });
  };
  render() {
    const {
      requests,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      search,
      sortByStatus,
      isFormOpen,
      isUpdating,
      activeId,
      sortBy,
      order,
      first_name,
      role,
      showStaffSuccess,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filtersOption = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Pending",
        value: "Pending",
      },
      {
        label: "Accepted",
        value: "Accepted",
      },
      {
        label: "Invited",
        value: "Invited",
      },
      {
        label: "Cancelled",
        value: "Cancelled",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-start justify-content-between pl-0 mb-0 mt-0">
          <h3>
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>
        <div className="tableFilter d-flex justify-content-between pl-0 filter-row filter_holder">
          <div className="d-flex align-items-center flex-md-row-reverse w-100 justify-content-between flex-wrap mb-4 mb-lg-0">
            <div className="d-flex align-items-center">
              <div className="mr-3">
                {/* <FilterDropdown
                  style={
                    "no-arrow d-flex align-items-center justify-content-between sortBy-custom"
                  }
                  filtersOption={filtersOption}
                  pagesOption={pagesOption}
                  sortBy={sortBy}
                  setFilter={this.setSorting}
                /> */}
                <FilterComponent
                  options={filtersOption}
                  selectedFilter={sortByStatus}
                  label={"Sort by"}
                  onFilterChange={(selectedValue) =>
                    this.setFilter(selectedValue, "all")
                  }
                />
              </div>
              <div className="dp-right">
                <button
                  className="addProductbtn"
                  data-toggle="modal"
                  data-target="#invitestaff"
                  disabled={isLoading}
                  onClick={this.addStaff}
                >
                  {" "}
                  Invite New Staff
                </button>
              </div>
            </div>
            <SearchFilter
              id={"sm-search-filter"}
              placeholder={"Search by Name and Employee ID"}
              search={search}
              onChange={this.handleSearch}
            />
          </div>
        </div>
        <div className="">
          <StaffListRequestComponent
            handleShow={this.addStaff}
            requests={requests}
            isLoading={isLoading}
            isUpdating={isUpdating}
            activeId={activeId}
            isFormOpen={isFormOpen}
            onCancel={this.onCancel}
            viewHandler={this.viewHandler}
            updateStatus={this.updateStaffStatus}
            resendInvitation={this.resendInvitation}
            setSorting={this.setSorting}
            sortBy={sortBy}
            order={order}
          />
        </div>
        <div className="footer-pg px-0 px-lg-3">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>

        <AddStaff
          modalShow={this.state.modalShow}
          modalClose={this.onCloseModal}
          roleOptions={role}
          reloadAPI={this.reloadAPI}
          // loadRoleOptions={this.loadRoleOptions}
          onSelect={this.onSelect}
        />

        <Modal
          show={showStaffSuccess}
          onHide={this.closeSuccess}
          className="logoutModal Custom2"
        >
          <Modal.Header>
            <span className="logoutAlert">!</span>
          </Modal.Header>
          <Modal.Body>
            <h3>Can't add Staff</h3>
            <p>Please add staff id from settings to onboard staff members</p>
          </Modal.Body>

          <Modal.Footer className="justify-content-between">
            <Button onClick={this.setStaffRouter} className="logout-btn">
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ManageStaffRequests;
