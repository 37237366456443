import React, { useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import rightArrow from "../../../../assets/img/right-arrow.svg";
import crossIcon from "../../../../assets/img/closeModal.svg";
import info_btn from "../../../../assets/img/info_btn.svg";
import googleIcon from "../../../../assets/img/googleIcon.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import googleAlert from "../../../../assets/img/googleAlert.svg";

function GoogleAuthenticator({ clientEdit, pageLoader, getProfile }) {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        Get time-based OTPs generated by the google authenticator app to
        securely sign in to your Clarity client account.
      </Popover.Body>
    </Popover>
  );
  const [show, setShow] = useState(false);
  const [qrCode, setQrcode] = useState();
  const [nextStep, setNexStep] = useState(0);
  const [otpEdit, setOtpEdit] = useState();
  const [password, setPassword] = useState();
  const [loader, setLoader] = useState();
  const [error, setError] = useState();

  const otpChange = (value) => {
    setOtpEdit(value ? value : "");
  };
  const passwordChange = (event) => {
    const { value } = event.target;
    setPassword(value.trimLeft());
  };

  const getGoogleAuth = async () => {
    setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_GOOGLE_AUTH.service,
      ApiRoutes.GET_GOOGLE_AUTH.url,
      ApiRoutes.GET_GOOGLE_AUTH.method,
      ApiRoutes.GET_GOOGLE_AUTH.authenticate,
      undefined,
      {
        token: otpEdit,
      }
    );
    setLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data;
      toast.success(response?.data?.message);
      setNexStep(3);
      getProfile();
      setQrcode();
      setOtpEdit();
    }
  };
  const enableGoogleAuth = async (event) => {
    event && event.preventDefault();
    setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GOOGLE_AUTH_ENABLE.service,
      ApiRoutes.GOOGLE_AUTH_ENABLE.url,
      ApiRoutes.GOOGLE_AUTH_ENABLE.method,
      ApiRoutes.GOOGLE_AUTH_ENABLE.authenticate,
      undefined
    );
    setLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      setQrcode(data);
      setNexStep(1);
      toast.success(response?.data?.message);
    }
  };

  const disableGoogleAuth = async (event) => {
    event && event.preventDefault();
    let data = {
      password: password,
    };
    if (password) {
      setLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GOOGLE_AUTH_DISABLE.service,
        ApiRoutes.GOOGLE_AUTH_DISABLE.url,
        ApiRoutes.GOOGLE_AUTH_DISABLE.method,
        ApiRoutes.GOOGLE_AUTH_DISABLE.authenticate,
        undefined,
        data
      );
      setLoader(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        const data = response?.data;
        setShow(false);
        toast.success(response?.data?.message);
        setPassword();
        getProfile();
      }
    } else {
      setError("Please enter password");
    }
  };

  const onCancelQr = () => {
    setQrcode();
    setNexStep(0);
  };
  const onClickNext = (count) => {
    setNexStep(count);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings profileMainBox smsAuth"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Google Authenticator</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row googleAuthenticator">
              <div className="col-md-12">
                <div className="row ">
                  <div className="col-md-12 otp_row">
                    <p style={{ marginBottom: "8px" }}>
                      Put a double security check by enabling the google
                      authenticator to your account to avoid any unauthorized
                      access
                    </p>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 ">
                    {qrCode?.secret && (
                      <div className="googleNote">
                        <img src={googleAlert} className="mr-2" alt="" />
                        <p>
                          Please turn on the Google authenticator until you make
                          sure you've got it set up properly. Download the{" "}
                          <a
                            href="https://googleauthenticator.net/"
                            target="_blank"
                          >
                            Google Authenticator App
                          </a>{" "}
                          then follow the instructions below.
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 otp_row">
                    {!clientEdit?.googleAuthEnable && (
                      <>
                        {nextStep === 0 && (
                          <form>
                            <div className="d-flex align-items-center justify-content-center flex-column otp_form mt-4 qrCodeBox">
                              <img src={googleIcon} alt="" />
                              <h5 style={{ fontWeight: "500" }}>
                                Not Enabled Yet!
                              </h5>
                            </div>
                            <div className="d-flex justify-content-center action-Btns">
                              <Button
                                onClick={enableGoogleAuth}
                                className="btn customSubmitBtn mt-0"
                                variant=""
                                disabled={loader}
                              >
                                {loader ? (
                                  <>
                                    <i className="fa fa-spinner fa-spin test-spin"></i>
                                    Enable
                                  </>
                                ) : (
                                  <>Enable</>
                                )}
                              </Button>
                            </div>
                          </form>
                        )}
                      </>
                    )}

                    {nextStep === 1 && (
                      <form>
                        <div className="d-flex align-items-center justify-content-center flex-column otp_form qrCodeBox">
                          <p>Scan this QR code:</p>
                          <img src={qrCode?.imagePath} />
                          <h4>
                            Or enter this secret key into your Google
                            Authencation App:
                          </h4>
                          <h5>{qrCode?.secret}</h5>
                        </div>
                        <div className="d-flex justify-content-center action-Btns">
                          <Button
                            className="customCancelBtn mt-0 btn"
                            variant=""
                            onClick={onCancelQr}
                            disabled={loader}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn customSubmitBtn mt-0"
                            variant=""
                            onClick={() => onClickNext(2)}
                          >
                            Next
                            <img
                              style={{ marginLeft: "8px" }}
                              src={rightArrow}
                            />
                          </Button>
                        </div>
                      </form>
                    )}
                    {nextStep === 2 && (
                      <>
                        <h4 style={{ marginTop: "113px" }}>
                          Enter the 6-digit passcode appearing in your Google
                          Authenticator App here to activate
                        </h4>
                        <form>
                          <div className="d-flex align-items-center justify-content-center otp_form">
                            <OTPInput
                              value={otpEdit}
                              onChange={otpChange}
                              OTPLength={6}
                              otpType="number"
                              inputClassName="form-control p-0"
                            />
                          </div>
                          <div className="d-flex justify-content-center action-Btns">
                            <Button
                              className="customCancelBtn mt-0 btn"
                              variant=""
                              onClick={() => setNexStep(0)}
                              disabled={loader}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="btn customSubmitBtn mt-0"
                              variant=""
                              onClick={getGoogleAuth}
                              disabled={loader}
                            >
                              {loader ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin"></i>
                                  Next
                                </>
                              ) : (
                                <>Next</>
                              )}
                              <img
                                style={{ marginLeft: "8px" }}
                                src={rightArrow}
                              />
                            </Button>
                          </div>
                        </form>
                      </>
                    )}

                    {clientEdit?.googleAuthEnable && (
                      <div className="regenerateKeySection">
                        <h4>Google Authenticator is currently enabled!</h4>
                        <form>
                          <div className="d-flex justify-content-center action-Btns">
                            <Button
                              className="customCancelBtn mt-0 btn"
                              variant=""
                              onClick={enableGoogleAuth}
                            >
                              Regenerate Key
                            </Button>
                            <Button
                              className="btn customSubmitBtn mt-0"
                              variant=""
                              on
                              onClick={() => setShow(true)}
                            >
                              Disable
                            </Button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="emailAddresssModal regenerateKeyModal"
        show={show}
        onHide={() => {
          setShow(false);
          setPassword();
        }}
      >
        <Modal.Header>
          <Modal.Title>Disable Google Authenticator</Modal.Title>
          <div>
            <Button
              variant=""
              onClick={() => {
                setShow(false);
                setPassword();
              }}
            >
              <img src={crossIcon} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h5>Enter Password</h5>
          <p style={{ marginTop: "0", textTransform: "unset" }}>
            This action seems sensitive please enter your password to let the
            system know its you.
          </p>
          <form>
            <InputTextFeild
              placeholder="Enter Password to verify"
              value={password}
              type="password"
              onChange={passwordChange}
              errorMessage={error}
            />
            <Button
              variant=""
              disabled={loader}
              className="otp_btn"
              onClick={disableGoogleAuth}
            >
              {loader ? (
                <>
                  <i className="fa fa-spinner fa-spin test-spin"></i>
                  Submit
                </>
              ) : (
                <>Submit</>
              )}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GoogleAuthenticator;
