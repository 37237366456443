import React from "react";
import BillingsInvoice from "../../../components/manageSubscription/BillingsInvoice";

export default function Billings() {
  return (
    <div className="container-fluid fluid-pd-2">
      <div className="productFormBox reportBatches billing settings mt-4 billings">
        <div className="row">
          <BillingsInvoice />
        </div>
      </div>
    </div>
  );
}
