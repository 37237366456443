import React from "react";
import IdentityForm from "../../components/IdentityForm";

// import mainmob3xImg from '../../../assets/img/main_mob@3x.png';

const VerifyIdentity = () => {
  return (
    <>
      <IdentityForm />
    </>
  );
};

export default VerifyIdentity;
