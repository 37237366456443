/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, pagesOption } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { ListStaffComponent } from "../../components/staffs";
import { GridStaffComponent } from "../../components/staffs";
import { CategoryCardStaff } from "../../components/loader/Resusable/CategoryCardStaff";
import Pagination from "../../components/pagination";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { FilterComponent } from "../../components/filters/filterComponent";
import SearchFilter from "../../components/filters/SearchFilter";
import pageDropdownIcon from "../../../assets/img/home_icon.svg";
import gridActive from "../../../assets/img/manageStaffGridActive.svg";
import ListNoActive from "../../../assets/img/stafflistUnActive.svg";
import gridNoActive from "../../../assets/img/manageStaffGridUnActive.svg";
import ListActive from "../../../assets/img/stafflistActive.svg";
import noData from "../../../assets/img/noData.svg";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import rightPageArrow from "../../../assets/img/rightPageArrow.svg";
import leftPageArrow from "../../../assets/img/leftPageArrow.svg";
import "./manage-staff.css";
import FilterDropdown from "../../components/filters/FilterDropdown";

class ManageStaffList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: [],
      errors: null,
      isLoading: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      sortBy: "all",
      sortByStatus: "all",
      search: "",
      isFormOpen: false,
      isUpdating: false,
      activeId: "",
      viewMode: "grid",
      order: "desc",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sort || query.status || "all",
        // sortByStatus: query.status || "all",
        order: query.order ? query.order : "desc",
        viewMode: query.view || "grid",
      },
      () => this.fetchStaffReq()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchStaffReq = async (loading = true) => {
    const { currentPage, limit, sortBy, sortByStatus, search, order } =
      this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      sortByStatus: sortBy !== "name" ? sortBy : "",
      order: order,
      // order: sortBy === "createdAt" ? "desc" : "asc",
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF.service,
      ApiRoutes.GET_STAFF.url,
      ApiRoutes.GET_STAFF.method,
      ApiRoutes.GET_STAFF.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { staff, totalRecords },
      } = response.data;
      this.setState({
        staff,
        totalRecords,
      });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  viewHandler = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.VIEW_STAFF_REQUEST.replace(":id", id),
      state: {
        search,
      },
    });
  };
  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      if (sortBy === "name") {
        params.sort = sortBy;
        params.order = "asc";
      } else {
        delete query.sort;
        params.status = sortBy;
      }
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  setSorting = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    // params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  render() {
    const {
      staff,
      viewMode,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      search,
      isUpdating,
      order,
      // activeId,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filtersOption = [
      {
        label: "All",
        value: "all",
      },
      {
        label: "Staff Name",
        value: "name",
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Suspended",
        value: "Suspended",
      },
      {
        label: "Terminated",
        value: "Terminated",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
            />
          </h3>
        </div>

        <div className="tableFilter d-flex justify-content-between mb-0 mb-lg-4 ">
          {/* <div className="">
            <Dropdown className="no-arrow dp-right">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className={"filter-btn-mob"}
                >
                {" "}
                <span className="staff-value">
                {
                (
                filtersOption.filter(
                (filter) => filter.value === limit
                )[0] || {}
                ).label
                }
                </span>{" "}
                <img src={pageDropdownIcon} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                {filtersOption.map(({ label, value }, index) => (
                <Dropdown.Item
                as="div"
                key={index}
                onClick={() => this.setFilter(value)}
              >
                {label}
              </Dropdown.Item>
              ))}
              </Dropdown.Menu>
            </Dropdown>

          <div className="dp-right mob-ic">
          <Link
          className=" icon-btn"
          to={`${AppRoutes.MANAGE_STAFF}?view=list`}
          >
          <img
          src={viewMode === "list" ? ListActive : ListNoActive}
          alt=""
          />
          </Link>
          <Link
          className=" icon-btn"
          to={`${AppRoutes.MANAGE_STAFF}?view=grid`}
          >
          <img
          src={viewMode === "grid" ? gridActive : gridNoActive}
          alt=""
          />
          </Link>
          </div>
          </div> */}
          <SearchFilter
            id={"sm-search-filter"}
            placeholder={"Search by Name and Employee ID"}
            search={search}
            onChange={this.handleSearch}
          />
          <div className="d-flex align-items-center filter_holder">
            <div className="dp-right d-flex flex-row-reverse">
              <Link
                className="mr-3"
                to={`${AppRoutes.MANAGE_STAFF_LIST}?view=list`}
              >
                <img
                  src={viewMode === "list" ? ListActive : ListNoActive}
                  alt=""
                  // style={{ width: "45px" }}
                />
              </Link>
              <Link
                className="mr-3"
                to={`${AppRoutes.MANAGE_STAFF_LIST}?view=grid`}
              >
                <img
                  src={viewMode === "grid" ? gridActive : gridNoActive}
                  alt=""
                  // style={{ width: "45px" }}
                />
              </Link>
            </div>

            <div className="mr-3">
              {/* <FilterDropdown
                style={
                  "no-arrow d-flex align-items-center justify-content-between sortBy-custom"
                }
                filtersOption={filtersOption}
                pagesOption={pagesOption}
                sortBy={sortBy}
                setFilter={this.setFilter}
              /> */}
              <FilterComponent
                options={filtersOption}
                selectedFilter={sortBy}
                label={"Sort by"}
                onFilterChange={(selectedValue) =>
                  this.setFilter(selectedValue, "all")
                }
              />
            </div>

            <div className="dp-right">
              <Link
                className="addProductbtn no-background invitestafflist"
                to={AppRoutes.MANAGE_STAFF_REQUESTS}
              >
                {" "}
                Invited Staff Listing
              </Link>
            </div>
          </div>
        </div>
        <div className="staffListingui">
          {viewMode === "grid" ? (
            <div className="row-grid-fixed">
              <div className="row ">
                {isLoading ? (
                  <CategoryCardStaff />
                ) : staff && staff.length ? (
                  <GridStaffComponent
                    staff={staff}
                    isUpdating={isUpdating}
                    viewHandler={this.viewHandler}
                  />
                ) : (
                  <div
                    className="col-md-12 col-vd-nodata"
                    // style={{ padding: "10em 0" }}
                  >
                    <>
                      <img src={noData} alt="" />
                      <p>
                        No Staff Found,{" "}
                        <Link
                          to={AppRoutes.MANAGE_STAFF_REQUESTS}
                          className="text-decoration-none"
                        >
                          Click here
                        </Link>{" "}
                        to Invite New Staff
                      </p>
                    </>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <ListStaffComponent
              staff={staff}
              isLoading={isLoading}
              isUpdating={isUpdating}
              viewHandler={this.viewHandler}
              setSorting={this.setSorting}
              sortBy={sortBy}
              order={order}
            />
          )}
        </div>
        <div className="footer-pg px-0 px-lg-3">
          {!isLoading ? (
            <>
              {/* <div className="float-left mt-2">
                <p className="page-desc-left">
                  Total: <span className="bold-black">{totalRecords}</span>
                </p>
              </div> */}
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                {/* <p className="page-desc ml-2 float-right">Table View</p> */}

                {/* <Dropdown className="no-arrow dp-right ml-2">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={'filter-btn'}
                  >
                    {' '}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{' '}
                    <img src={filterarrowIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                    {pagesOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.onLimitChange(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      {/* <button className="paginationArrows">
                        <img src={leftPageArrow} />
                      </button> */}
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                      {/* <button className="paginationArrows">
                        <img src={rightPageArrow} />
                      </button> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ManageStaffList;
