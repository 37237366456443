import React from 'react';
import viewIcon from '../../../assets/img/editIcon.svg';
import printIcon from '../../../assets/img/batch_printer.svg';
import trashIcon from '../../../assets/img/trashIcon.svg';
import { Button } from 'react-bootstrap';
import InputCheckbox from '../form/InputCheckbox';

const BatchRow = ({ batchData, onDelete, onPrint, onView }) => {
  const { batchNumber = '', product = {}, availableUnits = '' } = batchData;
  const { productNumber = '', productName = '' } = product || {};
  return (
    <tr className='btachRow history'>
      {/* <td>
        <InputCheckbox 
          id='customCheck'
          name='islogged'
          value=""
        />
      </td> */}
      <td className={'cursor-pointer'} onClick={onView}>
        <span className='textContol'>{batchNumber}</span>
      </td>
      <td><span className='textContol'>{productNumber}</span></td>
      <td><span className='textContol'>{productName}</span></td>
      <td className='availableUnits'>
      <div className="d-flex align-items-center hoverEfect">
        <span className='textContol'>{availableUnits}</span>
        <div className="d-flex align-item-center editProduct-btn">
          <div className="imgHover_effect">
            <img src={viewIcon} alt="" onClick={onView} className='m-2 cursor-pointer' />
          </div>
          <div className="imgHover_effect">
            <img src={trashIcon} alt="" onClick={onDelete} className='m-2 cursor-pointer' />
          </div>
          <div className="imgHover_effect">
            <img src={printIcon} alt="" onClick={onPrint} className='m-2 cursor-pointer' />
          </div>
        </div>
        </div>
      </td>
      {/* <td>
        
        <div className="d-flex align-items-center hoverEfect">
          <img src={viewIcon} alt="" />
          <img src={trashIcon} alt="" />
          <img src={printIcon} alt="" />
        </div>
        </td> */}
        {/* <div className='btn btn-secondary th-btn edit-tb' onClick={onView}>
          <img src={listIcon} alt='' /> View
        </div>
        <div className='btn btn-secondary th-btn edit-tb' onClick={onPrint}>
          <img src={printIcon} alt='' /> Print
        </div>
        <div className='btn btn-secondary th-btn delete-tb' onClick={onDelete}>
          <img src={trashIcon} alt='' /> Delete
        </div> */}
    </tr>
  );
};

export default BatchRow;
