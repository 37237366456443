import React from "react";
import more from "../../../assets/img/more.svg";
import inProgress from "../../../assets/img/in-progressIcon.svg";
import viewIcon from "../../../assets/img/view-counter.svg";
import userPic from "../../../assets/img/avtar.jfif";
import contact from "../../../assets/img/contact_counter.svg";
import InputCheckbox from "../form/InputCheckbox";
import markInActive from "../../../assets/img/inactive_icon.svg";
import completed from "../../../assets/img/complete-chechk.svg";
import { Dropdown } from "react-bootstrap";

export const CounterfeitRow = ({ counterfeitData, viewHandler }) => {
  const {
    scan = {},
    batch = {},
    product = {},
    assigneeName = "",
    user = {},
    status = "",
    reportId = "",
    assignee = "",
  } = counterfeitData;
  const { scanId } = scan || {};
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const renderStatus = () => {
    switch (status) {
      case "Acknowledged":
        return (
          <span className="Acknowledged">
            {/* <img src={markInActive} className="mr-1" alt="" />  */}
            Acknowledged
          </span>
        );
      case "Assigned":
        return (
          <span className="Assigned">
            {/* <img src={markInActive} className="mr-2" alt="" />  */}
            Assigned
          </span>
        );
      case "Cancelled":
        return (
          <span className="Cancelled">
            {" "}
            {/* <img src={markInActive} className="mr-2" alt="" />  */}
            Cancelled
          </span>
        );
      case "Completed":
        return (
          <span className="completed">
            {" "}
            {/* <img src={completed} className="mr-2" alt="" />  */}
            Completed
          </span>
        );
      case "In Process":
        return (
          <span className="Cancelled">
            {" "}
            {/* <img src={markInActive} className="mr-2" alt="" />  */}
            Cancelled
          </span>
        );
      case "Reopened":
        return (
          <span className="Reopened">
            {/* <img src={markInActive} className="mr-2" alt="" />  */}
            Reopened
          </span>
        );
      case "In Review":
        return (
          <span className="inreview">
            {" "}
            {/* <img src={inProgress} alt="" className="mr-2" />  */}
            In Review
          </span>
        );
      case "In Process":
        return (
          <span className="inprocess">
            {" "}
            {/* <img src={inProgress} alt="" className="mr-2" />  */}
            In Process
          </span>
        );
      default:
        return <span className="Acknowledged">Acknowledged</span>;
    }
  };
  return (
    <>
      <tr className="btachRow history counter_feit">
        {/* <td>
        <InputCheckbox
          id='customCheck'
          name='islogged'
          value=""
        />
      </td> */}
        <td>
          <span className="textContol">{scanId || reportId}</span>
        </td>
        <td>
          <span className="textContol">{batchNumber || "-"}</span>
        </td>
        <td>
          <span className="textContol">{productName || "-"}</span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <div
              style={{
                width: "36px",
                height: "36px",
                marginRight: "12px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              {/* {userPic ? (
              <img style={{ width: "36px" }} src={userPic} />
              ):(
              <span class="profile_holder" style={{textTransform:"uppercase"}}>{assigneeName ? assigneeName.substring(0,1) : "-"}</span>
              )
            } */}
              <span
                class="profile_holder"
                style={{ textTransform: "uppercase" }}
              >
                {assigneeName ? assigneeName.substring(0, 1) : "-"}
              </span>
            </div>
            <span className="textContol">{assigneeName || "-"}</span>
          </div>
        </td>
        <td>
          <button className="status-btn">{renderStatus()}</button>
        </td>
        <td>
          <div className="d-flex align-items-center conuter-view">
            <button className="viewBtn" onClick={viewHandler}>
              <img src={viewIcon} className="mr-2" alt="" /> View
            </button>
            <Dropdown className="">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="InactiveDropdown"
              >
                <img src={more} alt="" />
              </Dropdown.Toggle>
              <div className="customDropdownBox">
                <Dropdown.Menu className="shadow dropdown-menu dropdown-staff">
                  <Dropdown.Item
                    as="div"
                    className="dropItem"
                    onClick={() => window.open(`mailto:${assignee.email}`)}
                  >
                    <img src={contact} alt="" className={"img-main mr-2"} />
                    Contact
                  </Dropdown.Item>
                  {/* <Dropdown.Item as="div" className="dropItem">
                    <img
                      src={markInActive}
                      alt=""
                      className={"img-main mr-2"}
                    />
                    Canceled
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </div>
            </Dropdown>
            {/* onClick={() => window.open(`mailto:${email}`)} */}
            {/* <button className='printButton reopen'>
            {' '}
            <img src={reopenIcon} alt='' /> Reopen{' '}
          </button> */}
          </div>
        </td>
      </tr>
    </>
  );
};
