import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../partial/Breadcrumbs";

function Notifications() {
  const location = useLocation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [notifyData, setNotifyData] = useState({
    data: "scan",
    value: "",
  });
  const [notifyValue, setNotifyValue] = useState();

  useEffect(() => {
    getNotification();
  }, []);

  const getNotification = async () => {
    setIsUpdating(true);

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setIsUpdating(false);

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.notifications;
      setNotifyData(data);
    }
  };

  const changeNotificationValue = async (data) => {
    setIsUpdating(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.NOTIFICATION_RESTRICT.service,
      ApiRoutes.NOTIFICATION_RESTRICT.url,
      ApiRoutes.NOTIFICATION_RESTRICT.method,
      ApiRoutes.NOTIFICATION_RESTRICT.authenticate,
      undefined,
      data
    );
    console.log("data", data);

    setIsUpdating(false);
    console.log("response", response.data.data);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      getNotification();
      setNotifyData(response.data.data);
      toast.success(response?.data?.message);
    }
  };

  const handleChange = (event) => {
    console.log("event", event.target.checked);
    const {
      target: { value, name, checked, type },
    } = event;

    setNotifyData({
      ...notifyData,
      [name]: type === "checkbox" ? checked : name,
    });
    let data = {
      data: event.target.name,
      value: event.target.checked,
    };

    changeNotificationValue(data);
  };

  console.log("notifyValue", notifyValue);
  return (
    <div className="container-fluid fluid-pd-2 pb-5">
      <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
        <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
          <Breadcrumbs pathname={location.pathname} />
        </h3>
      </div>
      <div className="productFormBox reportBatches settings Notify">
        <div className="row">
          <div className="col-md-12">
            <div className="exportRow">
              <div className="d-flex align-items-center">
                <h4>Preferences</h4>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <p>
              By this you can restrict / turn off the category for which you
              don’t want to receive the push notifications
            </p>
            <div className="switchBox">
              <div className="d-flex align-items-center justify-content-between">
                <h3>Counterfeits</h3>
                <label className="switch">
                  <input
                    name="counterfeit"
                    onChange={handleChange}
                    checked={notifyData?.counterfeit}
                    type="checkbox"
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h3>Scans</h3>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="scan"
                    onChange={handleChange}
                    checked={notifyData?.scan}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h3>Products</h3>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="product"
                    onChange={handleChange}
                    checked={notifyData?.product}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <h3>Batches</h3>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="batch"
                    onChange={handleChange}
                    checked={notifyData?.batch}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
