import React from "react";
import Skeleton from "react-skeleton-loader";

export const SaveCardsAccountLoader2 = () => (
  <>
    

    <div className="col-md-12">
        <div className="innerHolder paymentRow">
          <div className="sliderHolder">
            <Skeleton height={"75px"} width={"100%"} color="#e5e9ef" />

            <Skeleton height={"75px"} width={"100%"} color="#e5e9ef" />

            <Skeleton height={"75px"} width={"100%"} color="#e5e9ef" />
          </div>
        </div>
      </div>
  </>
);
