import React from "react";
import { Dropdown } from "react-bootstrap";
import filterarrowIcon from "../../../assets/img/counterDrop.svg";

export const FilterComponent = ({
  options,
  label = "Sort By",
  selectedFilter,
  onFilterChange,
  showImage = true,
}) => {
  return (
    <Dropdown className="no-arrow dp-right customDropdownBox ml-3">
      <Dropdown.Toggle
        variant=""
        id="dropdown-basic"
        className={
          "dropdown-custom d-flex align-items-center justify-content-between"
        }
      >
        {label}{" "}
        <div className="d-flex">
          <span className="staff-value ml-2">
            {
              (
                options.filter(
                  (filter) => filter.value === selectedFilter
                )[0] || {}
              ).label
            }
          </span>{" "}
          {showImage ? <img src={filterarrowIcon} alt="" /> : ""}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
        {options.map(({ label, value, order }, index) => (
          <Dropdown.Item
            as="div"
            key={index}
            onClick={() => onFilterChange(value, order)}
          >
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
