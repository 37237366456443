/* eslint-disable no-sparse-arrays */
import { AppRoutes } from "../../../config";
// import Shipping from '../../../assets/img/batches_icon.svg';
import sub_icon from "../../../assets/img/currentplanactive.svg";
import billing_icon from "../../../assets/img/billing.svg";
import billingactive from "../../../assets/img/billingactive.svg";
import currentplanImage from "../../../assets/img/currentplanImage.svg";
import paymentimage from "../../../assets/img/paymentimage.svg";
import paymentactive from "../../../assets/img/paymentactive.svg";
import purchaseImage from "../../../assets/img/purchaseImage.svg";
import purchaseactive from "../../../assets/img/purchaseactive.svg";

export const SubNavConfig = [
  {
    name: "Current Plan",
    path: AppRoutes.Create_Plan,
    imgUrl: currentplanImage,
    activeImgUrl: sub_icon,
  },
  {
    name: "Billings",
    path: AppRoutes.Billing,
    imgUrl: billing_icon,
    activeImgUrl: billingactive,
  },
  {
    name: "Payment Methods",
    path: AppRoutes.Payment,
    imgUrl: paymentimage,
    activeImgUrl: paymentactive,
  },
  {
    name: "Purchase Subscription",
    path: AppRoutes.Purchase,
    imgUrl: purchaseImage,
    activeImgUrl: purchaseactive,
  },
];
