import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { message } from "../../../common";
import { ApiRoutes, AppRoutes } from "../../../config";
// import { InputTextField, SubmitButton } from '../../components/forms';
import InputTextField from "../../components/form/InputTextFeild";
import SubmitButton from "../../components/form/SubmitButton";
// import fileMinusIcon from '../../../assets/img/file-minus.svg';
// import tagIcon from '../../../assets/img/shape-copy.svg';
// import calenderIcon from '../../../assets/img/calender.svg';

const PrintBatchModal = ({
  batchData = {},
  open,
  // itemCount = '',
  // printBatchError = '',
  // onChange,
  // handlePrint,
  handleClose,
  fetchData,
  fetchHistory,
}) => {
  // Item count admin wants to print
  const [itemCount, setItemCount] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    batchNumber = "",
    availableUnits = 0,
    // product = [],
    productName = "",
    _id = "",
  } = batchData;
  useEffect(() => {
    setItemCount("");
    setError("");
  }, [open]);
  const assignBatchToPHL = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ASSIGN_BATCH.service,
      ApiRoutes.ASSIGN_BATCH.url.replace(":id", _id),
      ApiRoutes.ASSIGN_BATCH.method,
      ApiRoutes.ASSIGN_BATCH.authenticate,
      undefined,
      { unit: itemCount }
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { batchGroup },
      } = response.data;
      handleClose();
      fetchData();
      if (fetchHistory) {
        fetchHistory();
      }
      let mapObj = {
        ":id": _id,
        ":unit": itemCount,
      };
      var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
      window.open(
        `${AppRoutes.PRINT_BATCH.replace(re, function (matched) {
          return mapObj[matched.toLowerCase()];
        })}?group=${batchGroup}`,
        "_blank"
      );
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setItemCount(value);
    setError("");
  };
  const handlePrint = async () => {
    let errorMsg = "";
    // To validate item count
    if (!itemCount) {
      errorMsg = message.Required.replace(":item", "Item count");
    } else if (!Number.isInteger(Number(itemCount))) {
      errorMsg = message.InvalidItemCount;
    } else if (Number(itemCount) <= 0) {
      errorMsg = message.MinItemCount;
    } else if (Number(itemCount) > availableUnits) {
      errorMsg = message.PrintBatchError.replace(":count", availableUnits);
    }
    if (errorMsg) {
      setError(errorMsg);
    } else {
      await assignBatchToPHL();
    }
  };
  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName="modal-30w customModelClient"
      restoreFocus={false}
      keyboard={false}
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>Print Batch</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <form>
          <div className="row" style={{ marginBottom: "14px" }}>
            <div className="col-md-6 mb-2">
              <div className="heading-col">
                {/* <img src={fileMinusIcon} alt="" /> */}
                <h5>Batch Number</h5>
              </div>
              <div className="description-col">
                <h5>{batchNumber}</h5>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="heading-col">
                {/* <img src={tagIcon} alt="" /> */}
                <h5>Product Name</h5>
              </div>
              <div className="description-col">
                <h5>
                  {productName}
                  {/* {product && product.length ? product[0].productName : '-'} */}
                </h5>
              </div>
            </div>
          </div>
          <div className="row mb-0">
            <div className="col-md-6 mb-2">
              <div className="heading-col">
                {/* <img src={calenderIcon} alt="" /> */}
                <h5>Available Count</h5>
              </div>
              <div className="description-col">
                <h5>{availableUnits}</h5>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="form-group" style={{ marginBottom: "32px" }}>
                <div className="heading-col">
                  {/* <img src={calenderIcon} alt="" /> */}
                  <h5>Enter Item Count</h5>
                </div>
                <InputTextField
                  id="item_count"
                  type={"number"}
                  placeholder={"Enter count here"}
                  className={"form-control-input-modal "}
                  name="itemCount"
                  value={itemCount}
                  onChange={handleChange}
                  errorMessage={error}
                />
                <div className={"text-danger"}></div>
              </div>
            </div>
            <div className="col-md-12 invitestaffmodal">
              <div className="d-flex align-items-center">
                <button
                  className="customCancelBtn mt-0"
                  onClick={handleClose}
                  disabled={loading}
                  type="button"
                  style={{ marginRight: "20px" }}
                >
                  {" "}
                  CANCEL
                </button>
                <button
                  type="button"
                  disabled={loading}
                  className="btn customSubmitBtn mt-0"
                  onClick={handlePrint}
                >
                  {loading ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin  mr-1"></i>{" "}
                      PRINT
                    </>
                  ) : (
                    <>PRINT</>
                  )}
                </button>
                {/* <SubmitButton
                  className={"btn customSubmitBtn mt-0"}
                  text={"PRINT"}
                  isLoading={loading}
                  onSubmit={handlePrint}
                /> */}
              </div>
            </div>
            {/* <div className="col-md-6 invitestaffmodal">
              <button
                className="customCancelBtn mt-0"
                onClick={handleClose}
                disabled={loading}
              >
                {" "}
                CANCEL
              </button>
            </div>
            <div className="col-md-6 invitestaffmodal">
              <SubmitButton
                className={"btn customSubmitBtn mt-0"}
                text={"PRINT"}
                isLoading={loading}
                onSubmit={handlePrint}
              />
            </div> */}
          </div>
        </form>
      </Modal.Body>

      {/* <Modal.Footer>
        <div className="col-md-12 footer-form d-flex align-items-center justify-content-center">
          <button
            className="cancel-btn"
            onClick={handleClose}
            disabled={loading}
          >
            {" "}
            CANCEL
          </button>

          <SubmitButton
            className={"submit-btn modal-btn-ft print-btn"}
            text={"PRINT"}
            isLoading={loading}
            onSubmit={handlePrint}
          />
        </div>
      </Modal.Footer> */}
    </Modal>
  );
};

export default PrintBatchModal;
