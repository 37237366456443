import React, {useState} from 'react';
import search_icon from '../../../assets/img/search.svg';

function SearchFilter({ placeholder, search, onChange}) {
    // const handleOnChange = (event) => {
    //     setText(event.target.value);
    // }
    // const [text, setText] = useState();

    return (
        <>
            <form>
                  <div className='input-group position-relative'>
                    <input
                      type='search'
                      className='form-control'
                      placeholder={placeholder}
                      id=''
                      name={'search'}
                      value={search}
                      onChange={onChange}
                      autoComplete='off'
                    />
                    {/* <img className='search_icon' src={search_icon} /> */}
                  </div>
            </form>
        </>
    );
}

export default SearchFilter;