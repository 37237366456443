import React from "react";
import Skeleton from "react-skeleton-loader";

export const CurrentPlanLoaderMini = () => (
  <>
    <div className="mycard">
      <p style={{marginBottom: "14px"}}>
        <Skeleton height={"19px"} width={"100%"} color="#e5e9ef" />
      </p>
      <h4 className="text-capitalize">
        <Skeleton height={"29px"} width={"100%"} color="#e5e9ef" />
      </h4>
    </div>
  </>
);
