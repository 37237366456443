import React, { useEffect,useState } from 'react';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { ApiHelper } from '../../../helpers';
import ResetPasswordFormComponent from '../../components/ResetPassword';
import { ApiRoutes, AppRoutes } from '../../../config';
import { resetPasswordValidator } from '../../../validators';

const ResetPassword =()=>{
  let history = useHistory();
    let { token  } = useParams()
  const [userResetPassword, setUserResetPassword] = useState({
    token,
    password: '',
    cnfpassword:'',
    errors: null,
    islogged: false,
    isLoading: false,
  });

  useEffect(()=>{
    document.body.classList.add('bg-gradient-primary');
  },[])

  const handleChange = event => {
    const {
      target: { type, value, name, checked },
    } = event;
    var regexp = /^\S*$/;
    if ((name === 'password' || name === 'cnfpassword') && !regexp.test(value)) {
      setUserResetPassword({
          ...userResetPassword,
          errors: { [name]: 'Space not allowed' }
        });
    } else {
      setUserResetPassword({
        ...userResetPassword,
        [name]: type === 'checkbox' ? checked : value,
        errors: { ...userResetPassword.errors, [name]: '' },
      });
    }
  };

  const handleSubmit = async event => {
    event && event.preventDefault();
    let data = {
        token,
        password
    };
    console.log(userResetPassword);
    let { isValid, errors } = resetPasswordValidator({password,cnfpassword});
    console.log(errors);
    if (isValid) {
      if(password !== cnfpassword){
        setUserResetPassword({
          ...userResetPassword,
          errors: {'cnfpassword':'Password and confirm password didn\'t match'},
        });
        return;
      }
      console.log("hdhddh");
      setUserResetPassword({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.RESET_PASSWORD.service,
        ApiRoutes.RESET_PASSWORD.url,
        ApiRoutes.RESET_PASSWORD.method,
        ApiRoutes.RESET_PASSWORD.authenticate,
        undefined,
        data,
      );
      setUserResetPassword({
        isLoading: false,
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        const { data } = response.data;
        toast.success(response.messages[0]);
        history.push(AppRoutes.LOGIN)
        // setUserResetPassword(data);
        // const { email, password } = userResetPassword;
        // islogged ? setCheck(email, password) : clearCheck();
        // data.is_first_login == true ? history.push(AppRoutes.SET_PASSWORD):''
        // this.props.history.push(AppRoutes.DASHBOARD);
        // if(data.is_first_login){
        //   history.push(AppRoutes.SET_PASSWORD) 
        // }
      }
    } else{
      setUserResetPassword({
      ...userResetPassword,
        errors,
      });
      return;
    }
  };

    const {  password,cnfpassword, errors, islogged, isLoading } = userResetPassword;
    return (
      <>
      
                        <ResetPasswordFormComponent
                          password={password}
                          cnfpassword={cnfpassword}
                          errors={errors}
                          islogged={islogged}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
      </>
    );
}

export default ResetPassword;
