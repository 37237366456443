import React, { Component } from "react";
import { Dropdown, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import { TableLoader } from "../../components/loader/Resusable/Table";
import { ViewInputLoader } from "../../components/loader/Resusable/ViewInput";
import filterarrowIcon from "../../../assets/img/dropdown_down_iconBlue.svg";
import {
  ApiRoutes,
  AppConfig,
  AppRoutes,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  SocketEvents,
} from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ScanRow } from "../../components/scans";
import Pagination from "../../components/pagination";
import defaultImg from "../../../assets/img/home_icon.svg";
import defaultUserImg from "../../../assets/img/userProile.jpg";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import * as qs from "query-string";
import noData from "../../../assets/img/noData.svg";

import "./view-user.css";

let scanLimit = 5;
let counterfeitLimit = 5;
let lat, long;

class ViewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userId: "",
      isLoading: false,
      scans: [],
      totalScanRecords: 0,
      currentScanPage: 1,
      isScanLoading: false,
      counterfeits: [],
      totalCounterfeitsRecords: 0,
      currentCounterfeitsPage: 1,
      isCounterfeitLoading: false,
      sortBy: "created_at",
      order: "desc",
      counterOrder: "desc",
      counterSortBy: "created_at",
    };
  }
  componentDidMount = () => {
    this.getuser();
  };

  getuser = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (!id) {
      this.props.history.push("/manage-users");
    }
    this.setState({
      userId: id,
    });
    this.fetchUser(id);
    this.fetchScanRecords(id);
    this.fetchCounterfeitRecords(id);
  };

  fetchUser = async (id) => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_USER_INFO_BY_ID.service,
      ApiRoutes.GET_USER_INFO_BY_ID.url.replace(":id", id),
      ApiRoutes.GET_USER_INFO_BY_ID.method,
      ApiRoutes.GET_USER_INFO_BY_ID.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      this.props.history.push(AppRoutes.MANAGE_USER);
    } else {
      const { data } = response.data;
      this.setState({ userInfo: data });
    }
  };

  blockUser = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to block this user?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BLOCK_USER.service,
      ApiRoutes.BLOCK_USER.url.replace(":id", id),
      ApiRoutes.BLOCK_USER.method,
      ApiRoutes.BLOCK_USER.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchUser(id);
      toast.success(response.messages[0]);
    }
  };
  unBlockUser = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: 'Are you sure?',
      text: `Are you sure, you want to unblock this user?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNBLOCK_USER.service,
      ApiRoutes.UNBLOCK_USER.url.replace(":id", id),
      ApiRoutes.UNBLOCK_USER.method,
      ApiRoutes.UNBLOCK_USER.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchUser(id);
      toast.success(response.messages[0]);
    }
  };
  // componentDidMount = () => {
  //   let { socket } = this.context;
  //   if (socket) {
  //     socket.on(SocketEvents.NEW_SCAN_ADDED, (data) => {
  //       console.log("event emitted", data);
  //       // this.props.history.push(AppRoutes.SCANS_LIST);
  //       let newData = [...this.state.scans];
  //       const alreadyExistIndex = newData.findIndex(
  //         (item) => item.scanId === data[0].scanId
  //       );
  //       if (alreadyExistIndex > -1) {
  //         newData.splice(alreadyExistIndex, 1);
  //         newData.unshift(...data);
  //       } else {
  //         newData.splice(-1, 1);
  //         newData.unshift(...data);
  //       }
  //       this.setState({ scans: newData });
  //     });
  //   }
  //   this.handleQueryParams();
  // };
  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => this.fetchScanRecords()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchScanRecords = async (id) => {
    const { currentPage, sortBy, search, limit, newRecord, order } = this.state;
    const { currentScanPage } = this.state;
    let skip = scanLimit * (currentScanPage - 1);
    let data = {
      skip,
      limit: scanLimit,
      sortBy,
      search,
      order,
      lat,
      long,
      userId: id,
    };
    this.setState({
      isScanLoading: newRecord ? false : true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SCANS_LIST.service,
      ApiRoutes.SCANS_LIST.url,
      ApiRoutes.SCANS_LIST.method,
      ApiRoutes.SCANS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isScanLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { scans, totalRecords },
      } = response.data;
      console.log("scan request", response.data.data);

      this.setState({ scans, totalScanRecords: totalRecords });
    }
  };

  setSortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  sortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  fetchCounterfeitRecords = async (id) => {
    const {
      currentCounterfeitsPage,
      counterOrder,
      counterSortBy,
      sortBy,
      order,
    } = this.state;
    // const { currentCounterfeitsPage } = this.state;
    let skip = counterfeitLimit * (currentCounterfeitsPage - 1);
    let data = {
      skip,
      limit: counterfeitLimit,
      userId: id,
      counterOrder: order,
      counterSortBy: sortBy,
    };
    this.setState({
      isCounterfeitLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.COUNTERFEIT_LIST.service,
      ApiRoutes.COUNTERFEIT_LIST.url,
      ApiRoutes.COUNTERFEIT_LIST.method,
      ApiRoutes.COUNTERFEIT_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isCounterfeitLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { counterfeits, totalRecords },
      } = response.data;
      console.log("here ", response);
      this.setState({ counterfeits, totalCounterfeitsRecords: totalRecords });
    }
  };
  onPageChanged = (page) => {
    const { currentScanPage, userId } = this.state;
    if (page !== currentScanPage) {
      this.setState(
        {
          currentScanPage: page,
        },
        () => this.fetchScanRecords(userId)
      );
    }
  };
  onCounterfeitPageChanged = (page) => {
    const { currentCounterfeitsPage, userId } = this.state;
    if (page !== currentCounterfeitsPage) {
      this.setState(
        {
          currentCounterfeitsPage: page,
        },
        () => this.fetchCounterfeitRecords(userId)
      );
    }
  };
  customBreadCrumb = () => {
    const {
      isLoading,
      userInfo: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      [first_name, last_name].filter(Boolean).join(" ") || "View user"
    );
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  render() {
    const {
      userInfo: user,
      isLoading,
      scans,
      isScanLoading,
      currentScanPage,
      totalScanRecords,
      counterfeits,
      totalCounterfeitsRecords,
      currentCounterfeitsPage,
      isCounterfeitLoading,
      showImage = true,
      setFilter,
      sortBy,
      order,
      setSortFilter,
      counterOrder,
      counterSortBy,
    } = this.state;
    const {
      props: {
        location: { pathname, state },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
              search={state ? state.search : ""}
            />
          </h3>
        </div>
        <div className="tableFilter d-flex align-items-center justify-content-between mb-4 mt-0">
          <div className="tableFilter pl-0 mb-0 mt-0">
            <h3 className="m-0">User Details</h3>
          </div>

          <div className="user_detail">
            <Dropdown className="no-arrow dp-right customDropdownBox">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className={
                  "actionBtn d-flex align-items-center justify-content-center"
                }
              >
                <span>Action</span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                {user.email ? (
                  <Dropdown.Item
                    as="div"
                    onClick={() => {
                      window.open(`mailto:${user.email}`);
                    }}
                  >
                    Contact User
                  </Dropdown.Item>
                ) : (
                  <OverlayTrigger
                    placement="auto"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(p) => (
                      <Tooltip id="button-tooltip" {...p}>
                        This user doesn't have an email set with the account.
                      </Tooltip>
                    )}
                  >
                    <Dropdown.Item as="div">Contact User</Dropdown.Item>
                  </OverlayTrigger>
                )}
                <Dropdown.Item
                  as="div"
                  onClick={() =>
                    user.block
                      ? this.unBlockUser(user.id)
                      : this.blockUser(user.id)
                  }
                >
                  {user.block ? "Unblock" : "Block"} User
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="">
          <div className="productFormBox mb-4">
            {isLoading ? (
              <ViewInputLoader />
            ) : (
              <form className="user_add">
                <div className="row">
                  <div className="col-md-3 mob-show">
                    <div className="img-col-user">
                      <img
                        className="user-img"
                        // src={
                        //   user.profile_picture
                        //     ? `${AppConfig.FILES_ENDPOINT}${user.profile_picture}`
                        //     : defaultImg
                        // }
                        src={
                          user.profile_picture
                            ? user.profile_picture.startsWith("https://")
                              ? user.profile_picture
                              : `${AppConfig.FILES_ENDPOINT}${user.profile_picture}`
                            : defaultImg
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            htmlFor="first_name"
                            className="form-control-label"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="first_name"
                            aria-describedby="first_nameHelp"
                            defaultValue={user.first_name || ""}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            htmlFor="last_name"
                            className="form-control-label"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control "
                            id="last_name"
                            aria-describedby="last_nameHelp"
                            defaultValue={user.last_name || ""}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="phon" className="form-control-label">
                            Phone Number
                          </label>
                          <input
                            type="email"
                            className="form-control "
                            id="phon"
                            aria-describedby="phoneHelp"
                            defaultValue={user.phone || ""}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="email" className="form-control-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            aria-describedby="emailHelp"
                            defaultValue={user.email || ""}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            htmlFor="address_line_1"
                            className="form-control-label"
                          >
                            Address Line 1
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address_line_1"
                            aria-describedby="address_line_1Help"
                            defaultValue={
                              user.other_info &&
                              (user.other_info.address1 || "")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 mob-hide">
                    <div className="img-col-user">
                      <img
                        className="user-img"
                        src={
                          user.profile_picture
                            ? user.profile_picture.startsWith("https://")
                              ? user.profile_picture
                              : `${AppConfig.FILES_ENDPOINT}${user.profile_picture}`
                            : defaultUserImg
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultUserImg;
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>

        <div className="">
          <div className="tableFilter mb-4">
            <h3 className="mb-0">Scan Details</h3>
          </div>
          <div className="tableCard card">
            <div className="tabel-outer">
              <div className="table-responsive fixed-height-table">
                {isScanLoading ? (
                  <TableLoader columns={5} rows={5} />
                ) : (
                  <table
                    className="table batchTable intableCheckbox scroll table"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead className="thead-dash pad-top">
                      <tr className="batchesHeader">
                        <th>
                          Scan ID
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                sortBy === "scan_id"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.setFilter(
                                  "scan_id",
                                  sortBy === "scan_id"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Batch Number
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                sortBy === "batchNumber"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.setFilter(
                                  "batchNumber",
                                  sortBy === "batchNumber"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Product Name
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                sortBy === "productName"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.setFilter(
                                  "productName",
                                  sortBy === "productName"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Location
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                sortBy === "other_info.address1"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.setFilter(
                                  "other_info.address1",
                                  sortBy === "other_info.address1"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Scan Time
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                sortBy === "createdAt"
                                  ? order === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.setFilter(
                                  "createdAt",
                                  sortBy === "createdAt"
                                    ? order === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="tbody-dash">
                      {scans && scans.length ? (
                        scans.map((scan, index) => (
                          <ScanRow
                            key={index}
                            scanData={scan}
                            userColumn={false}
                          />
                        ))
                      ) : (
                        <tr className="no-tr">
                          <td
                            colSpan={5}
                            className={
                              "text-center image-no-data-sm nodataTablePosition"
                            }
                          >
                            User hasn't scanned any product yet.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-pg px-3 float-left w-100">
          {!isLoading && totalScanRecords > scanLimit ? (
            <>
              <div className="float-left mt-4 mt-lg-4 ">
                <div>
                  <p className="totalRecords">
                    Page {totalScanRecords > 0 ? currentScanPage : 0} of{" "}
                    {totalScanRecords % scanLimit > 0
                      ? parseInt(totalScanRecords / scanLimit) + 1
                      : parseInt(totalScanRecords / scanLimit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                <Pagination
                  totalRecords={totalScanRecords}
                  currentPage={currentScanPage}
                  pageLimit={scanLimit}
                  onPageChanged={this.onPageChanged}
                />
              </div>
            </>
          ) : null}
        </div>

        <div className="mb-3 float-left w-100">
          <div className="tableFilter mt-3 mb-0">
            <h3 className="mb-3">Counterfeit Details</h3>
          </div>
          <div className="tableCard card">
            <div className="tabel-outer">
              <div className="table-responsive fixed-height-table">
                {isCounterfeitLoading ? (
                  <TableLoader columns={5} rows={5} />
                ) : (
                  <table
                    className="scroll table batchTable intableCheckbox"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead className="thead-dash pad-top">
                      <tr className="batchesHeader">
                        <th>
                          Scan/Report ID
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                counterSortBy === "scanId"
                                  ? counterOrder === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.sortFilter(
                                  "scanId",
                                  counterSortBy === "scanId"
                                    ? counterOrder === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Batch Number
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                counterSortBy === "batchNumber"
                                  ? counterOrder === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.sortFilter(
                                  "batchNumber",
                                  counterSortBy === "batchNumber"
                                    ? counterOrder === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Product Name
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                counterSortBy === "productName"
                                  ? counterOrder === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.sortFilter(
                                  "productName",
                                  counterSortBy === "productName"
                                    ? counterOrder === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                        <th>
                          Request Time
                          <span className={"cursor-pointer"}>
                            <img
                              src={
                                counterSortBy === "status"
                                  ? counterOrder === "desc"
                                    ? dropDownActive
                                    : dropUpActive
                                  : dropDown
                              }
                              alt=""
                              onClick={() =>
                                this.sortFilter(
                                  "status",
                                  counterSortBy === "status"
                                    ? counterOrder === "asc"
                                      ? "desc"
                                      : "asc"
                                    : "asc"
                                )
                              }
                            />
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="tbody-dash">
                      {counterfeits && counterfeits.length ? (
                        counterfeits.map((counterfeit, index) => (
                          <tr
                            key={index}
                            className="scan btachRow history counter_feit"
                          >
                            <td>
                              <span class="textContol">
                                {counterfeit.scan
                                  ? counterfeit.scan.scanId
                                  : counterfeit.reportId}
                              </span>
                            </td>
                            <td>
                              <span class="textContol">
                                {counterfeit.batch
                                  ? counterfeit.batch.batchNumber
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span class="textContol">
                                {counterfeit.product
                                  ? counterfeit.product.productName
                                  : "-"}
                              </span>
                            </td>
                            <td>
                              <span class="textContol">
                                {counterfeit.requestDate
                                  ? [
                                      counterfeit.requestDateTime,
                                      counterfeit.requestDateFormat,
                                    ].join(" | ")
                                  : "-"}
                              </span>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="no-tr">
                          <td
                            colSpan={5}
                            className={
                              "text-center image-no-data-sm nodataTablePosition"
                            }
                          >
                            <>
                              <img src={noData} alt="" />
                              <p>User hasn't reported any product yet.</p>
                            </>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-pg px-3">
          {!isLoading && totalCounterfeitsRecords > counterfeitLimit ? (
            <>
              <div className="float-left mt-4 mt-lg-4 ">
                <div>
                  <p className="totalRecords">
                    Page {totalCounterfeitsRecords > 0 ? currentScanPage : 0} of{" "}
                    {totalCounterfeitsRecords % counterfeitLimit > 0
                      ? parseInt(totalCounterfeitsRecords / counterfeitLimit) +
                        1
                      : parseInt(
                          totalCounterfeitsRecords / counterfeitLimit
                        )}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                <Pagination
                  totalRecords={totalCounterfeitsRecords}
                  currentPage={currentCounterfeitsPage}
                  pageLimit={counterfeitLimit}
                  onPageChanged={this.onCounterfeitPageChanged}
                />
              </div>
            </>
          ) : null}
        </div>
        {/* {!isLoading && totalCounterfeitsRecords > counterfeitLimit ? (
          <div className="row">
            <div className="col-12">
              <div className="float-right mb-4">
                <Pagination
                  totalRecords={totalCounterfeitsRecords}
                  currentPage={currentCounterfeitsPage}
                  pageLimit={counterfeitLimit}
                  onPageChanged={this.onCounterfeitPageChanged}
                />
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    );
  }
}

export default ViewUser;
