import React, { useEffect, useState } from "react";
import logo_demo from "../../../../assets/img/logo_demo.svg";
import info_btn from "../../../../assets/img/info_btn.svg";
import eyeview from "../../../../assets/img/eyeview.svg";
import hideview from "../../../../assets/img/accordionHide.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Accordion from "react-bootstrap/Accordion";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import moment from "moment";
import { SessionLoader2 } from "../../loader/Session/SessionLoader2";

function AccountHistory() {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        View and manage all of your Clarity client account activity.
      </Popover.Body>
    </Popover>
  );

  const [deviceDetail, setDeviceHistory] = useState();
  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    getHistory();
  }, []);
  const getHistory = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DEVICE_HISTORY.service,
      ApiRoutes.GET_DEVICE_HISTORY.url,
      ApiRoutes.GET_DEVICE_HISTORY.method,
      ApiRoutes.GET_DEVICE_HISTORY.authenticate,
      undefined
    );
    setPageLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
      // Logout();
    } else {
      const data = response?.data?.data?.devices;
      setDeviceHistory(data);
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings accountHistory_box"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Account History</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row">
              {pageLoader ? (
                <SessionLoader2 />
              ) : (
                <div className="col-md-12 accordianBox">
                  <Accordion defaultActiveKey="0">
                    {deviceDetail?.map((info, index) => (
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center">
                              <img style={{ width: "46px" }} src={logo_demo} />
                              <h4 style={{ marginLeft: "12px" }}>
                                Client Portal
                              </h4>
                            </div>
                            <h4 className="browser">{info.browser}</h4>
                            <h4 className="location">
                              {info.city}, {info.state}, {info.country}
                            </h4>
                            <div>
                              <img src={eyeview} className="showAccordion" />
                              <img src={hideview} className="hideAccordion" />
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="row">
                            <div className="col-md-4">
                              <h5>Sign In</h5>
                              <p>
                                {info.signInTimeDate} {info.signInTimeFormat}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <h5>Sign Out</h5>
                              <p>
                                {info.signOutTimeDate} {info.signOutTimeFormat}
                              </p>
                            </div>
                            <div className="col-md-4">
                              <h5>Browser</h5>
                              <p>{info.browser}</p>
                            </div>
                            <div className="col-md-4">
                              <h5>Operating System</h5>
                              <p>{info.name}</p>
                            </div>
                            <div className="col-md-4">
                              <h5>Device</h5>
                              <p>{info.device}</p>
                            </div>
                            <div className="col-md-4">
                              <h5>Location</h5>
                              <p>
                                {info.city}, {info.state}, {info.country}
                              </p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccountHistory;
