import { initializeApp } from "firebase/app";

// Clarity config
const firebaseConfig = {
  apiKey: "AIzaSyBzt6EjzjpnvgEAtDn8cbT4sWaEf-LrXaI",
  authDomain: "clarity-283412.firebaseapp.com",
  databaseURL: "https://clarity-283412.firebaseio.com",
  projectId: "clarity-283412",
  storageBucket: "clarity-283412.appspot.com",
  messagingSenderId: "843509473707",
  appId: "1:843509473707:web:8802abfe0ac3487c4872ee",
};

initializeApp(firebaseConfig);