import React from "react";
import Skeleton from "react-skeleton-loader";

export const BatchInputLoader = () => (
  <>
    {" "}
    <form className='product_add'>
      <div className='row'>
        
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 order-2 order-lg-0 mt-5 mt-lg-0'>
          <div className='row'>
            <div className='col-md-4 skeleton-bottom mb-4'>
            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
            <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-4 skeleton-bottom mb-4'>

            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
              <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-4 skeleton-bottom mb-4'>

            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
              <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-4 skeleton-bottom '>

            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
              <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-4 skeleton-bottom'>

            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
              <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            <div className='col-md-4 skeleton-bottom'>

            <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
              <Skeleton color ="#e5e9ef" height={"35px"} width={"100%"}/>
              
            </div>
            
          </div>
        </div>
        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mob-show'>
        <div className='img-col-product-view'>
                <Skeleton color ="#e5e9ef" height={"200px"} width={"100%"}/>
                </div>
               
        </div>
        {/* <div className='col-md-3 mob-hide'>
                <div className='img-col-product-view'>
                <Skeleton color ="#e5e9ef" height={"200px"} width={"100%"}/>
                </div>
               
              
         </div> */}
        

        <div className='col-md-12 footer-form'>
              <Skeleton  color ="#e5e9ef" width={"35%"} height={"40px"} />

               <Skeleton color ="#e5e9ef" width={"35%"} height={"40px"} />
              </div>
      </div>
    </form>


    
  </>
);
