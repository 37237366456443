import React, { Component } from "react";

import { Link } from "react-router-dom";
// import Chart from "react-apexcharts";
import "./index.css";
import MemberData from "./MemberData";
import ScanData from "./ScanData";
import CounterfietStatic from "./CounterfietStatic";
import ProductStatics from "./ProductStatics";
import CalendarCustom from "../../components/dashboard/CalendarCustom";
import UpcomingEvents from "../../components/dashboard/UpcomingEvents";
import CreatePlanModal from "../../components/dashboard/CreatePlanModal";
import { ListComponentDashboard } from "../../components/scans/ListComponentDashboard";
import Notification from "./Notification";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import PHLContext from "../../../PHLContext";
import debounce from "lodash.debounce";
import MapCustom from "../../components/dashboard/Map";
import moment from "moment";

let lat, long;

class Home extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      scans: [],
      isLoading: false,
      sortBy: "created_at",
      order: "desc",
      search: "",
      newRecord: false,
      events: [],
      allEvents: [],
      calenderEvent: [],
      filterDateStore: null,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.getUpcomingevents();
    let { socket } = this.context;
    if (socket) {
      socket.on(SocketEvents.NEW_SCAN_ADDED, (data) => {
        let newData = [...this.state.scans];
        const alreadyExistIndex = newData.findIndex(
          (item) => item.scanId === data[0].scanId
        );
        if (alreadyExistIndex > -1) {
          newData.splice(alreadyExistIndex, 1);
          newData.unshift(...data);
        } else {
          newData.splice(-1, 1);
          newData.unshift(...data);
        }
        this.setState({ scans: newData });
      });
    }
    this.handleQueryParams();
  };

  getUpcomingevents = () => {
    this.getUpcomingEvent();
  };

  handleQueryParams = async () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    if (query && query.sort === "location") {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      lat = position.coords.latitude;
      long = position.coords.longitude;
    }
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order
          ? query.order
          : // : query.sort === 'scan_id' || query.sort === 'created_at'
            // ? 'desc'
            "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => {
        this.fetchScanRecords();
      }
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchScanRecords = async () => {
    const { currentPage, sortBy, search, limit, newRecord, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
      lat,
      long,
    };
    this.setState({
      isLoading: newRecord ? false : true,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SCANS_LIST.service,
      ApiRoutes.SCANS_LIST.url,
      ApiRoutes.SCANS_LIST.method,
      ApiRoutes.SCANS_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { scans, totalRecords },
      } = response.data;
      this.setState({ scans, totalRecords, newRecord: false });
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onSearch = () => {
    const { search } = this.state;
    console.log("here is onSearch", search);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    console.log("here is query", query);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  componentWillUnmount = () => {
    this.emitSearch.cancel();
  };

  batchViewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_BATCH.replace(":id", id));
  };
  userViewHandler = (id) => {
    this.props.history.push(AppRoutes.VIEW_USER.replace(":id", id));
  };

  getUpcomingEvent = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_UPCOMING_EVENT.service,
      ApiRoutes.GET_UPCOMING_EVENT.url,
      ApiRoutes.GET_UPCOMING_EVENT.method,
      ApiRoutes.GET_UPCOMING_EVENT.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      const eventData = response.data.planDataDateCount;
      this.setState({
        allEvents: data,
        events: data,
      });
      this.setState({
        calenderEvent: eventData,
      });
    }
  };

  getFilterDate = (date) => {
    let filterDate = [];
    let dateSelected = moment(date).format("DD-MM-YYYY");
    if (this.state.filterDateStore === dateSelected) {
      this.setState({ filterDateStore: null });
      filterDate = this.state.allEvents;
    } else {
      this.setState({ filterDateStore: dateSelected });
      filterDate = this.state.allEvents.filter(
        (x) => moment(x.eventDate).format("DD-MM-YYYY") === dateSelected
      );
    }
    this.setState({ events: filterDate });
  };

  submitTrigger = (value) => {
    if (value === true) {
      this.getUpcomingevents();
    }
  };
  render() {
    const { scans, isLoading, sortBy, order, events, calenderEvent } =
      this.state;

    return (
      <>
        <div className="container-fluid dashboardPage mb-5">
          <div className="row">
            <div className="col-md-12 col-lg-7 col-xl-8">
              <div className="row">
                <MemberData />
                <div className="col-md-6 col-lg-12 col-xl-6 mt-4">
                  <ScanData />
                </div>

                <div className="col-md-6 col-lg-12 col-xl-6 mt-4">
                  <CounterfietStatic />
                </div>

                <div className="col-md-6 col-lg-12 col-xl-6 mt-4">
                  <ProductStatics />
                </div>

                <div className="col-md-6 col-lg-12 col-xl-6 mt-4">
                  <Notification />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-5 col-xl-4 mt-4 mt-lg-0">
              <div className="row">
                <div className="col-md-12">
                  <CalendarCustom
                    isLoading={this.state.isLoading}
                    calenderEvent={calenderEvent}
                    getFilterDate={this.getFilterDate}
                  />
                </div>

                <div className="col-md-12  mt-4">
                  <UpcomingEvents
                    isLoading={this.state.isLoading}
                    events={events}
                    submitTrigger={this.submitTrigger}
                  />
                </div>
              </div>
            </div>

            {/* Map ui  */}
            <div className="col-md-12 mt-4">
              <MapCustom />
            </div>

            <div className="col-md-12 mt-4">
              <div className="d-flex align-items-center justify-content-between mb-3 mt-2">
                <h3 className="dashboard_recentscan">Recent Scans</h3>
                <Link
                  to={AppRoutes.SCANS}
                  className="addProductbtn no-background text-center px-0 d-flex align-items-center justify-content-center"
                  style={{ minWidth: "100px", height: "40px" }}
                >
                  See All
                </Link>
              </div>
              <div className="tableCard card mb-4">
                <ListComponentDashboard
                  scans={scans}
                  isLoading={isLoading}
                  onPageChanged={this.onPageChanged}
                  batchViewHandler={this.batchViewHandler}
                  userViewHandler={this.userViewHandler}
                  setFilter={this.setFilter}
                  order={order}
                  sortBy={sortBy}
                />
              </div>
            </div>
          </div>
        </div>
        <CreatePlanModal />
      </>
    );
  }
}
export default Home;
