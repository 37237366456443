import React, { useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import closepassList from "../../../../assets/img/closepassList.svg";
import info_btn from "../../../../assets/img/info_btn.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "react-phone-number-input/style.css";
import { ApiHelper, Logout } from "../../../../helpers";
import { ApiRoutes } from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { changePasswordValid } from "../../../../validators/changePassword";
import { PasswordSettingLoader } from "../../loader/HelpLoaders/PasswordSettingLoader";
import SweetAlertComponent from "../../sweetAlertComponent";

function PasswordSetting() {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        View the list of devices that have signed into your Clarity client
        account.
      </Popover.Body>
    </Popover>
  );

  const [deviceDetail, setDeviceDetail] = useState();

  const [userChangePassword, setUserChangePassword] = useState({});
  const [passwordError, setPasswordError] = useState({});
  const [saveLoader, setSaveLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setPageLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
      Logout();
    } else {
      const data = response?.data?.data?.user?.id;
      setUserChangePassword({
        ...userChangePassword,
        sub: data,
      });
      getDeviceDetail();
    }
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setUserChangePassword({
      ...userChangePassword,
      [name]: value.trimLeft(),
    });
    setPasswordError({
      ...passwordError,
      [name]: "",
    });
  };

  const handleCancel = () => {
    setPasswordError();
    getDeviceDetail();
    setUserChangePassword({
      ...userChangePassword,
      oldPassword: "",
      newPassword: "",
      cnfPassword: "",
    });
  };

  const handleSubmit = async (event) => {
    event && event.preventDefault();

    let data = {
      oldPassword: userChangePassword?.oldPassword,
      newPassword: userChangePassword?.newPassword,
      sub: userChangePassword?.sub,
      cnfPassword: userChangePassword?.cnfPassword,
    };

    let { isValid, errors } = changePasswordValid(data);
    if (isValid) {
      if (userChangePassword?.newPassword !== userChangePassword?.cnfPassword) {
        setPasswordError({
          ...passwordError,
          cnfPassword: "Password didn't match",
        });
        return;
      }
      setSaveLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.CHANGE_PASSWORD.service,
        ApiRoutes.CHANGE_PASSWORD.url,
        ApiRoutes.CHANGE_PASSWORD.method,
        ApiRoutes.CHANGE_PASSWORD.authenticate,
        undefined,
        data
      );
      setSaveLoader(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        toast.success(response.messages[0]);
        setPasswordError();
        setUserChangePassword({
          ...userChangePassword,
          oldPassword: "",
          newPassword: "",
          cnfPassword: "",
        });
        getProfile();
      }
    } else {
      setPasswordError(errors);
      setSaveLoader(false);
    }
  };
  const getDeviceDetail = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SIGNIN_DETAIL.service,
      ApiRoutes.GET_SIGNIN_DETAIL.url,
      ApiRoutes.GET_SIGNIN_DETAIL.method,
      ApiRoutes.GET_SIGNIN_DETAIL.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      Logout();
    } else {
      const data = response?.data?.data?.devices;
      setDeviceDetail(data);
    }
  };

  const deleteDetail = async (data) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure you want to remove this device ?`,
    });
    if (!value) {
      return;
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SIGNIN_DETAIL.service,
      ApiRoutes.DELETE_SIGNIN_DETAIL.url.replace(":id", data.id),
      ApiRoutes.DELETE_SIGNIN_DETAIL.method,
      ApiRoutes.DELETE_SIGNIN_DETAIL.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      getDeviceDetail();
      // history.push(AppRoutes.SECURITY)
    }
  };

  return (
    <>
      <div className="row" style={{ marginTop: "55px" }}>
        <div className="col-md-12">
          <div
            className="productFormBox passSettings profileMainBox"
            style={{ marginBottom: "30px" }}
          >
            <h4>Password Settings</h4>

            {pageLoader ? (
              <PasswordSettingLoader />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <p>
                    We suggest you change your password frequently to avoid any
                    security threats
                  </p>
                </div>
                <div className="col-md-12">
                  <form className="profileForm">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <label className={`addBatch-Labels`}>
                                Current Password
                              </label>
                              <InputTextFeild
                                name="oldPassword"
                                type="password"
                                placeholder="Enter your old password"
                                value={userChangePassword?.oldPassword}
                                onChange={handleChange}
                                errorMessage={passwordError?.oldPassword}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <label className={`addBatch-Labels`}>
                                New Password
                              </label>
                              <InputTextFeild
                                name="newPassword"
                                type="password"
                                placeholder="Enter your new password"
                                value={userChangePassword?.newPassword}
                                onChange={handleChange}
                                errorMessage={passwordError?.newPassword}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <label className={`addBatch-Labels`}>
                                Confirm Password
                              </label>
                              <InputTextFeild
                                name="cnfPassword"
                                type="password"
                                placeholder="Confirm Password"
                                value={userChangePassword?.cnfPassword}
                                onChange={handleChange}
                                errorMessage={passwordError?.cnfPassword}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="d-flex mt-3 align-items-center justify-content-center">
                          <>
                            <button
                              className="logout-cancel mr-2"
                              type="button"
                              onClick={handleCancel}
                              style={{ width: "178px" }}
                            >
                              Cancel
                            </button>
                            <button
                              disabled={saveLoader}
                              onClick={handleSubmit}
                              className="logout-btn"
                              style={{ width: "178px" }}
                            >
                              {saveLoader ? (
                                <>
                                  <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                                  Save
                                </>
                              ) : (
                                "Save"
                              )}
                            </button>
                          </>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="productFormBox passSettings profileMainBox">
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Device Sign In</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} alt="" />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row">
              {pageLoader ? (
                <div className="col-md-12">
                  <PasswordSettingLoader />
                </div>
              ) : (
                <div className="col-md-12">
                  {deviceDetail &&
                    deviceDetail?.map((info, index) => (
                      <div className="row customGrid" key={index}>
                        <div className="col-md-3">
                          <h3 className="text-capitalize">{info.name}</h3>
                        </div>
                        <div className="col-md-5">
                          <h4>
                            {info.city}, {info.state}, {info.country}
                          </h4>
                        </div>
                        <div className="col-md-4 text-right">
                          <button onClick={() => deleteDetail(info)}>
                            <img src={closepassList} alt="" />
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PasswordSetting;
