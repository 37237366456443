import React from "react";
import Skeleton from "react-skeleton-loader";

export const LoaderSubscriptionSlider = () => (
  <>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <div className="planGrid">
            <h3 className="text-capitalize">
              <Skeleton height={"29px"} width={"150px"} color="#e5e9ef" />
            </h3>
            <p>
              <Skeleton height={"24px"} width={"200px"} color="#e5e9ef" />
            </p>
            <h4>
              <Skeleton height={"29px"} width={"100px"} color="#e5e9ef" />
            </h4>
            <ul>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
              <span className="ml-4">
                <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
              </span>
            </ul>
            <div
              className="btn_wrap loaderBtn"
              // style={{ padding: "0 11px", marginTop: "40px" }}
              style={{ bottom: "-12px" }}
            >
              <Skeleton height={"48px"} width={""} color="#e5e9ef" />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="planGrid">
            <h3 className="text-capitalize">
              <Skeleton height={"29px"} width={"150px"} color="#e5e9ef" />
            </h3>
            <p>
              <Skeleton height={"24px"} width={"200px"} color="#e5e9ef" />
            </p>
            <h4>
              <Skeleton height={"29px"} width={"100px"} color="#e5e9ef" />
            </h4>
            <ul>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
              <span className="ml-4">
                <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
              </span>
            </ul>
            <div
              className="btn_wrap loaderBtn"
              // style={{ padding: "0 11px", marginTop: "40px" }}
              style={{ bottom: "-12px" }}
            >
              <Skeleton height={"48px"} width={""} color="#e5e9ef" />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="planGrid">
            <h3 className="text-capitalize">
              <Skeleton height={"29px"} width={"150px"} color="#e5e9ef" />
            </h3>
            <p>
              <Skeleton height={"24px"} width={"200px"} color="#e5e9ef" />
            </p>
            <h4>
              <Skeleton height={"29px"} width={"100px"} color="#e5e9ef" />
            </h4>
            <ul>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <li>
                <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
                <span className="ml-4">
                  <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
                </span>
              </li>
              <Skeleton height={"20px"} width={"30px"} color="#e5e9ef" />
              <span className="ml-4">
                <Skeleton height={"20px"} width={"200px"} color="#e5e9ef" />
              </span>
            </ul>
            <div
              className="btn_wrap loaderBtn"
              // style={{ padding: "0 11px", marginTop: "40px" }}
              style={{ bottom: "-12px" }}
            >
              <Skeleton height={"48px"} width={""} color="#e5e9ef" />
            </div>
          </div>
        </div>
        {/* <div className="col-md-12">
          <div 
          className="d-flex justify-content-center">
            <Skeleton height={"5px"} width={"80px"} color="#e5e9ef" />
          </div>
        </div> */}
      </div>
    </div>
  </>
);
