import React from "react";
import AccountHistory from "../../components/accountSetting/profile/AccountHistory";
import ActiveSession from "../../components/accountSetting/profile/ActiveSession";

function SessionModule() {
  return (
    <div className="container-fluid fluid-pd-2">
      <div className="row">
        <div className="col-md-12">
          <ActiveSession />
        </div>
        <div className="col-md-12">
          <AccountHistory />
        </div>
      </div>
    </div>
  );
}

export default SessionModule;
