import React from "react";

function SubmitButton({
  text = "",
  className = "",
  isLoading,
  onSubmit,
  disabled,
}) {
  return (
    <button
      className={`btn ${className}`}
      type="submit"
      disabled={isLoading || disabled}
      onClick={onSubmit}
    >
      {isLoading ? (
        <>
          <i className="fa fa-spinner fa-spin test-spin  mr-1"></i> {text}
        </>
      ) : (
        <>{text}</>
      )}
    </button>
  );
}

export default SubmitButton;
