import Validator from "js-object-validation";
import { message } from "../common";

export const changePasswordValid = (data) => {
  const validations = {
    oldPassword: {
      required: true,
    },
    newPassword: {
      required: true,
      password: true,
    },
    cnfPassword: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    oldPassword: {
      required: message.Required.replace(":item", "old password"),
    },
    newPassword: {
      required: message.Required.replace(":item", "new password"),
      password: message.Password.replace(":item", "password"),
    },
    cnfPassword: {
      required: message.Required.replace(":item", "confirm password"),
      // equal:message.PasswordMatch
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
