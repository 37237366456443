import React, { useEffect, useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import downloadGreen from "../../../../assets/img/downloadGreen.svg";
import crossIcon from "../../../../assets/img/closeModal.svg";
import dltIcon from "../../../../assets/img/trash-22.svg";
import info_btn from "../../../../assets/img/info_btn.svg";
import plusIcon from "../../../../assets/img/plusIconWhite.svg";
// import { Button, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SubmitButton from "../../form/SubmitButton";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { BatchViewLoader } from "../../loader/Resusable/BatchView";

function Alerts() {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        Receive email alerts whenever your account is signed in from a new
        device, browser, or location.
      </Popover.Body>
    </Popover>
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [status, setStatus] = useState();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setIsUpdating(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setIsUpdating(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.emailAlert;
      setStatus(data);
    }
  };

  const enableEmailAlert = async (data) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CLIENT_EMAIL_ALERT.service,
      ApiRoutes.CLIENT_EMAIL_ALERT.url,
      ApiRoutes.CLIENT_EMAIL_ALERT.method,
      ApiRoutes.CLIENT_EMAIL_ALERT.authenticate,
      undefined,
      data
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      getProfile();
      setStatus(response?.data?.data?.emailAlert);
      toast.success(response?.data?.message);
    }
  };

  const handleChange = (event) => {
    const {
      target: { checked },
    } = event;

    setStatus(checked);
    let data = {
      status: checked,
    };
    enableEmailAlert(data);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings alertCard"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Alerts</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            {isUpdating ? (
              <BatchViewLoader />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col-md-12">
                      <h5>Sign In Alerts</h5>
                      <p>
                        We will send you a notification on your email to verify
                        if it’s you or someone else
                      </p>
                      <div className="switchBox d-flex align-items-center">
                        <h3>Want to receive email alert?</h3>
                        <label className="switch">
                          <input
                            name="status"
                            onChange={handleChange}
                            checked={status}
                            type="checkbox"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Alerts;
