import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import InputTextFeild from "../form/InputTextFeild";
import createPlan from "../../../assets/img/flags/createPlan.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Modal } from "react-bootstrap";
import SubmitButton from "../form/SubmitButton";
import { ApiRoutes, AppRoutes, DB_ACCPTABLE_FORMAT } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { planValidator } from "../../../validators";
import { useHistory } from "react-router";
import closeModalCreatePlan from "../../../assets/img/closeModalCreatePlan.svg";
import moment from "moment";
import { useEffect } from "react";
import { useRef } from "react";
import SweetAlertComponent from "../sweetAlertComponent";

export const UpdatePlanModal = ({
  planModalShow,
  planModalClose,
  getUpcomingEvent,
  submitTrigger,
  eventDetail,
}) => {
  const [planData, setPlanData] = useState({
    title: "",
    eventDate: "",
    emailReminder: null,
    eventDescription: "",
    priority: "",
    errors: null,
  });
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const options = [
    { value: "Lowest", label: "Lowest" },
    { value: "Low", label: "Low" },
    { value: "Moderate", label: "Moderate" },
    { value: "High", label: "High" },
    { value: "Highest", label: "Highest" },
  ];

  const handleSelectChange = (event) => {
    console.log(event);
    const {
      target: { value, name },
    } = event;

    setPlanData({
      ...planData,
      [name]: value && value === "yes" ? true : false,
    });
  };

  const onSelect = (label) => {
    if (label) {
      setPlanData({
        ...planData,
        priority: label,
      });
    }
  };

  useEffect(() => {
    if (eventDetail && eventDetail.plan) {
      setPlanData({
        title: eventDetail.plan.title,
        eventDate: eventDetail.plan.eventDate
          ? new Date(eventDetail.plan.eventDate)
          : null,
        eventDescription: eventDetail.plan.eventDescription,
        priority: options.filter(
          (x) => x.value === eventDetail.plan.priority
        )[0],
        emailReminder: eventDetail.plan.emailReminder,
        errors: null,
      });
    }
  }, [eventDetail]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setPlanData({
      ...planData,
      [name]: value.trimLeft(),
      errors: { ...planData.errors, [name]: "" },
    });
  };
  const updatePlan = async (payload) => {
    setIsAdding(true);
    const id = eventDetail.plan.id;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_EVENT_DETAIL.service,
      ApiRoutes.UPDATE_EVENT_DETAIL.url.replace(":id", id),
      ApiRoutes.UPDATE_EVENT_DETAIL.method,
      ApiRoutes.UPDATE_EVENT_DETAIL.authenticate,
      undefined,
      payload
    );
    setIsAdding(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
      submitTrigger(false);
    } else {
      submitTrigger(true);
      toast.success(response.data.message);
      planModalClose();
    }
  };

  const handleSubmit = async (event) => {
    event && event.preventDefault();

    let data = {
      title: planData.title,
      eventDate: planData.eventDate
        ? moment(planData.eventDate).format(DB_ACCPTABLE_FORMAT)
        : "",
      emailReminder: planData.emailReminder,
      eventDescription: planData.eventDescription,
      priority:
        planData.priority && planData.priority.value
          ? planData.priority.value
          : null,
    };

    let { isValid, errors } = planValidator(data);

    if (isValid) {
      updatePlan(data);
    }
  };

  const handleDateChange = (date) => {
    setPlanData({
      ...planData,
      eventDate: date,
    });
  };

  const deleteEvent = async (id) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this Event?`,
    });
    if (!value) {
      return;
    }

    setIsLoading(true);

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_EVENT_DETAIL.service,
      ApiRoutes.DELETE_EVENT_DETAIL.url.replace(":id", id),
      ApiRoutes.DELETE_EVENT_DETAIL.method,
      ApiRoutes.DELETE_EVENT_DETAIL.authenticate,
      undefined
    );
    setIsLoading(false);

    if (response && response.isError) {
      toast.error(response.messages[0]);
      submitTrigger(false);
    } else {
      submitTrigger(true);
      toast.success(response.messages[0]);
      planModalClose(true);
    }
  };

  const tomorrow = new Date();

  return (
    <>
      <Modal
        className="modal fade CreatePlanModal"
        show={planModalShow}
        onHide={planModalClose}
      >
        <span onClick={planModalClose} className="closeModalCreatePlan">
          <img src={closeModalCreatePlan} />
        </span>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center flex-column titleBox">
            <img src={createPlan} />
            <h5>Update a plan for you</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label>Title</label>
                <InputTextFeild
                  placeholder="Enter Title"
                  name="title"
                  value={planData.title}
                  onChange={handleChange}
                  errorMessage={
                    planData.errors && planData.errors.title
                      ? planData.errors.title
                      : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <label>Date of Event</label>
                <DatePicker
                  className="form-control"
                  placeholder={"Date of Event(DD/MM/YYYY)"}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
                  // value={moment(
                  //   eventDetail &&
                  //     eventDetail.plan &&
                  //     eventDetail.plan.eventDate
                  // ).format("DD/MM/yyyy")}
                  selected={planData.eventDate}
                  onChange={handleDateChange}
                />
              </div>

              <div className="col-md-12 mb-20px">
                <label>Brief</label>
                <textarea
                  onChange={handleChange}
                  className="form-control form-control-user wt-resize"
                  placeholder="Enter a brief decription of the event"
                  rows="4"
                  value={planData.eventDescription}
                  name={"eventDescription"}
                />
              </div>

              <div className="col-md-6">
                <label>Priority</label>
                <AsyncSelect
                  placeholder={" --Select Priority-- "}
                  loadOptions={options}
                  className="form-control p-0"
                  maxMenuHeight={200}
                  value={planData.priority}
                  defaultOptions={options}
                  name="priority"
                  onChange={(selectedOption) => {
                    onSelect(selectedOption, "priority");
                  }}
                  isSearchable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#00bdcc33",
                      primary: "#00acb9",
                    },
                  })}
                />
              </div>

              <div className="col-md-6">
                <div className="checkboxBox mt-4">
                  <h5>Do you want to receive email reminder?</h5>
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="custom_radio">
                      <input
                        type="radio"
                        id="featured-1"
                        name="emailReminder"
                        onChange={handleSelectChange}
                        checked={planData.emailReminder}
                        value="yes"
                      />
                      <label htmlFor="featured-1">Yes</label>
                    </div>
                    <div className="custom_radio">
                      <input
                        type="radio"
                        id="featured-2"
                        name="emailReminder"
                        onChange={handleSelectChange}
                        checked={!planData.emailReminder}
                        value="no"
                      />
                      <label htmlFor="featured-2">No</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-sm-0 mt-md-4">
                <span
                  className={
                    "customCancelBtn mt-0 btn mt-4 d-flex align-items-center justify-content-center"
                  }
                  onClick={() =>
                    deleteEvent(
                      eventDetail && eventDetail.plan && eventDetail.plan.id
                    )
                  }
                >
                  {isLoading ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin"></i>
                      Delete Event
                    </>
                  ) : (
                    " Delete Event"
                  )}
                </span>
              </div>
              <div className="col-md-6 mt-sm-0 mt-md-4">
                <SubmitButton
                  className={"customSubmitBtn mt-4"}
                  text={"Update Event"}
                  isLoading={isAdding}
                  onSubmit={handleSubmit}
                  disabled={
                    !planData.title ||
                    planData.emailReminder === null ||
                    !planData.eventDate ||
                    !planData.eventDescription ||
                    !planData.priority
                  }
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatePlanModal;
