import Validator from "js-object-validation";
import { message } from "../common";

export const clientOwnerInfo = (data) => {
  const validations = {
    first_name: {
      required: true,
    },
    // last_name: {
    //   required: true,
    // },
    // gender: {
    //   required: true,
    // },
    // birth_date: {
    //   required: true,
    // },
    country: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    first_name: {
      required: message.Required.replace(":item", "first name"),
    },
    last_name: {
      required: message.Required.replace(":item", "last name"),
    },
    gender: {
      required: message.Required.replace(":item", "gender"),
    },
    birth_date: {
      required: message.Required.replace(":item", "birth date"),
    },
    country: {
      required: message.Required.replace(":item", "country"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
    data
  };
};
