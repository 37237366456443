import Validator from "js-object-validation";
import { message } from "../common";
import { replaceAll } from "../helpers";

export const phoneInfo = (data) => {
  const validations = {
    secondPhone: {
      required: true,
      minlength: 6,
      numeric: true,
    },
    backupPhone: {
      required: true,
      minlength: 6,
      numeric: true,
    },
    countryCode: {
      required: true,
    },
  };

  const messages = {
    secondPhone: {
      required: message.Required.replace(":item", "phone number"),
      numeric: message.InvalidPhone,
      minlength: replaceAll(message.MinItemCount, {
        ":item": "Phone number",
        ":length": 6,
      }),
    },
    backupPhone: {
      required: message.Required.replace(":item", "phone number"),
      numeric: message.InvalidPhone,
      minlength: replaceAll(message.MinItemCount, {
        ":item": "Phone number",
        ":length": 6,
      }),
    },
    countryCode: {
      required: message.Required.replace(":item", "country code"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
