import React from "react";
import Skeleton from "react-skeleton-loader";

export const EmailLoader = () => (
  <>
    <div className="card-body card-table">
      <div className="row">
        <div className="col-md-4">
          <form className="product_add mr-0">
            <Skeleton height={"40px"} width={"400px"} color="#e5e9ef" />
            <Skeleton height={"40px"} width={"400px"} color="#e5e9ef" />
            <Skeleton height={"40px"} width={"400px"} color="#e5e9ef" />
            <div style={{ marginTop: "29px" }}>
              <Skeleton height={"40px"} width={"130px"} color="#e5e9ef" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
);
