import React from "react";
import Skeleton from "react-skeleton-loader";

export const BillingLoader = () => (
  <>
    <div className="col-md-12">
      <div className="innerHolder">
        <div className="billingInviceCard active">
          <div className="CardHeader">
            <h4>
              <Skeleton height={"24px"} width={"200px"} color="#e5e9ef" />
            </h4>
            <div className="rightSection">
              <div className="buttonHolder">
                <Skeleton height={"40px"} width={"120px"} color="#e5e9ef" />
              </div>
            </div>
          </div>
          <div className="CardBody">
            <div className="row">
              <div className="col-md-5">
                <ul className="invoice_list">
                  <li>
                    <div className="image_holder">
                    <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
                    </div>
                    <div className="detail text-capitalize">
                      <span><Skeleton height={"20px"} width={"80px"} color="#e5e9ef" /></span>
                      <p><Skeleton height={"20px"} width={"110px"} color="#e5e9ef" /></p>
                    </div>
                  </li>
                  <li>
                    <div className="image_holder">
                    <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
                    </div>
                    <div className="detail text-capitalize">
                      <span><Skeleton height={"20px"} width={"80px"} color="#e5e9ef" /></span>
                      <p><Skeleton height={"20px"} width={"110px"} color="#e5e9ef" /></p>
                    </div>
                  </li>
                  <li>
                    <div className="image_holder">
                    <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
                    </div>
                    <div className="detail text-capitalize">
                      <span><Skeleton height={"20px"} width={"80px"} color="#e5e9ef" /></span>
                      <p><Skeleton height={"20px"} width={"110px"} color="#e5e9ef" /></p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-md-7">
                <ul className="invoice_list">
                  <li>
                    <div className="image_holder">
                    <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
                    </div>
                    <div className="detail text-capitalize">
                      <span><Skeleton height={"20px"} width={"80px"} color="#e5e9ef" /></span>
                      <p><Skeleton height={"20px"} width={"110px"} color="#e5e9ef" /></p>
                    </div>
                  </li>
                  <li>
                    <div className="image_holder">
                    <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
                    </div>
                    <div className="detail text-capitalize">
                      <span><Skeleton height={"20px"} width={"80px"} color="#e5e9ef" /></span>
                      <p><Skeleton height={"20px"} width={"110px"} color="#e5e9ef" /></p>
                    </div>
                  </li>
                  <li>
                    <div className="image_holder">
                    <Skeleton height={"40px"} width={"40px"} color="#e5e9ef" />
                    </div>
                    <div className="detail text-capitalize">
                      <span><Skeleton height={"20px"} width={"80px"} color="#e5e9ef" /></span>
                      <p><Skeleton height={"20px"} width={"110px"} color="#e5e9ef" /></p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
