/* eslint-disable no-sparse-arrays */
import { AppRoutes } from "../../../config";
// import Shipping from '../../../assets/img/batches_icon.svg';
import sub_icon from "../../../assets/img/currentplanactive.svg";
import billing_icon from "../../../assets/img/security.svg";
import billingactive from "../../../assets/img/security_active.svg";
import paymentimage from "../../../assets/img/multifactor.svg";
import paymentactive from "../../../assets/img/multifactor_active.svg";
import profileImage from "../../../assets/img/profileImage.svg";
import profileImageActive from "../../../assets/img/profileImageActive.svg";
import sessionimage from "../../../assets/img/sessionIcon.svg";
import sessionIconactive from "../../../assets/img/sessionIconactive.svg";
import preferencesImage from "../../../assets/img/preferences.svg";
import preferencesactive from "../../../assets/img/preferencesActive.svg";
import privacyImage from "../../../assets/img/privacyImage.svg";
import privacyImageactive from "../../../assets/img/privacyImageactive.svg";

export const AccountNavConfig = [
  {
    name: "Profile",
    path: AppRoutes.PROFILE,
    imgUrl: profileImage,
    activeImgUrl: profileImageActive,
  },
  {
    name: "Security",
    path: AppRoutes.SECURITY,
    imgUrl: billing_icon,
    activeImgUrl: billingactive,
  },
  {
    name: "Multi Factor Authentication",
    path: AppRoutes.AUTHENTICATION,
    imgUrl: paymentimage,
    activeImgUrl: paymentactive,
  },
  {
    name: "Preferences",
    path: AppRoutes.PREFERENCES,
    imgUrl: preferencesImage,
    activeImgUrl: preferencesactive,
  },
  {
    name: "Session",
    path: AppRoutes.SESSION,
    imgUrl: sessionimage,
    activeImgUrl: sessionIconactive,
  },
  // {
  //   name: "Privacy",
  //   path: AppRoutes.Purchase,
  //   imgUrl: privacyImage,
  //   activeImgUrl: privacyImageactive,
  // },
];
