import React from "react";
import Skeleton from "react-skeleton-loader";

export const BatchViewLoaderNew = () => (
  <>
    <div className="productFormBox viewStaff_page mb-5">
            <div className="row">
              <div className="col-md-3 col-img-sf">
                <div className="img-col-staff">
                  <Skeleton
                    color="#e5e9ef"
                    height={"150px"}
                    style={{ maxwidth: "150px", minWidth: "250px" }}
                  />
                </div>
                <div className="st-name-head">
                  <Skeleton
                    className="sf_name"
                    color="#e5e9ef"
                    height={"24px"}
                    style={{ maxwidth: "100%", minWidth: "150px" }}
                  />
                </div>

                <div className="mt-5">
                  <Skeleton color="#e5e9ef" height={"40px"} width={"180px"} />
                </div>
                <div className="mt-4">
                  <Skeleton color="#e5e9ef" height={"17px"} width={"180px"} />
                  <Skeleton color="#e5e9ef" height={"40px"} width={"180px"} />
                </div>
              </div>

              <div className="col-md-9 col-det-sf">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-head mt-0 mb-4">
                      <Skeleton color="#e5e9ef" height={"24px"} width={"360px"} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-12 mb-3">
                    <Skeleton color="#e5e9ef" height={"100px"} width={"100%"} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <div className="form-head mt-0 mb-4">
                      <Skeleton color="#e5e9ef" height={"24px"} width={"360px"} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"231px"} />
                  </div>
                  <div className="col-md-8 mb-3">
                    <Skeleton color="#e5e9ef" height={"17px"} width={"150px"} />
                    <Skeleton color="#e5e9ef" height={"40px"} width={"100%"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
  </>
);
