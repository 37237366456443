import React from "react";
import {  Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import failedMessage from "../../../../assets/img/paymentFailed.svg";
import modalClose from "../../../../assets/img/modalClose.svg";
import paymentCancelled from "../../../../assets/img/paymentCancelled.svg";
import completedIcon from "../../../../assets/img/completedIcon.svg";
import { AppRoutes } from "../../../../config";

export default function SuccessPopup({ successShow, close, paymentType }) {
  return (
    <>
      <Modal className="failedModal" show={successShow} onHide={close}>
        <Modal.Body className="position-relative">
          {/* START Payment Failed modal */}

          {paymentType === "failure" ? (
            <div className="failedMessage">
              <div className="d-flex align-items-center justify-content-center">
                <img alt="" src={failedMessage}  />
              </div>
              <h3>Payment Failed</h3>
              <p>Your transaction has failed. Please go back and try again.</p>

              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={AppRoutes.Purchase}
                  onClick={close}
                  className="addProductbtn text-center"
                  style={{
                    width: "171px",
                    height: "48px",
                  }}
                >
                  Try Again
                </Link>
              </div>
            </div>
          ) : //   START Payment Cancelled modal
          paymentType === "cancel" ? (
            <div className="failedMessage">
              <div className="d-flex align-items-center justify-content-center">
                <img alt="" src={paymentCancelled} />
              </div>
              <h3>Payment Cancelled</h3>
              <p>
                Your transaction has cancelled. Please go back and try again.
              </p>

              <div className="d-flex align-items-center justify-content-center">
                <Link
                  to={AppRoutes.Purchase}
                  onClick={close}
                  className="addProductbtn text-center"
                  style={{
                    width: "171px",
                    height: "48px",
                  }}
                >
                  Try Again
                </Link>
              </div>
            </div>
          ) : //   START success modal
          paymentType === "success" ? (
            <div className="failedMessage successMessage">
              <div className="d-flex align-items-center justify-content-center">
                <img alt="" src={completedIcon} />
              </div>
              <h3>Completed!</h3>
              <p className="mb-0">We have received your payment</p>
              <img alt="" className="closeModal" onClick={close} src={modalClose} />
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ marginTop: "30px" }}
              >
                <span
                  onClick={close}
                  // to={AppRoutes.Create_Plan}
                  className="addProductbtn text-center"
                  style={{
                    width: "171px",
                    height: "48px",
                  }}
                >
                  Okay
                </span>
              </div>
            </div>
          ) : (
            ""
          )}

          {/* START Completed modal */}

          {/* <div className="failedMessage successMessage">
            <div className="d-flex align-items-center justify-content-center">
              <img alt="" src={completedIcon} />
            </div>
            <h3>Completed!</h3>
            <p className="mb-0">We have received your payment</p>
            <img alt="" className="closeModal" onClick={close} src={modalClose} />
            <div className="d-flex align-items-center justify-content-center" style={{marginTop: "30px"}}>
              <Link
                className="addProductbtn text-center"
                style={{
                  width: "171px",
                  height: "48px",
                }}
              >
                Okay
              </Link>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
