import React from "react";
import StaffRow from "./StaffRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import { Link } from "react-router-dom";
import {  AppRoutes } from "../../../config";

export const ListStaffComponent = ({
  staff,
  isLoading,
  viewHandler,
  setSorting,
  order,
  sortBy,
}) => {
  return (
    <div className="tableCard card">
      <div className="tabel-outer">
        <div className="table-responsive fixed-height-table">
          {isLoading ? (
            <TableLoader columns={6} rows={10} />
          ) : (
            <table
              className="table batchTable staffTableNew"
              width="100%"
              cellSpacing="0"
            >
              <thead>
                <tr className="batchesHeader">
                  <th>
                    Name
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "name"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setSorting(
                            "name",
                            sortBy === "name"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>
                    STAFF ID
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "employee_id"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setSorting(
                            "employee_id",
                            sortBy === "employee_id"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>
                    ROLE
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "roleName"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setSorting(
                            "roleName",
                            sortBy === "roleName"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>
                    Email
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "email"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setSorting(
                            "email",
                            sortBy === "email"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>
                    Status
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "status"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setSorting(
                            "status",
                            sortBy === "status"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {staff && staff.length ? (
                  staff.map((element, index) => (
                    <StaffRow
                      key={index}
                      staffData={element}
                      viewHandler={() => viewHandler(element._id)}
                    />
                  ))
                ) : (
                  <tr className="no-tr">
                    <td colSpan={6} className={"text-center image-no-data-xs nodataTablePosition"}>
                    {/* <>
                        <img src={noData} alt="" />
                        <p>The data you're looking is not available at this moment</p>
                      </> */}
                      <>
                      <img src={noData} alt="" />
                      <p>
                      No Staff Found,{" "}
                      <Link
                        to={AppRoutes.MANAGE_STAFF_REQUESTS}
                        className="text-decoration-none"
                      >
                        Click here
                      </Link>{" "}
                      to Invite New Staff
                    </p> 
                    </>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
