import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import ReactApexChart from "react-apexcharts";
import no_data from "../../../assets/img/no_data.svg";
import { Dropdown, Form } from "react-bootstrap";
import arrow from "../../../assets/img/thisWeekDownIcon.svg";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import { CardLoader } from "../../components/loader/Dashboard/CardLoader";

export default function ScanData() {
  useEffect(() => {
    fetchGraphData();
  }, []);
  const [scanStatic, setScanStatic] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState("Week");

  const fetchGraphData = (selectedTime) => {
    const payload = {
      selectedTime,
    };
    getScanStatics(payload);
  };

  const getScanStatics = async (data) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.service,
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.url,
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.method,
      ApiRoutes.DASHBOARD_SCAN_STATISTICS.authenticate,
      data,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data;
      setScanStatic(data);
    }
  };

  let chartCategoryArray = [];
  if (scanStatic && selectedTime !== "Year") {
    for (let x in scanStatic.currentScanDataSet.label) {
      if (scanStatic.currentScanDataSet.label[x]) {
        chartCategoryArray.push(
          new Date(
            scanStatic.currentScanDataSet.label[x] + "T00:00:00.025385Z"
          ).getTime()
        );
      }
    }
  }
  let chartPrevArray = [];
  if (scanStatic && selectedTime !== "Year") {
    for (let x in scanStatic.currentScanDataSet.label) {
      if (scanStatic.prevScanDataSet.label[x]) {
        chartPrevArray.push(
          new Date(
            scanStatic.prevScanDataSet.label[x] + "T00:00:00.025385Z"
          ).getTime()
        );
      }
    }
  }
  const series = [
    {
      name: `Current ${selectedTime}`,
      data:
        scanStatic &&
        scanStatic.currentScanDataSet &&
        scanStatic.currentScanDataSet.data,
    },
    {
      name: `Last ${selectedTime}`,
      data:
        scanStatic &&
        scanStatic.prevScanDataSet &&
        scanStatic.prevScanDataSet.data,
    },
  ];

  const options = {
    chart: {
      id: "realtime",
      animations: {
        enabled: true,
        easing: "linear",
        dynamicAnimation: {
          speed: 1000,
        },
      },

      colors: ["#00ACB9"],
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },

    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories:
        selectedTime === "Year"
          ? scanStatic &&
            scanStatic.currentScanDataSet &&
            scanStatic.currentScanDataSet.label
          : chartCategoryArray,
      type: "datetime",
      // range: XAXISRANGE,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        // style: {
        //   colors: [],
        //   fontSize: "12px",
        //   fontFamily: "Helvetica, Arial, sans-serif",
        //   fontWeight: 400,
        //   padding:"65px",
        //   cssClass: "apexcharts-xaxis-label",
        // },
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#00ACB9"],
  };

  if (selectedTime === "Year") {
    options.xaxis.type = "category";
  }

  return (
    <>
      <div className="mycard py-0">
        {loading ? (
          <CardLoader />
        ) : (
          <>
            <div className="cardHeader">
              <h4>Total Scans</h4>
              <Dropdown className="customDropdownBox">
                <Dropdown.Toggle className="span" id="dropdown-basic">
                  {selectedTime === "Week"
                    ? "This Week"
                    : "" || selectedTime === "Month"
                    ? "This Month"
                    : "" || selectedTime === "Year"
                    ? "This Year"
                    : ""}
                  <img src={arrow} style={{ marginLeft: "10.72px" }} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Week");
                      fetchGraphData("Week");
                    }}
                  >
                    This Week
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("LastWeek");
                      fetchGraphData("LastWeek");
                    }}
                  >
                    Last Week
                  </Dropdown.Item> */}
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Month");
                      fetchGraphData("Month");
                    }}
                  >
                    This Month
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setSelectedTime("Year");
                      fetchGraphData("Year");
                    }}
                  >
                    This Year
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {scanStatic &&
            scanStatic.totalScans &&
            scanStatic.totalScans > 0 ? (
              <div className="cardBody">
                <div>
                  <h4>
                    {scanStatic && scanStatic.totalScans
                      ? scanStatic.totalScans
                      : "0"}
                  </h4>
                  <h5>
                    Today{" "}
                    {scanStatic && scanStatic.todayScans
                      ? scanStatic.todayScans
                      : "0"}{" "}
                    Scanned
                  </h5>
                </div>
                {selectedTime && (
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    width="100%"
                    height={"80%"}
                  />
                )}
              </div>
            ) : (
              <div
                className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                style={{
                  minHeight: "415px",
                  padding: "0",
                  marginTop: "-65px",
                }}
              >
                <img src={no_data} />
                <h5>No Data Yet</h5>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
