import React from "react";
import { Breadcrumbs } from "../../partial/Breadcrumbs";
import { useLocation } from "react-router";
import { ApiHelper } from "../../../../helpers";
import { ApiRoutes } from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import chat_team from "../../../../assets/img/chat_team.svg";
import call_calling from "../../../../assets/img/call_calling.svg";
import location_contact from "../../../../assets/img/location_contact.svg";
import messages_contact from "../../../../assets/img/messages_contact.svg";
import telegram_contact from "../../../../assets/img/telegram_contact.svg";
import whatsapp_contact from "../../../../assets/img/whatsapp_contact.svg";
import user_add_contact from "../../../../assets/img/user_add_contact.svg";
import fb_contact from "../../../../assets/img/fb_contact.svg";
import linkdinIcon from "../../../../assets/img/linkdinIcon.svg";
import twitter_contact from "../../../../assets/img/twitter_contact.svg";
import { ContactLoader } from "../../loader/HelpLoaders/ContactLoader";

function Contact() {
  const location = useLocation();

  const [contactData, setContactData] = useState();
  const [pageLoader, setPageLoader] = useState();

  useEffect(() => {
    getContactData();
  }, []);

  const getContactData = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CONTACT.service,
      ApiRoutes.GET_CONTACT.url,
      ApiRoutes.GET_CONTACT.method,
      ApiRoutes.GET_CONTACT.authenticate,
      undefined
    );
    setPageLoader(false);

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.contact[0];
      setContactData(data);
    }
  };
  console.log("contactData", contactData);
  return (
    <>
      <div className="container-fluid fluid-pd-2 pb-5 support_contact">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={location.pathname} />
          </h3>
        </div>

        <div className="productFormBox help tutorials">
          {pageLoader ? (
            <ContactLoader />
          ) : (
            <div className="row">
              <div className="col-md-12 titleRow">
                <h4>Contact us</h4>
                <p>
                  Any question or remarks? Our freindly team would love to here
                  from you!
                </p>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4">
                <div className="contactGrid">
                  <img src={chat_team} />
                  <h5>Chat to Clarity Team</h5>
                  <h6>Speak to our friendly team</h6>
                  <p
                    className="cursor-pointer"
                    onClick={() => window.open(`mailto:${contactData?.email}`)}
                  >
                    Email us: {contactData?.email}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4">
                <div className="contactGrid">
                  <img src={call_calling} />
                  <h5>Call Us</h5>
                  <h6>Mon-Fri from 8am to 5pm (EDT)</h6>
                  <p
                    className="cursor-pointer"
                    onClick={() => window.open(`tel:${contactData?.phone}`)}
                  >
                    {contactData?.phone}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4">
                <div className="contactGrid">
                  <img src={location_contact} />
                  <h5>Visit Us</h5>
                  <h6>Visit our office HQ</h6>
                  <p> {contactData?.address}</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4">
                <div className="contactGrid" style={{ paddingBottom: "20px" }}>
                  <img src={messages_contact} />
                  <h5>Send Message</h5>
                  <h6>Mon-Fri from 8am to 5pm(EDT)</h6>
                  <div>
                    <a
                      href={contactData?.telegramLink}
                      target="_blank"
                      style={{ marginRight: "30px" }}
                    >
                      <img src={telegram_contact} />
                    </a>
                    <a href={contactData?.whatsappLink} target="_blank">
                      <img src={whatsapp_contact} />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-xl-4">
                <div className="contactGrid" style={{ paddingBottom: "20px" }}>
                  <img src={user_add_contact} />
                  <h5>Connect Us</h5>
                  <h6>Connet us on social media for the updates</h6>
                  <div>
                    <a
                      href={contactData?.facebook}
                      style={{ marginRight: "17px" }}
                      target="_blank"
                    >
                      <img src={fb_contact} />
                    </a>
                    <a
                      href={contactData?.linkedIn}
                      style={{ marginRight: "17px" }}
                      target="_blank"
                    >
                      <img src={linkdinIcon} />
                    </a>
                    <a href={contactData?.twitter} target="_blank">
                      <img src={twitter_contact} />
                    </a>
                  </div>
                </div>
              </div>
              
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Contact;
