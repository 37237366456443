import React from "react";
import { BatchHistoryLoader } from "../../loader/Resusable/BatchHistory";
import { Button } from "react-bootstrap";
import dropDown from "../../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../../assets/img/dropDown-long_icon_up_active.svg";
// import noData from "../../../../assets/img/no-data.png";
import removeData from "../../../../assets/img/remove_icon.svg";
import { DEFAULT_DATE_FORMAT } from "../../../../config";
import noData from "../../../../assets/img/noData.svg";
import moment from "moment";

const AssignedPermissionsListComponent = ({
  isLoading,
  permission,
  deletingModule,
  removePermission,
  sortBy,
  order,
  setSorting,
  isAdding,
}) => {
  return (
    <div className="">
      {isLoading ? (
        <BatchHistoryLoader />
      ) : (
        <>
          <div className="permission_holder">
            <div className="row">
              <div className="col-md-12">
                <h6 className="font-book assigned-table">
                  Assigned Permissions
                </h6>
              </div>
            </div>
          </div>
          <div className="card tableCard">
            <div className="tabel-outer">
              <div className="table-responsive">
                <table
                  width="100%"
                  cellSpacing="0"
                  className="table batchTable intableCheckbox batchHistoryTable"
                >
                  <thead className="pad-top">
                    <tr className="batchesHeader scan-head">
                      <th>
                        Permitted Module
                        <span className={"cursor-pointer"}>
                          <img
                            src={
                              sortBy === "module"
                                ? order === "desc"
                                  ? dropDownActive
                                  : dropUpActive
                                : dropDown
                            }
                            alt=""
                            onClick={() =>
                              setSorting(
                                "module",
                                sortBy === "module"
                                  ? order === "asc"
                                    ? "desc"
                                    : "asc"
                                  : "asc"
                              )
                            }
                          />
                        </span>
                      </th>
                      <th>
                        Sub module
                        <span className={"cursor-pointer"}>
                          <img
                            src={
                              sortBy === "subModules"
                                ? order === "desc"
                                  ? dropDownActive
                                  : dropUpActive
                                : dropDown
                            }
                            alt=""
                            onClick={() =>
                              setSorting(
                                "subModules",
                                sortBy === "subModules"
                                  ? order === "asc"
                                    ? "desc"
                                    : "asc"
                                  : "asc"
                              )
                            }
                          />
                        </span>
                      </th>
                      <th>
                        Access type
                        <span className={"cursor-pointer"}>
                          <img
                            src={
                              sortBy === "accessType"
                                ? order === "desc"
                                  ? dropDownActive
                                  : dropUpActive
                                : dropDown
                            }
                            alt=""
                            onClick={() =>
                              setSorting(
                                "accessType",
                                sortBy === "accessType"
                                  ? order === "asc"
                                    ? "desc"
                                    : "asc"
                                  : "asc"
                              )
                            }
                          />
                        </span>
                      </th>
                      <th>
                        date assigned
                        <span className={"cursor-pointer"}>
                          <img
                            src={
                              sortBy === "dateAssigned"
                                ? order === "desc"
                                  ? dropDownActive
                                  : dropUpActive
                                : dropDown
                            }
                            alt=""
                            onClick={() =>
                              setSorting(
                                "dateAssigned",
                                sortBy === "dateAssigned"
                                  ? order === "asc"
                                    ? "desc"
                                    : "asc"
                                  : "asc"
                              )
                            }
                          />
                        </span>
                      </th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permission && permission.length ? (
                      permission.map((item, index) => (
                        <tr key={index} className="btachRow history">
                          <td>
                            <span className="textContol">{item.module}</span>
                          </td>
                          <td>
                            <span className="textContol   text-capitalize">
                              {item?.subModules?.join(", ")}
                            </span>
                          </td>
                          <td>
                            <span className="textContol">
                              {item.accessType}
                            </span>
                          </td>
                          <td>
                            <span className="textContol">
                              {item.dateAssigned
                                ? moment(item.dateAssigned).format(
                                    "DD MMM yyyy"
                                  )
                                : "-"}
                            </span>
                          </td>
                          <td>
                            <div className="batchHistory_table d-flex align-items-center">
                              <Button
                                variant=""
                                className="exportBatchBtn mx-2"
                                onClick={() => removePermission(item.module)}
                                disabled={deletingModule === item.module}
                              >
                                {deletingModule === item.module ? (
                                  <>
                                    <i className="fa fa-spinner fa-spin"></i>
                                    Removing
                                  </>
                                ) : (
                                  <>
                                    <img src={removeData} alt="" /> Remove
                                  </>
                                )}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={6}
                          className={"text-center image-no-data-xs pt-5 pb-0"}
                        >
                          <>
                            <img
                              style={{ width: "120px" }}
                              src={noData}
                              alt=""
                            />
                            <p className="nodatatextp">
                              {"Permission is not assigned to client yet."}
                            </p>
                          </>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AssignedPermissionsListComponent;
