import React from "react";
import Skeleton from "react-skeleton-loader";

export const TermsPage = () => (
  <>
    <div className="row">
      <div className="col-md-12">
        <h1><Skeleton color="#e5e9ef" height={"40px"} width={"40%"} /></h1>
        <h2><Skeleton color="#e5e9ef" height={"24px"} width={"30%"} /></h2>

        <div className="mb-3">
          <Skeleton color="#e5e9ef" height={"29px"} width={"30%"} />
        </div>
        <div className="mb-3">
          <Skeleton color="#e5e9ef" height={"27px"} width={"35%"} />
        </div>

        <div>
          <Skeleton color="#e5e9ef" height={"12px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"12px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"12px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"12px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"12px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"12px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"15px"} width={"100%"} />
          <Skeleton color="#e5e9ef" height={"20px"} width={"100%"} />
        </div>
      </div>
    </div>
  </>
);
