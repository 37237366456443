import React, { Component } from "react";
import SearchFilter from "../../filters/SearchFilter";
import { Breadcrumbs } from "../../partial/Breadcrumbs";
import "../index.css";
import * as qs from "query-string";
import debounce from "lodash.debounce";

import FaqAccordion from "./FaqAccordion";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      faqs: [],
      category: [],
      loading: false,
      catLoader: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.fetchFaqData();
    this.fetchCategoryData();
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    let searchInput = {
      search: search,
    };
    this.fetchFaqData(searchInput);
  };

  fetchCategoryData = async () => {
    this.setState({
      catLoader: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FAQ_CAT.service,
      ApiRoutes.GET_FAQ_CAT.url,
      ApiRoutes.GET_FAQ_CAT.method,
      ApiRoutes.GET_FAQ_CAT.authenticate,
      undefined
    );
    this.setState({
      catLoader: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        category: data.faqs,
      });
    }
  };
  fetchFaqData = async (queryData) => {
    this.setState({
      loading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FAQ.service,
      ApiRoutes.GET_FAQ.url,
      ApiRoutes.GET_FAQ.method,
      ApiRoutes.GET_FAQ.authenticate,
      queryData,
      undefined
    );

    this.setState({
      loading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      this.setState({
        faqs: data.faqs,
      });
    }
  };

  fetchData = (data) => {
    let id = {
      categoryId: data,
    };
    this.fetchFaqData(id);
  };

  render() {
    const { search, faqs, category, loading, catLoader } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2 pb-5">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>
        <div className="productFormBox faqBox">
          <div className="row">
            <div className="col-md-12">
              <div className="faqRow">
                <div className="d-flex align-items-center">
                  <h4 className="faq-head">
                    Have a <span>Question?</span>
                  </h4>
                </div>
                <div className="tableFilter d-flex justify-content-between mb-0 mb-lg-4 mt-3">
                  <SearchFilter
                    id={"sm-search-filter"}
                    placeholder={"Search your question here"}
                    search={search}
                    onSearch={this.onSearch}
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="accrordianHolder d-flex">
                  <FaqAccordion
                    faqs={faqs}
                    loading={loading}
                    category={category}
                    fetchData={this.fetchData}
                    catLoader={catLoader}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Faq;
