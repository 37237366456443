import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { AppConfig } from "../../../config";
import { CounterfeitProduct } from "../../components/loader/Resusable/CounterfeitProduct";
import { ImageView } from "./ImageView";
import arrowopenmap from "../../../assets/img/arrow-openmap.svg";
import "react-image-lightbox/style.css";
import InputTextField from "../../components/form/InputTextFeild";
import arrowleftpopup from "../../../assets/img/arrow-left-popup-small.svg";
import arrowrightpopup from "../../../assets/img/arrow-right-popup-small.svg";
import dummyImage from "../../../assets/img/noimage.jpeg";

export const RequestDetails = ({
  requestDetails,
  isLoading,
  mapHandler,
  onChange,
  errors,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const handlePrevious = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };
  const handleNext = () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };
  const {
    isManual = false,
    reportId = "",
    requestSubject = "",
    requestType = "",
    requestDescription = "",
    // requestDate = "",
    storeName = "",
    storeLocation = "",
    images = [],
    scan = {},
    latitude = "",
    longitude = "",
    requestDateFormat = "",
    requestDateTime = "",
  } = requestDetails || {};
  const { scanId } = scan || {};

  const openGallery = () => {
    setOpen(true);
  };

  const closeGallery = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="productFormBox">
        {isLoading ? (
          <>
            <CounterfeitProduct />
            <CounterfeitProduct />
          </>
        ) : (
          <form>
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`form-control-label`}>Scan ID</label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={isManual ? reportId : scanId}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Request Subjects
                      </label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={requestSubject}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Seller Name
                      </label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={storeName}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Request Type
                      </label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={requestType}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <div className="d-flex align-items-center justify-content-between">
                        <label className={`form-control-label`}>
                          Purchase Location
                        </label>
                        {latitude && longitude ? (
                          <span
                            className="a-link cursor-pointer"
                            onClick={() =>
                              mapHandler(latitude, longitude, storeLocation)
                            }
                          >
                            <span style={{ opacity: ".7" }}>Open Map</span>{" "}
                            <img src={arrowopenmap} alt='' />
                          </span>
                        ) : null}
                      </div>

                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={storeLocation}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Request Description
                      </label>

                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={requestDescription || "-"}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-4">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Request Date Time
                      </label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={`${requestDateTime} | ${requestDateFormat}`}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Product Images
                      </label>
                      <div className="imageBoxView">
                        {images.length ? (
                          <>
                            <div
                              className={
                                "text-center d-flex align-items-center justify-content-center"
                              }
                            >
                              {activeIndex > 0 ? (
                                <span
                                  className={
                                    "cursor-pointer left-ar-counterfeit"
                                  }
                                  onClick={handlePrevious}
                                >
                                  <img src={arrowleftpopup} alt="prev" />
                                </span>
                              ) : null}
                              <img
                                // src={`${AppConfig.FILES_ENDPOINT}${images[activeIndex]}`}
                                src={
                                  images[activeIndex]
                                    ? images[activeIndex].startsWith("https://")
                                      ? images[activeIndex]
                                      : `${AppConfig.FILES_ENDPOINT}${images[activeIndex]}`
                                    : dummyImage
                                }
                                alt=""
                                style={{
                                  maxWidth: "500px",
                                  maxHeight: "110px",
                                }}
                                onClick={openGallery}
                              />
                              {activeIndex < images.length - 1 ? (
                                <span
                                  className={
                                    "cursor-pointer right-ar-counterfeit"
                                  }
                                  onClick={handleNext}
                                >
                                  <img src={arrowrightpopup} alt="next" />
                                </span>
                              ) : null}
                            </div>
                          </>
                        ) : (
                          <span>No images added</span>
                        )}
                        {false && (
                          <div>
                            <Lightbox
                              mainSrc={`${AppConfig.FILES_ENDPOINT}${images[photoIndex]}`}
                              nextSrc={`${AppConfig.FILES_ENDPOINT}${
                                images[(photoIndex + 1) % images.length]
                              }`}
                              prevSrc={`${AppConfig.FILES_ENDPOINT}${
                                images[
                                  (photoIndex + images.length - 1) %
                                    images.length
                                ]
                              }`}
                              onCloseRequest={() => setOpen(false)}
                              onMovePrevRequest={() =>
                                setPhotoIndex(
                                  (photoIndex + images.length - 1) %
                                    images.length
                                )
                              }
                              onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % images.length)
                              }
                              enableZoom={false}
                              reactModalStyle={{
                                content: {
                                  top: "50%",
                                  left: "50%",
                                  right: "10%",
                                  transform: "translate(-50%, -50%)",
                                },
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}

        <ImageView open={isOpen} handleClose={closeGallery} images={images} />
      </div>
    </div>
  );
};
