import React from "react";
import { Button, Form } from "react-bootstrap";
import logo from "../../../assets/img/clarity_logo.png";
import vector from "../../../assets/img/verify_identity.svg";
import InputTextFeild from "../form/InputTextFeild";
import SubmitButton from "../form/SubmitButton";
import feild_buttonArrorw from "../../../assets/img/feild_buttonArrorw.svg";
import { useState } from "react";
import { useEffect } from "react";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { emailInfo } from "../../../validators/emailvalid";
import { useHistory, useLocation } from "react-router";
import AsyncSelect from "react-select/async";
import { areaCode } from "../../../common/phonecode";
import { components } from "react-select";
import { phoneInfo } from "../../../validators/poneInfo";
import Skeleton from "react-skeleton-loader";
const IdentityForm = () => {
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const history = useHistory();

  const location = useLocation();

  const stateData = location?.state?.data;

  const handleSubmit = async (info, index, type) => {
    let data = {};
    if (type === "phone") {
      data = { countryCode: info?.countryCode, backupPhone: info?.phone };
      setLoader(index);
    } else {
      data = { backupEmail: info };
      setLoader(info);
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PHONE_AUTH_OTP.service,
      ApiRoutes.PHONE_AUTH_OTP.url,
      ApiRoutes.PHONE_AUTH_OTP.method,
      ApiRoutes.PHONE_AUTH_OTP.authenticate,
      undefined,
      data
    );
    setLoader(false);

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      if (data ? data : response.messages[0]) {
        var payloadValue = {
          email: info,
        };
        if (type === "phone") {
          payloadValue = {
            countryCode: info?.countryCode,
            phone: info?.phone,
          };
        }
        localStorage.setItem("otp_value", JSON.stringify(payloadValue));
      }
      toast.success(response.messages[0]);
      history.push(AppRoutes.OTPFORM);
    }
  };

  const protect_email = function (data) {
    var splitted, part1, part2;
    splitted = data.split("@");
    part1 = splitted[0];
    part2 = splitted[1];
    if (part1?.length > 4) {
      return (
        part1.slice(0, 2) +
        part1.slice(3).replace(/.(?=..)/g, "*") +
        "@" +
        part2
      );
    } else {
      return (
        part1.slice(0, 1) + part1.slice(1).replace(/.(?=.)/g, "*") + "@" + part2
      );
    }
  };

  const onNext = () => {
    let data = {
      userId: stateData?.userId,
    };
    localStorage.setItem("otp_value", JSON.stringify(data));
    history.push(AppRoutes.OTPFORM);
  };
  return (
    <>
      <section className="formLeftSection">
        <div className="glassEffects nextonboarding verifyidentity_box">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img className="logo" src={logo} />
                <h2>Verify your identity</h2>
                <p>Choose the method below to receive your verification code</p>
                <Form>
                  {stateData?.phone?.length > 0 && (
                    <div className="form-group position-relative trash_option">
                      <label>Phone Number</label>

                      <div className="position-relative">
                        {pageLoader ? (
                          <>
                            <Skeleton
                              height={"40px"}
                              width={"400px"}
                              color="#e5e9ef"
                            />
                            <Skeleton
                              height={"40px"}
                              width={"400px"}
                              color="#e5e9ef"
                            />
                          </>
                        ) : (
                          stateData?.phone?.map((info, index) => (
                            <>
                              <div className="form-group">
                                <div
                                  className={
                                    loader
                                      ? "form-control divDisable position-relative d-flex align-items-center"
                                      : "form-control cursor-pointer position-relative d-flex align-items-center"
                                  }
                                  onClick={() =>
                                    handleSubmit(info, index, "phone")
                                  }
                                >
                                  {info.phone
                                    .slice(0)
                                    .replace(/.(?=...)/g, "*")}
                                  <Button
                                    variant=""
                                    className="arrowBtn"
                                    disabled={loader}
                                  >
                                    {loader === index ? (
                                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>
                                    ) : (
                                      <img src={feild_buttonArrorw} />
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  )}

                  {stateData?.email?.length > 0 && (
                    <div className="form-group position-relative trash_option">
                      <label>Email</label>
                      <div className="position-relative">
                        {pageLoader ? (
                          <>
                            <Skeleton
                              height={"40px"}
                              width={"400px"}
                              color="#e5e9ef"
                            />
                            <Skeleton
                              height={"40px"}
                              width={"400px"}
                              color="#e5e9ef"
                            />
                          </>
                        ) : (
                          stateData?.email?.map((info, index) => (
                            <>
                              <div key={index} className="form-group">
                                <div
                                  className={
                                    loader
                                      ? "form-control divDisable position-relative"
                                      : "form-control cursor-pointer position-relative"
                                  }
                                  onClick={() =>
                                    handleSubmit(info, index, "email")
                                  }
                                >
                                  {protect_email(info)}
                                  <Button
                                    variant=""
                                    className="arrowBtn"
                                    disabled={loader}
                                  >
                                    {loader === info ? (
                                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>
                                    ) : (
                                      <img src={feild_buttonArrorw} />
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  )}
                  {stateData?.GoogleAuth === true && (
                    <div className="form-grou position-relative trash_option">
                      <label>Google Authenticator</label>
                      <div className="position-relative">
                        <div className="form-group">
                          <div
                            className={
                              loader
                                ? "form-control divDisable position-relative"
                                : "form-control cursor-pointer position-relative"
                            }
                            onClick={onNext}
                          >
                            Get code via Google authenticator
                            <Button variant="" className="arrowBtn">
                              <img src={feild_buttonArrorw} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
              <div className="col-md-6 d-none d-md-block image-loader">
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <img className="w-100" src={vector} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IdentityForm;
