import Validator from "js-object-validation";
import { message } from "../common";

export const contactValidate = (data) => {
  const validations = {
    name: {
      required: true,
      alpha: true,
    },
    email: {
      required: true,
      email: true,
    },
    message: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    name: {
      required: message.Required.replace(":item", "name"),
      alpha: message.InvalidName.replace(":item", "name"),
    },
    message: {
      required: message.Required.replace(":item", "message"),
    },
    email: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
