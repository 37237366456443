import React from "react";
import Skeleton from "react-skeleton-loader";

export const CalendarCardLoader = () => (
  <>
    <div className="text-center" style={{padding: "32px"}}>
      <Skeleton color="#e5e9ef" height={"40px"} width={"100%"} />
      <Skeleton color="#e5e9ef" height={"30px"} width={"100%"} />
      <Skeleton color="#e5e9ef" height={"50px"} width={"100%"} />
      <Skeleton color="#e5e9ef" height={"50px"} width={"100%"} />
      <Skeleton color="#e5e9ef" height={"50px"} width={"100%"} />
      <Skeleton color="#e5e9ef" height={"50px"} width={"100%"} />
      <Skeleton color="#e5e9ef" height={"50px"} width={"100%"} />
    </div>
  </>
);
