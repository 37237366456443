import React from "react";
import PasswordSetting from "../../components/accountSetting/profile/PasswordSetting";

function Security(props) {
  return (
    <div className="container-fluid fluid-pd-2">
      <div className="row">
        <div className="col-md-12">
          <PasswordSetting />
        </div>
      </div>
    </div>
  );
}

export default Security;
