import React from "react";
import { Link } from "react-router-dom";
import invoice_download from "../../../assets/img/invoice_download.svg";
import unsplash_img2 from "../../../assets/img/unsplash_img2.svg";
import inviteStaffIcon from "../../../assets/img/inviteStaffIcon.svg";
import ReactApexChart from "react-apexcharts";
import { ApiRoutes, AppConfig, AppRoutes } from "../../../config";
import { useEffect } from "react";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import { CurrentPlanLoaderMini } from "../../components/loader/Resusable/CurrentPlanLoaderMini.jsx";
import { CurrentPlanGraphsLoader } from "../../components/loader/Resusable/CurrentPlanGraphsLoader.jsx";
import { CurrentPlanGraphsLoader2 } from "../../components/loader/Resusable/CurrentPlanGraphsLoader2.jsx";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import no_data from "../../../assets/img/no_data.svg";

export default function CurrentPlan() {
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();
  const [sortBy, setSortBy] = useState("createdAt");
  const [order, setOrder] = useState("desc");
  const [billing, setBilling] = useState();
  const [detail, setDetail] = useState();
  const [updating, setUpdating] = useState(false);

  const option = {
    fill: {
      colors: ["#00ACB9", "#E5FDFF"],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        expandonClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    labels: ["Total Print Codes", "Used Print Codes"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      custom: function ({ series, seriesIndex, w }) {
        if (seriesIndex === 0) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            "</span>" +
            "</div>"
          );
        } else {
          return (
            '<div class="bottom_box">' +
            "<span>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            "</span>" +
            "</div>"
          );
        }
      },
    },
    colors: ["#00ACB9", "#E5FDFF"],
    // grid: {
    //   padding: {
    //     left: -20,
    //     right: 0,
    //     top: 0,
    //     bottom: 0,
    //   },
    // },
  };
  const series = [
    dashboardData && dashboardData.subscribedPlan,
    dashboardData && dashboardData.reported,
  ];

  const planOption = {
    fill: {
      colors: ["#00ACB9", "#E5FDFF"],
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        expandonClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    labels: ["Remaining Days", "Used Days"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      fillSeriesColor: true,
      custom: function ({ series, seriesIndex, w }) {
        if (seriesIndex === 0) {
          return (
            '<div class="arrow_box">' +
            "<span>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            "</span>" +
            "</div>"
          );
        } else {
          return (
            '<div class="bottom_box">' +
            "<span>" +
            w.globals.labels[seriesIndex] +
            ": " +
            series[seriesIndex] +
            "</span>" +
            "</div>"
          );
        }
      },
    },
    colors: ["#00ACB9", "#E5FDFF"],
    // grid: {
    //   padding: {
    //     left: -20,
    //     right: 0,
    //     top: 0,
    //     bottom: 0,
    //   },
    // },
  };
  const planseries = [
    dashboardData && dashboardData.remainingDays,
    dashboardData && dashboardData.usedDays,
  ];
  useEffect(() => {
    getSubDashboard();
  }, []);

  const getSubDashboard = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.SUBSCRIPTION_DASHBOARD.service,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.url,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.method,
      ApiRoutes.SUBSCRIPTION_DASHBOARD.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setLoading(false);
    } else {
      const data = response?.data;
      getCompanyDetail();
      getBilling();
      setDashboardData(data);
      setLoading(false);
    }
  };

  const getCompanyDetail = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      setDetail(data);
    }
  };

  const getBilling = async () => {
    let data = {
      order,
      sortBy,
    };
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.service,
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.url,
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.method,
      ApiRoutes.GET_CLIENT_SUBSCRIPTION.authenticate,
      data,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      setBilling(data);
    }
  };

  const setFilter = (sortBy, order) => {
    if (sortBy) {
      setSortBy(sortBy);
    }
    if (order) {
      setOrder(order);
    }
    getBilling();
  };

  const getInvoice = async (value) => {
    setUpdating(value._id);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_INVOICE.service,
      ApiRoutes.GET_INVOICE.url.replace(":id", value?.stripeInvoiceId),
      ApiRoutes.GET_INVOICE.method,
      ApiRoutes.GET_INVOICE.authenticate,
      undefined
    );
    setUpdating(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      window.location.href = data;
    }
  };
  return (
    <>
      <div
        className="container-fluid fluid-pd-2 pb-5 dashboardPage createplanpage"
        style={{ marginTop: "42px" }}
      >
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 col-xl-6 mb-4 mb-xl-0">
            {loading ? (
              <>
                <CurrentPlanLoaderMini />
              </>
            ) : (
              <div className="mycard">
                <p>Organization Name</p>
                <h4>{detail?.companyDetails?.companyName}</h4>
              </div>
            )}
          </div>
          {/* <div className="col-12 col-md-6 col-lg-6 col-xl-4">
          {loading ? (
            <>
              <CurrentPlanLoaderMini />
            </>
          ) : (
            <div className="mycard subdomaincard position-relative">
              <p>Organization Subdomain</p>
              <h4>{(detail && detail.domainName) || "thehairdiagram.com"}</h4>
              <button>
                <img src={editMinicardSubscription} />
              </button>
            </div>
          )}
        </div> */}
          <div className="col-12 col-md-12 col-lg-12 col-xl-6 mt-md-0 mt-4">
            {loading ? (
              <>
                <CurrentPlanLoaderMini />
              </>
            ) : (
              // dashboardData &&
              // dashboardData.subscriptionList.map((info, index) => (
              <div className="mycard">
                <p>Current Plan</p>
                <h4 className="text-capitalize">
                  {dashboardData &&
                    dashboardData.subscriptionList &&
                    dashboardData?.subscriptionList[0]?.subscription?.name}
                  (
                  {dashboardData &&
                    dashboardData.subscriptionList &&
                    dashboardData?.subscriptionList[0]?.planDuration}
                  )
                </h4>
              </div>
              // ))
            )}
          </div>

          <div className="col-12" style={{ marginTop: "11px" }}></div>

          <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-4">
            <div className="mycard pt-0 subGraphs pl-0 pr-0 graphcard pb-0">
              {loading ? (
                <>
                  <CurrentPlanGraphsLoader />
                </>
              ) : (
                <>
                  <div class="cardHeader">
                    <h4>Batch Usage</h4>
                  </div>

                  <div className="cardBody card_overflow">
                    {dashboardData && dashboardData?.subscribedPlan > 0 ? (
                      <>
                        <div className="w-100">
                          <ReactApexChart
                            options={option}
                            series={series}
                            type="donut"
                            width="100%"
                            height={310}
                          />
                        </div>

                        <div className="dataHolder">
                          <h5>
                            {dashboardData && dashboardData.reported}/
                            {dashboardData && dashboardData.subscribedPlan}{" "}
                            Exported
                          </h5>
                          <p>
                            {dashboardData && dashboardData.reportPercentage}%
                            used
                          </p>
                        </div>
                      </>
                    ) : (
                      <div
                        className=" d-flex align-items-center justify-content-center nodata_box flex-column"
                        style={{
                          minHeight: "415px",
                          padding: "0",
                          marginTop: "-65px",
                        }}
                      >
                        <img src={no_data} />
                        <h5>No Data Yet</h5>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 col-xl-4 mt-4">
            <div
              className="mycard pt-0 graphcard pb-0 subGraphs pl-0 pr-0 "
              // style={{ paddingTop: "24px" }}
            >
              {loading ? (
                <>
                  <CurrentPlanGraphsLoader />
                </>
              ) : (
                <>
                  <div
                    class={
                      billing && billing.length > 0
                        ? "cardHeader renewPadding"
                        : "cardHeader"
                    }
                  >
                    <h4>Plan Usage</h4>
                    {billing && billing.length > 0 && (
                      <Link
                        to={AppRoutes.Purchase}
                        className="addProductbtn no-background text-center px-0 d-flex align-items-center justify-content-center"
                        style={{ minWidth: "90px", height: "40px" }}
                      >
                        Renew
                      </Link>
                    )}
                  </div>
                  <div className="cardBody card_overflow">
                    {dashboardData && dashboardData?.totalAvailableDays > 0 ? (
                      <>
                        <div className="w-100">
                          <ReactApexChart
                            options={planOption}
                            series={planseries}
                            type="donut"
                            width="100%"
                            height={310}
                          />
                        </div>

                        <div className="dataHolder">
                          <h5>
                            {dashboardData && dashboardData.usedDays}/
                            {dashboardData && dashboardData.totalAvailableDays}{" "}
                            Days
                          </h5>
                          <p>
                            {dashboardData && dashboardData.remainingDays} days
                            remaining
                          </p>
                        </div>
                      </>
                    ) : (
                      <div
                        className=" d-flex align-items-center justify-content-center nodata_box flex-column"
                        style={{
                          minHeight: "415px",
                          padding: "0",
                          marginTop: "-65px",
                        }}
                      >
                        <img src={no_data} />
                        <h5>No Data Yet</h5>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-4 mt-4">
            <div className="mycard graphcard pb-0">
              {loading ? (
                <>
                  <CurrentPlanGraphsLoader2 />
                </>
              ) : (
                <>
                  <div class="cardHeader pt-0">
                    <h4>Users</h4>
                  </div>
                  <div className="cardBody py-0">
                    <div className="staffCardBody">
                      <h4>Staff Members</h4>
                      {dashboardData &&
                      dashboardData.staffList &&
                      dashboardData.staffList.staff.length > 0 ? (
                        <div className="staffImages">
                          {dashboardData &&
                            dashboardData.staffList &&
                            dashboardData.staffList.staff.map((info, index) => (
                              <div className="imgBox">
                                <img
                                  className="w-100"
                                  src={
                                    info.profile_picture
                                      ? info.profile_picture.startsWith(
                                          "https://"
                                        )
                                        ? info.profile_picture
                                        : `${AppConfig.FILES_ENDPOINT}${info.profile_picture}`
                                      : unsplash_img2
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = unsplash_img2;
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div
                          className="  nodata_box "
                          style={{
                            padding: "0",
                            marginTop: "15px",
                          }}
                        >
                          <img src={no_data} />

                          <h5>No Data Yet</h5>
                        </div>
                      )}
                      <Link
                        to={AppRoutes.MANAGE_STAFF_REQUESTS}
                        className="inviteStaffBtn text-decoration-none"
                      >
                        <img src={inviteStaffIcon} />
                        Invite Staff
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-4">
            <div
              className="d-flex align-items-center justify-content-between mb-3"
              style={{ marginTop: "11px", marginBottom: "24px" }}
            >
              <h3
                className="dashboard_recentscan"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  letterSpacing: "-0.5px",
                }}
              >
                Billings
              </h3>
              {billing && billing.length > 0 && (
                <Link
                  to={AppRoutes.Billing}
                  className="addProductbtn no-background text-center px-0 d-flex align-items-center justify-content-center"
                  style={{ minWidth: "100px", height: "40px" }}
                >
                  View All
                </Link>
              )}
            </div>
            <div className="tableCard card mb-4">
              <div className="tabel-outer">
                <div
                  className="table-responsive fixed-height-table"
                  style={{ height: "501px" }}
                >
                  {loading ? (
                    <>
                      <TableLoader columns={3} rows={10} />
                    </>
                  ) : (
                    <table
                      className="table batchTable billingTable"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead className="pad-top">
                        <tr className="batchesHeader scan-head">
                          <th style={{ minWidth: "181px" }}>
                            Renewed On{" "}
                            <span className={"cursor-pointer"}>
                              <img
                                src={
                                  sortBy === "purchaseDate"
                                    ? order === "desc"
                                      ? dropDownActive
                                      : dropUpActive
                                    : dropDown
                                }
                                alt=""
                                onClick={() =>
                                  setFilter(
                                    "purchaseDate",
                                    sortBy === "purchaseDate"
                                      ? order === "asc"
                                        ? "desc"
                                        : "asc"
                                      : "asc"
                                  )
                                }
                              />
                            </span>
                          </th>
                          <th style={{ minWidth: "181px" }}>
                            Plan name{" "}
                            <span className={"cursor-pointer"}>
                              <img
                                src={
                                  sortBy === "subscriptionPlan"
                                    ? order === "desc"
                                      ? dropDownActive
                                      : dropUpActive
                                    : dropDown
                                }
                                alt=""
                                onClick={() =>
                                  setFilter(
                                    "subscriptionPlan",
                                    sortBy === "subscriptionPlan"
                                      ? order === "asc"
                                        ? "desc"
                                        : "asc"
                                      : "asc"
                                  )
                                }
                              />
                            </span>
                          </th>
                          <th style={{ minWidth: "181px" }}>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {billing && billing.length ? (
                          billing.map((info, index) => (
                            <tr key={index} className="scan createPlanRow">
                              <td>
                                <span className="textContol">
                                  {info.purchaseDateFormat}
                                </span>
                              </td>
                              <td>
                                <span className="textContol text-capitalize">
                                  {info.subscription && info.subscription.name}(
                                  {info.billingCycle})
                                </span>
                              </td>
                              <td>
                                {/* <button className="viewBtn">
                                  <img src={invoice_download} /> Invoice
                                </button> */}
                                {/* {info?.stripeInvoiceId && ( */}
                                <button
                                  className="viewBtn"
                                  disabled={
                                    updating === info._id ||
                                    !info?.stripeInvoiceId
                                  }
                                  onClick={() => getInvoice(info)}
                                >
                                  {updating === info._id ? (
                                    <>
                                      <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                      Invoice
                                    </>
                                  ) : (
                                    <>
                                      <img src={invoice_download} alt="" />{" "}
                                      Invoice
                                    </>
                                  )}
                                </button>
                                {/* )} */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="no-tr">
                            <td
                              colSpan={6}
                              className={
                                "text-center image-no-data-xs nodataTablePosition"
                              }
                            >
                              <>
                                <img src={noData} alt="" />
                                <p>You haven’t subscribed to a plan yet</p>
                              </>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
