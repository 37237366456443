import React from "react";
import { Modal } from "react-bootstrap";

export default function SetCard({ show, close, handleChange, update }) {
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      className="logoutModal Custom2"
    >
      <Modal.Header>
        <span className="logoutAlert">!</span>
      </Modal.Header>
      <Modal.Body>
        <h3>Are you sure you want to make this card primary?</h3>
        <p>Your all future payments will be deducted from this card.</p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={close} className="logout-cancel">
          Cancel
        </button>
        <button disabled={update} href="#" onClick={handleChange} className="logout-btn">
          {update ? (
            <>
              <i className="fa fa-spinner fa-spin"></i> Yes
            </>
          ) : (
            "yes"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
