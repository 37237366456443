import React from "react";
import { CounterfeitProduct } from "../../components/loader/Resusable/CounterfeitProduct";
import InputTextField from "../../components/form/InputTextFeild";

export const ProductDetails = ({
  productDetails,
  batchDetails,
  isLoading,
  onChange,
  errors,
}) => {
  const { batchNumber = "-", ExpiryDateFormat = "" } = batchDetails || {};
  const { productName = "", productNumber = "" } = productDetails || {};
  return (
    <div className="w-100">
      <div className="productFormBox">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <form>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>
                      Product Number
                    </label>
                    <InputTextField
                      id="batch_number"
                      label={"Enter Product Number"}
                      className={"form-control"}
                      placeholder="Enter Product Number"
                      name="productNumber"
                      value={productNumber || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>Product Name</label>
                    <InputTextField
                      id="batch_number"
                      label={"Enter Product Name"}
                      className={"form-control"}
                      placeholder="Enter Product Name"
                      name="productName"
                      value={productName || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>Batch Number</label>
                    <InputTextField
                      id="batch_number"
                      label={"Enter Batch Number"}
                      className={"form-control mb-0"}
                      placeholder="Enter Batch Number"
                      name="productName"
                      value={batchNumber || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>Expiry Date</label>
                    <InputTextField
                      id="expiry_date"
                      label={"Enter Expiry Date"}
                      className={"form-control mb-0"}
                      placeholder="Enter Expiry Date"
                      name="expiryDate"
                      value={ExpiryDateFormat}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
