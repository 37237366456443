import React from "react";
import Skeleton from "react-skeleton-loader";

export const SessionLoader2 = () => (
  <>
    <div className="col-md-12 accordianBox">
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"40px"} width={"240px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"22px"} width={"160px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"20px"} width={"187px"} />
        </h4>
        <div>
          <h4 className="border-0">
            <Skeleton color="#e5e9ef" height={"25px"} width={"35px"} />
          </h4>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"40px"} width={"240px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"22px"} width={"160px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"20px"} width={"187px"} />
        </h4>
        <div>
          <h4 className="border-0">
            <Skeleton color="#e5e9ef" height={"25px"} width={"35px"} />
          </h4>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"40px"} width={"240px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"22px"} width={"160px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"20px"} width={"187px"} />
        </h4>
        <div>
          <h4 className="border-0">
            <Skeleton color="#e5e9ef" height={"25px"} width={"35px"} />
          </h4>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100">
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"40px"} width={"240px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"22px"} width={"160px"} />
        </h4>
        <h4 className="border-0">
          <Skeleton color="#e5e9ef" height={"20px"} width={"187px"} />
        </h4>
        <div>
          <h4 className="border-0">
            <Skeleton color="#e5e9ef" height={"25px"} width={"35px"} />
          </h4>
        </div>
      </div>
    </div>
  </>
);
