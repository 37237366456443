import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import InputTextFeild from "../form/InputTextFeild";
import createPlan from "../../../assets/img/flags/createPlan.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Modal } from "react-bootstrap";
import SubmitButton from "../form/SubmitButton";
import { ApiRoutes, AppRoutes, DB_ACCPTABLE_FORMAT } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { planValidator } from "../../../validators";
import { useHistory } from "react-router";
import moment from "moment";

export const CreatePlanModal = ({
  planModalShow,
  planModalClose,
  submitTrigger,
}) => {
  const [planData, setPlanData] = useState({
    title: "",
    eventDate: "",
    emailReminder: null,
    eventDescription: "",
    priority: "",
    errors: null,
  });
  const [isAdding, setIsAdding] = useState(false);
  const history = useHistory();
  const options = [
    { value: "Lowest", label: "Lowest" },
    { value: "Low", label: "Low" },
    { value: "Moderate", label: "Moderate" },
    { value: "High", label: "High" },
    { value: "Highest", label: "Highest" },
  ];

  const close = () => {
    planModalClose();
    setPlanData({
      title: "",
      eventDate: "",
      emailReminder: null,
      eventDescription: "",
      priority: "",
      errors: null,
    });
  };
  const handleSelectChange = (event) => {
    const {
      target: { value, name },
    } = event;

    setPlanData({
      ...planData,
      [name]: value && value === "yes" ? true : false,
    });
  };

  const onSelect = (label) => {
    if (label.value) {
      setPlanData({
        ...planData,
        priority: label.value,
      });
    }
  };

  const handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    setPlanData({
      ...planData,
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      errors: { ...planData.errors, [name]: "" },
    });
  };
  const addPlan = async (payload) => {
    setIsAdding(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_EVENT.service,
      ApiRoutes.ADD_EVENT.url,
      ApiRoutes.ADD_EVENT.method,
      ApiRoutes.ADD_EVENT.authenticate,
      undefined,
      payload
    );
    setIsAdding(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
      submitTrigger(false);
    } else {
      submitTrigger(true);
      toast.success(response.data.message);
      planModalClose();
      setPlanData({
        title: "",
        eventDate: "",
        emailReminder: null,
        eventDescription: "",
        priority: "",
        errors: null,
      });
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
  };

  const handleSubmit = async (event) => {
    event && event.preventDefault();

    let data = {
      title: planData.title,
      eventDate: planData.eventDate
        ? moment(planData.eventDate).format(DB_ACCPTABLE_FORMAT)
        : "",
      emailReminder: planData.emailReminder,
      eventDescription: planData.eventDescription,
      priority: planData.priority,
    };
    let { isValid, errors } = planValidator(data);

    if (isValid) {
      addPlan(data);
    }
  };

  const handleDateChange = (date) => {
    setPlanData({
      ...planData,
      eventDate: date,
    });
  };

  const tomorrow = new Date();

  return (
    <>
      <Modal
        className="modal fade CreatePlanModal"
        show={planModalShow}
        onHide={close}
      >
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center flex-column titleBox">
            <img src={createPlan} />
            <h5>Create a plan for you</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label>Title</label>
                <InputTextFeild
                  placeholder="Enter Title"
                  name="title"
                  value={planData.title}
                  onChange={handleChange}
                  errorMessage={
                    planData.errors && planData.errors.title
                      ? planData.errors.title
                      : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <label>Date of Event</label>
                <DatePicker
                  className="form-control"
                  placeholder={"Date of Event(DD/MM/YYYY)"}
                  dateFormat="dd/MM/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  minDate={tomorrow.setDate(tomorrow.getDate() + 1)}
                  selected={planData.eventDate}
                  onChange={handleDateChange}
                />
              </div>

              <div className="col-md-12 mb-20px">
                <label>Brief</label>
                <textarea
                  onChange={handleChange}
                  className="form-control form-control-user wt-resize"
                  placeholder="Enter a brief decription of the event"
                  rows="4"
                  value={planData.eventDescription}
                  name={"eventDescription"}
                />
              </div>

              <div className="col-md-6">
                <label>Priority</label>
                <AsyncSelect
                  placeholder={" --Select Priority-- "}
                  loadOptions={options}
                  className="form-control p-0"
                  maxMenuHeight={200}
                  defaultOptions={options}
                  name="priority"
                  onChange={(selectedOption) => {
                    onSelect(selectedOption, "priority");
                  }}
                  isSearchable={false}
                  styles={customStyles}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: "#00bdcc33",
                      primary: "#00acb9",
                    },
                  })}
                />
              </div>

              <div className="col-md-6">
                <div className="checkboxBox mt-4">
                  <h5>Do you want to receive email reminder?</h5>
                  <div className="d-flex align-items-center justify-content-start">
                    <div className="custom_radio">
                      <input
                        type="radio"
                        id="featured-1"
                        name="emailReminder"
                        onChange={handleSelectChange}
                        value="yes"
                      />
                      <label htmlFor="featured-1">Yes</label>
                    </div>
                    <div className="custom_radio">
                      <input
                        type="radio"
                        id="featured-2"
                        name="emailReminder"
                        onChange={handleSelectChange}
                        value="no"
                      />
                      <label htmlFor="featured-2">No</label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mt-sm-0 mt-md-4">
                <span
                  className="customCancelBtn mt-0 btn mt-4 d-flex align-items-center justify-content-center"
                  onClick={close}
                >
                  Cancel
                </span>
              </div>
              <div className="col-md-6 mt-sm-0 mt-md-4">
                <SubmitButton
                  className={"customSubmitBtn mt-4"}
                  text={"Create"}
                  isLoading={isAdding}
                  onSubmit={handleSubmit}
                  disabled={
                    !planData.title ||
                    planData.emailReminder===null ||
                    !planData.eventDate ||
                    !planData.eventDescription ||
                    !planData.priority
                  }
                />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePlanModal;
