import React from "react";
import moment from "moment";
import { AppConfig, DB_ACCPTABLE_FORMAT } from "../../../config";
import dummyclient from "../../../assets/img/staffuser.png";
import listIcon from "../../../assets/img/staff_menu_light.svg";
import phoneIcon from "../../../assets/img/staffuser.svg";

const StaffRow = ({ staffData, viewHandler }) => {
  console.log("yeah i am", staffData);

  const {
    first_name = "",
    last_name = "",
    employee_id = "",
    email = "",
    phone = "",
    status = "",
    profile_picture = "",
    joining_date = "",
  } = staffData;
  let dateDifference = moment(new Date(), DB_ACCPTABLE_FORMAT).diff(
    moment(joining_date, DB_ACCPTABLE_FORMAT),
    "days"
  );

  const renderStatus = () => {
    switch (status) {
      case "Active":
        return <span className="staffStatus success-span">Active</span>;
      case "Incomplete":
        return <span className="staffStatus warning-span">Incomplete</span>;
      case "Suspended":
        return <span className="staffStatus danger-span">Suspended</span>;
      case "Terminated":
        return <span className="staffStatus secondary-span">Terminated</span>;
      default:
        return <span className="staffStatus success-span">Active</span>;
    }
  };

  return (
    <tr className="btachRow">
      <td>
        <div className="nameBox_stafflist">
          {profile_picture ? (
            <div className="">
              <img
                className="list-view-img-staff"
                // src={
                //   profile_picture
                //     ? profile_picture.startsWith("https://")
                //       ? profile_picture
                //       : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                //     : dummyclient
                // }
                src={
                  profile_picture
                    ? profile_picture.startsWith("https://")
                      ? profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                    : profile_picture
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = dummyclient;
                }}
                alt=""
              />
            </div>
          ) : (
            <div className="img_placeholder" style={{ fontSize: "14px" }}>
              {first_name ? first_name.substring(0, 1) : ""}
              {last_name ? last_name.substring(0, 1) : ""}
            </div>
          )}
          <p>
            {[first_name, last_name].filter(Boolean).join(" ") || "-"}{" "}
            {/* {dateDifference < 15 ? <span className="new-span">New</span> : null} */}
          </p>
        </div>
      </td>
      <td>
        {" "}
        <span className="textContol">{employee_id || "-"}</span>
      </td>
      <td>
        <span className="textContol">{staffData.roleName}</span>
      </td>
      <td>
        <span className="textContol">
          {" "}
          {email ? (
            <a className="mailto" href={`mailto:${email}`}>
              {email}
            </a>
          ) : (
            "-"
          )}{" "}
        </span>
      </td>
      <td> {renderStatus()} </td>
      <td>
        <div className="d-flex align-items-center">
          <button onClick={viewHandler} className="editButtonNew">
            {" "}
            <img src={listIcon} alt="" /> View{" "}
          </button>

          <button
            className="contactButtonNew"
            disabled={!email}
            onClick={() => window.open(`mailto:${email}`)}
          >
            {" "}
            <img src={phoneIcon} alt="" /> Contact{" "}
          </button>
        </div>
      </td>
    </tr>
  );
};

export default StaffRow;
