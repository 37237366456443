/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { RequestDetails } from "./RequestDetails";
import { UserDetails } from "./UserDetails";
import { ProductDetails } from "./ProductDetails";
import { AssigneeDetails } from "./AssigneeDetails";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
class ViewCounterfeit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conterfeitId: "",
      counterfeit: {},
      isLoading: false,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ conterfeitId: id }, () => this.viewCounterfeitRequest());
    }
  };

  viewCounterfeitRequest = async (loading = true) => {
    const { conterfeitId } = this.state;
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.service,
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.url.replace(":id", conterfeitId),
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.method,
      ApiRoutes.VIEW_COUNTERFEIT_DETAIL.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { counterfeit },
      } = response.data;
      this.setState({
        counterfeit,
      });
    }
  };

  changeRequestRequest = async (status = "Acknowledged") => {
    const { conterfeitId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.service,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.url.replace(
        ":id",
        conterfeitId
      ),
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.method,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isLoading: false,
    });
    console.log("response: ", response);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.viewCounterfeitRequest();
    }
  };

  mapHandler = (lat, long, storeLocation) => {
    const { conterfeitId } = this.state;
    this.props.history.push(
      `${AppRoutes.COUNTERFEIT_MAP_VIEW.replace(
        ":id",
        conterfeitId
      )}?lat=${lat}&long=${long}`,
      { storeLocation }
    );
  };
  onchange = (event) => {};

  render() {
    const { isLoading, counterfeit, conterfeitId } = this.state;
    const {
      assignee = {},
      batch = {},
      product = {},
      user: userDetails = {},
      status = "",
      dateOfActionDate = "",
    } = counterfeit || {};
    const productDetails = product;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        {/* <h1 class="h3-head-mob d-md-none">Counterfeit View</h1> */}
        <div className="tableFilter add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
            />
          </h3>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="tableFilter pl-0 mb-0 mt-0">
              <h3 style={{ margin: "0px 0 25px 0" }}>Product Details</h3>
            </div>
            <ProductDetails
              productDetails={productDetails}
              batchDetails={batch}
              isLoading={isLoading}
              onChange={this.onchange}
            />
          </div>
          <div className="col-md-12 col-lg-6 mt-3 mt-lg-0">
            <div className="tableFilter pl-0 mb-0 mt-0">
              <h3 style={{ margin: "0px 0 25px 0" }}>User Details</h3>
            </div>
            <UserDetails
              userDetails={userDetails}
              isLoading={isLoading}
              onChange={this.onchange}
              mapHandler={this.mapHandler}
            />
          </div>
          <div className="col-md-12">
            <div className="tableFilter pl-0 mb-0 mt-0">
              <h3 style={{ margin: "35px 0 25px 0" }}>Request Details</h3>
            </div>
            <RequestDetails
              requestDetails={counterfeit}
              mapHandler={this.mapHandler}
              isLoading={isLoading}
              onChange={this.onchange}
            />
          </div>
          {isLoading ? (
            // <CounterfeitClient />
            <></>
          ) : (
            <AssigneeDetails
              assigneeDetails={assignee}
              status={status}
              dateOfActionDate={dateOfActionDate}
              conterfeitId={conterfeitId}
              viewCounterfeitRequest={this.viewCounterfeitRequest}
              changeRequestRequest={this.changeRequestRequest}
              onChange={this.onchange}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ViewCounterfeit;
