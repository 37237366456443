import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import ForgotPasswordFormComponent from "../../components/ForgotPassword";
import { ApiRoutes, AppRoutes } from "../../../config";
import { useHistory } from "react-router-dom";
import { forget } from "../../../validators/forget";

// import mainmob3xImg from '../../../assets/img/main_mob@3x.png';

const ForgotPassword = () => {
  let history = useHistory();

  const [userForgotPassword, setForgotPassword] = useState({
    email: "",
    errors: null,
    islogged: false,
    isLoading: false,
  });

  useEffect(() => {
    document.body.classList.add("bg-gradient-primary");
  }, []);

  const handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;
    setForgotPassword({
      ...userForgotPassword,
      [name]: type === "checkbox" ? checked : value,
      errors: { ...userForgotPassword.errors, [name]: "" },
    });
  };

  const handleSubmit = async (event) => {
    event && event.preventDefault();
    const { email } = userForgotPassword;
    let data = {
      email,
    };
    const { isValid, errors } = forget(data);
    console.log(errors);
    if (isValid) {
      setForgotPassword({
        errors: errors,
      });
      getForgetPassword(data);
    } else {
      setForgotPassword({
        errors: errors,
      });
    }
  };

  const getForgetPassword = async (data) => {
    setForgotPassword({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.FORGOT_PASSWORD.service,
      ApiRoutes.FORGOT_PASSWORD.url,
      ApiRoutes.FORGOT_PASSWORD.method,
      ApiRoutes.FORGOT_PASSWORD.authenticate,
      undefined,
      data
    );
    setForgotPassword({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      toast.success(response.messages[0]);
      history.push(AppRoutes.LOGIN);
    }
  };

  // getCheckbox = () => {
  //   setForgotPassword({ islogged: !userForgotPassword.islogged });
  // };

  // componentWillUnmount = () => {
  //   document.body.classList.remove('bg-gradient-primary');
  // };
  // render() {
  const { email, errors, islogged, isLoading } = userForgotPassword;
  return (
    <>
      {/* <div className='container'>
          <div className='row login-row justify-content-center'>
            <div className='col-xl-5 col-lg-5 col-md-6'>
              <div className='card o-hidden border-0 shadow-lg my-7'>
                <div className='card-body p-0'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className='p-5'>
                        <div className='text-center'>
                          <img
                            src={mainImg}
                            srcSet={`${main2xImg} 2x,
                             ${main3xImg} 3x`}
                            className='main-blue'
                            alt=''
                          />

                          <img
                            src={mainmobImg}
    console.log("hjhjh");
                            srcSet={`${mainmob2xImg} 2x,
                             ${mainmob3xImg}.png 3x`}
                            className='main-blue-mob'
                            alt=''
                          />
                          <h1 className='h4 head-top mbt-3'>Login</h1>
                        </div> */}
      <ForgotPasswordFormComponent
        email={email}
        errors={errors}
        islogged={islogged}
        isLoading={isLoading}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      {/* </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         <Footer />  */}
    </>
  );
  // }
};

export default ForgotPassword;
