import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { userValidator } from "../../../validators";
import { AddUserFormComponent } from "../../components/users";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
// import './add-users.css';

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      address1: "",
      address2: "",
      country: "",
      postCode: "",
      state: "",
      phone: "",
      originId: "",
      profile_picture: "",
      originOptions: [],
      stateOptions: [],
      errors: null,
      isUploading: false,
      isAdding: false,
      uploadPercentage: 0,
    };
  }

  componentDidMount = () => {
    this.fetchOriginsOption();
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    var regexp = /^\S*$/;
    if (
      (name === "first_name" ||
        name === "last_name" ||
        name === "phone" ||
        name === "email") &&
      !regexp.test(value)
    ) {
      this.setState({
        errors: { [name]: "Space not allowed" },
      });
    } else {
      this.setState({
        [name]: value,
        errors: {
          ...this.state.errors,
          [name]: "",
        },
      });
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      this.setState({
        errors: {
          imageFile: "Only image files are allowed",
        },
      });
      return;
    }
    // Update the state
    this.setState({
      errors: {
        imageFile: "",
      },
    });
    await this.uploadPicture(files[0]);
    event.target.value = "";
  };
  onDeleteFile = () => {
    this.setState({
      profile_picture: "",
    });
  };
  uploadPicture = async (data) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        profile_picture: path,
      });
    }
  };

  addUser = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_USER.service,
      ApiRoutes.ADD_USER.url,
      ApiRoutes.ADD_USER.method,
      ApiRoutes.ADD_USER.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success("Invitation has been sent successfully.");
      this.props.history.push(AppRoutes.MANAGE_USER);
    }
  };
  fetchOriginsOption = async (search = "") => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ORIGINS_OPTION_LIST.service,
      ApiRoutes.ORIGINS_OPTION_LIST.url,
      ApiRoutes.ORIGINS_OPTION_LIST.method,
      ApiRoutes.ORIGINS_OPTION_LIST.authenticate,
      { search },
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      this.setState({
        originOptions: data,
      });
      return data;
    }
  };
  loadOriginOptions = async (inputValue, callback) => {
    new ApiHelper().cancelRequest("cancel");
    const result = await this.fetchOriginsOption(inputValue);
    callback(result);
  };
  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    // TO reset states on country selection
    if (name === "originId") {
      this.setState({
        stateOptions: options.states.map((state) => ({
          label: state,
          value: state,
        })),
        state: "",
        errors: {
          ...this.state.errors,
          state: "",
          originId: "",
        },
      });
    }
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      email,
      first_name,
      last_name,
      address1,
      address2,
      originId,

      state,
      postCode,
      phone,
      profile_picture,
      stateOptions,
    } = this.state;
    let payload = {
      email,
      first_name,
      last_name,
      phone,
      profile_picture,
      other_info: {
        address1,
        address2,
        country: originId && originId.value ? originId.label : "",
        postCode,
        state,
      },
    };

    if ((stateOptions && stateOptions.length) || !payload.other_info.country) {
      payload.other_info.state = state && state.value ? state.value : "";
    }

    const { isValid, errors } = userValidator(payload);
    if (isValid) {
      this.addUser(payload);
    } else {
      this.setState({
        errors,
      });
      return;
    }
  };

  handleCancel = () => {
    // this.setState({
    //   email: '',
    //   first_name: '',
    //   last_name: '',
    //   address1: '',
    //   address2: '',
    //   country: '',
    //   postCode: '',
    //   state: '',
    //   phone: '',
    //   profile_picture: '',
    // });
    this.props.history.push(AppRoutes.MANAGE_USER);
  };

  render() {
    const {
      email,
      first_name,
      last_name,
      address1,
      address2,
      country,
      postCode,
      originId,
      state,
      phone,
      profile_picture,
      errors,
      isUploading,
      isAdding,
      uploadPercentage,
      originOptions,
      stateOptions,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter d-flex align-items-center justify-content-between pl-4 mb-4">
          <h3>
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>

        {/* <p className='breadcrums-top'>
          {routesArray.map(route => (
            <>
              {route.path ? (
                <Link to={route.path}>{route.label}</Link>
              ) : (
                route.label
              )}
              <img src={arrowIcon} className='breadcrum-seperator' alt='' />
            </>
          ))}
          {currentPage}
        </p> */}
        <div className="">
          <div className="productFormBox">
            <AddUserFormComponent
              first_name={first_name}
              last_name={last_name}
              email={email}
              phone={phone}
              address1={address1}
              address2={address2}
              country={country}
              state={state}
              originId={originId}
              originOptions={originOptions}
              stateOptions={stateOptions}
              postCode={postCode}
              profile_picture={profile_picture}
              errors={errors}
              isUploading={isUploading}
              isAdding={isAdding}
              uploadPercentage={uploadPercentage}
              onSelect={this.onSelect}
              onChange={this.handleChange}
              onFileChange={this.onFileChange}
              onDeleteFile={this.onDeleteFile}
              handleCancel={this.handleCancel}
              handleSubmit={this.handleSubmit}
              loadOriginOptions={this.loadOriginOptions}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AddUser;
