import React from "react";
import Skeleton from "react-skeleton-loader";

export const SmallCardMini = () => (
  <>
      <div className="d-flex align-items-center">
        <div>
          <Skeleton height={"50px"} width={"50px"} color="#e5e9ef" />
        </div>
        <div className="valueBox">
          <h5>
            <Skeleton height={"24px"} width={"75px"} color="#e5e9ef" />
          </h5>
          <p>
            <Skeleton height={"17px"} width={"74px"} color="#e5e9ef" />
          </p>
        </div>
      </div>
      <div className="graphBox">
        <Skeleton height={"50px"} width={"54px"} color ="#e5e9ef" />
      </div>
  </>
);
