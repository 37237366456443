/** @format */
import React from "react";
import "./batches.css";
import { TableLoader } from "../../components/loader/Resusable/Table";
import { AppConfig } from "../../../config";
import noData from "../../../assets/img/noData.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";

export const CounterfeitTable = ({
  thead,
  tbody,
  loading,
  setFilter,
  sortBy,
  order,
}) => {
  return loading ? (
    <TableLoader columns={thead.length} />
  ) : (
    <table
      width="100%"
      cellSpacing="0"
      className="table batchTable intableCheckbox counterfeifReport"
    >
      <thead className="pad-topp">
        <tr className="batchesHeader">
          <th className="table_header">
            Scan/Report ID
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "scanId"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "scanId",
                    sortBy === "scanId"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            batch Number
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "batchNumber"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "batchNumber",
                    sortBy === "batchNumber"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            product name
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "productName"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "productName",
                    sortBy === "productName"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            user name
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "assigneeName"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "assigneeName",
                    sortBy === "assigneeName"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            Status
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "status"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "status",
                    sortBy === "status"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
          <th className="table_header">
            SCAN TIME
            <span className={"cursor-pointer"}>
              <img
                src={
                  sortBy === "createdAt"
                    ? order === "desc"
                      ? dropDownActive
                      : dropUpActive
                    : dropDown
                }
                alt=""
                onClick={() =>
                  setFilter(
                    "createdAt",
                    sortBy === "createdAt"
                      ? order === "asc"
                        ? "desc"
                        : "asc"
                      : "asc"
                  )
                }
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {tbody && tbody.length ? (
          tbody.map((th, index) => (
            <tr key={index} className="btachRow">
              <td>
                <span className="textContol">{th.scanId || th.reportId}</span>
              </td>
              <td>
                <span className="textContol">{th.batch.batchNumber}</span>
              </td>
              <td>
                <span className="textContol">{th.product.productName}</span>
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      marginRight: "12px",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    {th.profile ? (
                      <div
                        style={{
                          width: "36px",
                          backgroundImage:
                            "url(" +
                            (th.profile && th.profile.startsWith("https://")
                              ? th.profile
                              : `${AppConfig.FILES_ENDPOINT}${th.profile}`) +
                            ")",
                          backgroundPosition: "center",
                          height: "36px",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    ) : (
                      <span
                        class="profile_holder"
                        style={{ textTransform: "uppercase" }}
                      >
                        {th.assigneeName
                          ? th.assigneeName.substring(0, 1)
                          : "-"}
                      </span>
                    )}
                    {/* <span
                      class="profile_holder"
                      style={{ textTransform: "uppercase" }}
                    >
                      {th.assigneeName ? th.assigneeName.substring(0, 2) : "-"}
                    </span> */}
                  </div>
                  <span className="textContol">{th.assigneeName || "-"}</span>
                </div>
              </td>
              <td>
                {/* "statusReports" */}
                <span
                  className={
                    th.status === "Acknowledged"
                      ? "acknowledge statusReports"
                      : "statusReports" && th.status === "Completed"
                      ? "Completed statusReports"
                      : "statusReports" && th.status === "In Review"
                      ? "review statusReports"
                      : "statusReports"
                  }
                >
                  {th.status}
                </span>
              </td>
              <td>
                <span className="textContol">
                  {`${th.createdAtDate} | ${th.createdAtTime}`}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td
              colSpan={6}
              className="text-center image-no-data-sm nodataTablePosition"
              // style={{ paddingTop: "150px" }}
            >
              <>
                <img style={{ width: "110px" }} src={noData} alt="" />
                <p>The data you're looking is not available at this moment</p>
              </>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
