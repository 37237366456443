import React, { useState, useEffect } from "react";
// import other from "../../../assets/img/flags/other.svg";
import nodata_map from "../../../assets/img/nodata_map.svg";
import { ApiRoutes, AppConfig } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import mapboxgl from "mapbox-gl";
import ReactCountryFlag from "react-country-flag";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { DashboardWidgetLoader } from "../loader/Resusable/DashboardWidget";
const countryState = require("../../../common/countryState.json");

mapboxgl.accessToken = AppConfig.MAPBOX_ACCESS_TOKEN;
export default function MapCustom() {
  useEffect(() => {
    getUserLocation();
  }, []);
  const [userLocation, setUserLocation] = useState();
  const [myPlaces, setMyPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerMap, setMarkerMap] = useState({});
  const [zoom, setZoom] = useState(5);
  const [infoOpen, setInfoOpen] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [allData, setAllData] = useState();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: AppConfig.GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const getUserLocation = async () => {
    setIsloading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.service,
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.url,
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.method,
      ApiRoutes.DASHBOARD_ACTIVE_USER_LOCATION.authenticate,
      undefined
    );
    setIsloading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.activeLocations;
      const mapData = response.data.allActiveLocations;
      const totalData = response.data.totalActiveLocations;
      setAllData(totalData);
      setUserLocation(data);
      var arrayPlaces = [];
      mapData &&
        mapData.forEach((element) => {
          if (
            element.coordinates[2] &&
            element.coordinates[1] &&
            element.coordinates[0]
          ) {
            arrayPlaces.push({
              id: element.coordinates[2],
              pos: {
                lat: parseFloat(element.coordinates[1]),
                lng: parseFloat(element.coordinates[0]),
              },
            });
          }
        });
      setMyPlaces(arrayPlaces);
    }
  };

  const getCountryFlag = (countryCode) => {
    var result = countryState.filter((x) => x.code3 === countryCode);
    return result[0].code2;
  };

  const fitBounds = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    myPlaces.map((place) => {
      bounds.extend(place.pos);
      return place.id;
    });
    map.fitBounds(bounds);
  };

  const loadHandler = (map) => {
    fitBounds(map);
  };

  const markerLoadHandler = (marker, place) => {
    return setMarkerMap((prevState) => {
      return { ...prevState, [place.id]: marker };
    });
  };

  const markerClickHandler = (event, place) => {
    setSelectedPlace(place);

    if (infoOpen) {
      setInfoOpen(false);
    }
    setInfoOpen(true);
    if (zoom < 13) {
      setZoom(13);
    }
  };
  return (
    <>
      <div className="mycard mapcard">
        {allData && allData > 0 ? (
          <div className="cardBody p-0">
            <div className="row">
              <div className="col-md-8">
                {isLoaded && myPlaces.length > 0 ? (
                  <GoogleMap
                    onLoad={loadHandler}
                    zoom={zoom}
                    mapContainerStyle={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "14px",
                    }}
                  >
                    {myPlaces.map((place, index) => (
                      <Marker
                        key={index}
                        position={place.pos}
                        onLoad={(marker) => markerLoadHandler(marker, place)}
                        onClick={(event) => markerClickHandler(event, place)}
                      />
                    ))}

                    {infoOpen && selectedPlace && (
                      <InfoWindow
                        anchor={markerMap[selectedPlace.id]}
                        onCloseClick={() => setInfoOpen(false)}
                      >
                        <div>
                          <h3>{selectedPlace.id}</h3>
                        </div>
                      </InfoWindow>
                    )}
                  </GoogleMap>
                ) : null}
              </div>

              <div className="col-md-4">
                {isloading ? (
                  <DashboardWidgetLoader />
                ) : (
                  <>
                    <div className="scrollBox_map">
                      <ul>
                        {userLocation &&
                          userLocation.map((info, index) => (
                            <li
                              key={index}
                              className={index === 0 ? "active" : ""}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="imgBox">
                                    <ReactCountryFlag
                                      countryCode={getCountryFlag(info.code)}
                                      svg
                                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                      cdnSuffix="svg"
                                      style={{
                                        width: "32px",
                                        height: "32px",
                                        borderRadius: "50px",
                                      }}
                                    />
                                  </div>
                                  <p className="m-0">{info.country}</p>
                                </div>
                                <h5 className="m-0">{info.percentage}%</h5>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
            style={{ minHeight: "409px" }}
          >
            <img src={nodata_map} />
            <h5>Your users location with map will appear here!</h5>
          </div>
        )}
      </div>
    </>
  );
}
