import React from "react";
import logo from "../../../assets/img/logo-v2.svg";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import moment from "moment";
import { TermsPage } from "../loader/HelpLoaders/TermsPage";

export default function PrivacyPolicyStaff() {
  const [privacyData, setPrivacyData] = useState();
  const [updateOn, setUpdateOn] = useState();
  const [pageLoader, setPageLoader] = useState();

  useEffect(() => {
    getPrivacyData();
  }, []);

  const getPrivacyData = async (key) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_TERMS_PRIVACY.service,
      ApiRoutes.GET_TERMS_PRIVACY.url.replace(":key", "staffPrivacyAndPolicy"),
      ApiRoutes.GET_TERMS_PRIVACY.method,
      ApiRoutes.GET_TERMS_PRIVACY.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
      setPageLoader(false);
    } else {
      if (response.data.data.getKeyValue) {
        setUpdateOn(response.data.data.getKeyValue?.updatedAt);
        handleChange(
          decodeURIComponent(
            escape(window.atob(response?.data?.data?.getKeyValue?.value))
          )
        );
      }
      setPageLoader(false);
    }
  };

  const handleChange = (value) => {
    setPrivacyData(value);
  };
  return (
    <>
      <div className="container mt-5">
        {pageLoader ? (
          <TermsPage />
        ) : (
          <>
            <div className="row centerow">
              <div className="col-md-6">
                <div className="logo">
                  <img src={logo} alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="termsheading">
                  <h5>Privacy Policy</h5>
                  <p>Updated on {moment(updateOn).format("DD MMMM YYYY")}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="terms-page">
                  <ReactQuill
                    value={privacyData}
                    readOnly={true}
                    theme={"bubble"}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
