import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../assets/img/roleEdit.svg";
import trashIcon from "../../../assets/img/delete_icon.svg";
import AddRole from "../../containers/manage-staff-role/AddRole";
import { useState } from "react";

export const RoleRow = ({ roleData, onDelete, refreshAPI }) => {
  const [modalShow, setModalShow] = useState(false);
  const [editRoleData, SeteditRoleDate] = useState(false);

  const handleShow = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const editRole = (value) => {
    SeteditRoleDate(value);
    handleShow();
  };
  return (
    <>
      <tr className="btachRow history">
        <td>
          <span className="textContol">{roleData.roleName}</span>
        </td>
        <td className={"cursor-pointer"}>
          <span className="textContol">{roleData.onBordedStaff}</span>
        </td>
        <td className="pname_scan">
          <span className="textContol">{roleData.updatedAtDate}</span>
        </td>

        <td>
          <div className="batchHistory_table d-flex align-items-center">
            <Button
              variant=""
              className="EditButton mx-2"
              onClick={() => editRole(roleData)}
            >
              <img src={editIcon} alt="" className={"img-main"} />
              Edit
            </Button>
            <Button
              variant=""
              className="exportBatchBtn mx-2"
              onClick={() => onDelete(roleData)}
            >
              <img src={trashIcon} alt="" className={"img-main mr-2"} />
              Delete
            </Button>
          </div>
        </td>
      </tr>
      <AddRole
        modalShow={modalShow}
        modalClose={handleClose}
        placeholder={"Administration"}
        rolePrevData={editRoleData}
        refreshAPI={refreshAPI}
      />
    </>
  );
};
