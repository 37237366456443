import React from "react";
import InputTextFeild from "../../../components/form/InputTextFeild";
import downloadGreen from "../../../../assets/img/downloadGreen.svg";
import downloadWhite from "../../../../assets/img/downloadWhite.svg";
import logo from "../../../../assets/img/demo_logo.svg";
import editBtn from "../../../../assets/img/editBtn.svg";
import { AppConfig } from "../../../../config";
import AsyncSelect from "react-select/async";
import { saveAs } from "file-saver";
import { BatchViewLoader } from "../../../components/loader/Resusable/BatchView";
import { useState } from "react";

function OrganizationInfo({
  storeEdit,
  handleChange,
  isEditStore,
  onEditStore,
  onCancelStore,
  handleStoreSubmit,
  onFileChange,
  onSelectStore,
  error,
  storeLoader,
  imageLoader,
  pageLoader,
}) {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };

  const [saveLoader, setSaveLoader] = useState(false);
  const saveAsFunction = (image, name) => {
    setSaveLoader(image);
    setTimeout(() => {
      if (image && !saveLoader)
        saveAs(`${AppConfig.FILES_ENDPOINT}${image}`, [name]);
      setSaveLoader(false);
    }, 1000);
  };
  return (
    <>
      <div className="tableFilter overflow-hidden pl-0 mb-0 mt-0">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ margin: "0px 0 25px 0" }}
        >
          <h3 className="m-0">Organization Information</h3>
          <div className="d-flex align-items-center">
            {isEditStore ? (
              <>
                <button className="editbtn_table mr-2" onClick={onCancelStore}>
                  Cancel
                </button>
                <button
                  disabled={storeLoader}
                  onClick={handleStoreSubmit}
                  className="editbtn_table"
                >
                  {storeLoader ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                      Save
                    </>
                  ) : (
                    <>Save</>
                  )}
                </button>
              </>
            ) : (
              <button className="editbtn_table" onClick={onEditStore}>
                <img src={editBtn} style={{ marginRight: "8px" }} /> Edit
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="productFormBox profileMainBox">
        {pageLoader ? (
          <BatchViewLoader />
        ) : (
          <form className="profileForm">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>Company Name</label>
                      <InputTextFeild
                        name="companyName"
                        value={storeEdit?.companyName}
                        maxLength={30}
                        onChange={handleChange}
                        errorMessage={error?.companyName}
                        disabled={!isEditStore}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>Company ID</label>
                      <InputTextFeild
                        name="companyId"
                        value={storeEdit?.companyId}
                        maxLength={30}
                        readOnly={true}
                        disabled={true}
                        // onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>Website</label>
                      <InputTextFeild
                        name="website"
                        value={storeEdit?.website}
                        maxLength={30}
                        onChange={handleChange}
                        errorMessage={error?.website}
                        disabled={!isEditStore}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    {!isEditStore ? (
                      <div className="form-group position-relative">
                        <label className={`addBatch-Labels`}>
                          Inside USA & Canada
                        </label>
                        <div className="position-relative">
                          <div className="form-group">
                            <div className="form-control d-flex align-items-center">
                              {storeEdit?.insideUSACanada}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>
                          Inside USA & Canada
                        </label>

                        <AsyncSelect
                          // loadOptions={loadCountryData}
                          placeholder={"Select"}
                          styles={customStyles}
                          maxMenuHeight={200}
                          className={"form-control"}
                          defaultOptions={[
                            {
                              label: "Yes",
                              value: true,
                            },
                            {
                              label: "No",
                              value: false,
                            },
                          ]}
                          value={{
                            label: storeEdit?.insideUSACanada,
                            value: storeEdit?.insideUSACanada,
                          }}
                          onChange={(selectedOption) =>
                            onSelectStore(
                              selectedOption,
                              "insideUSACanada",
                              storeEdit
                            )
                          }
                          noOptionsMessage={() =>
                            "No option found related to your search"
                          }
                        />
                        <div>{error?.insideUSACanada}</div>
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>
                        Business Registration Number
                      </label>
                      <div className=" downloadBtnBox">
                        {/* {isEditStore && (
                          <label
                            type="button"
                            className={
                              imageLoader === "businessRegNo_image"
                                ? "chooseBtn uploading"
                                : "chooseBtn"
                            }
                          >
                            <input
                              className="imgUpload img_up "
                              name="businessRegNo_image"
                              type="file"
                              accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                              onChange={onFileChange}
                            />
                            {imageLoader === "businessRegNo_image" ? (
                              <>
                                <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                                Choose
                              </>
                            ) : (
                              <>Choose</>
                            )}
                          </label>
                        )} */}

                        <InputTextFeild
                          name="businessRegNo"
                          value={storeEdit?.businessRegNo}
                          maxLength={30}
                          onChange={handleChange}
                          errorMessage={error?.businessRegNo}
                          disabled={true}
                        />
                        <span
                          className="downloadBtn"
                          type="button"
                          onClick={() =>
                            saveAsFunction(
                              storeEdit?.businessRegNo_image,
                              "Business Registration Number"
                            )
                          }
                        >
                          {saveLoader === storeEdit?.businessRegNo_image ? (
                            <>
                              <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                              Download
                            </>
                          ) : (
                            <>
                              <img className="IconGreen" src={downloadGreen} />{" "}
                              <img className="IconWhite" src={downloadWhite} />{" "}
                              Download
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>
                        VAT or EIN Number
                      </label>
                      <div className="downloadBtnBox">
                        <InputTextFeild
                          name="VATNumber"
                          value={storeEdit?.VATNumber}
                          maxLength={30}
                          onChange={handleChange}
                          errorMessage={error?.VATNumber}
                          disabled={true}
                        />
                        <span
                          className="downloadBtn"
                          type="button"
                          onClick={() =>
                            saveAsFunction(
                              storeEdit?.VATNumber_image,
                              "VAT Number"
                            )
                          }
                        >
                          {saveLoader === storeEdit?.VATNumber_image ? (
                            <>
                              <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                              Download
                            </>
                          ) : (
                            <>
                              <img className="IconGreen" src={downloadGreen} />{" "}
                              <img className="IconWhite" src={downloadWhite} />{" "}
                              Download
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>
                        Trade Mark Certification Number
                      </label>
                      <div className="downloadBtnBox">
                        <InputTextFeild
                          name="TradeMarkCertificateNo"
                          value={storeEdit?.TradeMarkCertificateNo}
                          maxLength={30}
                          onChange={handleChange}
                          errorMessage={error?.TradeMarkCertificateNo}
                          disabled={true}
                        />
                        <span
                          className="downloadBtn"
                          type="button"
                          onClick={() =>
                            saveAsFunction(
                              storeEdit?.TradeMarkCertificateNo_image,
                              "Trade Mark Certificate Number"
                            )
                          }
                        >
                          {saveLoader ===
                          storeEdit?.TradeMarkCertificateNo_image ? (
                            <>
                              <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                              Download
                            </>
                          ) : (
                            <>
                              <img className="IconGreen" src={downloadGreen} />{" "}
                              <img className="IconWhite" src={downloadWhite} />{" "}
                              Download
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6"></div>

                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className={`addBatch-Labels`}>Company Logo</label>
                      <div
                        className={
                          isEditStore
                            ? "chooseFileBox position-relative downloadBtnBox"
                            : "downloadBtnBox"
                        }
                      >
                        {isEditStore ? (
                          <div className="btn_collapes">
                            <button>Choose</button>

                            <input
                              type="file"
                              accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                              name="storeLogo"
                              onChange={onFileChange}
                              disabled={!isEditStore}
                            />
                          </div>
                        ) : (
                          <>
                            {storeEdit?.storeLogo && (
                              <>
                                <div className="form-group mb-20px">
                                  <div className="form-control d-flex align-items-center logo_name_length">
                                    {storeEdit?.storeLogo}
                                  </div>
                                </div>
                                <span
                                  className="downloadBtn"
                                  type="button"
                                  onClick={() =>
                                    saveAsFunction(
                                      storeEdit?.storeLogo,
                                      "Store Logo"
                                    )
                                  }
                                >
                                  {saveLoader === storeEdit?.storeLogo ? (
                                    <>
                                      <i className="fa fa-spinner fa-spin test-spin"></i>{" "}
                                      Download
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        className="IconGreen"
                                        src={downloadGreen}
                                      />
                                      <img
                                        className="IconWhite"
                                        src={downloadWhite}
                                      />
                                      Download
                                    </>
                                  )}
                                </span>
                              </>
                            )}
                          </>
                        )}

                        {!storeEdit?.storeLogo && (
                          <p className="noFile">No File Choosen</p>
                        )}
                      </div>
                      <div className="text-danger">{error?.storeLogo}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                    <div
                      className="d-flex align-items-center"
                      style={{ height: "94px", width: "94px" }}
                    >
                      <img
                        className="w-100"
                        src={
                          storeEdit?.storeLogo
                            ? storeEdit?.storeLogo.startsWith("https://")
                              ? storeEdit?.storeLogo
                              : `${AppConfig.FILES_ENDPOINT}${storeEdit?.storeLogo}`
                            : logo
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = logo;
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

export default OrganizationInfo;
