/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Collapse, OverlayTrigger, Popover } from "react-bootstrap";
import { NavConfig } from "./NavConfig";
import { ApiRoutes, AppConfig, AppRoutes, MobileWidth } from "../../../config";
import AppsActive from "../../../assets/img/manage_clarity_icon.svg";
import avtaruser from "../../../assets/img/avtar.jfif";
import downArrowIcon from "../../../assets/img/sidebarDropdown.svg";
import upArrowIcon from "../../../assets/img/sidebarDropdown.svg";
import avtar from "../../../assets/img/logo-v2.svg";
import { Modal } from "react-bootstrap";
import SettingPopup from "../accountSetting/SettingPopup";
import { SubNavConfig } from "./SubNavConfig";
import { ApiHelper, setUserData } from "../../../helpers";
import { toast } from "react-toastify";
import { CurrentPlanLoaderMini } from "../loader/Resusable/CurrentPlanLoaderMini";
import { AccountNavConfig } from "./accountNavConfig";
import { Button } from "react-bootstrap";
import dummyimg from "../../../assets/img/avtar.jfif";
import closeNotify from "../../../assets/img/closeNotify.svg";
import notificationBell from "../../../assets/img/notificationBell.svg";
import no_notify from "../../../assets/img/no_notify.svg";
import Shopping from "../../../assets/img/product_icon.svg";
import batchesIcon from "../../../assets/img/batches_icon.svg";
import manage_staff_icon from "../../../assets/img/manage_staff_icon.svg";
import Clarity from "../../../assets/img/manage_clarity_icon.svg";
import logo from "../../../assets/img/demo_logo.svg";
import counterfeitLogo from "../../../assets/img/counterfeitNotify.svg";
import scanLogo from "../../../assets/img/scanNotify.svg";

const Nav = (props) => {
  let history = useHistory();
  const { location } = history;
  const { pathname } = location;

  const overlayRef = useRef();
  const [open, setOpen] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileDetail, setProfileDetail] = useState(false);
  const [isUploading, setIsUploading] = useState();
  const [permissionModule, setPermissionModule] = useState();
  const [notificationCount, setNotificationCount] = useState();
  const [notifications, setNotifications] = useState([]);
  const [loader, setLoader] = useState(false);
  const [companyLogo, setCompanyLogo] = useState();
  const loadref = useRef(true);
  // handle window resizing
  const handleResizing = () => {
    if (window.innerWidth < MobileWidth) {
      document.body.classList.remove("sidebar-toggled");
      setIsMobile(true);
    } else {
      setIsMobile(false);
      document.body.classList.remove("sidebar-toggled");
    }
  };
  useEffect(() => {
    handleResizing();
    window.addEventListener("resize", handleResizing);
    return () => window.removeEventListener("resize", () => null);
  }, []);
  useEffect(() => {
    NavConfig.filter((nav) => nav.childrens && nav.childrens.length).map(
      (element, index) => {
        const initialPath = `/${pathname.split("/")[2]}`;

        const submenu = element.childrens?.find(
          (sub) => sub.path.search(initialPath) > 0
        );
        const isSubMenuActive = submenu?.tabName;
        if (isSubMenuActive) {
          setOpen(isSubMenuActive);
        }
        return true;
      }
    );
    if (loadref.current) {
      setLoading(true);
    }
    getProfile();
    return () => {
      loadref.current = false;
    };
  }, [pathname]);

  const closeLogut = () => {
    setShowLogout(false);
  };
  const openLogut = () => {
    setShowLogout(true);
  };

  const closeNotification = () => {
    overlayRef.current.click();
  };
  const getProfile = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      setProfileDetail(data);
      setPermissionModule(response?.data?.data?.user?.permission);
      pageLevelPermission(response?.data?.data?.user?.permission);
      fetchNotifications();
      getClientProfile(data?.user?.id);
    }
  };

  const getClientProfile = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ACCOUNT_SETTINGS.service,
      ApiRoutes.GET_ACCOUNT_SETTINGS.url.replace(":id", id),
      ApiRoutes.GET_ACCOUNT_SETTINGS.method,
      ApiRoutes.GET_ACCOUNT_SETTINGS.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.store?.storeLogo;
      setCompanyLogo(data);
    }
  };

  const fetchNotifications = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NOTIFICATIONS_ACTIVITY.service,
      ApiRoutes.GET_NOTIFICATIONS_ACTIVITY.url,
      ApiRoutes.GET_NOTIFICATIONS_ACTIVITY.method,
      ApiRoutes.GET_NOTIFICATIONS_ACTIVITY.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      setNotificationCount(response.data.count);
      setNotifications(data);
    }
  };

  const deleteNotification = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_NOTIFICATION.service,
      ApiRoutes.DELETE_NOTIFICATION.url.replace(":id", id),
      ApiRoutes.DELETE_NOTIFICATION.method,
      ApiRoutes.DELETE_NOTIFICATION.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      fetchNotifications();
    }
  };

  const clearAllNotifications = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.service,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.url,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.method,
      ApiRoutes.CLEAR_ALL_NOTIFICATIONS.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      fetchNotifications();
      overlayRef.current.click();
    }
  };

  const notificationRead = async (notification) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.NOTIFICATION_MARK_READ.service,
      ApiRoutes.NOTIFICATION_MARK_READ.url.replace(":id", notification.id),
      ApiRoutes.NOTIFICATION_MARK_READ.method,
      ApiRoutes.NOTIFICATION_MARK_READ.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      fetchNotifications();
      let url = `${notification.redirectionURL}`;
      let validate = url.slice(0, 1);
      console.log("validate", validate);
      if (validate[0] !== "/") {
        url = "/" + url;
      }

      console.log("url", url);
      history.push(url);
      overlayRef.current.click();
    }
  };
  const onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      if (name === "profile_picture") {
        setProfileDetail({
          ...profileDetail,
          errors: {
            ...this.state.storeData.errors,
          },
        });
      }
    }
    uploadPicture(files[0], name);
    event.target.value = "";
  };
  const uploadPicture = async (data, name) => {
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      handleSubmit(path, name);
    }
  };

  const handleSubmit = async (data, name) => {
    let id = profileDetail?.user?.id;

    let payload = {
      [name]: data,
    };
    setIsUploading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CLIENT_PIC.service,
      ApiRoutes.UPDATE_CLIENT_PIC.url.replace(":id", id),
      ApiRoutes.UPDATE_CLIENT_PIC.method,
      ApiRoutes.UPDATE_CLIENT_PIC.authenticate,
      undefined,
      payload
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setIsUploading(false);
    } else {
      getProfile();
      toast.success(response?.data?.message);
      setIsUploading(false);
      // setShowSetting(false);
    }
  };

  const showNavLink = (
    moduleName,
    childModuleName = null,
    childrenlength = null,
    permissions = []
  ) => {
    var returnValue = true;
    if (permissions && permissions.length > 0) {
      var filter = permissions.filter(
        (x) => x.module.toLowerCase() === moduleName.toLowerCase()
      );
      if (childModuleName) {
        // check child module
        if (filter.length > 0) {
          var subModulesFilter = filter[0].subModules.includes(
            childModuleName.toLowerCase()
          );

          if (subModulesFilter) {
            returnValue = false;
          }
        }
      } else {
        // check parent module
        if (filter.length > 0) {
          if (
            filter[0].accessType === "Read Only" &&
            filter[0].subModules.length === 0
          ) {
            returnValue = false;
          }
          if (filter[0].subModules.length === childrenlength) {
            returnValue = false;
          }
        }
      }
    }
    return returnValue;
  };

  const pageLevelPermission = (permissions) => {
    if (pathname) {
      var splitUrl = pathname.split("/");
      splitUrl = splitUrl.filter((a) => a);
      if (splitUrl.length === 1) {
        var filter = NavConfig.filter((x) => x.path === "/" + splitUrl[0]);
        var parentName = null;
        if (filter.length > 0) {
          parentName = filter[0].name;
          if (parentName) {
            const storeNavValue = showNavLink(parentName, null, 0, permissions);

            if (storeNavValue === false) {
              if (parentName === "Dashboard") {
                history.push("/help/faq");
              } else {
                history.push("/dashboard");
              }
            }
          }
        }
        // parent Level check
      } else if (splitUrl.length > 1) {
        var parentName = null;
        var childName = null;
        var childrenLength = null;
        var filter = NavConfig.filter((x) => x.path === "/" + splitUrl[0]);
        if (filter.length > 0) {
          parentName = filter[0].name;
          var childFilter = filter[0].childrens.filter(
            (x) =>
              x.path === "/" + splitUrl[0] + "/" + splitUrl[1] &&
              x.tabName === "/" + splitUrl[0]
          );
          childrenLength = childFilter.length;
          if (childFilter.length > 0) {
            childName = childFilter[0].name;
          }
        }

        if (parentName && childName) {
          const storeNavValue = showNavLink(
            parentName,
            childName,
            childrenLength,
            permissions
          );

          if (storeNavValue === false) {
            history.push("/dashboard");
          }
        }
      }
    }
  };

  const Logout = async () => {
    setLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.LOGOUT.service,
      ApiRoutes.LOGOUT.url,
      ApiRoutes.LOGOUT.method,
      ApiRoutes.LOGOUT.authenticate,
      undefined,
      undefined
    );
    setLoader(false);
    if (response && response.isError) {
      toast.error(response?.messages[0]);
    } else {
      toast.success(response?.data?.message);
      setShowLogout(false);
      setUserData();
      history.push("/");
      // window.location.href = "/";
    }
  };

  const getIcon = (type) => {
    var imgReturn = null;
    switch (type) {
      case "product":
        imgReturn = <img className="icon" src={Shopping} alt="" />;
        break;
      case "batch":
        imgReturn = <img className="icon" src={batchesIcon} alt="" />;
        break;
      case "staff":
        imgReturn = <img className="icon" src={manage_staff_icon} alt="" />;
        break;
      case "counterfeit":
        imgReturn = <img className="icon" src={counterfeitLogo} alt="" />;
        break;
      case "scan":
        imgReturn = <img className="icon" src={scanLogo} alt="" />;
        break;
      case "admin":
        imgReturn = (
          <img
            className="logo"
            src={
              companyLogo
                ? companyLogo.startsWith("https://")
                  ? companyLogo
                  : `${AppConfig.FILES_ENDPOINT}${companyLogo}`
                : logo
            }
            alt=""
          />
        );
        break;
      default:
        imgReturn = <img src={dummyimg} alt="" />;
        break;
    }
    return imgReturn;
  };
  const popover = (
    <Popover className="custom-popover">
      <div class="dropdown dropright notification_aside">
        <div class="dropdown-menu " aria-labelledby="dropdownMenuButton">
          <h4>Notifications</h4>

          <div className="scroll_box">
            {notifications?.length ? (
              notifications?.map((info, index) => (
                <div
                  key={index}
                  onClick={() => notificationRead(info)}
                  className="d-flex align-items-center notify_row cursor-pointer"
                >
                  {!info.isRead && <span className="readNotify"></span>}
                  <div className="img">
                    {/* <img
                      src={
                        info?.notificationImage
                          ? info?.notificationImage.startsWith("https://")
                            ? info?.notificationImage
                            : `${AppConfig.FILES_ENDPOINT}${info?.notificationImage}`
                          : logo
                      }
                    /> */}
                    {getIcon(info.notificationType)}
                  </div>
                  <div>
                    <h4>{info.title}</h4>
                    <h5>
                      {info.createdAtDate} | {info.createdAtTime}
                    </h5>
                  </div>
                  <Button
                    onClick={() => {
                      deleteNotification(info.id);
                    }}
                    variant=""
                    className="closeNotify"
                  >
                    <img src={closeNotify} />
                  </Button>
                </div>
              ))
            ) : (
              <div
                className="d-flex align-items-center justify-content-center flex-column h-100 w-100"
                style={{ paddingRight: "20px" }}
              >
                <img style={{ marginBottom: "12px" }} src={no_notify} />
                <h4 className="m-0" style={{ fontSize: "18px" }}>
                  No new notifications found
                </h4>
              </div>
            )}
          </div>

          {notifications?.length ? (
            <div className="buttonBox">
              <Button
                onClick={closeNotification}
                variant=""
                className="cancel_btn"
              >
                Cancel
              </Button>
              <Button
                onClick={clearAllNotifications}
                variant=""
                className="clear_btn"
              >
                Clear All
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </Popover>
  );

  return (
    <>
      <div ref={overlayRef}></div>
      <ul
        className={`navbar-nav sidebar sidebarOptions accordion ${
          props.showSidebar ? "toggled" : ""
        }`}
        // style={{
        //   zIndex: "9999",
        //   overflow: "unset",
        // }}
        id="sidebar"
      >
        <li>
          <div className="d-flex align-items-center justify-content-center flex-column mainAvtarBox">
            <div className="avtarBox">
              <Link to={AppRoutes.DASHBOARD}>
                <img src={avtar} alt="avtar" />
              </Link>
            </div>
            {/* <h3>Administrator</h3>
                <p>Client ID: THD-0546</p>
                <span>Super Admin</span> */}
          </div>
        </li>
        <li className="m-0">
          <span className="sectionTitle cursor-pointer">
            <Link to={AppRoutes.DASHBOARD} className="main">
              main menu
            </Link>
          </span>
        </li>
        {pathname === AppRoutes.Create_Plan ||
        pathname === AppRoutes.Billing ||
        pathname === AppRoutes.Payment ||
        pathname === AppRoutes.Purchase ? (
          SubNavConfig.map((element, index) => {
            return (
              <li
                className={`nav-item ${
                  pathname === element.path ||
                  (element.path && element.path === pathname.split("/")[1]) ||
                  (element.path &&
                    element.path.split("/")[1] === pathname.split("/")[1])
                    ? "active active-show"
                    : ""
                }`}
                key={index}
              >
                <Link className="nav-link collapsed sub-link" to={element.path}>
                  <img
                    src={
                      pathname === element.path ||
                      (element.path &&
                        element.path.search(`/${pathname.split("/")[1]}`) >= 0)
                        ? element.activeImgUrl
                        : element.imgUrl || AppsActive
                    }
                    className="apps"
                    alt=""
                  />
                  <span>{element.name}</span>
                </Link>
              </li>
            );
          })
        ) : pathname === AppRoutes.PROFILE ||
          pathname === AppRoutes.SECURITY ||
          pathname === AppRoutes.AUTHENTICATION ||
          pathname === AppRoutes.PREFERENCES ||
          pathname === AppRoutes.SESSION ? (
          AccountNavConfig.map((element, index) => {
            return (
              <li
                className={`nav-item ${
                  pathname === element.path ||
                  (element.path && element.path === pathname.split("/")[2]) ||
                  (element.path &&
                    element.path.split("/")[1] === pathname.split("/")[2])
                    ? "active active-show"
                    : ""
                }`}
                key={index}
              >
                <Link className="nav-link collapsed sub-link" to={element.path}>
                  <img
                    src={
                      pathname === element.path ||
                      (element.path &&
                        element.path.search(`/${pathname.split("/")[1]}`) >= 0)
                        ? element.activeImgUrl
                        : element.imgUrl || AppsActive
                    }
                    className="apps"
                    alt=""
                  />
                  <span>{element.name}</span>
                </Link>
              </li>
            );
          })
        ) : (
          <>
            {
              // permissionModule &&
              //   permissionModule.length > 0 &&
              !loading &&
                NavConfig.map((element, index) => {
                  const initialPath = `/${pathname.split("/")[2]}`;
                  const submenu = element.childrens?.findIndex(
                    (sub) => sub.path.search(initialPath) > 0
                  );
                  const isSubMenuActive = submenu?.tabName;

                  return (
                    <li
                      className={`nav-item ${
                        pathname === element.path ||
                        (element.path &&
                          element.path === pathname.split("/")[1]) ||
                        (element.path &&
                          element.path.split("/")[1] === pathname.split("/")[1])
                          ? "active active-show"
                          : ""
                      }`}
                      key={index}
                    >
                      {element.childrens && element.childrens.length ? (
                        showNavLink(
                          element.name,
                          null,
                          element.childrens.length,
                          permissionModule
                        ) && (
                          <>
                            <span
                              className={`nav-link sub-link cursor-pointer ${
                                isSubMenuActive === "" ? "collapsed" : ""
                              }`}
                              id={`collapse${index}`}
                              onClick={() => {
                                setOpen(
                                  open !== element.tabName
                                    ? element.tabName
                                    : ""
                                );
                              }}
                            >
                              <img
                                src={
                                  pathname === element.path ||
                                  (element.path &&
                                    element.path === pathname.split("/")[1]) ||
                                  (element.path &&
                                    element.path.split("/")[1] ===
                                      pathname.split("/")[1])
                                    ? element.activeImgUrl
                                    : element.imgUrl || AppsActive
                                }
                                className="Group"
                                alt=""
                              />
                              <span
                                className={`${
                                  pathname === element.path ||
                                  (element.path &&
                                    element.path === pathname.split("/")[1])
                                    ? "active active-show"
                                    : ""
                                }`}
                              >
                                {element.name}
                              </span>
                              <img
                                className="arr-icon-side"
                                src={
                                  open === element.tabName
                                    ? upArrowIcon
                                    : downArrowIcon
                                }
                                alt=""
                              />
                            </span>
                            <Collapse in={open === element.tabName}>
                              <div id={`collapse${index}`}>
                                <div className="py-1 collapse-inner">
                                  {element.childrens.map((subMenu, index) => {
                                    return (
                                      <>
                                        {showNavLink(
                                          element.name,
                                          subMenu.name,
                                          element.childrens.length,
                                          permissionModule
                                        ) && (
                                          <Link
                                            className={`collapse-item  ${
                                              subMenu.path.search(
                                                initialPath
                                              ) >= 0 ||
                                              subMenu.path === pathname ||
                                              ((pathname.split("/")[2] ===
                                                "onboarding" ||
                                                pathname.split("/")[2] ===
                                                  "view" ||
                                                pathname.split("/")[2] ===
                                                  "requests") &&
                                                subMenu.path === "/staff")
                                                ? "active"
                                                : ""
                                            }`}
                                            key={index}
                                            to={subMenu.path}
                                          >
                                            <span className="sub-li">
                                              {subMenu.name}
                                            </span>
                                          </Link>
                                        )}
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </Collapse>
                          </>
                        )
                      ) : element.tabName === AppRoutes.LOGOUT ? (
                        <>
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            rootClose
                            overlay={popover}
                          >
                            <a
                              class="dropdown-toggle no-arrow nav-link collapsed sub-link"
                              type="button"
                            >
                              <img
                                src={notificationBell}
                                className="apps"
                                alt=""
                              />
                              <span>Notifications </span>
                              <span className="count">{notificationCount}</span>
                            </a>
                          </OverlayTrigger>
                          <a
                            className="nav-link collapsed sub-link"
                            onClick={openLogut}
                          >
                            <img
                              src={element.imgUrl || AppsActive}
                              className="apps"
                              alt=""
                            />
                            <span>Logout</span>
                          </a>
                        </>
                      ) : (
                        showNavLink(
                          element.name,
                          null,
                          0,
                          permissionModule
                        ) && (
                          <Link
                            className="nav-link collapsed sub-link"
                            to={element.path}
                          >
                            <img
                              src={
                                pathname === element.path ||
                                (element.path &&
                                  element.path.search(
                                    `/${pathname.split("/")[1]}`
                                  ) >= 0)
                                  ? element.activeImgUrl
                                  : element.imgUrl || AppsActive
                              }
                              className="apps"
                              alt=""
                            />
                            <span>{element.name}</span>
                          </Link>
                        )
                      )}
                    </li>
                  );
                })
            }
            <li className="cursor-pointer ">
              {loading ? (
                <CurrentPlanLoaderMini />
              ) : (
                <div
                  onClick={() => setShowSetting(true)}
                  className="sidebarFooter d-flex hoverEffect"
                >
                  <div className="imgbox">
                    <div
                      className="user_image"
                      style={{
                        backgroundImage:
                          "url(" +
                          (profileDetail?.user?.profile_picture
                            ? profileDetail?.user?.profile_picture.startsWith(
                                "https://"
                              )
                              ? profileDetail?.user?.profile_picture
                              : `${AppConfig.FILES_ENDPOINT}${profileDetail?.user?.profile_picture}`
                            : avtaruser) +
                          ")",
                      }}
                    ></div>
                  </div>
                  <div style={{ marginLeft: "12px" }}>
                    <h3>
                      {[
                        profileDetail?.user?.first_name,
                        profileDetail?.user?.last_name,
                      ]
                        .filter(Boolean)
                        .join(" ") || "-"}
                    </h3>
                    <h4>
                      {profileDetail?.user?.role_id?.roleName || "Super Admin"}
                    </h4>
                    <p>
                      {profileDetail?.user?.clientId
                        ? profileDetail?.user?.clientId
                        : profileDetail?.user?.employee_id}
                    </p>
                  </div>
                </div>
              )}
            </li>
          </>
        )}
      </ul>

      <SettingPopup
        showSetting={showSetting}
        profileDetail={profileDetail}
        closeSetting={() => setShowSetting(false)}
        onFileChange={onFileChange}
        isUploading={isUploading}
      />

      <Modal show={showLogout} onHide={closeLogut} className="logoutModal">
        <Modal.Header>
          <span className="logoutAlert">!</span>
        </Modal.Header>
        <Modal.Body>
          <h3>Are you sure you want to log out?</h3>
          <p>
            By logging out you will need to log in again to access the system.
          </p>
        </Modal.Body>

        <Modal.Footer className="justify-content-between">
          <button
            disabled={loader}
            onClick={closeLogut}
            className="logout-cancel"
          >
            Cancel
          </button>
          <button disabled={loader} onClick={Logout} className="logout-btn">
            {loader ? (
              <>
                <i className="fa fa-spinner fa-spin test-spin"></i>
                Log Out
              </>
            ) : (
              <>Log Out</>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Nav;
