/* eslint-disable no-sparse-arrays */
import { AppRoutes } from "../../../config";
// import Shipping from '../../../assets/img/batches_icon.svg';
import home_icon from "../../../assets/img/home_icon.svg";
import active_home_icon from "../../../assets/img/acvtiveIcon/home_icon.svg";
import Clarity from "../../../assets/img/manage_clarity_icon.svg";
import active_Clarity from "../../../assets/img/acvtiveIcon/manage_clarity_icon.svg";
// import product_icon from '../../../assets/figma/product_icon.svg';
import settings from "../../../assets/img/settings_icon.svg";
import active_settings from "../../../assets/img/acvtiveIcon/settings_icon.svg";
import helpIcon from "../../../assets/img/help-circle.svg";
import active_helpIcon from "../../../assets/img/help-active.svg";
import cms_icon from "../../../assets/img/cms_icon.svg";
import Shopping from "../../../assets/img/product_icon.svg";
import active_Shopping from "../../../assets/img/acvtiveIcon/product_icon.svg";
import batchesIcon from "../../../assets/img/batches_icon.svg";
import active_batchesIcon from "../../../assets/img/acvtiveIcon/batches_icon.svg";
import notificationBell from "../../../assets/img/notificationBell.svg";
import active_notificationBell from "../../../assets/img/acvtiveIcon/notificationBell.svg";
import manage_staff_icon from "../../../assets/img/manage_staff_icon.svg";
import active_manage_staff_icon from "../../../assets/img/acvtiveIcon/manage_staff_icon.svg";
import reports from "../../../assets/img/reports_icon.svg";
import active_reports from "../../../assets/img/acvtiveIcon/active_report.svg";
import logoutIcon from "../../../assets/img/logoutIcon.svg";
import active_logoutIcon from "../../../assets/img/acvtiveIcon/logoutIcon.svg";

export const NavConfig = [
  {
    name: "Dashboard",
    path: AppRoutes.DASHBOARD,
    imgUrl: home_icon,
    activeImgUrl: active_home_icon,
  },
  {
    name: "Manage Clarity",
    imgUrl: Clarity,
    path: AppRoutes.CLARITY,
    tabName: AppRoutes.CLARITY,
    activeImgUrl: active_Clarity,
    childrens: [
      {
        name: "Counterfeit",
        path: AppRoutes.COUNTERFEIT,
        tabName: AppRoutes.CLARITY,
      },
      {
        name: "Scans",
        path: AppRoutes.SCANS,
        tabName: AppRoutes.CLARITY,
      },
      {
        name: "Manage User",
        path: AppRoutes.MANAGE_USER,
        tabName: AppRoutes.CLARITY,
      },
      {
        name: "Manage Video",
        path: AppRoutes.MANAGE_VIDEO_CATEGORY,
        tabName: AppRoutes.CLARITY,
      },
    ],
  },
  {
    name: "Manage Products",
    path: AppRoutes.MANAGE_PRODUCT,
    imgUrl: Shopping,
    activeImgUrl: active_Shopping,
    tabName: AppRoutes.MANAGE_PRODUCT,
  },
  // {
  //   name: 'Origin',
  //   path: AppRoutes.MANAGE_ORIGIN,
  //   // imgUrl: MapPin,
  //   tabName: AppRoutes.MANAGE_ORIGIN,
  // },
  {
    name: "Batches",
    path: AppRoutes.BATCHES,
    imgUrl: batchesIcon,
    tabName: AppRoutes.BATCHES,
    activeImgUrl: active_batchesIcon,
  },
  {
    name: "Manage Staff",
    path: AppRoutes.MANAGE_STAFF,
    imgUrl: manage_staff_icon,
    tabName: AppRoutes.MANAGE_STAFF,
    activeImgUrl: active_manage_staff_icon,
    childrens: [
      {
        name: "Staff Listing",
        path: AppRoutes.MANAGE_STAFF_LIST,
        tabName: AppRoutes.MANAGE_STAFF,
      },
      {
        name: "Manage Roles",
        path: AppRoutes.MANAGE_STAFF_ROLE,
        tabName: AppRoutes.MANAGE_STAFF,
      },
    ],
  },
  // {
  //   name: 'Manage Client',
  //   path: AppRoutes.MANAGE_CLIENTS,
  //   imgUrl: clients,
  //   tabName: AppRoutes.MANAGE_CLIENTS,
  // },
  // {
  //   name: 'CMS',
  //   imgUrl: cms_icon,
  //   path: AppRoutes.CMS,
  //   tabName: AppRoutes.CMS,
  //   childrens: [
  //     {
  //       name: 'Push Notification',
  //       path: AppRoutes.PUSH_NOTIFICATIONS,
  //       tabName: AppRoutes.CMS,
  //     },
  //     {
  //       name: 'FAQs',
  //       path: AppRoutes.FAQS,
  //       tabName: AppRoutes.CMS,
  //     },
  //     {
  //       name: 'Contact',
  //       path: AppRoutes.CONTACT,
  //       tabName: AppRoutes.CMS,
  //     },
  //     {
  //       name: 'Terms and Conditions',
  //       path: AppRoutes.TERMS_AND_CONDITION,
  //       tabName: AppRoutes.CMS,
  //     },
  //     {
  //       name: 'Privacy Policy',
  //       path: AppRoutes.CMS_PRIVACY_POLICY,
  //       tabName: AppRoutes.CMS,
  //     },
  //   ],
  // },
  {
    name: "Reports",
    imgUrl: reports,
    path: AppRoutes.REPORTS,
    tabName: AppRoutes.REPORTS,
    activeImgUrl: active_reports,
    childrens: [
      {
        name: "Batches",
        path: AppRoutes.BATCHES_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Product",
        path: AppRoutes.PRODUCT_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Users",
        path: AppRoutes.USERS_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Scans",
        path: AppRoutes.SCAN_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
      {
        name: "Counterfeit",
        path: AppRoutes.COUNTERFEIT_REPORTS,
        tabName: AppRoutes.REPORTS,
      },
    ],
  },
  // {
  //   name: 'Test',
  //   imgUrl: reports,
  //   path: AppRoutes.TEST,
  //   tabName: AppRoutes.TEST,
  //   childrens: [
  //     {
  //       name: 'Test 1',
  //       path: AppRoutes.TEST_ONE,
  //       tabName: AppRoutes.TEST,
  //     },
  //     {
  //       name: 'Test 2',
  //       path: AppRoutes.TEST_TWO,
  //       tabName: AppRoutes.TEST,
  //     },
  //   ],
  // },
  {
    name: "Help",
    path: AppRoutes.HELP,
    imgUrl: helpIcon,
    tabName: AppRoutes.HELP,
    activeImgUrl: active_helpIcon,
    childrens: [
      {
        name: "Faq",
        path: AppRoutes.FAQ,
        tabName: AppRoutes.HELP,
      },
      {
        name: "Tutorials",
        path: AppRoutes.TUTORIALS,
        tabName: AppRoutes.HELP,
      },
      {
        name: "Contact",
        path: AppRoutes.CONTACTUS,
        tabName: AppRoutes.HELP,
      },
      {
        name: "Terms and Conditions",
        path: AppRoutes.TERMANDCON,
        tabName: AppRoutes.HELP,
      },
      {
        name: "Privacy Policy",
        path: AppRoutes.PRIVACYPOLICY,
        tabName: AppRoutes.HELP,
      }
    ],
  },
  {
    name: "Settings",
    path: AppRoutes.SETTINGS,
    imgUrl: settings,
    tabName: AppRoutes.SETTINGS,
    activeImgUrl: active_settings,
    childrens: [
      // {
      //   name: "Branding",
      //   path: AppRoutes.TEST_ONE,
      //   tabName: AppRoutes.SETTINGS,
      // },
      {
        name: "ID Formats",
        path: AppRoutes.IDFORMATS,
        tabName: AppRoutes.SETTINGS,
      },
      {
        name: "Notifications",
        path: AppRoutes.NOTIFICATION,
        tabName: AppRoutes.SETTINGS,
      },
    ],
  },
  // {
  //   name: "Subscription",
  //   path: AppRoutes.Manage_Subscription,
  //   imgUrl: settings,
  //   tabName: AppRoutes.Manage_Subscription,
  //   activeImgUrl: active_settings,
  //   childrens: [
  //     {
  //       name: "Current Plan",
  //       path: AppRoutes.Create_Plan,
  //       tabName: AppRoutes.Manage_Subscription,
  //     },
  //     {
  //       name: "Billings",
  //       path: AppRoutes.Billing,
  //       tabName: AppRoutes.Manage_Subscription,
  //     },
  //     // {
  //     //   name: "Payment Methods",
  //     //   path: AppRoutes.IDFORMATS,
  //     //   tabName: AppRoutes.Manage_Subscription,
  //     // },
  //     // {
  //     //   name: "Purchase Subscription",
  //     //   path: AppRoutes.IDFORMATS,
  //     //   tabName: AppRoutes.Manage_Subscription,
  //     // }
  //   ],
  // },
  
  // {
  //   name: "Notifications",
  //   path: AppRoutes.SETTING,
  //   imgUrl: notificationBell,
  //   tabName: AppRoutes.SETTING,
  //   activeImgUrl: active_notificationBell,
  // },
  {
    name: "Logout",
    path: AppRoutes.LOGOUT,
    imgUrl: logoutIcon,
    tabName: AppRoutes.LOGOUT,
    activeImgUrl: active_logoutIcon,
  },
];
