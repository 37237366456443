import React from "react";
import logov2 from "../../../assets/img/logo-v2.svg";
import clockExpired from "../../../assets/img/clock_expired.svg";

function Expired() {
  return (
    <>
      <div className="row d-flex justify-content-center mt-md-5 pt-md-5">
        <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 col-xxl-9 customGrid-xxl  mt-md-5">
          <div className="formCard w-100 expiredCard">
            <div className="cardHeader">
              <img src={logov2} />
            </div>
            <div className="cardBody">
              <img src={clockExpired} />
              <p>“Your session has been expired”</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Expired;
