/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes, pagesOption } from "../../../config";
import { ApiHelper } from "../../../helpers";
import Pagination from "../../components/pagination";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import SearchFilter from "../../components/filters/SearchFilter";
import noData from "../../../assets/img/no-data.png";
import addIcon from "../../../assets/img/addIcon.svg";
import "./manage-role.css";
import FilterDropdown from "../../components/filters/FilterDropdown";
import { ListComponent } from "../../components/role";
import AddRole from "./AddRole";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { FilterComponent } from "../../components/filters";

class ManageRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: [],
      errors: null,
      isLoading: false,
      isFilterApplied: false,
      isUpdating: false,
      isAdding: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 11,
      sortBy: "roleName",
      search: "",
      modalShow: false,
      id: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sort || "roleName",
        order: query.order ? query.order : "desc",
      },
      () => this.fetchRoleData()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };
  fetchRoleData = async (loading = true) => {
    const { currentPage, limit, sortBy, search, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      // sortByStatus: sortBy !== "name" ? sortBy : "",
      search,
      order,
    };
    this.setState({
      isLoading: loading,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ROLE.service,
      ApiRoutes.GET_ROLE.url,
      ApiRoutes.GET_ROLE.method,
      ApiRoutes.GET_ROLE.authenticate,
      data,
      undefined
    );

    this.setState({
      isLoading: false,
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { role, totalRecords },
      } = response.data;
      this.setState({
        role,
        totalRecords,
      });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    console.log("onSearch", this.state.search);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  viewHandler = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.VIEW_STAFF.replace(":id", id),
      state: {
        search,
      },
    });
  };
  onRoleModal = () => {
    this.setState({ modalShow: true });
  };

  onCloseModal = () => {
    this.setState({ modalShow: false });
  };

  setSortFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  refreshAPI = () => {
    this.onCloseModal();
    this.fetchRoleData();
  };

  onDelete = async (roleData) => {
    console.log("role", roleData.onBordedStaff);

    if (roleData.onBordedStaff > 0) {
      await SweetAlertComponent({
        text: `You can't delete this role as it's already assigned`,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      const { value } = await SweetAlertComponent({
        // title: 'Are you sure?',
        text: `Are you sure, you want to delete this role ?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_ROLE.service,
        ApiRoutes.DELETE_ROLE.url.replace(":id", roleData.id),
        ApiRoutes.DELETE_ROLE.method,
        ApiRoutes.DELETE_ROLE.authenticate,
        undefined,
        undefined
      );

      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await this.fetchRoleData();
        toast.success(response.messages[0]);
      }
    }
  };
  render() {
    const {
      isLoading,
      totalRecords,
      currentPage,
      limit,
      sortBy,
      order,
      search,
      role,
      isFilterApplied,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const filtersOption = [
      {
        label: "Role",
        value: "roleName",
      },
      {
        label: "Onboardded Staff",
        value: "onBordedStaff",
      },
      {
        label: "Updated On",
        value: "updatedAt",
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
            />
          </h3>
        </div>

        <div className="tableFilter d-flex justify-content-between mb-0 mb-lg-4 ">
          <SearchFilter
            id={"sm-search-filter"}
            placeholder={"Search by Name and Employee ID"}
            search={search}
            onSearch={this.onSearch}
            onChange={this.handleSearch}
          />
          <div className="d-flex align-items-center filter_holder">
            <div className="mr-3">
              {/* <FilterDropdown
                style={
                  "no-arrow d-flex align-items-center justify-content-between sortBy-custom"
                }
                filtersOption={filtersOption}
                pagesOption={pagesOption}
                sortBy={sortBy}
                setFilter={this.setSortFilter}
              /> */}
              <FilterComponent
                options={filtersOption}
                selectedFilter={sortBy}
                label={"Sort by"}
                onFilterChange={(selectedValue) =>
                  this.setSortFilter(selectedValue, "roleName")
                }
              />
            </div>

            <div className="dp-right">
              <button
                className="addProductbtn no-background"
                onClick={this.onRoleModal}
              >
                <img src={addIcon} alt="" className="mr-2" />
                Add New Role
              </button>
            </div>
          </div>
        </div>
        <div className="card tableCard">
          <ListComponent
            role={role}
            isLoading={isLoading}
            isFilterApplied={isFilterApplied}
            setFilter={this.setSortFilter}
            onDelete={this.onDelete}
            refreshAPI={this.refreshAPI}
            order={order}
            handleModal={this.onRoleModal}
            sortBy={sortBy}
          />
        </div>
        <div className="footer-pg px-0 px-lg-3">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
        <AddRole
          modalShow={this.state.modalShow}
          modalClose={this.onCloseModal}
          placeholder={"Enter Role here"}
          refreshAPI={this.refreshAPI}
        />
      </div>
    );
  }
}

export default ManageRole;
