/** @format */

import React, { Component } from "react";
import { toast } from "react-toastify";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";
import "./batches.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import Pagination from "../../components/pagination";
import { ReportHeader } from "./ReportHeader";
import { ReportCard } from "./ReportCard";
import { UsersTable } from "./UsersTable";
import { GraphLoader } from "../../components/loader/Dashboard/GraphLoader";
import { UsersGraphOSType } from "./UsersGraphOSType";
import { UsersGraphLocation } from "./UsersGraphLocation";
import FileSaver from "file-saver";
import no_data from "../../../assets/img/no_data.svg";
import moment from "moment";

class ReportUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTime: "Today",
      customStartDate: new Date(),
      customEndDate: new Date(),
      cardsLoading: true,
      tableLoading: true,
      osGraphLoading: true,
      locationGraphLoading: true,
      osGraphData: null,
      locationGraphData: null,
      cardsData: null,
      tableData: null,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      topScannedProductDataSet: {},
      sortBy: "createdAt",
      order: "desc",
      isBatchExporting: false,
    };
  }

  dateRangeCallback = async (start, end, label) => {
    this.setState({
      selectedTime: "Custom",
      customStartDate: new Date(moment(start)),
      customEndDate: new Date(moment(end)),
    });
    setTimeout(() => {
      this.fetchData();
    }, 500);
  };

  onPageChanged = (page) => {
    const { selectedTime, customStartDate, customEndDate, limit, currentPage } =
      this.state;
    if (page !== currentPage) {
      this.setState({
        currentPage: page,
      });
      let skip = limit * (page - 1);
      const payload = {
        selectedTime,
        customStartDate,
        customEndDate,
        skip,
        limit,
      };
      this.fetchTable(payload);
    }
  };

  changeSelectedTime = async (val) => {
    await this.setState({
      selectedTime: val,
    });

    switch (val) {
      case "Today":
        this.setState({
          customStartDate: new Date(),
          customEndDate: new Date(),
        });
        break;
      case "Yesterday":
        this.setState({
          customStartDate: new Date(moment().subtract(1, "day")),
          customEndDate: new Date(moment().subtract(1, "day")),
        });
        break;
      case "Week":
        this.setState({
          customStartDate: new Date(moment().startOf("week")),
          customEndDate: new Date(moment().endOf("week")),
        });
        break;
      case "Month":
        this.setState({
          customStartDate: new Date(moment().startOf("month")),
          customEndDate: new Date(moment().endOf("month")),
        });
        break;
      case "Year":
        this.setState({
          customStartDate: new Date(moment().startOf("year")),
          customEndDate: new Date(moment().endOf("year")),
        });
        break;
      default:
    }

    this.fetchData();
  };

  componentDidMount = () => {
    this.fetchData();
  };
  setFilter = (sortBy, order) => {
    if (sortBy) {
      this.setState({
        sortBy: sortBy,
      });
    }
    if (order) {
      this.setState({
        order: order,
      });
    }
    this.fetchData();
  };

  fetchData = async () => {
    const {
      selectedTime,
      customStartDate,
      customEndDate,
      limit,
      currentPage,
      order,
      sortBy,
    } = this.state;
    let skip = limit * (currentPage - 1);
    const payload = {
      selectedTime,
      customStartDate,
      customEndDate,
      skip,
      limit,
    };
    let data = {
      selectedTime,
      order,
      sortBy,
      customStartDate,
      customEndDate,
    };
    this.fetchCards(payload);
    this.fetchTable(data);
    this.fetchOSGraph(payload);
    this.fetchLocationGraph(payload);
  };

  fetchCards = async (data) => {
    this.setState({
      cardsLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_CARDS.service,
      ApiRoutes.REPORTS_USERS_CARDS.url,
      ApiRoutes.REPORTS_USERS_CARDS.method,
      ApiRoutes.REPORTS_USERS_CARDS.authenticate,
      data,
      undefined
    );

    this.setState({
      cardsLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        cardsData: response.data.data,
      });
    }
  };

  fetchTable = async (data) => {
    this.setState({
      tableLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_TABLE.service,
      ApiRoutes.REPORTS_USERS_TABLE.url,
      ApiRoutes.REPORTS_USERS_TABLE.method,
      ApiRoutes.REPORTS_USERS_TABLE.authenticate,
      data,
      undefined
    );

    this.setState({
      tableLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        tableData: response.data.data.users,
        totalRecords: response.data.data.totalRecords,
      });
    }
  };

  fetchOSGraph = async (data) => {
    this.setState({
      osGraphLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_GRAPH_OS.service,
      ApiRoutes.REPORTS_USERS_GRAPH_OS.url,
      ApiRoutes.REPORTS_USERS_GRAPH_OS.method,
      ApiRoutes.REPORTS_USERS_GRAPH_OS.authenticate,
      data,
      undefined
    );

    this.setState({
      osGraphLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        osGraphData: response.data.data,
      });
    }
  };

  fetchLocationGraph = async (data) => {
    this.setState({
      locationGraphLoading: true,
    });

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.service,
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.url,
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.method,
      ApiRoutes.REPORTS_USERS_GRAPH_LOCATION.authenticate,
      data,
      undefined
    );

    this.setState({
      locationGraphLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        locationGraphData: response.data.data,
      });
    }
  };

  //  10/06/22
  onExport = async () => {
    const { selectedTime, customStartDate, customEndDate } = this.state;
    let data = {
      selectedTime,
      customStartDate,
      customEndDate,
    };
    let type = "csv";
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }
    // if (selectedTime === 'Custom') data = { selectedTime, customStartDate, customEndDate }

    let blobType, extension;
    if (data) {
      this.setState({
        isBatchExporting: true,
      });
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.service,
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.url,
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.method,
      ApiRoutes.EXPORT_REPORTS_USERS_TABLE.authenticate,
      data,
      undefined,
      "blob"
    );
    this.setState({
      isBatchExporting: false,
      activeBatchGroup: "",
    });
    if (response && response.isError) {
    } else {
      switch (type) {
        case "pdf":
          blobType = "application/pdf";
          extension = "pdf";
          break;
        case "excel":
          blobType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          extension = "xlsx";
          break;
        case "csv":
          blobType = "application/csv";
          extension = "csv";
          break;
        default:
          break;
      }
      let blob = new Blob([response.data], {
        type: blobType,
      });
      FileSaver.saveAs(
        blob,
        `Users.${extension}`
        // `${moment().format('YYYY_MM_DD')}_${batchId}.${extension}`,
      );
    }
  };

  render() {
    const {
      props: {
        location: { pathname },
      },
    } = this;

    const thead = ["User Name", "User Email", "Created On"];

    // const activePerc = parseInt(
    //   this.state.cardsData?.activeUsersPercentage
    // ).toFixed(0);
    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>
        <div className="productFormBox reportBatches">
          <div className="row">
            <ReportHeader
              selectedTime={this.state.selectedTime}
              dateRangeCallback={this.dateRangeCallback}
              changeSelectedTime={this.changeSelectedTime}
              onExport={this.onExport}
              customStartDate={this.state.customStartDate}
              customEndDate={this.state.customEndDate}
              isExporting={this.state.isBatchExporting}
            />

            <ReportCard
              loading={this.state.cardsLoading}
              title="Total Users"
              total={this.state.cardsData?.totalUsers}
              perc={this.state.cardsData?.totalCalculation}
              // prevString = {''}
              prevString={this.state.cardsData?.dateString}
              size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4 mt-md-0"
            />

            <ReportCard
              loading={this.state.cardsLoading}
              title="Active Users"
              total={this.state.cardsData?.activeUsersPercentage + "%"}
              perc={this.state.cardsData?.activeCalculation}
              prevString={this.state.cardsData?.dateString}
              size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4 mt-md-0"
            />

            <ReportCard
              loading={this.state.cardsLoading}
              title="Users Increased"
              total={this.state.cardsData?.addedUserPercentage + "%"}
              perc={this.state.cardsData?.addedCalculation}
              prevString={this.state.cardsData?.dateString}
              size="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4  mt-4 mt-lg-0"
            />

            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-7 mt-2">
                  <div className="card tableCard">
                    <div className="tabel-outer">
                      <div className="table-responsive fixed-height-table" style={{height: "890px"}}>
                        <UsersTable
                          loading={this.state.tableLoading}
                          thead={thead}
                          tbody={this.state.tableData}
                          setFilter={this.setFilter}
                          sortBy={this.state.sortBy}
                          order={this.state.order}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="footer-pg px-0 px-lg-3 w-100">
                    {!this.state.tableLoading ? (
                      <>
                        <div className="float-left mt-2">
                          {this.state.totalRecords > this.state.limit ? (
                            <div>
                              <p className="totalRecords">
                                Page{" "}
                                {this.state.totalRecords > 0
                                  ? this.state.currentPage
                                  : 0}{" "}
                                of{" "}
                                {this.state.totalRecords % this.state.limit > 0
                                  ? parseInt(
                                      this.state.totalRecords / this.state.limit
                                    ) + 1
                                  : parseInt(
                                      this.state.totalRecords / this.state.limit
                                    )}{" "}
                              </p>
                            </div>
                          ) : null}
                        </div>
                        <div className="float-right mt-0">
                          <div className="d-flex align-items-center tableFooter">
                            {this.state.totalRecords > this.state.limit ? (
                              <div className="float-right d-flex align-items-center">
                                <Pagination
                                  totalRecords={this.state.totalRecords}
                                  currentPage={this.state.currentPage}
                                  pageLimit={this.state.limit}
                                  onPageChanged={this.onPageChanged}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-12 col-lg-12 col-xl-5 mt-2">
                  <div className="userReport dashboardPage">
                    <div className="mycard">
                      <div className=" p-0">
                        <div className="cardHeader p-0">
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="mt-0 font-book ">
                                Devices and OS metrics
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="cardBody">
                          {this.state.osGraphLoading ? (
                            <GraphLoader />
                          ) : (
                            <div className="">
                              {this.state.osGraphData?.labels?.length ? (
                                <UsersGraphOSType
                                  dataset={this.state.osGraphData}
                                />
                              ) : (
                                <div
                                  className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                                  style={{ paddingRight: "20px" }}
                                >
                                  <img src={no_data} alt="" />
                                  <h5>No Data Yet</h5>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      </div>

                      <div className="mycard mt-4">
                      <div className="">
                        <div className="cardHeader p-0">
                          <div className="row">
                            <div className="col-md-12">
                              <h6 className="mt-0 font-book ">Places</h6>
                            </div>
                          </div>
                        </div>
                        <div className="cardBody">
                          {this.state.locationGraphLoading ? (
                            <GraphLoader />
                          ) : (
                            <div>
                              {this.state.locationGraphData?.labels?.length ? (
                                <UsersGraphLocation
                                  dataset={this.state.locationGraphData}
                                />
                              ) : (
                                <div
                                  className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                                  style={{ paddingRight: "20px" }}
                                >
                                  <img src={no_data} alt="" />
                                  <h5>No Data Yet</h5>
                                </div>
                              )}
                            </div>
                          )}

                          {/* <UsersGraphLocation
                      dataset={this.state.locationGraphData}
                    /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportUsers;
