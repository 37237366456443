import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { DashboardWidgetLoader } from "../loader/Resusable/DashboardWidget";
import { CalendarCardLoader } from "../loader/Dashboard/CalendarCardLoader";
export default function CalendarCustom({
  calenderEvent,
  getFilterDate,
  isLoading,
}) {
  const [value, onChange] = useState(new Date());

  const getDateCount = (date) => {
    var returnVal = "";
    var result = calenderEvent.filter(
      (x) =>
        moment(x.eventDate).format("DD-MM-YYYY") ===
        moment(date).format("DD-MM-YYYY")
    );
    if (result.length > 0) {
      returnVal = result[0].count;
    }
    return returnVal;
  };
  return (
    <>
      <div
        className="mycard calendarCustom p-0"
        style={{ paddingRight: "12px", minHeight: "409px" }}
      >
        <div className="cardBody">
          <div>
            {isLoading ? (
              <CalendarCardLoader />
            ) : (
              <>
              <div  className="d-flex align-items-center justify-content-center">
                <Calendar
                  onChange={onChange}
                  goToRangeStartOnSelect={false}
                  value={value}
                  showNeighboringMonth={false}
                  onClickDay={getFilterDate}
                  tileContent={({ date }) => {
                    if (
                      calenderEvent &&
                      calenderEvent.find(
                        (x) =>
                          moment(x.eventDate).format("DD-MM-YYYY") ===
                          moment(date).format("DD-MM-YYYY")
                      )
                    ) {
                      return (
                        <div className="contentBefore">
                          {getDateCount(date)}
                        </div>
                      );
                    }
                  }}
                  tileClassName={({ date }) => {
                    if (
                      calenderEvent &&
                      calenderEvent.find(
                        (x) =>
                          moment(x.eventDate).format("DD-MM-YYYY") ===
                          moment(date).format("DD-MM-YYYY")
                      )
                    ) {
                      return "contentDate";
                    }
                  }}
                />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
