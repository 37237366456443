import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import EmailOtp from "../../components/accountSetting/profile/EmailOtp";
import GoogleAuthenticator from "../../components/accountSetting/profile/GoogleAuthenticator";
import SmsOtp from "../../components/accountSetting/profile/SmsOtp";

function Authentication(props) {
  const [clientEdit, setClientEdit] = useState();
  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setPageLoader(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.id;
      getClientProfile(data);
    }
  };

  const getClientProfile = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ACCOUNT_SETTINGS.service,
      ApiRoutes.GET_ACCOUNT_SETTINGS.url.replace(":id", id),
      ApiRoutes.GET_ACCOUNT_SETTINGS.method,
      ApiRoutes.GET_ACCOUNT_SETTINGS.authenticate,
      undefined
    );

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      setClientEdit(data);
    }
  };
  return (
    <div className="container-fluid fluid-pd-2">
      <div className="row">
        <div className="col-md-12">
          <SmsOtp
            pageLoader={pageLoader}
            clientEdit={clientEdit}
            getProfile={getProfile}
          />
        </div>
        <div className="col-md-12">
          <EmailOtp
            pageLoader={pageLoader}
            clientEdit={clientEdit}
            getProfile={getProfile}
          />
        </div>
        <div className="col-md-12">
          <GoogleAuthenticator
            pageLoader={pageLoader}
            clientEdit={clientEdit}
            getProfile={getProfile}
          />
        </div>
      </div>
    </div>
  );
}

export default Authentication;
