// import { lazy } from 'react';
import { AppRoutes } from "../config";
// import { DashboardLoader } from '../view/components/loader/Dashboard';
// import { OriginLoader } from '../view/components/loader/Origin';
// import { ProductLoader } from '../view/components/loader/Product';
import { CounterfeitLoader } from "../view/components/loader/Counterfeit";
import { ScanLoader } from "../view/components/loader/Scan";
import { UsersLoader } from "../view/components/loader/Users";
// import { BatchesLoader } from '../view/components/loader/Batches';
import { CategoryLoader } from "../view/components/loader/Category";
import { VideoLoader } from "../view/components/loader/Video";
import { AddUserLoader } from "../view/components/loader/Users/AddUsers";
import { ViewUserLoader } from "../view/components/loader/Users/ViewUser";
// import { ProductAddLoader } from '../view/components/loader/Product/ProductAdd';
import { BatchAddLoader } from "../view/components/loader/Batches/BatchAddLoader";
import { ViewBatchLoader } from "../view/components/loader/Batches/ViewBatchLoader";
import { ViewCounterfeitLoader } from "../view/components/loader/Counterfeit/ViewCounterfeit";
import { ClientRequestLoader } from "../view/components/loader/Client/ClientRequestLoader";

import Login from "../view/containers/login";
import Invitation from "../view/containers/InviteForm";

import SetPassword from "../view/components/SetPassword";
// import Home from '../view/containers/home';
import Counterfeit from "../view/containers/counterfeit";
import Scans from "../view/containers/scans";
// import Faqs from '../view/containers/faqs';
// import PushNotifications from '../view/containers/push-notifications';
import ManageUser from "../view/containers/manage-users";

import ManageVideoCategory from "../view/containers/manage-video-category";
import ManageVideo from "../view/containers/manage-video";
import AddUser from "../view/containers/add-users/index";
import ViewUser from "../view/containers/view-user";

import ViewCounterfeit from "../view/containers/view-counterfeit";

import ViewCounterfeitMap from "../view/containers/view-counterfeit/MapCompnent";
import ManageProduct from "../view/containers/Product";
import ManageBatches from "../view/containers/Batches";
import Mailer from "../view/containers/mailer/Mailer";
// import ListComponent from '../view/components/products/ProductListComponent';

// import ManageOrigin from '../view/containers/manage-origin';

// import AddProduct from '../view/components/products/AddProductFormComponent';
import AddProduct from "../view/containers/Product/AddProduct";

import AddBatch from "../view/containers/Batches/AddBatch";

import ViewBatch from "../view/containers/Batches/ViewBatch";

// import ManageBatches from '../view/containers/manage-batches';
// import Setting from '../view/containers/setting';
import { MapLoader } from "../view/components/loader/Counterfeit/Map";
// import ManageClient from '../view/containers/manage-clients';
// import ManageClientRequests from '../view/containers/manage-clients/Request';
// import ViewClient from '../view/containers/manage-clients/ViewClient';
// import BatchView from '../view/containers/manage-clients/Batch/BatchView';
// import ViewClientRequest from '../view/containers/manage-clients/Request/View';
// import ManagePermission from '../view/containers/manage-clients/Permissions';
// import ManageContact from '../view/containers/manage-contact';
// import ManageTerms from '../view/containers/manage-terms';
// import ManagePrivacy from '../view/containers/manage-privacy';
import ManageStaffRequests from "../view/containers/manage-staff/Request";
import ViewStaff from "../view/containers/manage-staff/ViewStaff";
import ViewStaffRequest from "../view/containers/manage-staff/Request/View";
import ManageStaffPermission from "../view/containers/manage-staff/Permissions";
import ManageRole from "../view/containers/manage-staff-role";
import Home from "../view/containers/Dashboard/Home";
// import EditClientRequest from '../view/containers/manage-clients/Request/edit';
import Reports from "../view/containers/reports";
import Batches from "../view/containers/reports/Batches";
import ReportCounterfeit from "../view/containers/reports/Counterfeit";
import ReportProduct from "../view/containers/reports/Product";
import ReportScan from "../view/containers/reports/Scan";
import ReportUsers from "../view/containers/reports/Users";
import Idformats from "../view/components/settings/Idformats";
import Settings from "../view/containers/Settings";
import Notifications from "../view/components/settings/Notifications/Index";
import Help from "../view/containers/Help";
import Faq from "../view/components/Help/Faq/Faq";
import Tutorials from "../view/components/Help/Tutorials/Tutorials";
import Contact from "../view/components//Help/Contact/Contact";
import ManageStaffList from "../view/containers/manage-staff";
import ManageStaff from "../view/containers/manage-staff/ManageStaff";
import ManageSubscription from "../view/components/manageSubscription/ManageSubscription";
import Billings from "../view/containers/Subscription/billing/Billing";
import Payment from "../view/containers/Subscription/payment/Payment";
import Purchase from "../view/containers/Subscription/purchase/Purchase";
import CurrentPlan from "../view/components/manageSubscription/CurrentPlan";
import TrackRequest from "../view/components/inviteForm/TrackRequest";
import Profile from "../view/containers/AccountSettings/Profile";
import Security from "../view/containers/AccountSettings/Security";
import Authentication from "../view/containers/AccountSettings/Authentication";
import Preferences from "../view/containers/AccountSettings/Preferences";
import SessionModule from "../view/containers/AccountSettings/SessionModule";
import TermCondition from "../view/components/Help/TermCondition/TermCondition";
import PrivcacyPolicy from "../view/components/Help/PrivacyPolicy/PrivacyPolicy";
import PrivacyPolicy from "../view/components/Help/PrivacyPolicy/PrivacyPolicy";
import VerifyIdentity from "../view/containers/VerifyIdentity";
import OtpForm from "../view/components/IdentityForm/otp";
import TermsAndConditionApp from "../view/components/terms&condition/TermsAndConditionApp";
import TermsAndConditionClient from "../view/components/terms&condition/TermsAndConditionClient";
import TermsAndConditionStaff from "../view/components/terms&condition/TermsAndConditionStaff";
import PrivacyPolicyClient from "../view/components/terms&condition/PrivacyPolicyClient";
import PrivacyPolicyApp from "../view/components/terms&condition/PrivacyPolicyApp";
import PrivacyPolicyStaff from "../view/components/terms&condition/PrivacyPolicyStaff";
// import TestOne from '../view/containers/testing/TestOne';
// import TestTwo from '../view/containers/testing/TestTwo';
// const Login = lazy(() => import('../view/containers/login'));
// const Home = lazy(() => import('../view/containers/home'));
// const Counterfeit = lazy(() => import('../view/containers/counterfeit'));
// const Scans = lazy(() => import('../view/containers/scans'));
// const ManageUser = lazy(() => import('../view/containers/manage-users'));
// const ManageVideoCategory = lazy(() =>
//   import('../view/containers/manage-video-category'),
// );
// const ManageVideo = lazy(() => import('../view/containers/manage-video'));
// const AddUser = lazy(() => import('../view/containers/add-users'));
// const ViewUser = lazy(() => import('../view/containers/view-user'));
// const ViewCounterfeit = lazy(() =>
//   import('../view/containers/view-counterfeit'),
// );
// const ViewCounterfeitMap = lazy(() =>
//   import('../view/containers/view-counterfeit/MapCompnent'),
// );
// const ManageProduct = lazy(() => import('../view/containers/manage-product'));
// const ManageOrigin = lazy(() => import('../view/containers/manage-origin'));
// const AddProduct = lazy(() =>
//   import('../view/containers/manage-product/AddProduct'),
// );
// const AddBatch = lazy(() =>
//   import('../view/containers/manage-batches/AddBatch'),
// );
// const ViewBatch = lazy(() =>
//   import('../view/containers/manage-batches/ViewBatch'),
// );

// const ManageBatches = lazy(() => import('../view/containers/manage-batches'));
// const Setting = lazy(() => import('../view/containers/setting'));

export const routesArray = [
  {
    path: AppRoutes.LOGIN,
    exact: true,
    name: "Login",
    breadcrumb: "Login",
    component: Login,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.SET_PASSWORD,
    exact: true,
    name: "SetPassword",
    breadcrumb: "SetPassword",
    component: SetPassword,
    isAuthenticatedRoute: false,
  },

  {
    path: AppRoutes.DASHBOARD,
    exact: true,
    name: "Dashboard",
    breadcrumb: "Dashboard",
    component: Home,
    isAuthenticatedRoute: true,
    // loader: DashboardLoader,
  },
  {
    path: AppRoutes.CLARITY,
    exact: true,
    name: "Manage Clarity",
    breadcrumb: "Manage Clarity",
    component: Counterfeit,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.COUNTERFEIT,
    exact: true,
    name: "Counterfeit",
    breadcrumb: "Counterfeit",
    component: Counterfeit,
    isAuthenticatedRoute: true,
    loader: CounterfeitLoader,
  },
  {
    path: AppRoutes.COUNTERFEIT_VIEW,
    exact: true,
    name: "View",
    breadcrumb: "View",
    component: ViewCounterfeit,
    isAuthenticatedRoute: true,
    loader: ViewCounterfeitLoader,
  },
  {
    path: AppRoutes.COUNTERFEIT_MAP_VIEW,
    exact: true,
    name: "Map",
    breadcrumb: "Map",
    component: ViewCounterfeitMap,
    isAuthenticatedRoute: true,
    loader: MapLoader,
  },
  {
    path: AppRoutes.SCANS,
    exact: true,
    name: "Scans",
    breadcrumb: "Scans",
    component: Scans,
    isAuthenticatedRoute: true,
    loader: ScanLoader,
  },
  {
    path: AppRoutes.MANAGE_USER,
    exact: true,
    name: "Manage User",
    breadcrumb: "Users",
    component: ManageUser,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.ADD_USER,
    exact: true,
    name: "Add User",
    breadcrumb: "Add User",
    component: AddUser,
    isAuthenticatedRoute: true,
    loader: AddUserLoader,
  },
  {
    path: AppRoutes.VIEW_USER,
    exact: true,
    name: "View",
    breadcrumb: "View", // for custom breadcrumb like user name
    component: ViewUser,
    isAuthenticatedRoute: true,
    loader: ViewUserLoader,
  },
  {
    path: AppRoutes.MANAGE_VIDEO_CATEGORY,
    exact: true,
    name: "Manage Video",
    breadcrumb: "Manage Video",
    component: ManageVideoCategory,
    isAuthenticatedRoute: true,
    loader: CategoryLoader,
  },
  {
    path: AppRoutes.MANAGE_VIDEO,
    exact: true,
    name: "Manage Video",
    breadcrumb: "custom",
    component: ManageVideo,
    isAuthenticatedRoute: true,
    loader: VideoLoader,
  },
  {
    path: AppRoutes.MANAGE_PRODUCT,
    exact: true,
    name: "Manage Products",
    breadcrumb: "Manage Products",
    component: ManageProduct,
    isAuthenticatedRoute: true,
    // loader: ProductLoader,
  },
  {
    path: AppRoutes.INVITE_FORM,
    exact: true,
    name: "Invite Form",
    breadcrumb: "Invite Form",
    component: Invitation,
    isAuthenticatedRoute: true,
    // loader: ProductLoader,
  },
  {
    path: AppRoutes.MANAGE_BATCH,
    exact: true,
    name: "Batches",
    breadcrumb: "Batch Listing",
    component: ManageBatches,
    isAuthenticatedRoute: true,
    // loader: ProductLoader,
  },
  {
    path: AppRoutes.ADD_BATCH,
    exact: true,
    name: "Batches",
    breadcrumb: "Add Batch",
    component: AddBatch,
    isAuthenticatedRoute: true,
    // loader: BatchAddLoader,
  },
  {
    path: AppRoutes.ADD_PRODUCT,
    exact: true,
    name: "Add Product",
    breadcrumb: "Add Product",
    component: AddProduct,
    isAuthenticatedRoute: true,
    // loader: ProductAddLoader,
  },
  {
    path: AppRoutes.EDIT_PRODUCT,
    exact: true,
    name: "Edit Product",
    breadcrumb: "Edit Product",
    component: AddProduct,
    isAuthenticatedRoute: true,
    // loader: ProductAddLoader,
  },

  // {
  //   path: AppRoutes.MANAGE_BATCH,
  //   exact: true,
  //   name: 'Batches',
  //   breadcrumb: 'Batch Listing',
  //   component: ManageBatches,
  //   isAuthenticatedRoute: true,
  //   loader: BatchesLoader,
  // },
  // {
  //   path: AppRoutes.ADD_BATCH,
  //   exact: true,
  //   name: 'Add Batch',
  //   breadcrumb: 'Add Batch',
  //   component: AddBatch,
  //   isAuthenticatedRoute: true,
  //   loader: BatchAddLoader,
  // },
  {
    path: AppRoutes.EDIT_BATCH,
    exact: true,
    name: "Edit Batch",
    breadcrumb: "Edit Batch",
    component: AddBatch,
    isAuthenticatedRoute: true,
    loader: BatchAddLoader,
  },
  {
    path: AppRoutes.VIEW_BATCH,
    exact: true,
    name: "Batches",
    breadcrumb: "Batch History",
    component: ViewBatch,
    isAuthenticatedRoute: true,
    loader: ViewBatchLoader,
  },
  // {
  //   path: AppRoutes.MANAGE_ORIGIN,
  //   exact: true,
  //   name: 'Manage Origin',
  //   breadcrumb: 'Manage Origin',
  //   component: ManageOrigin,
  //   isAuthenticatedRoute: true,
  //   loader: OriginLoader,
  // },

  // {
  //   path: AppRoutes.MANAGE_CLIENTS,
  //   exact: true,
  //   name: 'Manage Clients',
  //   breadcrumb: 'Client listing details',
  //   component: ManageClient,
  //   isAuthenticatedRoute: true,
  //   loader: CategoryLoader,
  // },
  // {
  //   path: AppRoutes.MANAGE_CLIENTS_REQUESTS,
  //   exact: true,
  //   name: 'Client Requests',
  //   breadcrumb: 'View Client Requests',
  //   component: ManageClientRequests,
  //   isAuthenticatedRoute: true,
  //   loader: ClientRequestLoader,
  // },
  // {
  //   path: AppRoutes.VIEW_CLIENT_REQUEST,
  //   exact: true,
  //   name: 'View Details',
  //   breadcrumb: 'custom',
  //   component: ViewClientRequest,
  //   isAuthenticatedRoute: true,
  // },
  // {
  //   path: AppRoutes.VIEW_CLIENT,
  //   exact: true,
  //   name: 'View Details',
  //   breadcrumb: 'custom',
  //   component: ViewClient,
  //   isAuthenticatedRoute: true,
  // },
  // {
  //   path: AppRoutes.VIEW_CLIENT_BATCH,
  //   exact: true,
  //   name: 'Batches History',
  //   breadcrumb: 'Batches History',
  //   component: BatchView,
  //   isAuthenticatedRoute: true,
  // },
  // {
  //   path: AppRoutes.MANAGE_CLIENTS_PERMISSION,
  //   exact: true,
  //   name: 'View Details',
  //   breadcrumb: 'Permissions',
  //   component: ManagePermission,
  //   isAuthenticatedRoute: true,
  // },
  {
    path: AppRoutes.MANAGE_STAFF,
    exact: true,
    name: "Manage Staff",
    breadcrumb: "Manage Staff",
    component: ManageStaffList,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_LIST,
    exact: true,
    name: "Manage Staff",
    breadcrumb: "Staff Listing",
    component: ManageStaffList,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_ROLE,
    exact: true,
    name: "Manage Staff",
    breadcrumb: "Manage Roles",
    component: ManageRole,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_REQUESTS,
    exact: true,
    name: "Staff Onboarding",
    breadcrumb: "Invited Staff Listing",
    component: ManageStaffRequests,
    isAuthenticatedRoute: true,
    loader: ClientRequestLoader,
  },
  {
    path: AppRoutes.VIEW_STAFF_REQUEST,
    exact: true,
    name: "View Details",
    breadcrumb: "custom",
    component: ViewStaffRequest,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.VIEW_STAFF,
    exact: true,
    name: "View Staff",
    breadcrumb: "custom",
    component: ViewStaff,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_PERMISSION,
    exact: true,
    name: "View Staff",
    breadcrumb: "Permissions",
    component: ManageStaffPermission,
    isAuthenticatedRoute: true,
  },
  // {
  //   path: AppRoutes.SETTING,
  //   exact: true,
  //   name: 'Setting',
  //   breadcrumb: 'Setting',
  //   component: Setting,
  //   isAuthenticatedRoute: true,
  // },
  // {
  //   path: AppRoutes.EDIT_CLIENT_REQUEST,
  //   exact: true,
  //   name: 'Edit Detail',
  //   breadcrumb: 'custom',
  //   component: EditClientRequest,
  //   isAuthenticatedRoute: true,
  // },{
  //   path: AppRoutes.CONTACT,
  //   exact: true,
  //   name: 'Contact',
  //   breadcrumb: 'Contact',
  //   component: ManageContact,
  //   isAuthenticatedRoute: true,
  // },{
  //   path: AppRoutes.TERMS_AND_CONDITION,
  //   exact: true,
  //   name: 'Terms and Conditions',
  //   breadcrumb: 'CMS',
  //   component: ManageTerms,
  //   isAuthenticatedRoute: true,
  // },{
  //   path: AppRoutes.CMS_PRIVACY_POLICY,
  //   exact: true,
  //   name: 'Privacy Policy',
  //   breadcrumb: 'Privacy Policy',
  //   component: ManagePrivacy,
  //   isAuthenticatedRoute: true,
  // },
  // {
  //   path: AppRoutes.FAQS,
  //   exact: true,
  //   name: 'FAQs',
  //   breadcrumb: 'Faqs',
  //   component: Faqs,
  //   isAuthenticatedRoute: true,
  // },
  // {
  //   path: AppRoutes.PUSH_NOTIFICATIONS,
  //   exact: true,
  //   name: 'Push Notifications',
  //   breadcrumb: 'Push Notifications',
  //   component: PushNotifications,
  //   isAuthenticatedRoute: true,
  // },
  {
    path: AppRoutes.REPORTS,
    exact: true,
    name: "Reports",
    breadcrumb: "Reports",
    component: Batches,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.BATCHES_REPORTS,
    exact: true,
    name: "Batches",
    breadcrumb: "Batches",
    component: Batches,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.COUNTERFEIT_REPORTS,
    exact: true,
    name: "Counterfeit",
    breadcrumb: "Counterfeit",
    component: ReportCounterfeit,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PRODUCT_REPORTS,
    exact: true,
    name: "Product",
    breadcrumb: "Product",
    component: ReportProduct,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.USERS_REPORTS,
    exact: true,
    name: "Users",
    breadcrumb: "Users",
    component: ReportUsers,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SCAN_REPORTS,
    exact: true,
    name: "Scan",
    breadcrumb: "Scan",
    component: ReportScan,
    isAuthenticatedRoute: true,
  },
  // {
  //   path: AppRoutes.TEST_ONE,
  //   exact: true,
  //   name: 'Test One',
  //   breadcrumb: 'Test One',
  //   component: TestOne,
  //   isAuthenticatedRoute: true,
  // },
  {
    path: AppRoutes.SETTINGS,
    exact: true,
    name: "Settings",
    breadcrumb: "Settings",
    component: Settings,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.IDFORMATS,
    exact: true,
    name: "ID Formats",
    breadcrumb: "ID Formats",
    component: Idformats,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.NOTIFICATION,
    exact: true,
    name: "Notifications",
    breadcrumb: "Notifications",
    component: Notifications,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.HELP,
    exact: true,
    name: "Support",
    breadcrumb: "Support",
    component: Faq,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.FAQ,
    exact: true,
    name: "Faq",
    breadcrumb: "FAQ",
    component: Faq,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TUTORIALS,
    exact: true,
    name: "Tutorials",
    breadcrumb: "Tutorials",
    component: Tutorials,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CONTACTUS,
    exact: true,
    name: "Contact",
    breadcrumb: "Contact",
    component: Contact,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TERMANDCON,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: TermCondition,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PRIVACYPOLICY,
    exact: true,
    name: "Privacy Policy",
    breadcrumb: "Privacy Policy",
    component: PrivacyPolicy,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Manage_Subscription,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Manage Subscription",
    component: ManageSubscription,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Create_Plan,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Manage Subscription",
    component: CurrentPlan,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Billing,
    exact: true,
    name: "Billings",
    breadcrumb: "Manage Subscription",
    component: Billings,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Payment,
    exact: true,
    name: "Payment Methods",
    breadcrumb: "Manage Subscription",
    component: Payment,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Purchase,
    exact: true,
    name: "Purchase Subscription",
    breadcrumb: "Manage Subscription",
    component: Purchase,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Track_Status,
    exact: true,
    name: "Track Status",
    breadcrumb: "Track Status",
    component: TrackRequest,
    isAuthenticatedRoute: true,
    // loader: ProductLoader,
  },
  {
    path: AppRoutes.PROFILE,
    exact: true,
    name: "Profile",
    breadcrumb: "Profile",
    component: Profile,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SECURITY,
    exact: true,
    name: "Security",
    breadcrumb: "Security",
    component: Security,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.AUTHENTICATION,
    exact: true,
    name: "Multi Factor Authentication",
    breadcrumb: "Authentication",
    component: Authentication,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PREFERENCES,
    exact: true,
    name: "Preferences",
    breadcrumb: "Preferences",
    component: Preferences,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SESSION,
    exact: true,
    name: "Session",
    breadcrumb: "Session",
    component: SessionModule,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MAILER,
    exact: true,
    name: "Mailer",
    breadcrumb: "Mailer",
    component: Mailer,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.VERIFYIDENTITY,
    exact: true,
    name: "Verify Identity",
    breadcrumb: "Verify Identity",
    component: VerifyIdentity,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.OTPFORM,
    exact: true,
    name: "OTP",
    breadcrumb: "OTP",
    component: OtpForm,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION_APP,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: TermsAndConditionApp,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION_CLIENT,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: TermsAndConditionClient,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION_STAFF,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: TermsAndConditionStaff,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.PRIVACY_POLICY_APP,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: PrivacyPolicyApp,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.PRIVACY_POLICY_CLIENT,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: PrivacyPolicyClient,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.PRIVACY_POLICY_STAFF,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "Terms and Conditions",
    component: PrivacyPolicyStaff,
    isAuthenticatedRoute: false,
  },
];
