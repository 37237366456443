import React, { useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import downloadGreen from "../../../../assets/img/downloadGreen.svg";
import downloadWhite from "../../../../assets/img/downloadWhite.svg";
import verified from "../../../../assets/img/verifiedIcon.svg";
import crossIcon from "../../../../assets/img/closeModal.svg";
import trash22 from "../../../../assets/img/trash-22.svg";
import plusIcon from "../../../../assets/img/plusIconWhite.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import OTPInput from "otp-input-react";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../../helpers";
import { areaCode } from "../../../../common/phonecode";
import { components, DropdownIndicatorProps } from "react-select";
import { EmailLoader } from "../../loader/HelpLoaders/EmailLoader";
import PrimaryEmail from "./PrimaryEmail";

function ContactDetails({
  clientEdit,
  handleChange,
  emailSubmit,
  emailError,
  emailLoader,
  otp,
  emailverfiy,
  show,
  close,
  handleShow,
  handlePhoneShow,
  closePhone,
  PhoneShow,
  onChangePhone,
  phoneSubmit,
  onCodeSelect,
  phoneVerfiy,
  pageLoader,
  otpChange,
  otpEdit,
  setPrimaryEmail,
  secondEmail,
  dletePhone,
  phoneOtp,
  onPopupCancel,
  countryCode,
  defaultEmail,
  setModalOpen,
}) {
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="d-none" />
      </components.DropdownIndicator>
    );
  };

  const customStylesAsync = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "unset",
        border: "0px",
      },
      // overflow: "hidden",
      // whiteSpace: "nowrap",
      border: "0px",
      padding: "0px",
      height: "100%",
      backgroundColor: "transparent",
      boxShadow: "unset",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
    }),
    container: (provided, state) => ({
      ...provided,
      maxWidth: "50px",
      padding: "0px",
      backgroundColor: "transparent",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxWidth: "100%",
    }),
  };

  let codeData = [];
  areaCode.forEach((element) => {
    codeData.push({ label: element.dial_code, value: element.dial_code });
  });

  const loadAreaCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = codeData.filter((code) =>
        code.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };
  return (
    <>
      <div className="tableFilter overflow-hidden pl-0 mb-0 mt-0">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ margin: "0px 0 25px 0" }}
        >
          <h3 className="m-0">Contact Details</h3>
        </div>
      </div>

      <div className="productFormBox profileMainBox">
        {pageLoader ? (
          <EmailLoader />
        ) : (
          <form className="profileForm">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                <div className="form-group position-relative trash_option">
                  <label className={`addBatch-Labels`}>Primary Email</label>
                  <div className="position-relative verify">
                    <div className="form-group">
                      <div className="form-control">{clientEdit?.email}</div>
                    </div>
                    <img src={verified} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-xl-12"></div>
              {clientEdit?.emailList?.map((info, index) => (
                <>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="form-group position-relative trash_option">
                      <label className={`addBatch-Labels`}>
                        {`Email ${index + 2}`}
                      </label>
                      <div className="position-relative verify">
                        <div className="form-group">
                          <div className="form-control">{info}</div>
                        </div>
                        <button
                          type="button"
                          onClick={() => setModalOpen(info)}
                          className="verifyIcon"
                        >
                          <img src={verified} alt="" />
                        </button>
                        <button
                          type="button"
                          onClick={() => dletePhone(info, "email")}
                          className="trashBtn"
                        >
                          <img src={trash22} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12"></div>
                </>
              ))}
              <div
                className="col-xl-12 text-center"
                style={{ marginTop: "24px" }}
              >
                <Button className="addEmailBtn" variant="" onClick={handleShow}>
                  <img src={plusIcon} /> Add New Email
                </Button>
              </div>

              <div className="col-xl-12">
                <hr />
              </div>
              <>
                <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                  <div className="form-group position-relative trash_option">
                    <label className={`addBatch-Labels`}>Phone Number</label>
                    <div className="position-relative verify">
                      <div className="form-group">
                        <div className="form-control">
                          {clientEdit?.phone ? (
                            <>
                              {clientEdit?.countryCode} {clientEdit?.phone}
                            </>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </div>
                      <img src={verified} alt="" />
                    </div>
                  </div>
                </div>

                <div className="col-xl-12"></div>
              </>

              {clientEdit?.phoneList?.map((info, index) => (
                <>
                  <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="form-group position-relative trash_option">
                      <label className={`addBatch-Labels`}>
                        {`Phone Number ${index + 1}`}
                      </label>
                      <div className="position-relative verify">
                        <div className="form-group">
                          <div className="form-control">
                            {info?.countryCode} {info?.phone}
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={() => setModalOpen(info)}
                          className="verifyIcon"
                        >
                          <img src={verified} alt="" />
                        </button>
                        <button type="button" className="trashBtn">
                          <img
                            src={trash22}
                            onClick={() => dletePhone(info?.phone, "phone")}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12"></div>
                </>
              ))}
              <div
                className="col-xl-12 text-center"
                style={{ marginTop: "24px" }}
              >
                <Button
                  className="addEmailBtn"
                  variant=""
                  onClick={handlePhoneShow}
                >
                  <img src={plusIcon} /> Add Phone Number
                </Button>
              </div>
            </div>
          </form>
        )}
        <Modal className="emailAddresssModal" show={show} onHide={close}>
          <Modal.Header>
            <Modal.Title>Add Email Address</Modal.Title>
            <div>
              <Button
                variant=""
                onClick={otp ? () => onPopupCancel("email") : close}
              >
                <img src={crossIcon} />
              </Button>
            </div>
          </Modal.Header>
          <Modal.Body>
            {!otp ? (
              <>
                <p>You can use this email address to recover your account</p>
                <form style={{ maxWidth: "455px" }}>
                  <InputTextFeild
                    placeholder="Enter Email Address here"
                    value={secondEmail}
                    onChange={handleChange}
                    name="secondEmail"
                    errorMessage={emailError?.secondEmail}
                  />
                  <Button
                    disabled={emailLoader}
                    onClick={emailSubmit}
                    variant=""
                    className="otp_btn"
                  >
                    {emailLoader ? (
                      <>
                        <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                        Get OTP
                      </>
                    ) : (
                      <> Get OTP</>
                    )}
                  </Button>
                </form>
              </>
            ) : (
              <form className="otp_emailForm">
                <p>Verify your email</p>
                <div className="d-flex align-items-center justify-content-start otp_form">
                  <OTPInput
                    value={otpEdit}
                    onChange={otpChange}
                    OTPLength={6}
                    otpType="number"
                    inputClassName="form-control p-0"
                  />
                </div>
                {/* <p className="otp_count" style={{marginTop:"8px"}}>Resend OTP in 00:10</p> */}
                <Button
                  variant=""
                  type="button"
                  disabled={emailLoader}
                  onClick={() => onPopupCancel("email")}
                  className="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  disabled={emailLoader}
                  onClick={emailverfiy}
                  variant=""
                  className="otp_btn"
                >
                  {emailLoader ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                      Verify
                    </>
                  ) : (
                    <> Verify</>
                  )}
                </Button>
              </form>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          className="emailAddresssModal phoneNumber"
          show={PhoneShow}
          onHide={closePhone}
        >
          <Modal.Header>
            <Modal.Title>Add Mobile Number</Modal.Title>
            <div>
              <Button
                variant=""
                onClick={phoneOtp ? () => onPopupCancel("phone") : closePhone}
              >
                <img src={crossIcon} />
              </Button>
            </div>
          </Modal.Header>
          <Modal.Body>
            {!phoneOtp ? (
              <>
                <form style={{ maxWidth: "455px" }}>
                  <div className="form-group mt-4">
                    <div className="d-flex form-control group-input companyDeatilContactNum">
                      <AsyncSelect
                        cacheOptions
                        components={{ DropdownIndicator }}
                        loadOptions={loadAreaCodeData}
                        styles={customStylesAsync}
                        placeholder={"00"}
                        className="areaCode"
                        maxMenuHeight={200}
                        width="200px"
                        defaultOptions={codeData}
                        value={countryCode}
                        name="countryCode"
                        onChange={(selectedOption) => {
                          onCodeSelect(selectedOption, "countryCode");
                        }}
                        isSearchable={true}
                      />
                      <InputTextFeild
                        name="secondPhone"
                        type="tel"
                        placeholder=""
                        value={clientEdit?.secondPhone}
                        onChange={onChangePhone}
                        min="0"
                        maxLength={12}
                      />
                    </div>
                    <div className="text-danger">{emailError?.secondPhone}</div>
                    <div className="text-danger">{emailError?.countryCode}</div>
                    <Button
                      onClick={phoneSubmit}
                      variant=""
                      className="otp_btn"
                      disabled={emailLoader}
                    >
                      {emailLoader ? (
                        <>
                          <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                          Get OTP
                        </>
                      ) : (
                        <> Get OTP</>
                      )}
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              <form>
                <p>Verify your phone</p>
                <div className="d-flex align-items-center justify-content-start otp_form">
                  <OTPInput
                    value={otpEdit}
                    onChange={otpChange}
                    OTPLength={6}
                    otpType="number"
                    inputClassName="form-control p-0"
                  />
                </div>
                {/* <p className="otp_count">Resend OTP in 00:10</p> */}
                <Button
                  variant=""
                  type="button"
                  disabled={emailLoader}
                  onClick={() => onPopupCancel("phone")}
                  className="cancelBtn"
                >
                  Cancel
                </Button>
                <Button
                  disabled={emailLoader}
                  onClick={phoneVerfiy}
                  variant=""
                  className="otp_btn"
                >
                  {emailLoader ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                      Verify
                    </>
                  ) : (
                    <> Verify</>
                  )}
                </Button>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </div>
      <PrimaryEmail
        show={defaultEmail}
        close={() => setModalOpen(false)}
        handleChange={setPrimaryEmail}
        update={emailLoader}
      />
    </>
  );
}

export default ContactDetails;
