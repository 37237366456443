import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import InputTextField from '../../components/form/InputTextFeild';
import SubmitButton from '../../components/form/SubmitButton';
import { AppConfig } from '../../../config';
import uploadImage from '../../../assets/img/uploadImg.png';
import trashIcon from '../../../assets/img/deletepro_icon.svg';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import './user.css';

export const AddUserFormComponent = ({
  first_name,
  last_name,
  email,
  phone,
  address1,
  address2,
  state,
  postCode,
  profile_picture,
  errors,
  isUploading,
  isAdding,
  uploadPercentage,
  onChange,
  onFileChange,
  onDeleteFile,
  handleCancel,
  handleSubmit,
  loadOriginOptions,
  customStyles,
  originOptions,
  originId,
  onSelect,
  stateOptions
}) => {
  return (
    <form className='user_add' onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-md-3 mob-show'>
          <div className='img-col-user'>
            <img
              className='user-img'
              // src={
              //   profile_picture
              //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
              //     : uploadImage
              // }
              src={
                profile_picture
                  ? profile_picture.startsWith("https://")
                    ? profile_picture
                    : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                  : uploadImage
              }
              alt=''
            />
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={'mt-2'} />
          ) : null}
          <div className='file-upload-btn'>
            <label className='file-label'>
              {' '}
              {profile_picture ? 'Change Photo' : 'Choose Photo'}
              <input
                type='file'
                name='profile_picture'
                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                onChange={onFileChange}
              />
            </label>
            <span onClick={onDeleteFile} className={'cursor-pointer btn'}>
              <img src={trashIcon} alt='' />
            </span>
          </div>
        </div>
        <div className='col-md-9'>
          <div className='row'>
            <div className='col-md-4'>
              <label className="form-control-label">First Name *</label>
              <InputTextField
                id='first_name'
                label={'First Name'}
                className={'form-control-user'}
                name='first_name'
                value={first_name}
                onChange={onChange}
                errorMessage={
                  errors && errors.first_name ? errors.first_name : ''
                }
                maxLength={30}
              />
            </div>
            <div className='col-md-4'>
            <label className="form-control-label">Last Name</label>
              <InputTextField
                id='last_name'
                label={'Last Name'}
                className={'form-control-user'}
                name='last_name'
                value={last_name}
                onChange={onChange}
                maxLength={30}
                errorMessage={
                  errors && errors.last_name ? errors.last_name : ''
                }
               
              />
            </div>
            <div className='col-md-4'>
            <label className="form-control-label">Phone Number *</label>
              <InputTextField
                id='phone'
                label={'Phone Number'}
                className={'form-control-user'}
                name='phone'
                value={phone}
                onChange={onChange}
                errorMessage={errors && errors.phone ? errors.phone : ''}
                maxLength={14}
              />
            </div>
            <div className='col-md-4'>
            <label className="form-control-label">Email *</label>
              <InputTextField
                id='email'
                label={'Email'}
                className={'form-control-user'}
                name='email'
                value={email}
                onChange={onChange}
                errorMessage={errors && errors.email ? errors.email : ''}
              />
            </div>
            <div className='col-md-4'>
            <label className="form-control-label">Address Line 1</label>
              <InputTextField
                id='address_line_1'
                label={'Address Line 1'}
                className={'form-control-user'}
                name='address1'
                value={address1}
                onChange={onChange}
             
              />
            </div>
            <div className='col-md-4'>
            <label className="form-control-label">Address Line 2</label>
              <InputTextField
                id='address_line_2'
                label={'Address Line 2'}
                className={'form-control-user'}
                name='address2'
                value={address2}
                onChange={onChange}
                
              />
            </div>
          
            <div className='col-md-4'>
              {/* <label className="form-control-label">Country</label>
              <InputTextField
                id='country'
                label={'Country'}
                className={'form-control-user'}
                name='country'
                value={country}
                onChange={onChange}
              /> */}
              <div className="form-group">
                  <label className={`form-control-label`}>Country</label>
                  <AsyncSelect
                    loadOptions={loadOriginOptions}
                    placeholder={'Select Country'}
                    className='form-control p-0'
                    styles={customStyles}
                    maxMenuHeight={200}
                    defaultOptions={originOptions}
                    value={originId}
                    onChange={(selectedOption) =>
                    onSelect(selectedOption, 'originId')
                  }
                  noOptionsMessage={() =>
                  'No origin found related to your search'
                  }
                  />
                  <div className={'text-danger'}>
                    {errors && errors.originId ? errors.originId : ''}
                  </div>
                </div>
            </div>
            <div className='col-md-4'>
              {/* <label className="form-control-label">State</label>
              <InputTextField
                id='state'
                label={'State'}
                className={'form-control-user'}
                name='state'
                value={state}
                onChange={onChange}
              /> */}

              <div className="form-group">
                <label className={`form-control-label`}>State</label>
                  <Select
                    placeholder={'Select State'}
                    options={stateOptions}
                    styles={customStyles}
                    className='form-control p-0'
                    value={state}
                    maxMenuHeight={200}
                    onChange={(selectedOption) =>
                    onSelect(selectedOption, 'state')
                  }
                  noOptionsMessage={() => {
                    return originId && originId.value
                    ? 'No states found related to your search'
                    : 'First select an origin';
                  }}
                />
                <div className={'text-danger'}>
                  {errors && errors.state ? errors.state : ''}
                </div>
              </div>


            </div>
            <div className='col-md-4'>
            <label className="form-control-label">Post Code</label>
              <InputTextField
                type="number"
                id='post_code'
                label={'Post Code'}
                className={'form-control-user'}
                name='postCode'
                value={postCode}
                onChange={onChange}
                maxLength={6}
                errorMessage ={ errors && errors.postCode ? errors.postCode : ""}
              />
            </div>
          </div>
        </div>
        <div className='col-md-3 mob-hide'>
          <div className='img-col-user'>
            <img
              className='user-img'
              // src={
              //   profile_picture
              //     ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
              //     : uploadImage
              // }
              src={
                profile_picture
                  ? profile_picture.startsWith("https://")
                    ? profile_picture
                    : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                  : uploadImage
              }
              alt=''
            />
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={'mt-2'} />
          ) : null}
          <div className='file-upload-btn d-flex align-items-center mt-4'>
            <label className='customCancelBtn mt-0 m-0 w-100 d-flex align-items-center justify-content-center'>
              {' '}
              {profile_picture ? 'Change Photo' : 'Choose Photo'}
              <input
                className='imgUpload img_up'
                name='profile_picture'
                type='file'
                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                onChange={onFileChange}
              />
            </label>
            <span onClick={onDeleteFile} className={'cursor-pointer dltimgbtn ml-2'}>
              <img src={trashIcon} alt='' />
            </span>
          </div>
        </div>

        <div className='col-md-9 footer-form'>
          <div className='d-flex align-items-center justify-content-center'>
            <button
              type='button'
              className='customCancelBtn mt-0 btn'
              onClick={handleCancel}
            >
              {' '}
              CANCEL
            </button>

            <SubmitButton
              className={'btn customSubmitBtn mt-0'}
              text={'INVITE'}
              isLoading={isAdding}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
