import React from "react";
import editBtn from "../../../../assets/img/editBtn.svg";
import plusIcon from "../../../../assets/img/plusIconWhite.svg";
import Button from "react-bootstrap/Button";

function Addresses({ address, handleShow, handleEdit }) {
  return (
    <>
      <div className="tableFilter overflow-hidden pl-0 mb-0 mt-0">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ margin: "0px 0 25px 0" }}
        >
          <h3 className="m-0">Addresses</h3>
        </div>
      </div>

      <div className="productFormBox profileMainBox">
        <form className="profileForm">
          <div className="row">
            {address && address.length > 0
              ? address.map((info, index) => (
                  <>
                    <div
                      key={index}
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <h4 className="heading">{info?.addressType}</h4>
                      <button
                        type="button"
                        className="editbtn_table"
                        onClick={() => handleEdit(info)}
                      >
                        <img src={editBtn} style={{ marginRight: "8px" }} />{" "}
                        Edit
                      </button>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>
                          Address Line 1
                        </label>
                        <div className="form-group">
                          <div className="form-control d-flex aling-items-center">
                            {info?.addressLine1}
                          </div>
                        </div>
                        {/* <InputTextFeild
                          value={info?.addressLine1}
                          readOnly={true}
                        /> */}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>
                          Address Line 2
                        </label>
                        <div className="form-group">
                          <div className="form-control d-flex aling-items-center">
                            {info?.addressLine2}
                          </div>
                        </div>
                        {/* <InputTextFeild
                          value={info?.addressLine2}
                          readOnly={true}
                        /> */}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>State</label>
                        <div className="form-group">
                          <div className="form-control d-flex aling-items-center">
                            {info?.state || 'N/A'}
                          </div>
                        </div>
                        {/* <InputTextFeild value={info?.state} readOnly={true} /> */}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>Country</label>
                        <div className="form-group">
                          <div className="form-control d-flex aling-items-center">
                            {info?.country}
                          </div>
                        </div>
                        {/* <InputTextFeild value={info?.country} readOnly={true} /> */}
                      </div>
                    </div>

                    <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                      <div className="form-group">
                        <label className={`addBatch-Labels`}>Post Code</label>
                        <div className="form-group">
                          <div className="form-control d-flex aling-items-center">
                            {info?.postCode}
                          </div>
                        </div>
                        {/* <InputTextFeild
                          value={info?.postCode}
                          readOnly={true}
                        /> */}
                      </div>
                    </div>

                    <div className="col-xl-12">
                      <hr />
                    </div>
                  </>
                ))
              : ""}
            <div
              className="col-xl-12 text-center"
              style={{ marginTop: "24px" }}
            >
              <Button className="addEmailBtn" variant="" onClick={handleShow}>
                <img src={plusIcon} /> Add Addresses
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Addresses;
