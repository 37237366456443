import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import downgradeSuccessIcon from "../../../../assets/img/downgradeSuccess.svg";
import { AppRoutes } from "../../../../config";

export default function DowngradeSuccess({
  showSuccess,
  closeSuccess,
  downgradeData,
  futureData,
}) {
  // console.log("futureData", futureData);
  // let status = "";
  // let name = "";
  // downgradeData &&
  //   downgradeData.forEach((element) => {
  //     status = element.status;
  //     if (status === "Next") {
  //       name = element.name;
  //     }
  //   });
  return (
    <Modal
      show={showSuccess}
      onHide={closeSuccess}
      className="logoutModal Custom2"
    >
      <div className="d-flex align-items-center justify-content-center">
        <img alt="" src={downgradeSuccessIcon} />
      </div>
      <Modal.Body>
        <h3>Plan Downgraded successfully!</h3>
        <p>
          {/* Your {name} plan will be activated from next month and you can check
          the further details from the billings section. */}
          Your plan will be activated from the next month on{" "}
          {moment(futureData && futureData.expireDate).format("DD/MM/YYYY")}
        </p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        {/* <button onClick={closeSuccess} className="logout-cancel">
          Cancel
        </button>
        <Link to={AppRoutes.Billing} className="logout-btn">
          Yes
        </Link> */}
        <Button onClick={closeSuccess} className="logout-btn">
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
