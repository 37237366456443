/** @format */

import React from "react";
import { ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import InputTextField from "../../components/form/InputTextFeild";
import {
  AppConfig,
  genderOptions,
  customReactSelectStyles,
  ApiRoutes,
} from "../../../config";
import { BatchViewLoaderNew } from "../../components/loader/Resusable/BatchViewLoaderNew";
import defaultImg from "../../../assets/img/noimage.jpeg";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import SubmitButton from "../form/SubmitButton";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import AsyncSelect from "react-select/async";

export const InfoDetailComponent = ({
  staffData,
  staffId,
  isEditable,
  actionBtn = true,
  isLoading,
  isUpdating,
  isUploading,
  uploadPercentage,
  onEdit,
  onChange,
  onSubmit,
  onSelect,
  onFileChange,
  sortByAction,
  onDateChange,
  onDeleteFile,
  onCancel,
  viewPermission,
  changeStaffStatus,
  portalAccessSwitcher,
  portalAccessDiv,
  handleCancel,
  isEditMode,
  isAdding,
  handleSubmit,
  onRoleSelect,
  handleBirthChange,
  errors,
}) => {
  /* let isPermission = staffData.isPermission
    ? staffData.isPermission
    : staffData.permission !== ""
    ? "assign"
    : "unAssign";
  let permissionText =
    "The staff will be able to manage scans, counterfeit request, clients and more through the staff portal.";
  if (permission !== "") {
    permissionText = "The staff will be able to manage ";
    let textArray = [];
    for (let x in permission) {
      textArray[x] = permission[x].module;
    }
    permissionText = permissionText + textArray.join(", ");
  } */

  const [roleData, setRoleData] = useState();
  const fetchRoleData = async () => {
    let data = {};
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_ROLE.service,
      ApiRoutes.GET_ROLE.url,
      ApiRoutes.GET_ROLE.method,
      ApiRoutes.GET_ROLE.authenticate,
      data,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { role },
      } = response.data;
      setRoleData(role);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  let roles = [];
  roleData &&
    roleData.forEach((element) => {
      roles.push({ value: element.id, label: element.roleName });
    });

  if (staffData.temp_role_id) {
    var a = roles.filter((e) => e.value === staffData.temp_role_id);
    onRoleSelect(a ? a[0] : "");
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  return (
    <div className="">
      {isLoading ? (
        <BatchViewLoaderNew />
      ) : (
        <>
          {" "}
          <div className="productFormBox viewStaff_page">
            <form className="">
              <div className="row">
                <div className="col-md-3 col-img-sf">
                  <div className="img-col-staff">
                    <img
                      className="user-img"
                      // src={
                      //   staffData.profile_picture
                      //     ? `${AppConfig.FILES_ENDPOINT}${staffData.profile_picture}`
                      //     : defaultImg
                      // }
                      src={
                        staffData.profile_picture
                          ? staffData.profile_picture.startsWith("https://")
                            ? staffData.profile_picture
                            : `${AppConfig.FILES_ENDPOINT}${staffData.profile_picture}`
                          : defaultImg
                      }
                      alt=""
                    />

                    {isUploading ? (
                      <ProgressBar
                        now={uploadPercentage}
                        className={"mt-2 mb-2"}
                      />
                    ) : null}
                    {isEditable ? (
                      <div
                        className="file-upload-btn-sf d-flex justify-content-start"
                        style={{ paddingLeft: "2em" }}
                      >
                        <label className="file-label">
                          {staffData.profile_picture ? "Change" : "Upload"}
                          <input
                            className="imgUpload img_up"
                            name="profile_picture"
                            type="file"
                            accept="image/x-png,image/jpeg ,image/jpg, image/png ,image/svg"
                            onChange={onFileChange}
                          />
                        </label>
                      </div>
                    ) : null}
                    <div className="st-name-head center">
                      <h4 className="sf_name">
                        {staffData.first_name}
                        {/* {[staffData.first_name, staffData.last_name]
                          .filter(Boolean)
                          .join(" ") || "-"}{" "} */}
                      </h4>
                      {isEditable ? (
                        <InputTextField
                          id="role_id"
                          label={"Role"}
                          className={""}
                          name="first_name"
                          onChange={onChange}
                          errorMessage={
                            errors && errors.first_name ? errors.first_name : ""
                          }
                          value={staffData.first_name}
                        />
                      ) : null}
                      {/* <h4 className="sf_desc">{employee_id}</h4> */}
                    </div>
                  </div>

                  <div>
                    <label className={`form-control-label`}>Role</label>
                    {!isEditable ? (
                      <div className="readonlyFeild">
                        <InputTextField
                          id="role_id"
                          label={"Role"}
                          className={""}
                          name="roleName"
                          value={staffData.roleName}
                          maxLength={30}
                          readOnly={true}
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <AsyncSelect
                          placeholder={"-- Select Role -- "}
                          className="form-control p-0"
                          maxMenuHeight={200}
                          styles={customStyles}
                          defaultOptions={roles}
                          value={staffData.role_id}
                          name="role_id"
                          onChange={(selectedOption) => {
                            onRoleSelect(selectedOption, "role_id");
                          }}
                        />
                        <div className="text-danger">
                          {errors && errors.role_id ? errors.role_id : ""}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* {portalAccessDiv && !isEditable && ( */}
                  <div>
                    <label className={`form-control-label`}>
                      Portal Access
                    </label>
                    <div className="form-group">
                      <div className="input-group-prepend">
                        <label className="switch">
                          <span
                            className="slider round slide-yes-no-wrap btn-group"
                            role="group"
                          >
                            <span
                              className={`btn yes-field ${
                                staffData.portal_access ? "active" : ""
                              }`}
                              onClick={() => portalAccessSwitcher(true)}
                            >
                              Enable
                            </span>
                            {/* <span className="separator-field">|</span> */}
                            <span
                              className={`btn no-field ${
                                staffData.portal_access ? "" : "active"
                              }`}
                              onClick={() => portalAccessSwitcher(false)}
                            >
                              Disable
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
                <div className="col-md-9 col-det-sf">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="form-head">Professional Information</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <label className={`form-control-label`}>
                        Employee ID
                      </label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <InputTextField
                            id="employee_id"
                            label={"Employee ID"}
                            className={"form-control-user disable-write"}
                            name="employee_id"
                            value={staffData.employee_id}
                            maxLength={30}
                            readOnly={true}
                            disabled={!isEditable}
                          />
                        </div>
                      ) : (
                        <InputTextField
                          id="employee_id"
                          label={"Employee ID"}
                          className={"form-control-user disable-write"}
                          name="employee_id"
                          value={staffData.employee_id}
                          maxLength={30}
                          readOnly={true}
                          disabled={!isEditable}
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <label className={`form-control-label`}>Email ID</label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <InputTextField
                            id="email"
                            label={"Email"}
                            className={"form-control-user disable-write"}
                            name="email"
                            value={staffData.email}
                            readOnly={true}
                            disabled={!isEditable}
                            // onChange={onChange}
                          />
                        </div>
                      ) : (
                        <InputTextField
                          id="email"
                          label={"Email"}
                          className={"form-control-user disable-write"}
                          name="email"
                          value={staffData.email}
                          readOnly={true}
                          disabled={!isEditable}
                          // onChange={onChange}
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <label className={`form-control-label`}>
                        Phone Number
                      </label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <InputTextField
                            id="phone"
                            label={"Phone Number"}
                            type="tel"
                            className={"form-control-user"}
                            name="office_phone"
                            value={staffData.office_phone}
                            readOnly={true}
                            disabled={!isEditable}
                          />
                        </div>
                      ) : (
                        <InputTextField
                          id="phone"
                          label={"Phone Number"}
                          type="tel"
                          className={"form-control-user"}
                          name="office_phone"
                          value={staffData.office_phone}
                          readOnly={!isEditable}
                          onChange={onChange}
                          maxLength={14}
                          errorMessage={
                            errors && errors.office_phone
                              ? errors.office_phone
                              : ""
                          }
                        />
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="form-group position-relative">
                        <label className={`addBatch-Labels`}>
                          Date of Joining
                        </label>
                        <div className="position-relative">
                          <div className="form-group">
                            <div className="form-control">
                              {staffData.joiningDateFormat}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <label className={`form-control-label`}>
                        Date of Joining
                      </label>
                      <DatePicker
                        className={"form-control"}
                        placeholder={`Date of Joining(DD/MM/YYYY)`}
                        dateFormat="dd/MM/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        disabled={true}
                        // onChange={onDateChange}
                        selected={staffData.joining_date}
                      /> */}
                    </div>

                    <div className="col-md-4">
                      <label className={`form-control-label`}>
                        Working Location
                      </label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <InputTextField
                            id="office_location"
                            label={"Working Location"}
                            className={"form-control-user"}
                            name="office_location"
                            value={staffData.office_location}
                            readOnly={true}
                            disabled={!isEditable}
                          />
                        </div>
                      ) : (
                        <InputTextField
                          id="office_location"
                          label={"Working Location"}
                          className={"form-control-user"}
                          name="office_location"
                          value={staffData.office_location}
                          onChange={onChange}
                          readOnly={!isEditable}
                          errorMessage={
                            errors && errors.office_location
                              ? errors.office_location
                              : ""
                          }
                        />
                      )}
                    </div>

                    <div className="col-md-12">
                      <label className={`form-control-label`}>Bio</label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <textarea
                            className="form-control"
                            name="bio"
                            value={staffData.bio}
                            readOnly={true}
                          ></textarea>
                        </div>
                      ) : (
                        <>
                          <textarea
                            className="form-control"
                            name="bio"
                            value={staffData.bio}
                            onChange={onChange}
                          ></textarea>
                          <div className="text-danger">
                            {errors && errors.bio ? errors.bio : ""}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "35px" }}>
                    <div className="col-md-4">
                      <h4 className="form-head">Personal Information</h4>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4">
                      {!isEditable ? (
                        <div className="form-group position-relative">
                          <label className={`addBatch-Labels`}>
                            Date of Birth
                          </label>
                          <div className="position-relative">
                            <div className="form-group">
                              <div className="form-control">
                                {staffData.birthDateFormat}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="form-group">
                          <label className={`form-control-label`}>
                            Date of Birth
                          </label>
                          <DatePicker
                            className={"form-control"}
                            placeholder={`Date of Birth(DD/MM/YYYY)`}
                            selected={staffData.birth_date}
                            maxDate={new Date()}
                            onChange={handleBirthChange}
                            dateFormat="dd/MM/yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            disabled={!isEditable}
                          />
                          <div className="text-danger">
                            {errors && errors.birth_date
                              ? errors.birth_date
                              : ""}
                          </div>
                        </div>
                      )}
                      {/* <InputTextField
                        id='date_of_birth'
                        label={'Date of Birth'}
                        className={'form-control-user'}
                        name='date_of_birth'
                        value={date_of_birth}
                        maxLength={30}
                        readOnly={true}
                      /> */}
                    </div>
                    <div className="col-md-4">
                      <label className={`form-control-label`}>
                        Phone Number
                      </label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <input
                            id="per_phone"
                            label={"Phone Number"}
                            className={"form-control"}
                            name="phone"
                            value={staffData.phone}
                            readOnly={true}
                          />
                        </div>
                      ) : (
                        <>
                          <InputTextField
                            id="per_phone"
                            label={"Phone Number"}
                            className={"form-control-user"}
                            name="phone"
                            type="tel"
                            value={staffData.phone}
                            maxLength={14}
                            onChange={onChange}
                            errorMessage={
                              errors && errors.phone ? errors.phone : ""
                            }
                          />
                        </>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label className={`form-control-label`}>Email ID</label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <InputTextField
                            id="personal_email"
                            label={"E-Mail"}
                            className={"form-control-user"}
                            name="personal_email"
                            value={staffData.personal_email}
                            readOnly={true}
                          />
                        </div>
                      ) : (
                        <InputTextField
                          id="personal_email"
                          label={"E-Mail"}
                          className={"form-control-user"}
                          name="personal_email"
                          value={staffData.personal_email}
                          readOnly={!isEditable}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.personal_email
                              ? errors.personal_email
                              : ""
                          }
                        />
                      )}
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label className={`form-control-label`}>Gender</label>
                        {!isEditable ? (
                          <div className="readonlyFeild">
                            <InputTextField
                              id="personal_email"
                              label={"E-Mail"}
                              className={"form-control-user text-capitalize"}
                              name="personal_email"
                              value={staffData.gender && staffData.gender.label}
                              readOnly={true}
                            />
                          </div>
                        ) : (
                          <>
                            <AsyncSelect
                              placeholder={""}
                              className="form-control"
                              defaultOptions={genderOptions}
                              styles={customReactSelectStyles}
                              value={staffData.gender}
                              name="gender"
                              // isDisabled={!isEditable}
                              onChange={(selectedOption) => {
                                onSelect(selectedOption, "gender");
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#00bdcc33",
                                  primary: "#00acb9",
                                },
                              })}
                            />
                            <div className="text-danger">
                              {errors && errors.gender ? errors.gender : ""}
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-md-8">
                      <label className={`form-control-label`}>Address</label>
                      {!isEditable ? (
                        <div className="readonlyFeild">
                          <input
                            id="address1"
                            label={"Address"}
                            className={"form-control"}
                            name="address1"
                            value={staffData.address1}
                            readOnly={true}
                          />
                        </div>
                      ) : (
                        <InputTextField
                          id="address1"
                          label={"Address"}
                          className={"form-control-user"}
                          name="address1"
                          value={staffData.address1}
                          readOnly={!isEditable}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.address1 ? errors.address1 : ""
                          }
                        />
                      )}
                    </div>
                    {isEditable ? (
                      <div className="col-md-12">
                        <div className="row align-items-center mt-3 batch-btn">
                          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <Button
                                className="customCancelBtn mt-0"
                                variant=""
                                type="button"
                                onClick={handleCancel}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="form-group">
                              <SubmitButton
                                className={"customSubmitBtn mt-0"}
                                text={isEditMode ? "Update" : "Update"}
                                isLoading={isAdding}
                                onSubmit={handleSubmit}
                                // disabled={
                                //   !role_id ||
                                //   !staffData.phone ||
                                //   !staffData.office_phone ||
                                //   !staffData.office_location ||
                                //   !staffData.bio ||
                                //   !staffData.personal_email ||
                                //   !staffData.gender ||
                                //   !staffData.address1
                                // }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};
