import React from "react";
import Skeleton from "react-skeleton-loader";

export const SaveCardsAccountLoader = () => (
  <>
    <div className="col-md-12">
      <div className="innerHolder paymentRow">
        {/* <p className="listHeading">
            <Skeleton height={"18px"} width={"150px"} color="#e5e9ef" />
          </p> */}
        <br />
        <div className="sliderHolder d-flex align-items-center justify-content-start">
          <div style={{ marginRight: "35px" }}>
            <Skeleton height={"237px"} width={"400px"} color="#e5e9ef" />
          </div>
          <div style={{ marginRight: "35px" }}>
            <Skeleton height={"237px"} width={"400px"} color="#e5e9ef" />
          </div>
          <div style={{ marginRight: "35px" }}>
            <Skeleton height={"237px"} width={"400px"} color="#e5e9ef" />
          </div>
        </div>
      </div>
    </div>
  </>
);
