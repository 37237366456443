import React from "react";
import UserBlock from "../../components/Mailer/UserBlock";
import UserInvite from "../../components/Mailer/UserInvite";
import UserRequestCancel from "../../components/Mailer/UserRequestCancel";
import UserRequestReopened from "../../components/Mailer/UserRequestReopened";
import UserRevoke from "../../components/Mailer/UserRevoke";
import UserApproved from "../../components/Mailer/UserApproved";
import CounterfeitReceived from "../../components/Mailer/CounterfeitReceived";
import MailSubscription from "../../components/Mailer/MailSubscription";
import ProfileCompleted from "../../components/Mailer/ProfileCompleted";
import Reminder from "../../components/Mailer/Reminder";
import TrackRequest from "../../components/Mailer/TrackRequest";
import "./mailer.css";

function Mailer(props) {
  return (
    <>
      <UserInvite />
      <UserBlock />
      <UserRevoke />
      <UserRequestCancel />
      <UserRequestReopened />
      <UserApproved />
      <CounterfeitReceived />
      <MailSubscription />
      <ProfileCompleted />
      <Reminder />
      <TrackRequest />
    </>
  );
}

export default Mailer;
