import React from "react";
import Skeleton from "react-skeleton-loader";

export const CurrentPlanGraphsLoader2 = () => (
  <>
    <div class="cardHeader pt-0">
      <h4><Skeleton height={"18px"} width={"150px"} color="#e5e9ef" /></h4>
    </div>
    <div className="cardBody card_overflow">
      <div className="text-center">
        <Skeleton height={"25px"} width={"300px"} color="#e5e9ef" />
        <Skeleton height={"25px"} width={"300px"} color="#e5e9ef" />
        <Skeleton height={"25px"} width={"300px"} color="#e5e9ef" />
        <Skeleton height={"25px"} width={"300px"} color="#e5e9ef" />
      </div>
    </div>
  </>
);
