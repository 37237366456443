import moment from "moment";
import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function DowngradedTolltip({
  showSuccess,
  closeSuccess,
  futurePlan,
}) {
  return (
    <Modal
      show={showSuccess}
      onHide={closeSuccess}
      className="logoutModal Custom2"
    >
      <Modal.Header>
        <span className="logoutAlert">!</span>
      </Modal.Header>
      <Modal.Body>
        <h3>You had downdgraded the Plan</h3>
        <p>
          Your plan will be activated from the next month on{" "}
          {moment(futurePlan && futurePlan.expireDate).format("DD/MM/YYYY")}
        </p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        {/* <button onClick={closeSuccess} className="logout-cancel">
          Cancel
        </button> */}
        <Button onClick={closeSuccess} className="logout-btn">
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
