import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import closeModalCreatePlan from "../../../../assets/img/closeModalCreatePlan.svg";
import masterLogo from "../../../../assets/img/masterLogo.svg";
import cardPlusIcon from "../../../../assets/img/cardPlusIcon.svg";
import cardMinusIcon from "../../../../assets/img/cardMinusIcon.svg";
import visaLogo from "../../../../assets/img/visaLogo.svg";
import dinerLogo from "../../../../assets/img/dinerLogo.svg";
import uninonLogo from "../../../../assets/img/uninonLogo.svg";
import jcbLogo from "../../../../assets/img/jcbLogo.svg";
import discoverlogo from "../../../../assets/img/discoverlogo.svg";
import americanLogo from "../../../../assets/img/americanLogo.svg";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "../../../components/manageSubscription/StripeForm";
import { useState } from "react";
import CardStripeForm from "../../../components/manageSubscription/CardStripeForm";
import { AppConfig } from "../../../../config";

const stripePromise = loadStripe(AppConfig.STRIPE_KEY);

export default function CardsDetail({
  show,
  close,
  userCards,
  changeSubscription,
  update,
  cardData,
  primaryCards,
  handleChange,
  priceData,
  fetchCards,
  showMethod,
  setShowMethod,
  planAmount,
}) {
  // const stripe= useStripe();
  const stripe = null;
  const handlePrimaryCardFunc = (value) => {
    handleChange(value);
  };

  const changeSubscriptionFunc = (stripe) => {
    // close();
    setShowMethod(false);
    changeSubscription(stripe);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={close}
        className="logoutModal Custom2 cardModal"
      >
        <Modal.Body>
          <div className="cardDetail_head">
            <p className="upgradePlan">
              {planAmount?.status === "change" ? "Change" : "Upgrade"} Plan to{" "}
              {cardData?.name}
            </p>
            <span onClick={close} className="closeModalCreatePlan">
              <img src={closeModalCreatePlan} />
            </span>
          </div>

          <div className="tierDeatil">
            <p>Plan Charges</p>
            <div className="planPrice">
              <p>$ {cardData?.price}</p>
            </div>
          </div>

          <div className="balanceDetail">
            <div className="charge">
              <p>
                Prorated cost for{" "}
                {planAmount?.status === "change" ? "change" : "upgrade"}
              </p>
              <span>This is the amount you'll charged Today</span>
            </div>
            <div className="balancePrice">
              <p>$ {priceData?.invoice?.amount_due / 100}</p>
            </div>
          </div>

          <div className="cardInfo">
            <h4>Billing Information</h4>
            {userCards &&
              userCards.map((info, index) => (
                <label className="cardDetail" key={index}>
                  <div className="logo">
                    <img
                      src={
                        info?.card?.brand === "visa"
                          ? visaLogo
                          : info?.card?.brand === "mastercard"
                          ? masterLogo
                          : info?.card?.brand === "diners"
                          ? dinerLogo
                          : info?.card?.brand === "unionpay"
                          ? uninonLogo
                          : info?.card?.brand === "jcb"
                          ? jcbLogo
                          : info?.card?.brand === "discover"
                          ? discoverlogo
                          : info?.card?.brand === "amex"
                          ? americanLogo
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div className="number">
                    <p>**** **** **** {info?.card?.last4}</p>
                    <span>
                      Expires {info?.card?.exp_month}/{info?.card?.exp_year}
                    </span>
                  </div>
                  <div className="custom_radio">
                    <input
                      type="radio"
                      id="featured-1"
                      name="card"
                      value={primaryCards}
                      onChange={() => [
                        handleChange(info.id),
                        setShowMethod(false),
                      ]}
                      checked={primaryCards === info?.id ? true : false}
                    />
                  </div>
                </label>
              ))}
          </div>

          <div className="addMethod" onClick={() => [setShowMethod(!showMethod)]}>
            <img src={showMethod ? cardMinusIcon : cardPlusIcon} alt="" />{" "}
            <p> Add new payment method</p>
          </div>

          <div className="formElement">
            <Elements stripe={stripePromise}>
              <CardStripeForm
                update={update}
                showMethod={showMethod}
                fetchCards={fetchCards}
                changeSubscription={changeSubscriptionFunc}
                handlePrimaryCard={handlePrimaryCardFunc}
                userCards={userCards}
                planAmount={planAmount}
              />
            </Elements>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
