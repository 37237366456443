import React from "react";
import { Modal } from "react-bootstrap";

export default function PrimaryEmail({ show, close, handleChange, update }) {
  return (
    <Modal show={show} onHide={close} className="logoutModal Custom2">
      <Modal.Header>
        <span className="logoutAlert">!</span>
      </Modal.Header>
      <Modal.Body>
        <h3>
          Are you sure you want to set this {show?.phone ? "phone" : "email"} as
          primary?
        </h3>
      </Modal.Body>

      <Modal.Footer className="justify-content-between mt-3">
        <button onClick={close} className="logout-cancel">
          Cancel
        </button>
        <button
          disabled={update}
          href="#"
          onClick={handleChange}
          className="logout-btn"
        >
          {update ? (
            <>
              <i className="fa fa-spinner fa-spin"></i> Yes
            </>
          ) : (
            "yes"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
