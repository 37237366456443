/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { DeleteErrorModal } from "../../components/sweetAlertComponent/DeleteError";
import Pagination from "../../components/pagination";
import { ListComponent } from "../../components/batch/listComponent";
import PrintBatchModal from "../../containers/Batches/PrintBatch";
import SearchFilter from "../../components/filters/SearchFilter";
import addIcon from "../../../assets/img/addIcon.svg";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { FilterComponent } from "../../components/filters";
import { Button, Modal } from "react-bootstrap";
import actionRestricted from "../../../assets/img/actionRestricted.svg";
import closeModal from "../../../assets/img/closeModal.svg";

// import './batches.css';

class ManageBatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batches: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeBatch: null,
      itemCount: "", // Item count admin wants to print
      printBatchError: "",
      sortBy: "createdAt",
      order: "desc",
      search: "",
      isFilterApplied: false,
      printModal: false,
      deleteBatchError: false,
      idFormat: "",
      showSuccess: false,
      showCounterSuccess: false,
      batchModal: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "createdAt",
        order: query.order ? query.order : "desc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
      },
      () => this.fetchBatches()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchBatches = async () => {
    const { currentPage, limit, search, sortBy, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      search,
      sortBy,
      order,
      // :
      //   sortBy === 'createdAt' || sortBy === 'availableUnits' ? 'desc' : 'asc',
    };
    this.setState({
      isLoading: true,
    });
    if (search) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BATCHES_LIST.service,
      ApiRoutes.BATCHES_LIST.url,
      ApiRoutes.BATCHES_LIST.method,
      ApiRoutes.BATCHES_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { batches, totalRecords },
      } = response.data;
      this.getProfile();
      this.setState({
        batches,
        totalRecords,
      });
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  // to manage item count change

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value, printBatchError: "" });
  };
  // To handle search on typing

  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onDelete = async (id, batchData) => {
    // If batch is already assigned to some clients
    if (batchData.itemCount !== batchData.availableUnits) {
      this.setState({
        deleteBatchError: true,
      });
      this.setState({
        batchModal: true,
      });
      // await SweetAlertComponent({
      //   // title: 'Are you sure?',
      //   text: `This batch has already been printed`,
      //   confirmButtonText: "OK",
      //   showCancelButton: false,
      // });
    } else {
      const { value } = await SweetAlertComponent({
        // title: 'Are you sure?',
        text: `Are you sure, you want to delete batch number ${batchData.batchNumber} ?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_BATCH.service,
        ApiRoutes.DELETE_BATCH.url.replace(":id", id),
        ApiRoutes.DELETE_BATCH.method,
        ApiRoutes.DELETE_BATCH.authenticate,
        undefined,
        undefined
      );
      this.setState({
        isLoading: false,
        id: "",
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await this.fetchBatches();
        toast.success(response.messages[0]);
      }
    }
  };
  viewHandler = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.VIEW_BATCH.replace(":id", id),
      state: {
        search,
      },
    });
  };
  onPrint = (id) => {
    const { batches } = this.state;
    this.setState({
      printModal: true,
      activeBatch: batches.filter((batch) => batch._id === id)[0] || {},
    });
  };
  // To close print batch modal
  handleClose = () => {
    this.setState({
      printModal: false,
      activeBatch: "",
      itemCount: "",
    });
  };
  // To close delete batch error modal
  handleCloseDelete = () => {
    this.setState({
      deleteBatchError: false,
    });
  };
  openPrintModal = () => {
    const { printModal, activeBatch, printBatchError, itemCount } = this.state;
    return (
      <PrintBatchModal
        open={printModal}
        batchData={{
          ...activeBatch,
          productName:
            activeBatch && activeBatch.product && activeBatch.product.length
              ? activeBatch.product[0].productName
              : "",
          productName:
            activeBatch && activeBatch.product
              ? activeBatch.product.productName
              : "",
        }}
        itemCount={itemCount}
        printBatchError={printBatchError}
        onChange={this.handleChange}
        handleClose={this.handleClose}
        fetchData={this.fetchBatches}
      />
    );
  };

  getProfile = async () => {
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user?.idFormat;
      this.setState({
        idFormat: data,
      });
    }
  };
  addBatch = async () => {
    const { idFormat } = this.state;
    if (!idFormat.scan || !idFormat.counterfeit) {
      this.setState({
        showSuccess: true,
      });
    } else {
      this.props.history.push(AppRoutes.ADD_BATCH);
    }
    //
  };

  setrouter = () => {
    const { idFormat } = this.state;
    return this.props.history.push({
      pathname: AppRoutes.IDFORMATS,
      search: `${idFormat.scan ? "?type=Counterfeit" : "?type=Scans"}`, // query string
      state: {
        // location state
        type: `${idFormat.scan ? "Counterfeit" : "Scans"}`,
      },
    });
  };

  closeSuccess = () => {
    this.setState({
      showSuccess: false,
    });
  };
  closeDelete = () => {
    this.setState({
      batchModal: false,
    });
  };
  render() {
    const {
      batches,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      order,
      sortBy,
      search,
      showImage = true,
      showSuccess,
      showCounterSuccess,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Batch Number",
        value: "batchNumber",
      },
      {
        label: "Product Number",
        value: "productNumber",
      },
      {
        label: "Product Name",
        value: "productName",
      },
      // {
      //   label: "Available Units",
      //   value: "availableUnits",
      // },
      {
        label: "Created Date",
        value: "createdAt",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        {/* <h1 className="h3-head-mob d-md-none">Batches</h1> */}
        <div className="tableFilter overflow-hidden btachMain add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>
        <div className="tableFilter d-flex justify-content-between pl-0 filter-row">
          <SearchFilter
            placeholder={"Search name, product number, batch number"}
            search={search}
            onChange={this.handleSearch}
            // onSearch={this.onSearch}
          />
          <div className="d-flex filter_holder">
            <FilterComponent
              options={filtersOption}
              selectedFilter={sortBy}
              label={"Sort by"}
              onFilterChange={(selectedValue) =>
                this.setFilter(selectedValue, "createdAt")
              }
            />
            <button
              disabled={isLoading}
              onClick={this.addBatch}
              className="addProductbtn ml-3"
            >
              <img src={addIcon} alt="" className="mr-2" /> Add Batch
            </button>
          </div>
        </div>

        <div className="card tableCard">
          {/* { !isLoading ? */}
          <ListComponent
            batches={batches}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            order={order}
            sortBy={sortBy}
            setFilter={this.setFilter}
            onDelete={this.onDelete}
            onPrint={this.onPrint}
            onDeleteModal={this.onDeleteModal}
            onView={this.viewHandler}
            filtersOption={filtersOption}
            addBatch={this.addBatch}
          />
          {/* : null
        } */}
        </div>
        <div className="footer-pg px-0 px-lg-3">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {this.openPrintModal()}
        <DeleteErrorModal
          // open={deleteBatchError}
          handleClose={this.handleCloseDelete}
          message={"This batch has been assigned to some client."}
          moduleName={"Batch"}
        />

        <Modal
          show={showSuccess}
          onHide={this.closeSuccess}
          className="logoutModal Custom2"
        >
          <Modal.Header>
            <span className="logoutAlert">!</span>
          </Modal.Header>
          <Modal.Body>
            <h3>Can't add Batch</h3>
            <p>Please add Scan Id and Counterfeit Id to add the batches</p>
          </Modal.Body>

          <Modal.Footer className="justify-content-between">
            <Button onClick={this.setrouter} className="logout-btn">
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.batchModal}
          onHide={this.closeDelete}
          className="logoutModal Custom2"
        >
          <Modal.Header className="justify-content-center p-4">
            <img src={actionRestricted} alt="" />
            <img
              src={closeModal}
              onClick={this.closeDelete}
              className="closeModal"
              alt=""
            />
          </Modal.Header>
          <Modal.Body>
            <h3>Action Restricted</h3>
            <p>This batch has already been printed</p>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ManageBatches;
