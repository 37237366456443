import React from "react";
import Skeleton from "react-skeleton-loader";
const getColumns = (count) => {
  const columns = [];
  for (let i = 0; i < count; i++) {
    columns.push(
      <div className='col-12 col-md-6 col-lg-4 col-xl-4 col-vd mb-4' key={i}>
        <div className='cat_head'>
          <h4 className='head_name'>
            <Skeleton color ="#e5e9ef" height={"25px"} width={"100%"}/>
          </h4>
          <div className='cat_desc'>
            <div className='num_head'>
              <h4 className='head_name'>
                <Skeleton  color ="#e5e9ef" height={"25px"}  width={"100%"}/>
              </h4>
              <Skeleton  color ="#e5e9ef" height={"25px"} width={"100%"}/>
            </div>
            <div className='date_head'>
              <h4 className='head_name rt-mg'>
                <Skeleton  color ="#e5e9ef" height={"25px"} width={"100%"} />
              </h4>
              <Skeleton  color ="#e5e9ef" height={"25px"} width={"100%"}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return columns;
};
export const CategoryCard = ({ columns = 8 }) => (
  <>
    {getColumns(columns)}
  </>
);
