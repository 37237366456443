import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { Dropdown, ProgressBar } from "react-bootstrap";
import {
  ApiRoutes,
  AppRoutes,
  DB_ACCPTABLE_FORMAT,
  genderOptions,
} from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import { InfoDetailComponent } from "../../../components/staffs";
import SubmitButton from "../../../components/form/SubmitButton";
import { message } from "../../../../common";
import editIcon from "../../../../assets/img/edit_viewstaff.svg";
import filterarrowIcon from "../../../../assets/img/filter-arrow.svg";
import { addStaffValidator } from "../../../../validators";
import SweetAlertComponent from "../../../components/sweetAlertComponent";
import moment from "moment";
// import '../manage-staff.css';

class ViewStaffRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: "",
      staffData: {},
      isLoading: false,
      isUpdating: false,
      updatingStatus: "",
      first_name: "",
      last_name: "",
      employee_id: "",
      email: "",
      phone: "",
      profile_picture: "",
      birth_date: "",
      personal_email: "",
      office_location: "",
      office_phone: "",
      gender: "",
      status: "",
      address1: "",
      address2: "",
      state: "",
      country: "",
      postCode: "",
      bio: "",
      joining_date: "",
      portal_access: "",
      isEditable: false,
      errors: null,
    };
  }
  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    this.setState(
      {
        staffId: id,
      },
      () => this.viewStaff()
    );
  };
  viewStaff = async () => {
    const { staffId } = this.state;
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_STAFF.service,
      ApiRoutes.VIEW_STAFF.url.replace(":id", staffId),
      ApiRoutes.VIEW_STAFF.method,
      ApiRoutes.VIEW_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data } = response.data;
      const [
        {
          first_name = "",
          last_name = "",
          employee_id = "",
          email = "",
          phone = "",
          profile_picture,
          other_info = {},
          birth_date = "",
          personal_email = "",
          office_location = "",
          office_phone = "",
          gender = "",
          status = "",
          roleName = "",
          bio = "",
          joining_date = "",
          role_id = "",
          joiningDateFormat = "",
          portal_access = "",
          birthDateFormat = "",
        },
      ] = data || {};
      const {
        address1 = "",
        address2 = "",
        state = "",
        country = "",
        postCode = "",
      } = other_info || {};
      this.setState({
        staffData: {
          first_name,
          last_name,
          employee_id,
          email,
          phone,
          profile_picture,
          bio,
          gender: gender
            ? genderOptions.filter((e) => e.value === gender)[0]
            : "",
          address1,
          address2,
          state,
          country,
          postCode,
          birth_date: birth_date ? new Date(birth_date) : null,
          joining_date: joining_date ? new Date(joining_date) : null,
          personal_email,
          office_location,
          office_phone,
          status,
          roleName,
          temp_role_id: role_id,
          role_id,
          joiningDateFormat: joiningDateFormat || "",
          portal_access,
          birthDateFormat: birthDateFormat || "",
        },
      });
    }
  };
  onlyNumbers = (str) => {
    return /^[0-9.,]+$/.test(str);
  };

  handleInfoChange = (event) => {
    const {
      target: { type, name, value },
    } = event;

    if (type === "tel" && value.length > 0) {
      if (!this.onlyNumbers(value)) return false;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        [name]: value ? value : value.trimLeft(),
        errors: {
          ...this.state.staffData.errors,
          [name]: "",
        },
      },
    });
  };
  onSelect = (data) => {
    if (data.value) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          gender: data,
        },
      });
    }
  };
  // On file select
  onFileChange = async (event) => {
    event.persist();
    const {
      target: { files, name },
    } = event;
    if (files[0] && !files[0].type.match("image.*")) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          errors: {
            ...this.state.staffData.errors,
            [name]: message.InvalidImages,
          },
        },
      });
      return;
    }
    this.setState({
      staffData: {
        ...this.state.staffData,
        errors: {
          ...this.state.staffData.errors,
          [name]: "",
        },
      },
    });
    await this.uploadPicture(files[0], name);
    event.target.value = "";
  };
  onDeleteFile = (fieldName) => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        [fieldName]: "",
        errors: {
          ...this.state.staffData.errors,
          [fieldName]: "",
        },
      },
    });
  };
  uploadPicture = async (data, name) => {
    this.setState({
      isUploading: true,
    });
    const response = await new ApiHelper().UploadFiles(
      ApiRoutes.UPLOAD_FILES.service,
      ApiRoutes.UPLOAD_FILES.url,
      { file: data },
      (progressEvent) => {
        const percent =
          Math.round((progressEvent.loaded / progressEvent.total) * 100 * 100) /
          100;
        this.setState({
          uploadPercentage: percent,
        });
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { path } = response.data || {};
      this.setState({
        staffData: { ...this.state.staffData, [name]: path },
      });
    }
    this.setState({
      isUploading: false,
      uploadPercentage: 0,
    });
  };
  updateStaffStatus = async (status) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_STATUS.service,
      ApiRoutes.UPDATE_STAFF_STATUS.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_STATUS.method,
      ApiRoutes.UPDATE_STAFF_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_STAFF_REQUESTS);
    }
  };
  updateStaffInfo = async () => {
    const { staffId, staffData } = this.state;
    this.setState({
      isUpdating: true,
      updatingSection: "contact",
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_INFO.service,
      ApiRoutes.UPDATE_STAFF_INFO.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_INFO.method,
      ApiRoutes.UPDATE_STAFF_INFO.authenticate,
      undefined,
      {
        ...staffData,
        gender: staffData.gender ? staffData.gender.value : "",
        role_id: staffData.role_id ? staffData.role_id.value : "",
      }
    );
    this.setState({
      isUpdating: false,
      updatingSection: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      this.setState({
        isEditable: false,
      });
      this.viewStaff();
    }
  };
  requestForModification = async (status) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.service,
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.url.replace(":id", staffId),
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.method,
      ApiRoutes.REQUEST_FOR_MODIFICATION_TO_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_STAFF_REQUESTS);
    }
  };
  resendInvitation = async (status) => {
    const { staffId } = this.state;
    this.setState({
      isUpdating: true,
      updatingStatus: status,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.RESEND_INVITATION_TO_STAFF.service,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.url.replace(":id", staffId),
      ApiRoutes.RESEND_INVITATION_TO_STAFF.method,
      ApiRoutes.RESEND_INVITATION_TO_STAFF.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isUpdating: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.props.history.push(AppRoutes.MANAGE_STAFF_REQUESTS);
    }
  };
  onEdit = () => {
    this.setState({
      isEditable: true,
    });
  };
  onCancel = () => {
    this.setState({
      isEditable: false,
    });
  };
  customBreadCrumb = () => {
    const {
      isLoading,
      staffData: { first_name = "", last_name = "" },
    } = this.state;
    return isLoading ? (
      <Spinner animation="border" size="sm" as={"span"} />
    ) : (
      "View Staff"
    );
  };
  viewPermission = (id) => {
    this.props.history.push(
      AppRoutes.MANAGE_STAFF_PERMISSION.replace(":id", id)
    );
  };
  handleSubmit = (event) => {
    event && event.preventDefault();
    const {
      email,
      office_phone,
      office_location,
      bio,
      profile_picture,
      role_id,
      address1,
      personal_email,
      phone,
      gender,
      portal_access,
      first_name,
      birth_date,
      joining_date,
    } = this.state.staffData;
    var payload = {
      email: email,
      office_phone: office_phone,
      first_name: first_name,
      joining_date: joining_date
        ? moment(joining_date).format(DB_ACCPTABLE_FORMAT)
        : "",
      office_location: office_location,
      bio: bio || "",
      profile_picture: profile_picture,
      role_id: role_id ? role_id.value : "",
      birth_date: birth_date
        ? moment(birth_date).format(DB_ACCPTABLE_FORMAT)
        : "",
      address1: address1 || "",
      personal_email: personal_email,
      phone: phone || "",
      gender: gender ? gender.value : "",
      portal_access: portal_access,
    };

    const { isValid, errors } = addStaffValidator(payload);
    if (isValid) {
      this.setState({
        errors: errors,
      });
      this.updateStaffInfo();
    } else {
      this.setState({
        errors: errors,
      });
    }
  };
  handleDateChange = (date) => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        joining_date: date,
        errors: {
          ...this.state.staffData.errors,
          joining_date: "",
        },
      },
    });
  };
  handleBirthDate = (birthDate) => {
    this.setState({
      staffData: {
        ...this.state.staffData,
        birth_date: birthDate,
      },
    });
  };

  portalAccessSwitcher = async (val) => {
    if (val === this.state.staffData.portal_access) return;
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: val
        ? "Are you sure, you want to enable portal access?"
        : "Are you sure, you want to disable portal access?",
    });
    if (!value) {
      return;
    }
    const { staffId, staffData } = this.state;
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_INFO.service,
      ApiRoutes.UPDATE_STAFF_INFO.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_INFO.method,
      ApiRoutes.UPDATE_STAFF_INFO.authenticate,
      undefined,
      { portal_access: val }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        staffData: {
          ...this.state.staffData,
          portal_access: val,
        },
      });
      toast.success(response.messages[0]);
    }
  };
  changeStaffStatus = async (status) => {
    const { staffId } = this.state;
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to change status of staff?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAFF_STATUS.service,
      ApiRoutes.UPDATE_STAFF_STATUS.url.replace(":id", staffId),
      ApiRoutes.UPDATE_STAFF_STATUS.method,
      ApiRoutes.UPDATE_STAFF_STATUS.authenticate,
      undefined,
      { status }
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      this.setState({
        staffData: {
          ...this.state.staffData,
          status: status,
        },
      });

      toast.success(response.messages[0]);
      this.viewStaff();

      // this.props.history.push(AppRoutes.MANAGE_STAFF_LIST);
    }
  };
  handleCancel = () => {
    this.setState({
      isEditable: false,
    });
  };
  onRoleSelect = (data) => {
    if (data && data.value) {
      this.setState({
        staffData: {
          ...this.state.staffData,
          role_id: data,
          temp_role_id: "",
        },
      });
    }
  };
  render() {
    const {
      isLoading,
      isUpdating,
      staffData,
      updatingStatus,
      isEditable,
      staffId,
      actionBtn = true,
      joining_date,
      birth_date,
      isUploading,
      uploadPercentage,
      onEdit,
      onChange,
      onSubmit,
      onSelect,
      onFileChange,
      sortByAction,
      onDateChange,
      onDeleteFile,
      onCancel,
      viewPermission,
      // changeStaffStatus,
      portalAccessSwitcher,
      portalAccessDiv,
      address1,
    } = this.state;
    const { status = "" } = staffData;
    const {
      props: {
        location: { pathname },
      },
    } = this;

    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-start justify-content-between pl-0 mb-0 mt-0">
          <h3>
            <Breadcrumbs
              pathname={pathname}
              customBreadCrumb={this.customBreadCrumb}
            />
          </h3>
        </div>

        <div
          className="tableFilter d-flex align-items-center justify-content-between pl-0 mt-0"
          style={{ margin: "0px 0px 25px" }}
        >
          <h3 className="m-0">View Staff</h3>
          <div className="">
            {actionBtn ? (
              <div className=" d-flex align-items-center">
                <div className="side-col dp-right">
                  <button
                    className="addProductbtn"
                    onClick={() => this.viewPermission(staffId)}
                  >
                    {" "}
                    Permissions
                  </button>
                </div>

                <Dropdown className="customDropdownBox ml-3">
                  <Dropdown.Toggle
                    variant=""
                    id="dropdown-basic"
                    className={
                      "sortBy-custom d-flex align-items-center justify-content-between"
                    }
                    style={{ width: "130px" }}
                  >
                    Action{" "}
                    <img src={filterarrowIcon} className="m-0 ml-2" alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                    <Dropdown.Item
                      as="div"
                      onClick={() => {
                        window.open(`mailto:${staffData.email}`);
                      }}
                    >
                      Contact User
                    </Dropdown.Item>
                    {status === "Suspended" ? (
                      <Dropdown.Item
                        as="div"
                        onClick={() => this.changeStaffStatus("Revoke")}
                      >
                        Revoke
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        as="div"
                        onClick={() => this.changeStaffStatus("Suspended")}
                      >
                        Suspend
                      </Dropdown.Item>
                    )}
                    {status === "Terminated" ? (
                      <Dropdown.Item
                        as="div"
                        onClick={() => this.changeStaffStatus("Rejoin")}
                      >
                        Rejoin
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        as="div"
                        onClick={() => this.changeStaffStatus("Terminated")}
                      >
                        Terminate
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="">
                  <button
                    className={
                      !isEditable ? "customEditbtn" : "customEditbtn_viewstaff"
                    }
                    onClick={this.onEdit}
                  >
                    <img src={editIcon} alt="" /> Edit
                  </button>
                </div>
                {/* <>
                    <div className="side-col dp-right">
                      <button
                        className="addProductbtn"
                        onClick={this.handleSubmit}
                        disabled={isUpdating}
                      >
                        {isUpdating ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Save
                          </>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                    <div className="side-col dp-right">
                      <button
                        className="customCancelBtn topheader mt-0 ml-3"
                        onClick={this.onCancel}
                        style={{minWidth: "131px"}}
                      >
                        {" "}
                        Cancel{" "}
                      </button>
                    </div>
                  </>
                )} */}
              </div>
            ) : (
              <div className="">
                <div className="">
                  <button
                    className="customEditbtn_viewstaff"
                    onClick={this.onEdit}
                  >
                    {" "}
                    <img src={editIcon} alt="" /> Edit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <InfoDetailComponent
            role_id={staffData.role_id}
            staffData={staffData}
            isLoading={isLoading}
            actionBtn={false}
            isEditable={isEditable}
            onEdit={this.onEdit}
            onChange={this.handleInfoChange}
            onSelect={this.onSelect}
            onRoleSelect={this.onRoleSelect}
            onFileChange={this.onFileChange}
            onDeleteFile={this.onDeleteFile}
            handleSubmit={this.handleSubmit}
            onDateChange={this.handleDateChange}
            handleBirthChange={this.handleBirthDate}
            portalAccessSwitcher={this.portalAccessSwitcher}
            portalAccessDiv={true}
            address1={address1}
            handleCancel={this.handleCancel}
            errors={this.state.errors}
          />
        </div>
      </div>
    );
  }
}
export default ViewStaffRequest;
