import React from "react";
import { Form } from "react-bootstrap";
import logo from "../../../assets/img/clarity_logo.png";
import vector from "../../../assets/img/verify_identity.svg";
import SubmitButton from "../form/SubmitButton";
import OTPInput, { ResendOTP } from "otp-input-react";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper, isLogin, setUserData } from "../../../helpers";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
const OtpForm = () => {
  const history = useHistory();
  const [otpEdit, setOtpEdit] = useState();
  const [otpValue, SetOtpValue] = useState(
    localStorage.getItem("otp_value")
      ? JSON.parse(localStorage.getItem("otp_value"))
      : null
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const otpChange = (value) => {
    setOtpEdit(value ? value : "");
  };

  useEffect(() => {
    if (!otpValue) {
      history.push(AppRoutes.VERIFYIDENTITY);
    }
    document.body.classList.add("bg-gradient-primary");
    const isLoggedInUser = isLogin();
    // Redirect to dashboard if admin already loggedin
    if (isLoggedInUser) {
      history.push(AppRoutes.DASHBOARD);
    }
  }, []);

  const protect_email = function (data) {
    var splitted, part1, part2;
    if ("email" in data && data.email) {
      splitted = data.email.split("@");
      part1 = splitted[0];
      part2 = splitted[1];
      if (part1?.length > 4) {
        return (
          part1.slice(0, 2) +
          part1.slice(3).replace(/.(?=..)/g, "*") +
          "@" +
          part2
        );
      } else {
        return (
          part1.slice(0, 1) +
          part1.slice(1).replace(/.(?=.)/g, "*") +
          "@" +
          part2
        );
      }
    } else if ("phone" in data && data.phone) {
      return data.phone.slice(0).replace(/.(?=....)/g, "*");
    }
  };

  const otpSubmit = async (event) => {
    event && event.preventDefault();
    let data = null;
    if ("email" in otpValue && otpValue.email) {
      data = {
        backupEmail: otpValue.email,
        code: otpEdit,
      };
    } else if (
      "countryCode" in otpValue &&
      "phone" in otpValue &&
      otpValue.countryCode &&
      otpValue.phone
    ) {
      data = {
        backupPhone: otpValue.phone,
        countryCode: otpValue.countryCode,
        code: otpEdit,
      };
    }
    if (data?.code) {
      setIsLoading(true);
      setErrors();
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.OTP_LOGIN.service,
        ApiRoutes.OTP_LOGIN.url,
        ApiRoutes.OTP_LOGIN.method,
        ApiRoutes.OTP_LOGIN.authenticate,
        undefined,
        data
      );
      setIsLoading(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
        // history.push(AppRoutes.VERIFYIDENTITY);
        setOtpEdit();
      } else {
        const data = response?.data?.data;
        setUserData(data);

        toast.success(response.messages[0]);
        history.push(AppRoutes.DASHBOARD);
        setOtpEdit();
      }
    } else {
      setErrors("Please enter otp");
    }
  };

  const googleOtpSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      userId: otpValue.userId,
      otp: otpEdit,
    };

    if (data?.otp) {
      setIsLoading(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GOOGLE_VERIFY_LOGIN.service,
        ApiRoutes.GOOGLE_VERIFY_LOGIN.url,
        ApiRoutes.GOOGLE_VERIFY_LOGIN.method,
        ApiRoutes.GOOGLE_VERIFY_LOGIN.authenticate,
        undefined,
        data
      );
      setIsLoading(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
        // history.push(AppRoutes.VERIFYIDENTITY);
        setOtpEdit();
      } else {
        const data = response?.data?.data;
        setUserData(data);

        toast.success(response.messages[0]);
        history.push(AppRoutes.DASHBOARD);
        setOtpEdit();
      }
    } else {
      setErrors("Please enter otp");
    }
  };

  const resendOtp = async () => {
    let data = null;
    if ("email" in otpValue && otpValue.email) {
      data = {
        backupEmail: otpValue.email,
      };
    } else if (
      "countryCode" in otpValue &&
      "phone" in otpValue &&
      otpValue.countryCode &&
      otpValue.phone
    ) {
      data = {
        backupPhone: otpValue.phone,
        countryCode: otpValue.countryCode,
      };
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PHONE_AUTH_OTP.service,
      ApiRoutes.PHONE_AUTH_OTP.url,
      ApiRoutes.PHONE_AUTH_OTP.method,
      ApiRoutes.PHONE_AUTH_OTP.authenticate,
      undefined,
      data
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      toast.success(response.messages[0]);
    }
  };

  const renderButton = (buttonProps) => {
    return (
      <button
        className={
          buttonProps.remainingTime !== 0 ? "resendBtngrey" : "resendBtn"
        }
        {...buttonProps}
      >
        {buttonProps.remainingTime !== 0
          ? `Resend OTP in 00:${
              buttonProps.remainingTime > 9
                ? buttonProps.remainingTime
                : "0" + buttonProps.remainingTime
            }`
          : "Resend OTP"}
      </button>
    );
  };
  const renderTime = () => {};
  return (
    <>
      <section className="formLeftSection">
        <div className="glassEffects nextonboarding verifyidentity_box otp">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <img className="logo" src={logo} />
                <h2>Verify your identity</h2>
                <p>
                  {otpValue?.userId
                    ? "Use google authenticator to validate otp"
                    : `You should have receive an OTP to ${protect_email(
                        otpValue
                      )}`}
                </p>
                <Form>
                  <h5>Enter OTP</h5>
                  <div className="d-flex align-items-center justify-content-start otp_form">
                    <OTPInput
                      value={otpEdit}
                      onChange={otpChange}
                      OTPLength={6}
                      otpType="number"
                      inputClassName="form-control p-0"
                    />
                  </div>
                  <div className="text-danger">{errors}</div>
                  {!otpValue?.userId && (
                    <ResendOTP
                      renderButton={renderButton}
                      renderTime={renderTime}
                      maxTime={20}
                      className="resendBtn"
                      onResendClick={resendOtp}
                    />
                  )}
                  <div
                    style={{
                      marginTop: "40px",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <SubmitButton
                      text={"Sign in"}
                      isLoading={isLoading}
                      onSubmit={
                        "userId" in otpValue && otpValue?.userId
                          ? googleOtpSubmit
                          : otpSubmit
                      }
                      className={"customSubmitBtn submitbtn mt-0"}
                    ></SubmitButton>
                  </div>
                </Form>
              </div>
              <div className="col-md-6 d-none d-md-block image-loader">
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <img className="w-100" src={vector} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OtpForm;
