import React from "react";
import { Modal } from "react-bootstrap";

export default function UpgradePopup({
  show,
  close,
  changeSubscription,
  update,
  planAmount,
}) {
  return (
    <Modal show={show} onHide={close} className="logoutModal Custom2">
      <Modal.Header>
        <span className="logoutAlert">!</span>
      </Modal.Header>
      <Modal.Body>
        <h3>
          Are you sure you want to{" "}
          {planAmount?.status === "change" ? "change" : "upgrade"} current Plan?
        </h3>
        <p>
          Your current plan will be{" "}
          {planAmount?.status === "change" ? "changed" : "upgraded"} right now
          and will be able to avail the{" "}
          {planAmount?.status === "change" ? "changed" : "upgraded"} features
          from Today only!
        </p>
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <button onClick={close} className="logout-cancel">
          Cancel
        </button>
        <button
          onClick={changeSubscription}
          disabled={update}
          className="logout-btn"
        >
          {update ? (
            <>
              <i className="fa fa-spinner fa-spin"></i>{" "}
              {planAmount?.status === "change" ? "Change" : "Upgrade"}
            </>
          ) : (
            <>{planAmount?.status === "change" ? "Change" : "Upgrade"}</>
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
