import React, { Component } from "react";
import * as qs from "query-string";
import { Dropdown } from "react-bootstrap";
import Pagination from "../../components/pagination";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes, SocketEvents } from "../../../config";
import { ApiHelper } from "../../../helpers";
import PHLContext from "../../../PHLContext";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ListComponent } from "../../components/users/ListComponent";
import { FilterComponent } from "../../components/filters/filterComponent";
import SearchFilter from "../../components/filters/SearchFilter";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import filterMobIcon from "../../../assets/img/home_icon.svg";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import rightPageArrow from "../../../assets/img/rightPageArrow.svg";
import leftPageArrow from "../../../assets/img/leftPageArrow.svg";
import FilterDropdown from "../../components/filters/FilterDropdown";
// import "./manage-users.css";

class ManageUser extends Component {
  static contextType = PHLContext;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: false,
      isUpdating: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeId: "",
      sortBy: "created_at",
      isFilterApplied: false,
      order: "desc",
      search: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    let { socket } = this.context;
    if (socket) {
      socket.on(SocketEvents.NEW_USER_ADDED, (data) => {
        console.log("event emitted", data);
        let newData = [...this.state.users];
        newData.splice(-1, 1);
        newData.unshift(data);
        this.setState({ users: newData });
      });
    }
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "created_at",
        order: query.order || "desc",
        search: query.search || "",
        isFilterApplied: query.sort ? true : false,
      },
      () => this.fetchUser()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchUser = async (loading = true) => {
    const { currentPage, sortBy, order, limit, search } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      order,
      sortBy,
      search,
    };
    this.setState({
      isLoading: loading,
    });
    if (search && this.state.isLoading) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.USER_LIST.service,
      ApiRoutes.USER_LIST.url,
      ApiRoutes.USER_LIST.method,
      ApiRoutes.USER_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
      isUpdating: false,
      activeId: "",
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { users, totalRecords },
      } = response.data;
      this.setState({ users, totalRecords });
    }
  };
  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  viewHandler = (id) => {
    const {
      location: { search },
    } = this.props;
    this.props.history.push({
      pathname: AppRoutes.VIEW_USER.replace(":id", id),
      state: {
        search,
      },
    });
  };

  blockUser = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to block this user?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BLOCK_USER.service,
      ApiRoutes.BLOCK_USER.url.replace(":id", id),
      ApiRoutes.BLOCK_USER.method,
      ApiRoutes.BLOCK_USER.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchUser(false);
      toast.success(response.messages[0]);
    }
  };
  unBlockUser = async (id) => {
    const { value } = await SweetAlertComponent({
      // title: "Are you sure?",
      text: `Are you sure, you want to unblock this user?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isUpdating: true,
      activeId: id,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNBLOCK_USER.service,
      ApiRoutes.UNBLOCK_USER.url.replace(":id", id),
      ApiRoutes.UNBLOCK_USER.method,
      ApiRoutes.UNBLOCK_USER.authenticate,
      undefined,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      await this.fetchUser(false);
      toast.success(response.messages[0]);
    }
  };

  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  render() {
    const {
      isLoading,
      users,
      totalRecords,
      currentPage,
      limit,
      isUpdating,
      activeId,
      sortBy,
      isFilterApplied,
      order,
      search,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Name",
        value: "name",
        order: "asc",
      },
      {
        label: "Blocked Users",
        value: "block",
        order: "asc",
      },
      {
        label: "Started At",
        value: "created_at",
        order: "desc",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];
    return (
      <div className="container-fluid fluid-pd-2">
        <div className="tableFilter overflow-hidden btachMain add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={pathname} />
          </h3>
        </div>

        <div className="tableFilter d-flex justify-content-between mb-0 mb-lg-4 ">
          <SearchFilter
            placeholder={"search by User name, Batch Number"}
            search={search}
            onChange={this.handleChange}
          />
          <div className="d-flex align-items-center filter_holder">
            {/* <FilterDropdown
              style={
                "no-arrow d-flex align-items-center justify-content-between sortBy-custom"
              }
              filtersOption={filtersOption}
              pagesOption={pagesOption}
              sortBy={sortBy}
              setFilter={this.setFilter}
            /> */}
            <FilterComponent
              options={filtersOption}
              selectedFilter={sortBy}
              label={"Sort by"}
              onFilterChange={(selectedValue) =>
                this.setFilter(selectedValue, "created_at")
              }
            />
          </div>
        </div>

        <div className="tableCard card">
          <ListComponent
            users={users}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            isUpdating={isUpdating}
            activeId={activeId}
            viewHandler={this.viewHandler}
            blockUser={this.blockUser}
            unBlockUser={this.unBlockUser}
            totalRecords={totalRecords}
            limit={limit}
            currentPage={currentPage}
            onPageChanged={this.onPageChanged}
            setFilter={this.setFilter}
            order={order}
            sortBy={sortBy}
          />
        </div>
        <div className="footer-pg px-3">
          {!isLoading ? (
            <>
              {/* <div className="float-left mt-2">
                <p className="page-desc-left">
                  Total: <span className="bold-black">{totalRecords}</span>
                </p>
              </div> */}
              <div className="float-left mt-2 mt-lg-2 ">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                {/* <p className="page-desc ml-2 float-right">Table View</p> */}

                {/* <Dropdown className="no-arrow dp-right ml-2">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={'filter-btn'}
                  >
                    {' '}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{' '}
                    <img src={filterarrowIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                    {pagesOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.onLimitChange(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}
                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      {/* <button className="paginationArrows">
                        <img src={leftPageArrow} />
                      </button> */}
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={this.onPageChanged}
                      />
                      {/* <button className="paginationArrows">
                        <img src={rightPageArrow} />
                      </button> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ManageUser;
