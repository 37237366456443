import React from "react";
import { AppConfig } from "../../../config";
import viewIcon from "../../../assets/img/view-counter.svg";
import contact from "../../../assets/img/contact_counter.svg";
import blockIcon from "../../../assets/img/block-icon.svg";
import { Dropdown } from "react-bootstrap";
import more from "../../../assets/img/more.svg";
import defaultUserImg from "../../../assets/img/home_icon.svg";

// import './user.css';

const UserRow = ({
  userData,
  isUpdating,
  viewUser,
  blockUser,
  unBlockUser,
}) => {
  const {
    first_name = "-",
    last_name = "",
    email = "",
    total_scans = 0,
    total_counterfeits = 0,
    created_atDate,
    block = false,
    profile_picture = "",
  } = userData;
  return (
    <tr className="scan btachRow history counter_feit">
      {/* <td>
        <InputCheckbox 
          id='customCheck'
          name='islogged'
          value=""
        />
      </td> */}
      <td className={"cursor-pointer"} onClick={viewUser}>
        <div className="d-flex align-items-center">
          <div
            style={{
              width: "36px",
              height: "36px",
              marginRight: "12px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            {profile_picture && defaultUserImg ? (
              <img
                alt=""
                style={{ width: "36px" }}
                src={
                  profile_picture
                    ? profile_picture.startsWith("https://")
                      ? profile_picture
                      : `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                    : defaultUserImg
                }
              />
            ) : (
              <span
                className="profile_holder"
                style={{ textTransform: "uppercase" }}
              >
                {first_name ? first_name.substring(0, 1) : "-"}
              </span>
            )}
          </div>
          <span className="textContol">
            {[first_name, last_name].filter(Boolean).join(" ") || "-"}
          </span>
        </div>
      </td>
      <td
        className={"cursor-pointer"}
        onClick={() => {
          if (email) {
            window.open(`mailto:${email}`);
          }
        }}
      >
        <span
          className="textContol"
          style={{ WebkitLineClamp: "1", maxWidth: "180px" }}
        >
          {email || "-"}
        </span>
      </td>
      <td>
        <span className="textContol">{total_scans}</span>
      </td>
      <td>
        <span className="textContol">{total_counterfeits}</span>
      </td>
      <td>
        <span className="textContol" style={{ opacity: "0.75" }}>
          {created_atDate || "-"}
        </span>
      </td>
      <td>
        <div className="d-flex align-items-center conuter-view">
          <button className="viewBtn" onClick={viewUser}>
            <img src={viewIcon} className="mr-2" alt="" /> View
          </button>
          <Dropdown className="">
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className="InactiveDropdown"
            >
              <img src={more} alt="" />
            </Dropdown.Toggle>
            <div className="customDropdownBox">
              <Dropdown.Menu className="shadow dropdown-menu dropdown-staff">
                <Dropdown.Item
                  as="div"
                  className="dropItem"
                  onClick={() => window.open(`mailto:${email}`)}
                >
                  <img src={contact} alt="" className={"img-main mr-2"} />
                  Contact
                </Dropdown.Item>
                <Dropdown.Item as="div" className="dropItem">
                  {block ? (
                    <span onClick={unBlockUser} disabled={isUpdating}>
                      {" "}
                      {isUpdating ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <img
                          src={blockIcon}
                          alt=""
                          className={"img-main mr-2"}
                        />
                      )}{" "}
                      Unblock{" "}
                    </span>
                  ) : (
                    <span onClick={blockUser} disabled={isUpdating}>
                      {" "}
                      {isUpdating ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <img
                          src={blockIcon}
                          alt=""
                          className={"img-main mr-2"}
                        />
                      )}{" "}
                      Block{" "}
                    </span>
                  )}
                </Dropdown.Item>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
};

export default UserRow;
