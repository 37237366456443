import Validator from "js-object-validation";
import { message } from "../common";

export const addStaffValidator = (data) => {
  const validations = {
    name: {
      required: true,
      alpha: true,
    },
    first_name: {
      required: true,
      alpha: true,
    },
    role_id: {
      required: true,
    },
    personal_email: {
      required: true,
      email: true,
    },
    personal_email: {
      required: true,
      email: true,
    },
    phone: {
      required: true,
      numeric: true,
    },
    office_phone: {
      required: true,
      numeric: true,
    },
    office_location: {
      required: true,
    },
    bio: {
      required: true,
    },
    birth_date: {
      required: true,
    },
    gender: {
      required: true,
    },
    address1: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    name: {
      required: message.Required.replace(":item", "first name"),
      alpha: message.InvalidName.replace(":item", "First name"),
    },
    first_name: {
      required: message.Required.replace(":item", "first name"),
      alpha: message.InvalidName.replace(":item", "Name"),
    },
    role_id: {
      required: message.Required.replace(":item", "role"),
    },
    email: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    personal_email: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    office_location: {
      required: message.Required.replace(":item", "loaction"),
    },
    bio: {
      required: message.Required.replace(":item", "bio"),
    },
    birth_date: {
      required: message.Required.replace(":item", "birth date"),
    },
    phone: {
      required: message.RequriedPhone.replace(":item"),
      numeric: message.InvalidPhone,
    },
    office_phone: {
      required: message.Required.replace(":item", "phone"),
      numeric: message.InvalidPhone,
    },
    gender: {
      required: message.Required.replace(":item", "gender"),
    },
    address1: {
      required: message.Required.replace(":item", "Address"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
