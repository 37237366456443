import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const CardLoader = () => (
  <>
    <div className="cardHeader">
              <h4><Skeleton color='#e5e9ef' height={'18px'} width={'90px'} /></h4>
              {/* <span>This Week</span> */}
              <Skeleton color='#e5e9ef' height={'18px'} width={'80px'} />
            </div>
              <div className="cardBody">
                <div>
                  <h4>
                    <Skeleton color='#e5e9ef' height={'27px'} width={'70px'} />
                  </h4>
                  <h5>
                  <Skeleton color='#e5e9ef' height={'18px'} width={'100px'} />
                  </h5>
                </div>
                <Skeleton color='#e5e9ef' height={'40px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'50px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'50px'} width={'100%'} />
                <Skeleton color='#e5e9ef' height={'50px'} width={'100%'} />


                {/* [Total Users Graph] */}
              </div>
  </>
);
