import Validator from "js-object-validation";
import { message } from "../common";

export const emailInfo = (data) => {
  const validations = {
    secondEmail: {
      required: true,
      email: true,
    },
    otp: {
      required: true,
    },
    backupEmail: {
      required: true,
      email: true,
    },
  };

  const messages = {
    secondEmail: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    backupEmail: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    otp: {
      required: message.Required.replace(":item", "otp"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
