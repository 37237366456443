import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./view/components/scrollToTop";
import AppRouter from "./router";
import logo from "./logo.svg";
import "react-toastify/dist/ReactToastify.css";
// import './App.css';
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Login from './view/containers/login';
// import SetPassword from './view/components/SetPassword';
// import ForgotPassword from './view/components/ForgotPassword';
// import PasswordSuccessSend from './view/components/PasswordSuccessSend';
// import ResetPassword from './view/components/ResetPassword';
// import ChangePassword from './view/components/ChangePassword';
// import Header from './view/components/partial/Header';
// import Sidebar from './view/components/partial/Sidebar';
// import SearchFilter from './view/components/filters/SearchFilter';
// import FilterDropdown from './view/components/filters/FilterDropdown';
// import AddProductButton from './view/components/filters/AddProductButton'
// import ListComponent from './view/components/products/ListComponent';
// import AddProductFormComponent from './view/components/products/AddProductFormComponent';

const history = createBrowserHistory();
function App() {
  return (
    <div className="App">
      <Router history={history}>
        {/* <Suspense fallback={""}> */}
        <ScrollToTop>
          <AppRouter />
        </ScrollToTop>
        {/* </Suspense> */}
      </Router>
      <ToastContainer autoClose={4000} />
      {/* <Login /> */}
      {/* <SetPassword /> */}
      {/* <ForgotPassword /> */}
      {/* <PasswordSuccessSend /> */}
      {/* <ChangePassword /> */}

      {/* <div id="wrapper">
      
        <Sidebar />

        <div id="content-wrapper" className='d-flex flex-column'>
          <div id="content">
            <Header />

            <div className='tableFilter d-flex align-items-center justify-content-between pl-4'>
              <h3>Product Listing </h3>
              <div className='d-flex align-items-center'>
                <SearchFilter />
                <FilterDropdown 
                  style={'no-arrow dropdown-custom d-flex align-items-center justify-content-between ml-3'}
                />
                <AddProductButton />
              </div>
            </div>

            <ListComponent />

            {/* <AddProductFormComponent /> */}
    </div>
    // </div>

    //   </div> */}

    // </div>
  );
}

export default App;
