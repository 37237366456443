import React, { useState } from "react";

import PlusIcon from "../../../../assets/img/plusIcon.svg";
import { AppConfig } from "../../../../config";
import { TableLoader } from "../../loader/Resusable/Table";
import noData from "../../../../assets/img/noData.svg";

export default function FaqAccordion({
  faqs,
  category,
  fetchData,
  loading,
  catLoader,
}) {
  const [isActive, setIsActive] = useState(null);
  const [account, setAccount] = useState(0);

  const handleActive = (val, index) => {
    setAccount(index);
    fetchData(val.id);
  };
  const handleAccordion = (index) => {
    var indevVal = null;
    if (isActive !== index) indevVal = index;
    setIsActive(indevVal);
  };

  return (
    <>
      <div className="leftAccordion">
        {catLoader ? (
          <TableLoader columns={1} rows={6} />
        ) : (
          <ul className="moduleList">
            {category?.map((info, index) =>
              info?.faqCount > 0 ? (
                <li
                  className={account === index ? "active" : ""}
                  key={index}
                  onClick={() => handleActive(info, index)}
                >
                  <span className="moduleHolder">
                    <img
                      src={
                        info?.categoryImg
                          ? info?.categoryImg.startsWith("https://")
                            ? info?.categoryImg
                            : `${AppConfig.FILES_ENDPOINT}${info?.categoryImg}`
                          : ""
                      }
                      // src={account === index ? info.categoryImg : info.categoryImg}
                      alt=""
                      style={{
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    <span>{info.categoryName}</span>
                  </span>
                </li>
              ) : (
                ""
              )
            )}
          </ul>
        )}
      </div>
      {loading ? (
        <TableLoader columns={1} rows={6} />
      ) : (
        <div className="rightAccordion">
          {faqs?.length ? (
            faqs?.map((info, index) => (
              <div
                key={index}
                className="accordion-item"
                onClick={() => handleAccordion(index)}
              >
                <div className="accordion-title d-flex">
                  <div>{info.question}</div>
                  <div className="icon">
                    {isActive === index ? (
                      <span className="iconHolder">
                        <img src={PlusIcon} className="cross" alt="" />
                      </span>
                    ) : (
                      <span className="iconHolder">
                        <img src={PlusIcon} alt="" />
                      </span>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isActive === index
                      ? "accordion-content show"
                      : "accordion-content hide"
                  }
                >
                  {info.answer}
                </div>
              </div>
            ))
          ) : (
            <div className="no-data w-100 d-flex align-items-center justify-content-center flex-column pt-5 pb-5">
              <img src={noData} alt="" style={{maxWidth: "120px"}} />
              <p>The data you're looking is not available at this moment</p>
            </div>
          )}
        </div>
      )}
    </>
  );
}
