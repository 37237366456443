import React from 'react';
import { Button, Form, NavLink } from 'react-bootstrap';
import logo from '../../../assets/img/clarity_logo.png';
import vector from '../../../assets/img/setPassword_vector.svg'
import InputTextFeild from '../form/InputTextFeild';
import SubmitButton from '../form/SubmitButton';
function SetPasswordFormComponent ({
    onChange,
    onSubmit,
    newpassword,
    cnfpassword,
    errors,
    isLoading,
}) {
    return (
        <>
            <section className='formLeftSection'>
                <div className='glassEffects nextonboarding'>
                    <div className='container'>
                        <div className='row'>
                            <div className="col-md-6">
                                <img className='logo' src={logo} />
                                <h2>Set Password</h2>
                                <p>Set your password and you will have to use this password to login to this portal</p>
                                <Form onSubmit={onSubmit}>
                                    <label>New Password</label>
                                    <InputTextFeild
                                        name="newpassword" 
                                        type="password" 
                                        placeholder="Enter your new password" 
                                        value={newpassword} 
                                        onChange={onChange} 
                                        errorMessage={errors && errors.newpassword ? errors.newpassword : ''}
                                    />

                                    <label>Confirm Password</label>
                                    <InputTextFeild
                                        name="cnfpassword" 
                                        type="password" 
                                        placeholder="Confirm your new password"
                                        value={cnfpassword} 
                                        onChange={onChange} 
                                        errorMessage={errors && errors.cnfpassword ? errors.cnfpassword : ''}
                                    />
                                    <div className='buttonSetPassword'>
                                        <Button className='customCancelBtn mt-0' variant="" type="button">
                                            Cancel
                                        </Button>

                                        <SubmitButton
                                            text={'Save'}
                                            isLoading={isLoading}
                                            // onSubmit={onSubmit}
                                            className={'customSubmitBtn mt-0'}
                                        >                                            
                                        </SubmitButton>
                                    </div>
                                </Form>
                            </div>
                            <div className="col-md-6 d-none d-md-block image-loader">
                                <div className='h-100 d-flex align-items-center justify-content-center'>
                                    <img className='w-100' src={vector} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>         
        </>
    );
}

export default SetPasswordFormComponent;