import * as qs from "query-string";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
// import { DeleteErrorModal } from "../../components/sweetAlertComponent/DeleteError";
import { ProductListComponent } from "../../components/products/ProductListComponent";
import Pagination from "../../components/pagination";
import SearchFilter from "../../components/filters/SearchFilter";
import FilterDropdown from "../../components/filters/FilterDropdown";
import rightPageArrow from "../../../assets/img/rightPage.svg";
import addIcon from "../../../assets/img/addIcon.svg";
import { FilterComponent } from "../../components/filters";
import { Modal } from "react-bootstrap";
import actionRestricted from "../../../assets/img/actionRestricted.svg";
import closeModal from "../../../assets/img/closeModal.svg";

// import "./manage-product.css";

// class ManageProduct extends Component {
const ManageProduct = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     products: [],
  //     isLoading: false,
  //     totalRecords: 0,
  //     currentPage: 1,
  //     limit: 10,
  //     deleteError: false,
  //     sortBy: "createdAt",
  //     order: "desc",
  //   };
  // }
  let history = useHistory();

  const [productDetails, setProductDetails] = useState({
    products: [],
    isLoading: false,
    totalRecords: 0,
    currentPage: 1,
    limit: 10,
    deleteError: false,
    sortBy: "createdAt",
    order: "desc",
  });
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [productDelete, setProductDelete] = useState(false);

  // componentDidMount() {
  //   console.log("dDAS");
  //   this.handleQueryParams();
  // }

  useEffect(() => {
    // const isLoggedInUser = isLogin();
    // Redirect to dashboard if admin already loggedin
    // if (isLoggedInUser) {
    //   this.props.history.push(AppRoutes.DASHBOARD);
    // }
    // await handleQueryParams();
    // console.log("innn");
    (async () => {
      console.log(props.location);
      await handleQueryParams();
      // await fetchProducts();
    })();
  }, [props.location.search]);
  const handleQueryParams = async () => {
    const {
      location: { search },
    } = props;
    const query = qs.parse(search);
    setProductDetails({
      ...productDetails,
      currentPage: query.page ? parseInt(query.page) : 1,
      limit: query.limit ? parseInt(query.limit) : 10,
      sortBy: query.sort || "createdAt",
      order: query.order ? query.order : "desc",
    });
    await fetchProducts();
  };

  // componentDidUpdate = ({ location: { search: prevSearch } }) => {
  //   const {
  //     location: { search },
  //   } = this.props;
  //   if (prevSearch !== search) {
  //     this.handleQueryParams();
  //   }
  // };

  const fetchProducts = async () => {
    const { currentPage, limit, order, sortBy } = productDetails;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      order,
      sortBy,
      search,
    };
    // this.setState({
    //   isLoading: true,
    // });
    setProductDetails({
      ...productDetails,
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.PRODUCTS_LIST.service,
      ApiRoutes.PRODUCTS_LIST.url,
      ApiRoutes.PRODUCTS_LIST.method,
      ApiRoutes.PRODUCTS_LIST.authenticate,
      data,
      undefined
    );
    // this.setState({
    //   isLoading: false,
    // });
    setProductDetails({
      ...productDetails,
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { products, totalRecords },
      } = response.data;

      setProductDetails((prevState) => ({
        ...prevState,
        products,
        totalRecords,
      }));
    }
  };
  const onPageChanged = (page) => {
    const { currentPage } = productDetails;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = props;
      const query = qs.parse(search);
      setProductDetails({
        ...productDetails,
        currentPage: page,
      });
      setPage(page);
      history.push([pathname, qs.stringify({ ...query, page })].join("?"));
    }
  };
  const handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    setSearch(value);
    const {
      location: { search: searchParam, pathname },
    } = props;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = value;
    history.push([pathname, qs.stringify({ ...query, ...params })].join("?"));
  };

  const onSearch = () => {
    const { search } = this.state;
    console.log("onSearch", this.state.search);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  const onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = props;
    const query = qs.parse(search);
    props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // Function to manage read more, read less
  const onExpand = (index) => {
    const { products } = productDetails;
    const temp = [...products];
    temp[index] = {
      ...temp[index],
      isExpand: !temp[index].isExpand,
    };

    setProductDetails({
      ...productDetails,
      products: temp,
    });
  };
  const onEdit = (id) => {
    // const {
    //   props: {
    //     location: { search },
    //   },
    // } = this;
    const {
      location: { search },
    } = props;
    // this.props.history.push({
    //   pathname: AppRoutes.EDIT_PRODUCT.replace(":id", id),
    //   state: {
    //     search,
    //   },
    // });
    props.history.push(AppRoutes.EDIT_PRODUCT.replace(":id", id));
  };
  const onDelete = async (id, name, batchCount = 0) => {
    if (batchCount > 0) {
      setProductDetails({
        ...productDetails,
        deleteError: true,
      });
      setProductDelete(true);
      // await SweetAlertComponent({
      //   // title: 'Are you sure?',
      //   text: `This product has current active batches.`,
      //   confirmButtonText: "OK",
      //   showCancelButton: false,
      // });
    } else {
      const { value } = await SweetAlertComponent({
        text: `Are you sure, you want to delete this product ?`,
      });
      if (!value) {
        return;
      }
      setProductDetails({
        ...productDetails,
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_PRODUCT.service,
        ApiRoutes.DELETE_PRODUCT.url.replace(":id", id),
        ApiRoutes.DELETE_PRODUCT.method,
        ApiRoutes.DELETE_PRODUCT.authenticate,
        undefined,
        undefined
      );
      setProductDetails({
        ...productDetails,
        id: "",
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await fetchProducts();
        toast.success(response.messages[0]);
      }
    }
  };
  // To close delete error modal
  const handleClose = () => {
    setProductDetails({
      ...productDetails,
      deleteError: false,
    });
  };
  const setFilter = (sortBy, order) => {
    // const {
    //   props: {
    //     location: { search: searchParam, pathname },
    //   },
    // } = this;
    console.log(sortBy);
    console.log(order);
    const {
      location: { search: searchParam, pathname },
    } = props;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sort = sortBy;
    }
    if (order) {
      params.order = order;
    }
    setProductDetails({
      ...productDetails,
      sortBy,
      order,
    });
    props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  // render() {
  const {
    isLoading,
    products,
    totalRecords,
    currentPage,
    limit,
    deleteError,
    order,
    sortBy,
  } = productDetails;
  // const {
  //   props: {
  //     location: { pathname },
  //   },
  // } = this;

  const {
    location: { pathname },
  } = props;

  const filtersOption = [
    {
      label: "Product Number",
      value: "productNumber",
    },
    {
      label: "Product Name",
      value: "productName",
    },
    {
      label: "Created Date",
      value: "createdAt",
    },
  ];

  const pagesOption = [
    {
      label: "10 Per page",
      value: 10,
    },
    {
      label: "20 Per page",
      value: 20,
    },
    {
      label: "50 Per page",
      value: 50,
    },
  ];

  return (
    <>
      <div className="container-fluid fluid-pd-2">
        {/* <h1 className="h3-head-mob d-md-none">Manage Product</h1> */}
        <div className="tableFilter overflow-hidden btachMain add_batch d-flex justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <p className="breadcrums-top">Product Listing</p>
          </h3>
        </div>

        <div className="tableFilter d-flex justify-content-between pl-0 filter-row">
          {/* <h3><Breadcrumbs pathname={pathname} /></h3> */}
          <SearchFilter
            placeholder={"Search name, number, description"}
            search={search}
            onChange={handleSearch}
          />
          <div className="d-flex align-items-center filter_holder">
            {/* <FilterDropdown
              style={
                "no-arrow d-flex align-items-center justify-content-between ml-3 sortBy-custom"
              }
              filtersOption={filtersOption}
              pagesOption={pagesOption}
              sortBy={sortBy}
              setFilter={setFilter}
            /> */}
            <FilterComponent
              options={filtersOption}
              selectedFilter={sortBy}
              label={"Sort by"}
              onFilterChange={(selectedValue) =>
                setFilter(selectedValue, "createdAt")
              }
            />
            {/* <AddProductButton
                to={AppRoutes.ADD_PRODUCT}
              >
                {" "}
                    Add Product
              </AddProductButton> */}
            <Link
              to={AppRoutes.ADD_PRODUCT}
              className="addProductbtn"
              style={{ marginLeft: "23px" }}
            >
              <img src={addIcon} alt="" className="mr-2" /> Add Product
            </Link>
          </div>
        </div>
        <div className="card tableCard">
          {/* {!isLoading ?  */}
          <ProductListComponent
            products={products}
            productDetails={productDetails}
            isLoading={isLoading}
            onExpand={onExpand}
            onEdit={onEdit}
            onDelete={onDelete}
            setFilter={setFilter}
            order={order}
            sortBy={sortBy}
            filtersOption={filtersOption}
          />
          {/* : null
      } */}
        </div>
        <div className="footer-pg px-3">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <div>
                  <p className="totalRecords">
                    Page {totalRecords > 0 ? currentPage : 0} of{" "}
                    {totalRecords % limit > 0
                      ? parseInt(totalRecords / limit) + 1
                      : parseInt(totalRecords / limit)}{" "}
                  </p>
                </div>
              </div>
              <div className="float-right mt-0">
                {/* <p className="page-desc ml-2 float-right">Table View</p>

                <Dropdown className="no-arrow dp-right ml-2">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn"}
                  >
                    {" "}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{" "}
                    <img src={filterarrowIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                    {pagesOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.onLimitChange(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown> */}

                <div className="d-flex align-items-center tableFooter">
                  {totalRecords > limit ? (
                    <div className="float-right d-flex align-items-center">
                      {/* <button className="paginationArrows">
                      <img src={leftPageArrow} />
                    </button> */}
                      <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageLimit={limit}
                        onPageChanged={onPageChanged}
                      />
                      {/* <button className="paginationArrows">
                        <img src={rightPageArrow} />
                      </button> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
        {/* <DeleteErrorModal
          open={deleteError}
          handleClose={this.handleClose}
          message={"This product has current active batches."}
          moduleName={"Product"}
        /> */}

        <Modal
          show={productDelete}
          onHide={() => setProductDelete(false)}
          className="logoutModal Custom2"
        >
          <Modal.Header className="justify-content-center p-4">
            <img src={actionRestricted} alt="" />
            <img
              src={closeModal}
              onClick={() => setProductDelete(false)}
              className="closeModal"
              alt=""
            />
          </Modal.Header>
          <Modal.Body>
            <h3>Action Restricted</h3>
            <p>This product has current active batches.</p>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
  // }
};

export default ManageProduct;
