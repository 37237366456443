import React from "react";
import Skeleton from "react-skeleton-loader";

export const SessionLoader = () => (
  <>
    <table className="table SocialloginDetails activeSession">
      <tbody>
        <tr>
          <td>
            <div className="socialProfile">
              <div>
                <h6>
                  <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
                </h6>
                <p>
                  <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
                </p>
              </div>
            </div>
          </td>
          <td>
            <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
          </td>
          <td className="cursor-pointer">
            <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
          </td>
        </tr>
        <tr>
          <td>
            <div className="socialProfile">
              <div>
                <h6>
                  <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
                </h6>
                <p>
                  <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
                </p>
              </div>
            </div>
          </td>
          <td>
            <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
          </td>
          <td className="cursor-pointer">
            <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
          </td>
        </tr>
      </tbody>
    </table>
  </>
);
