import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import InputTextField from "../../../components/form/InputTextFeild";
import { addClientValidator, addStaffValidator } from "../../../../validators";
import SubmitButton from "../../../components/form/SubmitButton";

export default function AddStaff({
  modalShow,
  modalClose,
  roleOptions,
  reloadAPI,
}) {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);
  const [inviteData, setInviteData] = useState({
    errors: null,
    first_name: "",
    last_name: "",
    role_id: "",
    email: "",
    name: "",
  });

  const handleChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    setInviteData({
      ...inviteData,
      [name]:
        type === "checkbox"
          ? checked
          : value.charAt(0).toUpperCase() + value.slice(1) && value.trimLeft(),
      errors: { ...inviteData.errors, [name]: "" },
    });
  };

  const handleEmailChange = (event) => {
    const {
      target: { type, value, name, checked },
    } = event;

    setInviteData({
      ...inviteData,
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      errors: { ...inviteData.errors, [name]: "" },
    });
  };

  const addClient = async (data) => {
    setIsAdding(true);

    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_STAFF.service,
      ApiRoutes.ADD_STAFF.url,
      ApiRoutes.ADD_STAFF.method,
      ApiRoutes.ADD_STAFF.authenticate,
      undefined,
      data
    );
    if (response && response.isError) {
      setIsAdding(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      reloadAPI();
      setIsAdding(false);
      setInviteData({
        first_name: "",
        last_name: "",
        role_id: "",
        email: "",
        name: "",
      });
    }
  };
  const handleSubmit = (event) => {
    event && event.preventDefault();
    var payload = {
      email: inviteData.email,
      name: inviteData.first_name,
      role_id: inviteData.role_id,
    };
    const { isValid, errors } = addClientValidator(payload);
    payload.name = inviteData.first_name + " " + inviteData.last_name;
    if (isValid) {
      addClient(payload);
    } else {
      setError(errors);
      return;
    }
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={modalClose}
        className="modal fade invitestaffmodal"
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label className={`form-control-label`}>First name</label>

                <InputTextField
                  placeholder="Enter First Name"
                  className={"form-control-user disable-write"}
                  name="first_name"
                  value={inviteData.first_name}
                  onChange={handleChange}
                  maxLength={30}
                  errorMessage={error && error.name ? error.name : ""}
                />
              </div>

              <div className="col-md-6">
                <label className={`form-control-label`}>Last name</label>

                <InputTextField
                  placeholder="Enter Last Name"
                  className={"form-control-user disable-write"}
                  name="last_name"
                  value={inviteData.last_name}
                  onChange={handleEmailChange}
                  maxLength={30}
                  // errorMessage={error && error.name ? error.name : ""}
                />
              </div>

              <div className="col-md-6">
                <label className={`form-control-label`}>Email</label>

                <InputTextField
                  placeholder="Enter Email"
                  className={"form-control-user disable-write"}
                  name="email"
                  type="email"
                  value={inviteData.email}
                  onChange={handleEmailChange}
                  maxLength={30}
                  errorMessage={error && error.email ? error.email : ""}
                />
              </div>

              <div className="col-md-6">
                <label className={`form-control-label`}>Role</label>

                <Form.Select
                  className="form-control"
                  name="role_id"
                  onChange={handleChange}
                >
                  <option value="" className="firstOption">
                    Select Role
                  </option>
                  {roleOptions &&
                    roleOptions.map((info, index) => (
                      <option key={index} value={info.id}>
                        {info.roleName}
                      </option>
                    ))}
                </Form.Select>
                <div className="text-danger" style={{fontSize: "12px"}}>
                  {roleOptions.length > 0
                    ? ""
                    : "Please add role from the manage role module"}
                </div>
                <div className="text-danger">
                  {error && error.role_id ? error.role_id : ""}
                </div>
              </div>

              <div className="col-md-12">
                <div
                  className="row flex-column-reverse flex-md-row"
                  style={{ marginTop: "14px" }}
                >
                  <div className="col-md-6 mt-2 mt-md-0">
                    <span className="customCancelBtn m-0" onClick={modalClose}>
                      Cancel
                    </span>
                  </div>
                  <div className="col-md-6">
                    <SubmitButton
                      className={"customSubmitBtn"}
                      text={"Send Invitation"}
                      isLoading={isAdding}
                      onSubmit={handleSubmit}
                      // disabled={!inviteData.first_name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
