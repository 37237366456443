import React from "react";
import { CounterfeitProduct } from "../../components/loader/Resusable/CounterfeitProduct";
import arrowopenmap from "../../../assets/img/arrow-openmap.svg";
import InputTextField from "../../components/form/InputTextFeild";

export const UserDetails = ({
  userDetails,
  isLoading,
  mapHandler,
  onChange,
  errors,
}) => {
  const {
    first_name = "",
    phone = "",
    email = "",
    other_info = {},
  } = userDetails || {};
  const { latitude = "", longitude = "", address1 } = other_info || {};
  return (
    <div className="">
      <div className="productFormBox">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <form>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>User Name</label>
                    <InputTextField
                      id="batch_number"
                      label={"Enter User Name"}
                      className={"form-control"}
                      placeholder="Enter User Name"
                      name="productNumber"
                      value={first_name || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>User Phone</label>
                    <InputTextField
                      id="batch_number"
                      label={"Enter User Phone"}
                      className={"form-control"}
                      placeholder="Enter User Phone"
                      name="productName"
                      value={phone || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`form-control-label`}>User Email</label>
                    <InputTextField
                      id="batch_number"
                      label={"Enter User Email"}
                      className={"form-control mb-0"}
                      placeholder="Enter User Email"
                      name="productName"
                      value={email || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                      <label className={`form-control-label`}>
                        User Location
                      </label>
                      {latitude && longitude ? (
                        <span
                          className="a-link cursor-pointer"
                          onClick={
                            () => mapHandler(latitude, longitude, address1)
                            //   () => {
                            //   let left = window.screen.width / 2 - 500 / 2;
                            //   let top = window.screen.height / 2 - 500 / 2;
                            //   window.open(
                            //     `https://maps.google.com/?q=${latitude},${longitude}`,
                            //     'popUpWindow',
                            //     `height=500,width=500,left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`,
                            //   );
                            // }
                          }
                        >
                          <span style={{ opacity: ".7" }}>Open Map</span>{" "}
                          <img src={arrowopenmap} alt="" />
                        </span>
                      ) : null}
                    </div>

                    <InputTextField
                      id="batch_number"
                      label={"Enter Batch Number"}
                      className={"form-control mb-0"}
                      placeholder="Enter Batch Number"
                      name="productName"
                      value={address1 || "-"}
                      onChange={onChange}
                      errorMessage={
                        errors && errors.batchNumber ? errors.batchNumber : ""
                      }
                      maxLength={15}
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
