import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import noRecentUpdate from "../../../assets/img/noRecentUpdate.svg";
import { DashboardWidgetLoader } from "../../components/loader/Resusable/DashboardWidget";
import { CardLoader } from "../../components/loader/Dashboard/CardLoader";
import { Link } from "react-router-dom";

export default function Notification() {
  useEffect(() => {
    getNotificationData();
  }, []);

  const [notificationData, setNotificationData] = useState();
  const [loading, setLoading] = useState();
  const getNotificationData = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NOTIFICATIONS.service,
      ApiRoutes.GET_NOTIFICATIONS.url,
      ApiRoutes.GET_NOTIFICATIONS.method,
      ApiRoutes.GET_NOTIFICATIONS.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response.data.data;
      setNotificationData(data);
    }
  };

  return (
    <>
      <div className="mycard py-0">
        {loading ? (
          <CardLoader />
        ) : (
          <>
            <div className="cardHeader">
              <h4>Latest Activities</h4>
            </div>
            {notificationData && notificationData.length > 0 ? (
              <div className="cardBody" style={{ padding: "17px 0 13px" }}>
                <div className="scrollBox">
                  <ul>
                    {notificationData &&
                      notificationData.slice(0, 5).map((info, index) => (
                        <li key={index}>
                          <Link className="notifyLink" to={info.redirectionURL}>
                            {info.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div
                className="cardBody d-flex align-items-center justify-content-center nodata_box flex-column"
                style={{ minHeight: "415px", padding: "0", marginTop: "-65px" }}
              >
                <img src={noRecentUpdate} />
                <h5>No Recent Update!</h5>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
