import React from "react";
import publishIcon from "../../../assets/img/publishIcon.svg";
import unpublishIcon from "../../../assets/img/unpublishIcon.svg";
import listIcon from "../../../assets/img/videoList_icon.svg";
import viewlist from "../../../assets/img/brushEdit.svg";
import deleteIcon from "../../../assets/img/trash-2.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";

export const VideoRow = (props) => {
  const { name = "-", link = "-", createdAtDate = "-", id } = props.videodata;
  const { isPublished, handleVideo } = props;

  return (
    <tr className="btachRow manage_video_row">
      {/* <td>
      <InputCheckbox 
        id='customCheck'
        name='islogged'
        value=""
      />
      </td> */}
      <td>
        <span className="textContol">{name}</span>
      </td>
      <td>
        <span className="textContol">{link}</span>
      </td>
      <td>
        <span className="textContol">{createdAtDate}</span>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {/* <button
            className='editButton'
            onClick={props.editVideo}
          >
            <img src={editIcon} alt='' /> Edit
          </button> */}
          {/* <button className='printButton mx-3'>
            <img className='mr-2' src={publishIcon} />
            Publish
          </button> */}
          {isPublished ? (
            <button
              className="printButton mx-3 Unpublishbtn"
              onClick={() =>
                handleVideo({
                  target: { name: "isPublished", value: false, id },
                })
              }
            >
              <img src={unpublishIcon} className="mr-2" alt="" /> Unpublish
            </button>
          ) : (
            <button
              className="printButton mx-3 publishbtn"
              onClick={() =>
                handleVideo({
                  target: { name: "isPublished", value: true, id },
                })
              }
            >
              <img src={publishIcon} className="mr-2" alt="" /> Publish
            </button>
          )}
          {/* <button className='deleteBtn mx-3'>
            <img className='mr-1' src={unpublishIcon} />
            Unpublish
          </button> */}
          {/* <button
            className='deleteBtn'
            onClick={props.deleteVideo}
          >
            <img className='mr-2' src={trashIcon} alt='' /> Delete
          </button> */}
          <Dropdown className="customDropdownVideo customDropdownBox manageVideoSelect">
            <Dropdown.Toggle variant="" id="dropdown-basic">
              <img src={listIcon} alt="" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Link to={{}} className="dropdown-item" onClick={props.editVideo}>
                {" "}
                <img src={viewlist} alt="" /> Edit{" "}
              </Link>
              <Link
                to={{}}
                className="dropdown-item"
                onClick={props.deleteVideo}
              >
                <img src={deleteIcon} alt="" />
                Delete{" "}
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
};

export default VideoRow;
