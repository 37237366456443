import React, { useEffect,useState } from 'react';
import { toast } from 'react-toastify';
import {
  getUserData
} from '../../../helpers/userData';
import { ApiRoutes } from '../../../config';
import { ApiHelper } from '../../../helpers';
import ChangePasswordFormComponent from '../../components/ChangePassword';

// import mainmob3xImg from '../../../assets/img/main_mob@3x.png';

const ChangePassword =()=>{
//   let history = useHistory();

    // let { token  } = useParams()
    // console.log(token);
  const [userChangePassword, setUserChangePassword] = useState({
    oldPassword:'',
    newPassword: '',
    cnfPassword:'',
    sub:'',
    errors: null,
    islogged: false,
    isLoading: false,
  });


  // componentWillMount() {
  //   const data = getCheck();
  //   if (data) {
  //     const { email, password } = data;
  //     this.setState({ email, password, islogged: true });
  //   }
  // }

  // componentDidMount() {
  //   document.body.classList.add('bg-gradient-primary');
  //   const isLoggedInUser = isLogin();
  //   // Redirect to dashboard if admin already loggedin
  //   if (isLoggedInUser) {
  //     this.props.history.push(AppRoutes.DASHBOARD);
  //   }
  // }

  useEffect(()=>{
    document.body.classList.add('bg-gradient-primary');
    // const isLoggedInUser = isLogin();
    // Redirect to dashboard if admin already loggedin
    // if (isLoggedInUser) {
    //   this.props.history.push(AppRoutes.DASHBOARD);
    // }

    const {id} = getUserData()
    setUserChangePassword({
      ...userChangePassword,
      sub:id
    });

  },[])

  const handleChange = event => {
    const {
      target: { type, value, name, checked },
    } = event;
    setUserChangePassword({
      ...userChangePassword,
      [name]: type === 'checkbox' ? checked : value,
      errors: { ...userChangePassword.errors, [name]: '' },
    });
  };

  const handleSubmit = async event => {
    event && event.preventDefault();
    const { oldPassword, cnfPassword, islogged ,newPassword ,sub} = userChangePassword;
    let data = {
        oldPassword,
        newPassword,
        sub
    };
    // let { isValid, errors } = loginValidator(data);
    // if (isValid) {
      setUserChangePassword({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.CHANGE_PASSWORD.service,
        ApiRoutes.CHANGE_PASSWORD.url,
        ApiRoutes.CHANGE_PASSWORD.method,
        ApiRoutes.CHANGE_PASSWORD.authenticate,
        undefined,
        data,
      );
      setUserChangePassword({
        isLoading: false,
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        const { data } = response.data;
        console.log(data);
        // setUserChangePassword(data);
        // const { email, password } = userChangePassword;
        // islogged ? setCheck(email, password) : clearCheck();
        // data.is_first_login == true ? history.push(AppRoutes.SET_PASSWORD):''
        // this.props.history.push(AppRoutes.DASHBOARD);
        // if(data.is_first_login){
        //   history.push(AppRoutes.SET_PASSWORD) 
        // }
      }
    // } else {
    //   setUserChangePassword({
    //     errors,
    //   });
    //   return;
    // }
  };

  // getCheckbox = () => {
  //   setUserChangePassword({ islogged: !userChangePassword.islogged });
  // };

  // componentWillUnmount = () => {
  //   document.body.classList.remove('bg-gradient-primary');
  // };
  // render() {
    const {  oldPassword,cnfPassword, errors, islogged, isLoading,newPassword } = userChangePassword;
    return (
      <>
      
                        <ChangePasswordFormComponent
                          oldPassword={oldPassword}
                          newPassword={newPassword}
                          cnfPassword={cnfPassword}
                          errors={errors}
                          islogged={islogged}
                          isLoading={isLoading}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
      </>
    );
  // }
}

export default ChangePassword;
