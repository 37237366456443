import React from 'react';
import Skeleton from 'react-skeleton-loader';

export const CounterfeitProduct = () => (
  <>
    {' '}
    <div className='row mtblf-5 w-100'>
      <div className='col-md-6 skeleton-bottom'>
        <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
        <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
      </div>
      <div className='col-md-6 skeleton-bottom'>
        <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
        <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
      </div>
    </div>
    <div className='row mtblf-5'>
      <div className='col-md-6 skeleton-bottom'>
        <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
        <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
      </div>
      <div className='col-md-6 skeleton-bottom'>
        <Skeleton color='#e5e9ef' height={'15px'} width={'60%'} />
        <Skeleton color='#e5e9ef' height={'35px'} width={'100%'} />
      </div>
    </div>
  </>
);
