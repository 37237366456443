import React, { useEffect, useState } from "react";
import info_btn from "../../../../assets/img/info_btn.svg";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "react-datepicker/dist/react-datepicker.css";
import SubmitButton from "../../form/SubmitButton";
import AsyncSelect from "react-select/async";
import { ApiHelper } from "../../../../helpers";
import { ApiRoutes } from "../../../../config";
import { toast } from "react-toastify";
import { timeFormater } from "../../../../validators/timeFormater";
import { PreferencesLoader } from "../../loader/Session/PreferencesLoader";

function PreferencesForm() {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        Select the date and time format in which you want to view your client
        portal data.
      </Popover.Body>
    </Popover>
  );

  

  const [formatList, setFormatList] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setPageLoader(false);
    } else {
      const data = response?.data?.data?.user;
      getFormatList();
      setDate({ label: data?.dateFormat, value: data?.dateFormat });
      setTime({ label: data?.timeFormat, value: data?.timeFormat });
      setPageLoader(false);
    }
  };

  const getFormatList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DATE_FORMAT_LIST.service,
      ApiRoutes.GET_DATE_FORMAT_LIST.url,
      ApiRoutes.GET_DATE_FORMAT_LIST.method,
      ApiRoutes.GET_DATE_FORMAT_LIST.authenticate,
      undefined
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      // Logout();
    } else {
      const data = response?.data?.data;
      setFormatList(data);
    }
  };

  const upateFormmat = async (event) => {
    event && event.preventDefault();
    let data = {
      date: date.value,
      time: time.value,
    };
    const { isValid, errors } = timeFormater(data);
    if (isValid) {
      setLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.UPDATE_DATE_TIME_FORMAT.service,
        ApiRoutes.UPDATE_DATE_TIME_FORMAT.url,
        ApiRoutes.UPDATE_DATE_TIME_FORMAT.method,
        ApiRoutes.UPDATE_DATE_TIME_FORMAT.authenticate,
        undefined,
        data
      );
      setLoader(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        toast.success(response?.data?.message);
        getProfile();
      }
    } else {
      setLoader(false);
      setError(errors);
    }
  };
  const onSelect = (value, name) => {
    if (name === "date") {
      setDate(value);
      setError({ ...error, [name]: "" });
    } else {
      setTime(value);
      setError({ ...error, [name]: "" });
    }
  };
  let dateFormat = [];
  formatList?.forEach((element) => {
    dateFormat.push({ label: element.key, value: element.key });
  });

  const timeFormat = [
    { label: "12:00", value: "12:00" },
    { label: "24:00", value: "24:00" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
  };

  
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings profileMainBox smsAuth"
            style={{ marginBottom: "30px", marginTop: "55px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Preferences</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            {pageLoader ? (
              <PreferencesLoader />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col-md-12">
                      <form>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Date Format</label>
                            <AsyncSelect
                              placeholder={"DD/MM/YYYY"}
                              className={"form-control"}
                              defaultOptions={dateFormat}
                              styles={customStyles}
                              value={date}
                              onChange={(selectedOption) =>
                                onSelect(selectedOption, "date")
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#00bdcc33",
                                  primary: "#00acb9",
                                },
                              })}
                            />
                            <div className="text-danger">{error?.date}</div>
                          </div>
                          <div className="col-md-4">
                            <label>Time Format</label>
                            <AsyncSelect
                              placeholder={"00:00 Hrs"}
                              className={"form-control"}
                              defaultOptions={timeFormat}
                              styles={customStyles}
                              value={time}
                              onChange={(selectedOption) =>
                                onSelect(selectedOption, "time")
                              }
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#00bdcc33",
                                  primary: "#00acb9",
                                },
                              })}
                            />
                            <div className="text-danger">{error?.time}</div>
                          </div>
                          <div
                            className="col-md-12 d-flex justify-content-center"
                            style={{ marginTop: "24px" }}
                          >
                            <div
                              style={{ maxWidth: "192px", minWidth: "192px" }}
                            >
                              <SubmitButton
                                className={"customSubmitBtn mt-0"}
                                text={"Save"}
                                isLoading={loader}
                                onSubmit={upateFormmat}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PreferencesForm;
