import React from "react";
import { Modal } from "react-bootstrap";
import { CANCELLATION_REASON } from "../../../config";

export default function CancelModal({
  cancelShow,
  cancelClose,
  handleCancelSubmit,
  handleChange,
  handleDescChange,
  loading,
  error,
}) {
  console.log(error);
  return (
    <Modal
      className="modal fade counterfeitModeltwo"
      show={cancelShow}
      onHide={cancelClose}
    >
      <div className="modal-body">
        <h3>Counterfeits</h3>
        <p>
          Please Select or Enter the reason of cancellation and we will notify
          the user for the same
        </p>
        <form onSubmit={handleCancelSubmit}>
          <div className="d-flex align-items-center mt-0 checkModalBox">
            <label className="container">
              <div className="m-0 p-0">
                {CANCELLATION_REASON.NOT_GENUINE.VALUE}
              </div>
              <input
                type="radio"
                name="Counterfeits"
                onChange={handleChange}
                value={CANCELLATION_REASON.NOT_GENUINE.VALUE}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="d-flex align-items-center checkModalBox">
            <label className="container">
              <div className="m-0 p-0">
                {CANCELLATION_REASON.INADEQUATE_DESCRIPTION.VALUE}
              </div>
              <input
                type="radio"
                name="Counterfeits"
                onChange={handleChange}
                value={"Inadequate Description"}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="d-flex align-items-center checkModalBox">
            <label className="container">
              <div className="m-0 p-0">
                {CANCELLATION_REASON.DUPLICATE_TICKET.VALUE}
              </div>
              <input
                type="radio"
                name="Counterfeits"
                onChange={handleChange}
                value={CANCELLATION_REASON.DUPLICATE_TICKET.VALUE}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="d-flex align-items-center checkModalBox">
            <label className="container">
              <div className="m-0 p-0">
                {CANCELLATION_REASON.USER_CREATED_BY_MISTAKE.VALUE}
              </div>
              <input
                type="radio"
                name="Counterfeits"
                onChange={handleChange}
                value={CANCELLATION_REASON.USER_CREATED_BY_MISTAKE.VALUE}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="d-flex align-items-center checkModalBox">
            <label className="container">
              <div className="m-0 p-0">
                {" "}
                {CANCELLATION_REASON.NO_RESOLUTION_REQUIRED.VALUE}
              </div>
              <input
                type="radio"
                name="Counterfeits"
                onChange={handleChange}
                value={CANCELLATION_REASON.NO_RESOLUTION_REQUIRED.VALUE}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="d-flex align-items-center checkModalBox">
            <label className="container">
              <div className="m-0 p-0"> {CANCELLATION_REASON.OTHER.VALUE}</div>
              <input
                type="radio"
                name="Counterfeits"
                value={CANCELLATION_REASON.OTHER.VALUE}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <div className="text-danger">
            {error && error.error && error.error.cancellationReason
              ? error.error.cancellationReason
              : ""}
          </div>
          <textarea
            name="Counterfeits"
            onChange={handleDescChange}
            placeholder="Please enter the reason if you’ve selected other"
          ></textarea>
          <div className="text-danger">
            {error && error.error && error.error.statusDescription
              ? error.error.statusDescription
              : ""}
          </div>

          <div className="d-flex align-items-center mt-4">
            <button
              type="button"
              className="customCancelBtn mt-0 btn"
              onClick={cancelClose}
            >
              Cancel
            </button>
            <button disabled={loading} className="btn customSubmitBtn mt-0" type="submit">
              {loading ? (
                <>
                  <i className="fa fa-spinner fa-spin test-spin"></i>
                  Updating
                </>
              ) : (
                "Update"
              )}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
