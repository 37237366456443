import React from "react";
// import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { AppConfig } from "../../../config";
import { BatchViewLoader } from "../../components/loader/Resusable/BatchView";
import InputTextField from "../../components/form/InputTextFeild";
// import calenderIcon from '../../../assets/img/chat.svg';
// import fileMinusIcon from '../../../assets/img/file-minus.svg';
// import filterarrowIcon from '../../../assets/img/filter-arrow.svg';
// import locationIcon from '../../../assets/img/location.svg';
// import tagIcon from '../../../assets/img/shape-copy.svg';
// import windowMinusIcon from '../../../assets/img/full-minus.svg';
import dummyImage from "../../../assets/img/no-image.jfif";
import printIcon from "../../../assets/img/batchPrint.svg";
// import InputTextFeild from '../form/InputTextFeild';
import viewIcon from "../../../assets/img/batchExport.svg";
import editpro_icon2 from "../../../assets/img/batchEdit.svg";

const BatchDetailComponent = ({
  batchData,
  isLoading,
  isExporting,
  onEdit,
  onPrint,
  onExport,
  handleSubmit,
  onChange,
  errors,
}) => {
  const {
    batchNumber = "",
    productId = {},
    // originId = {},
    itemCount = 0,
    availableUnits = 0,
    expiryDateFormat = "",
  } = batchData;
  const {
    productName = "-",
    productNumber = "-",
    productImage = "",
  } = productId || {};
  // const { originName = "-" } = originId || {};
  const filtersOption = [
    {
      label: "CSV",
      value: "csv",
    },
    {
      label: "PDF",
      value: "pdf",
    },
    {
      label: "Excel",
      value: "excel",
    },
  ];
  return (
    <div className="">
      {isLoading ? (
        <BatchViewLoader />
      ) : (
        <>
          <div className="productFormBox batchEdit mb-0 p-0">
            <div className="tableFilter batch_detail d-flex item-start justify-content-between mt-0">
              <h3 className="m-0 batchDetail ">
                Batch Details ({batchNumber})
              </h3>
              <div className="d-flex align-items-center mt-3 mt-lg-0">
                <div className="side-col dp-right">
                  <button className="editBatchBtn" onClick={onEdit}>
                    {" "}
                    <img
                      src={editpro_icon2}
                      alt=""
                      className={"img-main mr-2"}
                    />
                    Edit
                  </button>
                </div>

                <div className="side-col dp-right mx-3">
                  <button
                    className="batchPrint"
                    onClick={onPrint}
                    // disabled={!availableUnits}
                  >
                    {" "}
                    <img src={printIcon} alt="" className={"img-main mr-2"} />
                    Print
                  </button>
                </div>

                <div className="side-col dp-right">
                  <Dropdown className="no-arrow dp-right">
                    <Dropdown.Toggle
                      variant=""
                      id="dropdown-basic"
                      className={"exportBatchBtn"}
                      disabled={isExporting || itemCount === availableUnits}
                    >
                      {isExporting ? (
                        <>
                          <i className="fa fa-spinner fa-spin test-spin"></i>
                          Exporting
                        </>
                      ) : (
                        <>
                          <img
                            src={viewIcon}
                            alt=""
                            className={"img-main mr-2"}
                          />
                          Export
                        </>
                      )}
                    </Dropdown.Toggle>
                    <div className="customDropdownBox">
                      <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff">
                        {filtersOption.map(({ label, value }, index) => (
                          <Dropdown.Item
                            as="div"
                            key={index}
                            onClick={() => onExport(value)}
                          >
                            {label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
            <form
              className="product_add batch_detailPadding"
              onSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 order-2 order-lg-0 mt-5 mt-lg-0">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Batch Number
                        </label>
                        <InputTextField
                          id="batch_number"
                          label={"Enter Batch Number"}
                          className={"form-control"}
                          placeholder="Enter Batch Number"
                          name="batchNumber"
                          value={batchNumber}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.batchNumber
                              ? errors.batchNumber
                              : ""
                          }
                          maxLength={15}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Product Number
                        </label>
                        <InputTextField
                          id="batch_number"
                          label={"Enter Product Number"}
                          className={"form-control"}
                          placeholder="Enter Product Name"
                          name="batchNumber"
                          value={productNumber}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.batchNumber
                              ? errors.batchNumber
                              : ""
                          }
                          maxLength={15}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Product Name
                        </label>
                        <InputTextField
                          id="batch_number"
                          label={"Enter Product Name"}
                          className={"form-control"}
                          placeholder="Enter Product Name"
                          name="batchNumber"
                          value={productName}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.batchNumber
                              ? errors.batchNumber
                              : ""
                          }
                          maxLength={15}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Total Units
                        </label>
                        <InputTextField
                          id="batch_number"
                          label={"Total Units"}
                          className={"form-control"}
                          placeholder="Enter Product Name"
                          name="batchNumber"
                          value={itemCount}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.batchNumber
                              ? errors.batchNumber
                              : ""
                          }
                          maxLength={15}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label className={`form-control-label`}>
                          Available Units
                        </label>
                        <InputTextField
                          id="batch_number"
                          label={"Total Units"}
                          className={"form-control"}
                          placeholder="Enter Available Units"
                          name="batchNumber"
                          value={availableUnits}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.batchNumber
                              ? errors.batchNumber
                              : ""
                          }
                          maxLength={15}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="form-group">
                        <label className={`form-control-label`}>Expiry</label>
                        <InputTextField
                          id="batch_number"
                          label={"Total Units"}
                          className={"form-control"}
                          placeholder="Enter Available Units"
                          name="batchNumber"
                          value={expiryDateFormat}
                          onChange={onChange}
                          errorMessage={
                            errors && errors.batchNumber
                              ? errors.batchNumber
                              : ""
                          }
                          maxLength={15}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <div className="imageUploadBox">
                    <div className="imageUploadView viewbatch">
                      <img
                        className="batch-pr-img"
                        // src={
                        //   productImage
                        //     ? productImage.startsWith("https://")
                        //       ? productImage
                        //       : `${AppConfig.FILES_ENDPOINT}${productImage}`
                        //     : dummyImage
                        // }
                        src={
                          productImage
                            ? productImage.startsWith("https://")
                              ? productImage
                              : `${AppConfig.FILES_ENDPOINT}${productImage}`
                            : dummyImage
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = dummyImage;
                        }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default BatchDetailComponent;
