import React from "react";
import logo_mail from "../../../assets/img/logo_mail.svg";
import invite_mail_icon from "../../../assets/img/invite_mail_icon.svg";
import twitter_mail from "../../../assets/img/twitter_mail.svg";
import insta_mail from "../../../assets/img/insta_mail.svg";
import facebook_mail from "../../../assets/img/facebook_mail.svg";

function UserInvite(props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F6F9FE",
        padding: "48px",
        flexDirection: "column",
      }}
    >
      <table
        style={{
          background: "#fff",
          boxShadow: "-0.68px 3.67725px 13.19px rgb(67 86 100 / 4%)",
          borderRadius: "4px",
          width: "600px",
          minWidth: "600px",
          maxWidth: "600px",
        }}
      >
        <tr>
          <td
            style={{ padding: "16px 24px", borderBottom: "1px solid #DFEAFB" }}
          >
            <img src={logo_mail} />
          </td>
        </tr>
        <tr>
          <td>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "17px 24px",
              }}
            >
              <img src={invite_mail_icon} />
              <div style={{ paddingTop: "32px", width: "100%" }}>
                <h1
                  style={{
                    color: "#04172F",
                    lineHeight: "17px",
                    fontSize: "14px",
                    fontWeight: "700",
                    textAlign: "left",
                    marginBottom: "24px",
                  }}
                >
                  Hi Mark Collins,
                </h1>
                <p
                  style={{
                    color: "#5E6C84",
                    lineHeight: "22px",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "left",
                    marginBottom: "24px",
                  }}
                >
                  Clen Williams with Airhosting Ltd. has invited you to use
                  Clarity to manage product authenticity. Use the button below
                  to set up your account and get started:
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "24px",
                  }}
                >
                  <a
                    href="#"
                    style={{
                      color: "#FFFFFF",
                      lineHeight: "1",
                      fontSize: "14px",
                      fontWeight: "500",
                      borderRadius: "44px",
                      background: "#004AC3",
                      maxWidth: "223px",
                      minWidth: "223px",
                      height: "48px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                    }}
                  >
                    Set Up Your Account
                  </a>
                </div>
                <p
                  style={{
                    color: "#5E6C84",
                    lineHeight: "22px",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "left",
                    marginBottom: "24px",
                  }}
                >
                  If you have any questions for Clen Williams, you can contact
                  our customer success team by clicking here{" "}
                  <a
                    href="#"
                    style={{
                      color: "#009BF5",
                      lineHeight: "22px",
                      fontSize: "14px",
                      fontWeight: "500",
                      textAlign: "left",
                      textDecoration: "none",
                    }}
                  >
                    xyz@gmail.com
                  </a>
                  .
                </p>
                <p
                  style={{
                    color: "#5E6C84",
                    lineHeight: "22px",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "left",
                    marginBottom: "24px",
                  }}
                >
                  Welcome Aboard!
                </p>
                <p
                  style={{
                    color: "#5E6C84",
                    lineHeight: "22px",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "left",
                    marginBottom: "9px",
                  }}
                >
                  The Clarity Team
                </p>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td
            style={{
              padding: "16px 0 32px",
              borderTop: "1px solid #DFEAFB",
            }}
          >
            <div
              style={{
                display: "flex",
                alignIstems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h6
                style={{
                  color: "#97A0AF",
                  lineHeight: "17px",
                  fontSize: "12px",
                  fontWeight: "500",
                  textAlign: "center",
                  marginBottom: "4px",
                }}
              >
                Have a Question?
              </h6>
              <a
                href="#"
                style={{
                  color: "#5E6C84",
                  lineHeight: "17px",
                  fontSize: "16px",
                  fontWeight: "400",
                  textAlign: "center",
                  marginBottom: "12px",
                }}
              >
                support@clarity.com
              </a>
              <p
                style={{
                  color: "#97A0AF",
                  lineHeight: "18px",
                  fontSize: "10px",
                  fontWeight: "500",
                  textAlign: "center",
                  marginBottom: "20px",
                }}
              >
                Clarity LLP.
              </p>

              <div
                style={{
                  display: "flex",
                  alignIstems: "center",
                  justifyContent: "center",
                }}
              >
                <a href="#">
                  <img
                    src={twitter_mail}
                    style={{
                      boxShadow:
                        "2.98125px 8.27381px 16.5476px rgb(67 86 100 / 10%), -5.29875px 16.5476px 33.0952px rgb(67 86 100 / 8%)",
                      borderRadius: "16.5476px",
                    }}
                  />
                </a>
                <a href="#" style={{marginLeft: "24px", marginRight: "24px"}}>
                  <img
                    src={insta_mail}
                    style={{
                      boxShadow:
                        "2.98125px 8.27381px 16.5476px rgb(67 86 100 / 10%), -5.29875px 16.5476px 33.0952px rgb(67 86 100 / 8%)",
                      borderRadius: "16.5476px",
                    }}
                  />
                </a>
                <a href="#">
                  <img
                    src={facebook_mail}
                    style={{
                      boxShadow:
                        "2.98125px 8.27381px 16.5476px rgb(67 86 100 / 10%), -5.29875px 16.5476px 33.0952px rgb(67 86 100 / 8%)",
                      borderRadius: "16.5476px",
                    }}
                  />
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>
      <p style={{color: "#97A0AF", lineHeight:"18px", fontSize: "12px", fontWeight: "400", marginTop: "15px", marginBottom: "0"}}>Copyright 2020 @Clarity</p>
    </div>
  );
}

export default UserInvite;
