import React from "react";
import tickIconSteps from "../../../assets/img/submit_data.svg";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../config";

function Requested({ requestId, isSubmited }) {
  const history = useHistory();
  const closeTab = () => {
    return history.push(AppRoutes.LOGIN);
  };
  return (
    <>
      <div className="almostdone_box">
        <div className="text-center align-items-center justify-content-center">
          <img src={tickIconSteps} className="mb-4" />
          <h5>Submitted Successfuly!</h5>
        </div>
        <p>
          Your details will be sent to Clarity team. Your request Id is{" "}
          {requestId}
        </p>
        <button onClick={closeTab} className="submitButton">
          OK
        </button>
      </div>
    </>
  );
}

export default Requested;
