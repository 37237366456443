import React from "react";
import { Link } from "react-router-dom";
import { AppRoutes, ApiRoutes } from "../../../config";
import listIcon from "../../../assets/img/videoList_icon.svg";
import viewlist from "../../../assets/img/viewlist.svg";
import deleteIcon from "../../../assets/img/trash-2.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import publishIcon from "../../../assets/img/publishIcon.svg";
import unpublishIcon from "../../../assets/img/unpublishIcon.svg";
import SweetAlertComponent from "../sweetAlertComponent";

export const ListCategoryComponent = ({
  categories,
  deleteCategory,
  history,
  isUpdating,
  fetchCategories,
  activeId,
  ...rest
}) => {
  const {
    location: { search },
  } = rest;
  const [isLoading, setIsLoading] = useState(false);
  const changeCategoryStatus = async (data, id) => {
    const allData = {
      isPublished: data,
    };

    setIsLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CATEGORIES_LIST_STATUS.service,
      ApiRoutes.CATEGORIES_LIST_STATUS.url.replace(":id", id),
      ApiRoutes.CATEGORIES_LIST_STATUS.method,
      ApiRoutes.CATEGORIES_LIST_STATUS.authenticate,
      undefined,
      allData
    );
    setIsLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      fetchCategories();
    }
  };

  const handleVideo = async (event) => {
    const { target } = event;
    changeCategoryStatus(target.value, target.id);
  };

  return categories.map((catData) => {
    const {
      _id,
      cat_name = "-",
      total_videos = 0,
      orderDateFormat,
      publishedCount,
      unpublishedCount,
    } = catData;

    // console.log("catData", catData);

    return (
      <div className="col-12 col-md-6 col-lg-4 col-xl-4 col-vd mb-4" key={_id}>
        <div
          className={`cat_head  ${publishedCount !== 0 ? "" : "is_published"}`}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h4
              className="head_name"
              onClick={() =>
                history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", _id)}`)
              }
            >
              Category Name
            </h4>
            <div>
              <Dropdown className="customDropdownVideo customDropdownBox">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <img src={listIcon} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Link
                    to={{
                      pathname: `${AppRoutes.MANAGE_VIDEO.replace(":id", _id)}`,
                      state: {
                        search,
                      },
                    }}
                    className="dropdown-item"
                  >
                    {" "}
                    <img src={viewlist} alt="" /> View{" "}
                  </Link>
                  <Link
                    to={{}}
                    className="dropdown-item"
                    disabled={total_videos || (isUpdating && activeId === _id)}
                    onClick={() => deleteCategory(_id, cat_name)}
                  >
                    {" "}
                    {isUpdating && activeId === _id ? (
                      <i className="fa fa-spinner fa-spin"></i>
                    ) : (
                      <img src={deleteIcon} alt="" />
                    )}
                    Delete
                  </Link>
                  {publishedCount > 0 && total_videos > 0 ? (
                    <Link
                      to={{}}
                      className="dropdown-item"
                      onClick={() =>
                        handleVideo({
                          target: { value: false, id: _id },
                        })
                      }
                    >
                      {isLoading ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <img src={unpublishIcon} alt="" />
                      )}
                      Unpublish
                    </Link>
                  ) : (
                    <Link
                      to={{}}
                      className="dropdown-item"
                      onClick={() =>
                        handleVideo({
                          target: { value: true, id: _id },
                        })
                      }
                    >
                      {isLoading ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : (
                        <img src={unpublishIcon} alt="" />
                      )}
                      Publish
                    </Link>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <h4
            className="head_description cat-desc-head cursor-pointer"
            onClick={() =>
              history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", _id)}`)
            }
          >
            {cat_name}
          </h4>
          <div style={{ height: "34px" }}>
            {!publishedCount > 0 && total_videos > 0 && (
              <div
                onClick={() =>
                  history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", _id)}`)
                }
              >
                <span className="cardStatus">Unpublished</span>
              </div>
            )}
          </div>

          <div
            className="cat_desc"
            onClick={() =>
              history.push(`${AppRoutes.MANAGE_VIDEO.replace(":id", _id)}`)
            }
          >
            <div className="num_head d-flex justify-content-between">
              <div>
                <h4 className="head_name">Total</h4>
                <h4 className="head_description">{total_videos}</h4>
              </div>

              <div style={{ marginRight: "14px" }}>
                <h4 className="head_name rt-mg">Created At</h4>
                <h4 className="head_description">{orderDateFormat}</h4>
              </div>
            </div>
            <div className="num_head d-flex justify-content-between">
              <div>
                <h4 className="head_name published">Published</h4>
                <h4 className="head_description">{publishedCount}</h4>
              </div>

              <div>
                <h4 className="head_name rt-mg unpublished">Unpublished</h4>
                <h4 className="head_description">{unpublishedCount}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });
};
