/** @format */

import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-skeleton-loader";
import { AppRoutes, ApiRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import filterarrowIcon from "../../../assets/img/filterarrowIcon.svg";
import InputTextField from "../../components/form/InputTextFeild";
import CancelModal from "./CancelModal";
import ResolutionModal from "./ResolutionModal";
import { toast } from "react-toastify";
import { cancelValidate } from "../../../validators/cancelValidate";

export const AssigneeDetails = ({
  assigneeDetails,
  status,
  dateOfActionDate,
  conterfeitId,
  viewCounterfeitRequest,
  changeRequestRequest,
  errors,
  onChange,
}) => {
  const { name, role, id } = assigneeDetails;
  const [loading, setLoading] = useState(false);
  const [errorData, setError] = useState({
    error: null,
  });
  const [cancelShow, setCancelShow] = useState(false);
  const [resolutionShow, setResolutionShow] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);

  const fetchActiveStaffList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_STAFF_OPTION_LIST.service,
      ApiRoutes.GET_STAFF_OPTION_LIST.url,
      ApiRoutes.GET_STAFF_OPTION_LIST.method,
      ApiRoutes.GET_STAFF_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      setStaffOptions(data);
    }
  };
  const changeAssignee = async (newAssignee) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.service,
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.url.replace(
        ":id",
        conterfeitId
      ),
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.method,
      ApiRoutes.CHANGE_ASSIGNEE_OF_COUNTERFEIT_REQ.authenticate,
      undefined,
      { oldAssignee: id, newAssignee }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      await viewCounterfeitRequest(false);
      setLoading(false);
    }
  };
  const unAssign = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.service,
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.url.replace(":id", conterfeitId),
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.method,
      ApiRoutes.UNASSIGN_STAFF_FROM_REQ.authenticate,
      undefined,
      { assigneeId: id }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setLoading(false);
    } else {
      toast.success(response.messages[0]);
      await viewCounterfeitRequest(false);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (role === "S") {
      fetchActiveStaffList();
    }
  }, [role]);

  const handleCancel = () => {
    setCancelShow(true);
  };

  const handleResolution = () => {
    setResolutionShow(true);
  };

  const [cancelData, setCancelData] = useState();
  const [cancelDesc, setCancelDesc] = useState();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setCancelData(value.trimLeft());
  };

  const handleDescChange = (event) => {
    const {
      target: { value },
    } = event;

    setCancelDesc(value.trimLeft());
  };
  const handleCancelSubmit = (event) => {
    event && event.preventDefault();
    let data = {
      status: "Cancelled",
      cancellationReason: cancelData,
    };
    if (data.cancellationReason === "Other") {
      data = {
        status: "Cancelled",
        cancellationReason: cancelData || "",
        statusDescription: cancelDesc || "",
      };
    }

    let { isValid, errors } = cancelValidate(data);
    if (isValid) {
      console.log("data", data);
      changeCancelRequest(data);
    } else {
      console.log("errors", errors);
      setError({
        error: errors,
      });
    }
  };

  const handleCompleteSubmit = (event) => {
    event && event.preventDefault();
    let data = {
      status: "Completed",
      statusDescription: cancelDesc,
    };

    let { isValid, errors } = cancelValidate(data);
    if (isValid) {
      console.log("data", data);
      changeCancelRequest(data);
    } else {
      console.log("errors", errors);
      setError({
        error: errors,
      });
    }
  };

  const changeCancelRequest = async (data) => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.service,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.url.replace(
        ":id",
        conterfeitId
      ),
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.method,
      ApiRoutes.CHANGE_COUNTERFEIT_REQUEST_STATUS.authenticate,
      undefined,
      data
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response.messages[0]);
      viewCounterfeitRequest();
    }
  };

  return (
    <>
      <div className="col-md-12">
        <div
          className="tableFilter d-flex align-items-center justify-content-between pl-0 mb-4 assigneeFilter"
          style={{ margin: "35px 0 0 0" }}
        >
          <h3 style={{ margin: "0 0 10px 0" }}>Assignee Listing</h3>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
              {role === "S" && (
                <Link
                  className="addProductbtn normalbtn"
                  style={{ marginRight: "25px" }}
                  to={AppRoutes.VIEW_STAFF_REQUEST.replace(":id", id)}
                >
                  View Staff
                </Link>
              )}
              <button
                className="addProductbtn normalbtn"
                style={{ marginRight: "25px" }}
                onClick={unAssign}
              >
                {" "}
                Un-Assign
              </button>
            </div>
            <Dropdown
              className="customDropdownBox changeAssignee"
              style={{ marginRight: "25px" }}
            >
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                style={{ width: "170px", minWidth: "170px" }}
                className={"dropdown-custom"}
              >
                Change Assignee
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="shadow dropdown-menu-right dropdown-staff"
                style={{ padding: "2px 0" }}
              >
                {staffOptions.map(({ label, value }, index) => (
                  <Dropdown.Item
                    as="div"
                    key={index}
                    onClick={() => changeAssignee(value)}
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="customDropdownBox updateTicket">
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                style={{ width: "146px", minWidth: "146px" }}
                className={"dropdown-custom"}
              >
                Update Ticket
                <img src={filterarrowIcon} alt="" />
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="shadow dropdown-menu-right dropdown-staff"
                style={{ padding: "2px 0" }}
              >
                <Dropdown.Item
                  as="div"
                  onClick={() => changeRequestRequest("In Review")}
                >
                  In Review
                </Dropdown.Item>
                <Dropdown.Item as="div" onClick={handleResolution}>
                  Completed
                </Dropdown.Item>
                <Dropdown.Item
                  as="div"
                  onClick={() => changeRequestRequest("Reopened")}
                >
                  Re-open
                </Dropdown.Item>
                <Dropdown.Item as="div" onClick={handleCancel}>
                  Cancel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="col-md-12 d-flex mb-5">
        <div className="productFormBox w-100">
          <div className="">
            {loading ? (
              <div className="row mtblf-5">
                <div className="col dv-5 skeleton-bottom">
                  <Skeleton color="#e5e9ef" height={"15px"} width={"60%"} />
                  <Skeleton color="#e5e9ef" height={"35px"} width={"100%"} />
                </div>
                <div className="col dv-5 skeleton-bottom">
                  <Skeleton color="#e5e9ef" height={"15px"} width={"60%"} />
                  <Skeleton color="#e5e9ef" height={"35px"} width={"100%"} />
                </div>
                <div className="col dv-5 skeleton-bottom">
                  <Skeleton color="#e5e9ef" height={"15px"} width={"60%"} />
                  <Skeleton color="#e5e9ef" height={"35px"} width={"100%"} />
                </div>
              </div>
            ) : (
              <form>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className={`form-control-label`}>User Name</label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={name || "-"}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 col-xl-4 ">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Date of Action
                      </label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={dateOfActionDate}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className={`form-control-label`}>
                        Request Status
                      </label>
                      <InputTextField
                        id="batch_number"
                        label={"Enter Product Number"}
                        className={"form-control"}
                        placeholder="Enter Product Number"
                        name="productNumber"
                        value={status}
                        onChange={onChange}
                        errorMessage={
                          errors && errors.batchNumber ? errors.batchNumber : ""
                        }
                        maxLength={15}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <CancelModal
        cancelShow={cancelShow}
        cancelClose={() => setCancelShow(false)}
        handleChange={handleChange}
        handleDescChange={handleDescChange}
        handleCancelSubmit={handleCancelSubmit}
        loading={loading}
        error={errorData}
      />
      <ResolutionModal
        loading={loading}
        error={errorData}
        resolutionShow={resolutionShow}
        handleDescChange={handleDescChange}
        resolutionclose={() => setResolutionShow(false)}
        handleCompleteSubmit={handleCompleteSubmit}
      />
    </>
  );
};
