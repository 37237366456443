import React, { useEffect, useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import { Breadcrumbs } from "../../partial/Breadcrumbs";
import { useHistory, useLocation } from "react-router";
import { ApiRoutes, AppRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { idFormat } from "../../../../validators/idFormat";
import { FormatLoader } from "../../loader/FormatLoader";

function Idformats() {
  const location = useLocation();
  const history = useHistory();
  const [selected, setSelected] = useState(
    location?.state?.type ? location?.state?.type : "Scans"
  );
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idData, setIdData] = useState({
    scan: "",
    counterfeit: "",
    employee: "",
  });
  const [editIdData, setEditIdData] = useState({
    scan: "",
    counterfeit: "",
    employee: "",
  });
  const changeView = (val) => {
    setSelected(val);
  };

  const changeIdFormat = async (data) => {
    setIsUpdating(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ID_FORMAT.service,
      ApiRoutes.ID_FORMAT.url,
      ApiRoutes.ID_FORMAT.method,
      ApiRoutes.ID_FORMAT.authenticate,
      undefined,
      data
    );
    setIsUpdating(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      setError(null);
      getFormat();
    }
  };
  const handleScanSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      scan: editIdData.scan ? editIdData.scan : "",
    };

    let { isValid, errors } = idFormat(data);
    if (isValid) {
      changeIdFormat(data);
    } else {
      setError(errors);
    }
  };

  const handleCounterSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      counterfeit: editIdData.counterfeit ? editIdData.counterfeit : "",
    };

    let { isValid, errors } = idFormat(data);
    if (isValid) {
      changeIdFormat(data);
      setError(null);
    } else {
      setError(errors);
    }
  };

  const handleEmpSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      employee: editIdData.employee ? editIdData.employee : "",
    };

    let { isValid, errors } = idFormat(data);
    if (isValid) {
      changeIdFormat(data);
      setError(null);
    } else {
      setError(errors);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;

    const re = /^[A-Za-z]+$/;
    if (value === "" || re.test(value)) {
      setEditIdData({
        ...editIdData,
        [name]: value.trimLeft(),
      });
    }
    if (value) {
      setError(null);
    }
  };

  useEffect(() => {
    getFormat();
  }, []);

  const getFormat = async () => {
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data?.user;
      setIdData(data.idFormat);
    }
  };
  const onCancel = () => {
    history.push(AppRoutes.DASHBOARD);
  };
  return (
    <>
      <div className="container-fluid fluid-pd-2 pb-5">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={location.pathname} />
          </h3>
        </div>
        <div className="productFormBox reportBatches settings">
          {loading ? (
            <FormatLoader />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="exportRow">
                  <div className="d-flex align-items-center">
                    <div className="button_grp">
                      <button
                        className={selected === "Scans" ? "active" : ""}
                        onClick={() => changeView("Scans")}
                        style={{ minWidth: "150px" }}
                      >
                        Scans
                      </button>
                      <button
                        className={selected === "Counterfeit" ? "active" : ""}
                        onClick={() => changeView("Counterfeit")}
                        style={{ minWidth: "150px" }}
                      >
                        Counterfeit
                      </button>
                      <button
                        className={selected === "Employee" ? "active" : ""}
                        onClick={() => changeView("Employee")}
                        style={{ minWidth: "150px" }}
                      >
                        Staff
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {selected === "Scans" && (
                <form className="w-100" onSubmit={handleScanSubmit}>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <label>Scans Id Format</label>
                    {idData?.scan ? (
                      <InputTextFeild
                        type="text"
                        placeholder={`Enter Scans Id`}
                        name="scan"
                        value={idData?.scan}
                        disabled={idData?.scan}
                      />
                    ) : (
                      <InputTextFeild
                        type="text"
                        placeholder={`Enter Scans Id`}
                        name="scan"
                        value={
                          editIdData?.scan?.charAt(0).toUpperCase() +
                          editIdData?.scan?.slice(1)
                        }
                        onChange={handleChange}
                        errorMessage={error && error.scan ? error.scan : ""}
                      />
                    )}
                    {!idData?.scan && (
                      <>
                        <p>
                          Please input the alphabet characters only and the
                          system will add the accending numbers preceding with
                          the defined scan id format above
                        </p>
                        <h5>
                          Note: Once entered it will never be changed so kindly
                          make sure you are entering the righ format.
                        </h5>
                      </>
                    )}
                  </div>
                  {!idData.scan && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "40px" }}
                    >
                      <button
                        type="button"
                        className="customCancelBtn mt-0 btn"
                        style={{ maxWidth: "200px", marginRight: "30px" }}
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn customSubmitBtn mt-0"
                        style={{ maxWidth: "200px" }}
                        disabled={isUpdating}
                      >
                        {isUpdating ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Add
                          </>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  )}
                </form>
              )}
              {selected === "Counterfeit" && (
                <form className="w-100" onSubmit={handleCounterSubmit}>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <label>Counterfeit Id Format</label>
                    {idData?.counterfeit ? (
                      <InputTextFeild
                        type="text"
                        placeholder={`Enter Counterfeit Id`}
                        name="counterfeit"
                        value={idData?.counterfeit}
                        disabled={idData?.counterfeit}
                      />
                    ) : (
                      <InputTextFeild
                        type="text"
                        placeholder={`Enter Counterfeit Id`}
                        name="counterfeit"
                        value={
                          editIdData?.counterfeit?.charAt(0).toUpperCase() +
                          editIdData?.counterfeit?.slice(1)
                        }
                        onChange={handleChange}
                        errorMessage={
                          error && error.counterfeit ? error.counterfeit : ""
                        }
                      />
                    )}

                    {!idData.counterfeit && (
                      <>
                        <p>
                          Please input the alphabet characters only and the
                          system will add the accending numbers preceding with
                          the defined counterfeit id format above
                        </p>
                        <h5>
                          Note: Once entered it will never be changed so kindly
                          make sure you are entering the righ format.
                        </h5>
                      </>
                    )}
                  </div>
                  {!idData?.counterfeit && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "40px" }}
                    >
                      <button
                        type="button"
                        className="customCancelBtn mt-0 btn"
                        style={{ maxWidth: "200px", marginRight: "30px" }}
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn customSubmitBtn mt-0"
                        style={{ maxWidth: "200px" }}
                        disabled={isUpdating}
                      >
                        {isUpdating ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Add
                          </>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  )}
                </form>
              )}
              {selected === "Employee" && (
                <form className="w-100" onSubmit={handleEmpSubmit}>
                  <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                    <label>Staff Id Format</label>

                    {idData?.employee ? (
                      <InputTextFeild
                        type="text"
                        placeholder={`Enter Staff Id`}
                        name="employee"
                        value={idData?.employee}
                        disabled={idData?.employee}
                      />
                    ) : (
                      <InputTextFeild
                        type="text"
                        placeholder={`Enter Staff Id`}
                        name="employee"
                        value={
                          editIdData?.employee?.charAt(0).toUpperCase() +
                          editIdData?.employee?.slice(1)
                        }
                        onChange={handleChange}
                        errorMessage={
                          error && error.employee ? error.employee : ""
                        }
                      />
                    )}

                    {!idData?.employee && (
                      <>
                        <p>
                          Please input the alphabet characters only and the
                          system will add the accending numbers preceding with
                          the defined Staff id format above
                        </p>
                        <h5>
                          Note: Once entered it will never be changed so kindly
                          make sure you are entering the righ format.
                        </h5>
                      </>
                    )}
                  </div>
                  {!idData?.employee && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{ marginTop: "40px" }}
                    >
                      <button
                        type="button"
                        className="customCancelBtn mt-0 btn"
                        style={{ maxWidth: "200px", marginRight: "30px" }}
                        onClick={onCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn customSubmitBtn mt-0"
                        style={{ maxWidth: "200px" }}
                        disabled={isUpdating}
                      >
                        {isUpdating ? (
                          <>
                            <i className="fa fa-spinner fa-spin"></i> Add
                          </>
                        ) : (
                          "Add"
                        )}
                      </button>
                    </div>
                  )}
                </form>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Idformats;
