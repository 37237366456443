import React from "react";
import Chart from "react-apexcharts";
// import { Chart, HorizontalBar } from 'react-chartjs-2';

export const UsersGraphOSType = ({ dataset }) => {
  const { labels = [], value = [] } = dataset || {};
  const options = {
    chart: {
      id: "UsersGraphOSType",
    },
    labels: labels,
    colors: ["#246A18", "#F49E1D", "#C60F0F", "#344054"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "right",
      horizontalAlign: "center",
      floating: false,
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };
  const series = value;

  return <Chart options={options} series={series} type="donut" width="100%" />;
};
