import React from "react";
import Skeleton from "react-skeleton-loader";
const getColumns = (count) => {
  const columns = [];
  for (let i = 0; i < count; i++) {
    columns.push(
      <div className="col-md-3 mb-4" key={i}>
        <div className="staffGrid">
          <div className="userPic">
            <Skeleton color="#e5e9ef" height={"70px"} minWidth={"70px"} />
          </div>
          <h5>
            {" "}
            <Skeleton color="#e5e9ef" height={"20px"} minWidth={"100%"} />
          </h5>
          <div className="card_id" style={{ background: "transparent" }}>
            <Skeleton color="#e5e9ef" height={"38px"} minWidth={"148px"} />
          </div>
          <div className="contact_info" style={{marginBottom: "17px"}}>
            <div className="d-flex align-items-center justify-content-center mb-20px">
              <Skeleton color="#e5e9ef" height={"20px"} minWidth={"100%"} />
            </div>
            <div className="d-flex align-items-center justify-content-center mb-20px">
              <Skeleton color="#e5e9ef" height={"20px"} minWidth={"100%"} />
            </div>
            <div className="d-flex align-items-center justify-content-center mb-20px">
              <Skeleton color="#e5e9ef" height={"20px"} minWidth={"100%"} />
            </div>
          </div>
          <div className="buttonsBox">
            <Skeleton color="#e5e9ef" height={"34px"} Width={"200px"} />
          </div>

          {/* <div className="cat_desc">
            <div className="num_head">
              <h4 className="head_name">
                <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
              </h4>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
            <div className="date_head">
              <h4 className="head_name rt-mg">
                <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
              </h4>
              <Skeleton color="#e5e9ef" height={"25px"} width={"100%"} />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
  return columns;
};
export const CategoryCardStaff = ({ columns = 8 }) => (
  <>{getColumns(columns)}</>
);
