import React from "react";
import { CounterfeitRow } from "./CounterfeitRow";
import { TableLoader } from "../../components/loader/Resusable/Table";
import noData from "../../../assets/img/noData.svg";
import InputCheckbox from "../form/InputCheckbox";
import rightPageArrow from "../../../assets/img/rightPageArrow.svg";
import leftPageArrow from "../../../assets/img/leftPageArrow.svg";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";

export const ListComponent = ({
  counterfeits,
  isFilterApplied,
  isLoading,
  viewHandler,
  setFilter,
  sortBy,
  order,
}) => {
  return (
    <div className="tabel-outer">
      <div className="table-responsive fixed-height-table">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table
            className="table batchTable counterfeitTable"
            width="100%"
            cellSpacing="0"
          >
            <thead className="pad-top">
              <tr className="batchesHeader">
                {/* <th>
                    <InputCheckbox 
                        id='customCheck'
                        name='islogged'
                        value=""
                      />
                  </th> */}
                {[
                  { label: "Scan/Report ID", value: "scanId" },
                  { label: "Batch Number", value: "batchNumber" },
                  { label: "Product Name", value: "productName" },
                  { label: "assignee", value: "assigneeName" },
                  { label: "Status", value: "status" },
                ].map(({ label, value }) => (
                  <th key={value} style={{ minWidth: "170px" }}>
                    {label}{" "}
                    <span className={"cursor-pointer"}>
                      {/* <span
                        className={`th-up ${
                          order === "asc" && sortBy === value ? "active-ac" : ""
                        }`}
                        onClick={() => setFilter(value, "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === value
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter(value, "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span> */}
                      {/* <img
                        src={dropDown}
                        onClick={() => setFilter(value, "desc")}
                        alt=""
                      /> */}

                      <img
                        src={
                          sortBy === value
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setFilter(
                            value,
                            sortBy === value
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                ))}
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {counterfeits && counterfeits.length ? (
                counterfeits.map((counterfeit, index) => (
                  <CounterfeitRow
                    key={index}
                    counterfeitData={counterfeit}
                    viewHandler={() => viewHandler(counterfeit._id)}
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={8} className={"text-center image-no-data-xs nodataTablePosition"}>
                    {isFilterApplied ? (
                      <>
                        <img src={noData} alt="" />
                        <p>The data you're looking is not available at this moment</p>
                      </>
                    ) : (
                      <>
                        <img src={noData} alt="" />
                        <p>The data you're looking is not available at this moment</p>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {/*{!isLoading && totalRecords > limit ? (
          <div className="float-right d-flex align-items-center">
            <button className="paginationArrows">
              <img src={leftPageArrow} />
            </button>
            <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
            />
            <button className="paginationArrows">
              <img src={rightPageArrow} />
            </button>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
