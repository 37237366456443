import React, { useState } from "react";
import InputTextFeild from "../../form/InputTextFeild";
import crossIcon from "../../../../assets/img/closeModal.svg";
import dltIcon from "../../../../assets/img/trash-22.svg";
import info_btn from "../../../../assets/img/info_btn.svg";
import plusIcon from "../../../../assets/img/plusIconWhite.svg";
// import { Button, Modal } from "react-bootstrap";
import googleIcon from "../../../../assets/img/email_otp.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import OTPInput from "otp-input-react";
import { emailInfo } from "../../../../validators/emailvalid";
import { PasswordSettingLoader } from "../../loader/HelpLoaders/PasswordSettingLoader";
import DeleteBackup from "./DeleteBackup";

function EmailOtp({ clientEdit, pageLoader, getProfile }) {
  const popover = (
    <Popover className="tooltipCustom" id="popover-basic">
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        Add your email number for additional authentication, it will help secure
        your account from unauthorised access.
      </Popover.Body>
    </Popover>
  );

  const [emailError, setEmailError] = useState(false);
  const [emailLoader, setEmailLoader] = useState(false);
  const [otp, setOtp] = useState(false);
  const [otpEdit, setOtpEdit] = useState(false);
  const [PhoneShow, setPhoneShow] = useState(false);
  const [backupEmail, setBackupEmail] = useState();
  const [password, setPassword] = useState();
  const [deleteShow, setDeleteShow] = useState();
  const [deleteLoader, setDeleteLoader] = useState();
  const [deleteError, setDeleteError] = useState();

  const passwordChange = (event) => {
    const { value } = event.target;
    setPassword(value.trimLeft());
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setBackupEmail(value.trimLeft());
  };

  const otpChange = (value) => {
    setOtpEdit(value);
  };

  const emailSubmit = async (event) => {
    event && event.preventDefault();
    let data = {
      backupEmail: backupEmail,
      // secondEmail: backupEmail,
    };
    const { isValid, errors } = emailInfo(data);
    if (isValid) {
      setEmailError();
      setEmailLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ADD_CLIENT_EMAIL.service,
        ApiRoutes.ADD_CLIENT_EMAIL.url,
        ApiRoutes.ADD_CLIENT_EMAIL.method,
        ApiRoutes.ADD_CLIENT_EMAIL.authenticate,
        undefined,
        data
      );

      if (response && response.isError) {
        toast.error(response.messages[0]);
        setEmailLoader(false);
      } else {
        const data = response?.data?.data;
        setOtp(data?data:response.messages[0]);
        setEmailLoader(false);
        toast.success(response.messages[0]);
      }
    } else {
      setEmailLoader(false);
      setEmailError(errors);
    }
  };

  const emailVerfiy = async (event) => {
    event && event.preventDefault();

    setEmailLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_EMAIL.service,
      ApiRoutes.VERIFY_EMAIL.url,
      ApiRoutes.VERIFY_EMAIL.method,
      ApiRoutes.VERIFY_EMAIL.authenticate,
      undefined,
      {
        backupEmail: backupEmail,
        // secondEmail: backupEmail,
        otp: otpEdit,
      }
    );
    if (response && response.isError) {
      toast.error(response.messages[0]);
      setOtpEdit();
      setEmailLoader(false);
    } else {
      setEmailLoader(false);
      setPhoneShow(false);
      setOtp();
      setOtpEdit();
      setBackupEmail();
      toast.success(response.messages[0]);
      getProfile();
    }
  };

  const deleteMfa = async () => {
    if (password) {
      setDeleteLoader(true);
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_EMAIL.service,
        ApiRoutes.DELETE_EMAIL.url,
        ApiRoutes.DELETE_EMAIL.method,
        ApiRoutes.DELETE_EMAIL.authenticate,
        undefined,
        {
          backupEmail: deleteShow,
          password: password,
        }
      );
      setDeleteLoader(false);
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        toast.success(response.messages[0]);
        setDeleteShow(false);
        setDeleteError();
        setPassword();
        getProfile();
      }
    } else {
      setDeleteError("Please enter password");
    }
  };

  const onPopupCancel = () => {
    setOtp();
  };

  const close = () => {
    setPhoneShow(false);
    setOtp();
    setBackupEmail();
  };
  const onDeleteClose = () => {
    setPassword();
    setDeleteShow(false);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div
            className="productFormBox passSettings profileMainBox smsAuth"
            style={{ marginBottom: "30px" }}
          >
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ borderBottom: "1px solid #F1F4F3" }}
            >
              <h4 style={{ borderBottom: "none" }}>Email Based OTP</h4>
              <OverlayTrigger placement="left" overlay={popover}>
                <Button className="infoBtn" variant="">
                  <img src={info_btn} />
                </Button>
              </OverlayTrigger>
            </div>

            <div className="row">
              <div className="col-md-12">
                {pageLoader ? (
                  <PasswordSettingLoader />
                ) : (
                  <div className="row">
                    <div className="col-md-12 googleAuthenticator">
                      <p>
                        Put a double security check by adding your email with
                        your account to avoid any unauthorized access
                      </p>
                      {!clientEdit?.backupEmail?.length > 0 ? (
                        <div className="d-flex align-items-center justify-content-center flex-column otp_form mt-4 qrCodeBox mb-4">
                          <img src={googleIcon} alt="" />
                          <h5 className="mb-2" style={{ fontWeight: "500" }}>
                            No Email Added Yet!
                          </h5>
                        </div>
                      ) : (
                        <ul className="listing mt-0 mb-0">
                          {clientEdit?.backupEmail?.map((info, index) => (
                            <li key={index}>
                              {info}
                              <Button
                                variant=""
                                onClick={() => setDeleteShow(info)}
                                className=""
                              >
                                <img src={dltIcon} />
                              </Button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className="col-md-12 text-center">
                      <Button
                        className="addEmailBtn"
                        variant=""
                        onClick={() => setPhoneShow(true)}
                      >
                        <img src={plusIcon} /> Add Email
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="emailAddresssModal" show={PhoneShow} onHide={close}>
        <Modal.Header>
          <Modal.Title>Add Email Address</Modal.Title>
          <div>
            <Button variant="" onClick={otp ? onPopupCancel : close}>
              <img src={crossIcon} />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {!otp ? (
            <>
              <p>You can use this email address to recover your account</p>
              <form style={{ maxWidth: "455px" }}>
                <InputTextFeild
                  placeholder="Enter Email Address here"
                  value={backupEmail}
                  onChange={handleChange}
                  name="secondEmail"
                  errorMessage={emailError?.backupEmail}
                />
                <Button
                  disabled={emailLoader}
                  onClick={emailSubmit}
                  variant=""
                  className="otp_btn"
                >
                  {emailLoader ? (
                    <>
                      <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                      Get OTP
                    </>
                  ) : (
                    <> Get OTP</>
                  )}
                </Button>
              </form>
            </>
          ) : (
            <form className="otp_emailForm">
              <p>Verify your email</p>
              <div className="d-flex align-items-center justify-content-start otp_form">
                <OTPInput
                  value={otpEdit}
                  onChange={otpChange}
                  OTPLength={6}
                  otpType="number"
                  inputClassName="form-control p-0"
                />
              </div>
              {/* <p className="otp_count" style={{marginTop:"8px"}}>Resend OTP in 00:10</p> */}
              <Button
                variant=""
                type="button"
                disabled={emailLoader}
                onClick={onPopupCancel}
                className="cancelBtn"
              >
                Cancel
              </Button>
              <Button
                disabled={emailLoader}
                onClick={emailVerfiy}
                variant=""
                className="otp_btn"
              >
                {emailLoader ? (
                  <>
                    <i className="fa fa-spinner fa-spin test-spin mr-2"></i>{" "}
                    Verify
                  </>
                ) : (
                  <> Verify</>
                )}
              </Button>
            </form>
          )}
        </Modal.Body>
      </Modal>
      <DeleteBackup
        password={password}
        deleteShow={deleteShow}
        deleteClose={onDeleteClose}
        passwordChange={passwordChange}
        error={deleteError}
        deleteMfa={deleteMfa}
        loader={deleteLoader}
        title={"Delete Email Address"}
      />
    </>
  );
}

export default EmailOtp;
