import React from "react";
import "./index.css";
import InputCheckbox from "../form/InputCheckbox";
import ProductRow from "./ProductRow";
import noData from "../../../assets/img/noData.svg";
import { TableLoader } from "../../components/loader/Resusable/Table";
import dropDown from "../../../assets/img/dropDown-long_icon.svg";
import dropDownActive from "../../../assets/img/dropDown-long_icon_active.svg";
import dropUpActive from "../../../assets/img/dropDown-long_icon_up_active.svg";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../config";
import addIcon from "../../../assets/img/addIcon.svg";

export const ProductListComponent = ({
  products,
  isLoading,
  onExpand,
  onEdit,
  onDelete,
  setFilter,
  sortBy,
  order,
  productDetails,
  filtersOption,
}) => {
  return (
    <>
      <div className="tabel-outer">
        <div className="table-responsive fixed-height-table">
          {isLoading ? (
            <TableLoader columns={5} rows={10} />
          ) : (
            <table className="table batchTable productTable intableCheckbox">
              <thead>
                <tr className="batchesHeader">
                  {/* <th>
                                    <InputCheckbox 
                                        id='customCheck'
                                        name='islogged'
                                        value=""
                                    />
                                </th> */}
                  <th className="table-header" style={{ minWidth: "220px" }}>
                    Number{" "}
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "productNumber"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setFilter(
                            "productNumber",
                            sortBy === "productNumber"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  <th style={{ minWidth: "300px" }}>
                    Name{" "}
                    <span className={"cursor-pointer"}>
                      <img
                        src={
                          sortBy === "productName"
                            ? order === "desc"
                              ? dropDownActive
                              : dropUpActive
                            : dropDown
                        }
                        alt=""
                        onClick={() =>
                          setFilter(
                            "productName",
                            sortBy === "productName"
                              ? order === "asc"
                                ? "desc"
                                : "asc"
                              : "asc"
                          )
                        }
                      />
                    </span>
                  </th>
                  {/* {[
                    { label: "Number", value: "productNumber" },
                    { label: "Name", value: "productName" },
                  ].map(({ label, value }) => (
                    <th key={value} style={{minWidth: '190px'}}>
                      {label}{' '}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === value ? "active-ac" : ""
                            }`}
                          onClick={() => setFilter(value, "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === value
                              ? "active-ac"
                              : ""
                            }`}
                          onClick={() => setFilter(value, "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                        <img src={dropDown} alt="" />
                      </span>
                    </th>
                  ))} */}
                  <th>Description</th>
                  {/* <th>Product Image</th>
                                <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {/* <CounterfeitRow /> */}
                {products && products.length ? (
                  products.map((product, index) => (
                    <ProductRow
                      key={index}
                      productData={product}
                      onExpand={() => onExpand(index)}
                      onEdit={() => onEdit(product.id)}
                      onDelete={() =>
                        onDelete(
                          product.id,
                          product.productName,
                          product.totalBatches
                        )
                      }
                    />
                  ))
                ) : (
                  <tr className="no-tr">
                    <td colSpan={8} className={"text-center image-no-data-xs nodataTablePosition"}>
                      <img src={noData} alt="" />
                      <p>
                        No Product Found,{" "}
                        <Link
                          to={AppRoutes.ADD_PRODUCT}
                          className="text-decoration-none"
                        >
                          {" "}
                          Click here
                        </Link>{" "}
                        to Add New Product
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

// export default ProductListComponent;
