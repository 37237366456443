export const AppConfig = {
  FILES_ENDPOINT: process.env.REACT_APP_FILES_ENDPOINT, // For the static files path
  APP_ENDPOINT: process.env.REACT_APP_ENDPOINT,
  // SOCKET_ENDPOINT: process.env.REACT_APP_SOCKET_ENDPOINT,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY
  // API_VERSION: process.env.REACT_APP_API_VERSION,
  // DEFAULT_DATE_FORMAT: process.env.REACT_APP_DEFAULT_DATE_FORMAT,
};
