import React from "react";
import Skeleton from "react-skeleton-loader";
export const DashboardWidgetLoader = () => (
  <>
    <div className='card  shadow h-100 card-py-2'>
      <div className='card-body'>
        <div className='row no-gutters align-items-center'>
          <div className='col-auto'>
            <Skeleton color="#e5e9ef" />
          </div>
        </div>
        <div className='row no-gutters align-items-center'>
          <div className='col-auto'>
            <Skeleton color="#e5e9ef" />
          </div>
        </div>
        <div className='row no-gutters align-items-center'>
          <div className='col-auto'>
            <Skeleton color="#e5e9ef" />
          </div>
        </div>
      </div>
    </div>
  </>
);
