import React from "react";
import Skeleton from "react-skeleton-loader";

export const ReportCounterfeitCard = () => (
  <>
    {" "}
    <div className='graphDataCard' style={{paddingBottom: "31px"}}>
      <div className='card-body p-0'>
        <Skeleton height={"20px"} width={"100px"} color ="#e5e9ef" /> <br />
        <div className="d-flex align-items-center justify-content-between mt-1">
          <Skeleton height={"20px"} width={"100px"} color ="#e5e9ef" />
          <Skeleton height={"20px"} width={"50px"} color ="#e5e9ef" />
        </div>
        <Skeleton height={"20px"} width={"100%"} color ="#e5e9ef" />
      </div>
    </div>
  </>
);
