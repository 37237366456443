import React from "react";
import Skeleton from "react-skeleton-loader";

export const PasswordSettingLoader = () => (
  <>
    <div className="row">
      <div className="col-md-12">
        <p>
          <Skeleton color="#e5e9ef" height={"17px"} width={"60%"} />
        </p>
      </div>
      <div className="col-md-12">
        <form className="profileForm">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`addBatch-Labels`}>
                      <Skeleton
                        color="#e5e9ef"
                        height={"17px"}
                        width={"120px"}
                      />
                    </label>
                    <Skeleton color="#e5e9ef" height={"45px"} width={"100%"} />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`addBatch-Labels`}>
                      <Skeleton
                        color="#e5e9ef"
                        height={"17px"}
                        width={"120px"}
                      />
                    </label>
                    <Skeleton color="#e5e9ef" height={"45px"} width={"100%"} />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className={`addBatch-Labels`}>
                      <Skeleton
                        color="#e5e9ef"
                        height={"17px"}
                        width={"120px"}
                      />
                    </label>
                    <Skeleton color="#e5e9ef" height={"45px"} width={"100%"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="d-flex mt-3 align-items-center justify-content-center">
                <>
                  <div className="d-flex align-items-center justify-content-center">
                    <Skeleton color="#e5e9ef" height={"45px"} width={"180px"} />
                    <Skeleton color="#e5e9ef" height={"45px"} width={"180px"} />
                  </div>
                </>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </>
);
