import React from "react";
import { Breadcrumbs } from "../../partial/Breadcrumbs";
import { useLocation } from "react-router";
import VideoCard from "./VideoCard";
import { ApiHelper } from "../../../../helpers";
import { ApiRoutes } from "../../../../config";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { TutorialsLoader } from "../../loader/HelpLoaders/TutorialsLoader";

function Tutorials() {
  const location = useLocation();
  const [pageLoader, setPageLoader] = useState();
  const [videoData, setVideoData] = useState();

  useEffect(() => {
    getVideoData();
  }, []);

  const getVideoData = async () => {
    setPageLoader(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_VIDEO_TUTORIAL.service,
      ApiRoutes.GET_VIDEO_TUTORIAL.url,
      ApiRoutes.GET_VIDEO_TUTORIAL.method,
      ApiRoutes.GET_VIDEO_TUTORIAL.authenticate,
      undefined
    );
    setPageLoader(false);

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const data = response?.data?.data;
      setVideoData(data);
    }
  };
  console.log("videoData", videoData);
  return (
    <>
      <div className="container-fluid fluid-pd-2 pb-5">
        <div className="tableFilter overflow-hidden add_batch d-flex align-items-center justify-content-between pl-0 mb-0 mt-0">
          <h3 className="mt-0 breadcrumbDark mb-0 custombreadcrumbMargin">
            <Breadcrumbs pathname={location.pathname} />
          </h3>
        </div>

        <div className="productFormBox help tutorials">
        {pageLoader ? (
            <TutorialsLoader />
          ) : (
          <div className="row">
            <div className="col-md-12 titleRow">
              <h4>Learn how to use this portal</h4>
              <p>
                Follow our videos and articles to get friendly with this portal
              </p>
            </div>
            {videoData?.videos?.map((video, index) => (
              <div className="col-md-6 col-lg-4">
                <VideoCard video={video} index={index} />
              </div>
            ))}

            {/* <div className="col-md-6 col-lg-4">
                <VideoCard />
            </div>
            <div className="col-md-6 col-lg-4">
                <VideoCard />
            </div>
            <div className="col-md-6 col-lg-4">
                <VideoCard />
            </div> */}
          </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Tutorials;
