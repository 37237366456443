import React from "react";
import Skeleton from "react-skeleton-loader";

export const ManagePermissionLoader = () => (
  <>
    <div className="card permission-card mt-3 mb-5">
            <form className="user_add m-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="row overflow">
                    <div className="col-md-3">
                      <div className="form-group">
                        <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                        <Skeleton color ="#e5e9ef" height={"48px"} width={"100%"}/>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                        <Skeleton color ="#e5e9ef" height={"48px"} width={"100%"}/>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/>
                        <Skeleton color ="#e5e9ef" height={"48px"} width={"100%"}/>
                      </div>
                    </div>

                    <div className="col-md-3">
                        {/* <Skeleton color ="#e5e9ef" height={"15px"} width={"60%"}/> */}
                        <div style={{height:"25px", width: "100%"}}></div>
                        <Skeleton color ="#e5e9ef" height={"48px"} width={"100%"}/>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
  </>
);
